import { Injectable } from '@angular/core';
import { Store }      from '@ngxs/store';

import { SettingsService }   from '@settings/services/settings.service';
import { HasChangesService } from '@features/models';

@Injectable()
export class SettingsSendingLogicChangesService implements HasChangesService {
   constructor(private store: Store,
               private _settingsService: SettingsService) { }

  public hasChanges(): boolean {
    const settingsSendingLogicOriginal = this.store.selectSnapshot(state => state.settingsSendingLogic.settingsSendingLogicOriginal);
    const settingsSendingLogicForm = this.store.selectSnapshot(state => state.settingsSendingLogic.settings.model);

    if (settingsSendingLogicOriginal) {
      const originalRules = settingsSendingLogicOriginal;
      const formSettings = settingsSendingLogicForm;

      return (
        settingsSendingLogicOriginal &&
        (
          originalRules?.length !== formSettings?.length
          || settingsSendingLogicOriginal.quietHoursActive !== formSettings.quietHoursActive
          || settingsSendingLogicOriginal.quietHoursFrom !== formSettings.quietHoursFrom
          || settingsSendingLogicOriginal.messagesThrottlingActive !== formSettings.messagesThrottlingActive
          || settingsSendingLogicOriginal.messagesThrottlingDisplayType
             !== formSettings.messagesThrottlingDisplayType
          || this._settingsService.secondsConverter(

            settingsSendingLogicOriginal.messagesThrottlingDelay,
            settingsSendingLogicOriginal.messagesThrottlingDisplayType,
            false)
             !== formSettings.messagesThrottlingDelay
          || settingsSendingLogicOriginal.maxMessagesPerMonthActive !== formSettings.maxMessagesPerMonthActive
          || settingsSendingLogicOriginal.maxMessagesPerMonthValue !== formSettings.maxMessagesPerMonthValue
          || settingsSendingLogicOriginal.maxMessagePriceActive !== formSettings.maxMessagePriceActive
          || settingsSendingLogicOriginal.maxMessagePriceValue !== formSettings.maxMessagePriceValue
        )
      );
    }
  }
}
