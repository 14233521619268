import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isMessagePriceWarning',
})
export class IsMessagePriceWarningPipe implements PipeTransform {

  transform(settingsData, messagePrice: number): boolean {
    return messagePrice
      && settingsData
      && Object.keys(settingsData).length
      && settingsData?.maxMessagePriceActive
      && settingsData?.maxMessagePriceValue > 0
      && messagePrice >= (0.9 * settingsData?.maxMessagePriceValue)
      && messagePrice < settingsData?.maxMessagePriceValue;
  }
}
