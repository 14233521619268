import { Pipe, PipeTransform } from '@angular/core';
import { Observable, timer }   from 'rxjs';
import { map }                 from 'rxjs/operators';
import moment                  from 'moment';

@Pipe({
  name: 'updateTime',
})
export class UpdateTimePipe implements PipeTransform {

  transform(time: string, timeInterval: number = 1): Observable<string> {
    return timer(0, timeInterval * 1000).pipe(
      map(() => {
        const diffInSeconds = moment().diff(time, 'seconds');

        let residual = diffInSeconds;
        let diffInMinutes = '';
        let diffInHours = '';
        let diffInDays = '';

        const days = Math.floor(residual / (24 * 60 * 60));
        residual -= days * 24 * 60 * 60;
        const hours = Math.floor(residual / (60 * 60));
        residual -= hours * 60 * 60;
        const minutes = Math.floor(residual / 60);

        if (days) {
          diffInDays = days === 1 ? `${days} day ` : `${days} days `;
        }

        if (hours) {
          diffInHours = hours === 1 ? `${hours} hour ` : `${hours} hours `;
        }

        if (minutes) {
          diffInMinutes = minutes === 1 ? `${minutes} minute` : `${minutes} minutes`;
        }

        if (diffInSeconds < 60) {
          return '< 1 minute';
        } else if (diffInSeconds >= 60 && diffInSeconds < 3600) {
          return diffInMinutes;
        } else if (diffInSeconds >= 3600 && diffInSeconds < 86400) {
          return diffInHours;
        } else if (diffInSeconds >= 86400) {
          return diffInDays;
        }
      }),
    );
  }

}
