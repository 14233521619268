import { FormGroup, ValidatorFn } from '@angular/forms';
import { Store }                  from '@ngxs/store';
import { CurrencyService } from '@core/services';

export function PaymentAmountValidator(store: Store, currencyService: CurrencyService): ValidatorFn {
  return (fg: FormGroup) => {
    const autoRechargeSpent = currencyService.priceToNumber(store.selectSnapshot(state => state.merchant.autoRechargeSpent));
    const autoRechargeAmount = fg.get('autoRechargeAmount');
    const autoRechargeTotalAmountLimit = fg.get('autoRechargeTotalAmountLimit');

    if (currencyService.priceToNumber(autoRechargeTotalAmountLimit.value) < +autoRechargeSpent) {
      autoRechargeTotalAmountLimit.markAsTouched();
      autoRechargeTotalAmountLimit.setErrors({ ...autoRechargeTotalAmountLimit.errors });
      return { totalAmountInvalidDueToAutoRechargeSpent: true, autoRechargeSpentValue: autoRechargeSpent };
    } else {
      autoRechargeTotalAmountLimit.markAsUntouched();
    }

    if (currencyService.priceToNumber(autoRechargeTotalAmountLimit.value) < currencyService.priceToNumber(autoRechargeAmount.value)) {
      autoRechargeTotalAmountLimit.markAsTouched();
      autoRechargeTotalAmountLimit.setErrors({ ...autoRechargeTotalAmountLimit.errors });
      return { totalAmountInvalidDueToAutoRechargeAmount: true };
    } else {
      if (!Object.keys(autoRechargeTotalAmountLimit.errors || {}).length) {
        autoRechargeTotalAmountLimit.setErrors(null);
      }
      autoRechargeTotalAmountLimit.markAsUntouched();
    }

    return null;
  };
}
