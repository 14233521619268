import { Component, OnInit, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { NotificationsService, NotificationType }               from 'angular2-notifications';
import { Notification }                                         from 'angular2-notifications/lib/interfaces/notification.type';
import { Router }                                               from '@angular/router';
import { delay, takeUntil }                                     from 'rxjs/operators';
import { of, Subject }                                          from 'rxjs';
import { Store }                                                from '@ngxs/store';

import { NotiService }                                                     from '@notifications/services';
import { NotificationEventType, NotiParams }                               from '@notifications/models';
import { AddCurrentlyShownNotification, RemoveCurrentlyShownNotification } from '@store/actions';

@Component({
  selector: 'k-notification-toast',
  templateUrl: './notification-toast.component.html',
  styleUrls: ['./notification-toast.component.scss'],
})
export class NotificationToastComponent implements OnInit, OnDestroy {
  @ViewChild('notification') notification: TemplateRef<any>;

  public notifications: { [id: number]: Notification } = {};
  public msgChunks: string[];
  public linkIndexPattern: RegExp;
  public isCheckoutDoneTrigger: boolean = false;

  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(private store: Store,
              private notificationsService: NotificationsService,
              private _notiService: NotiService,
              private router: Router) { }

  ngOnInit() {
    this.linkIndexPattern = this._notiService.getLinkIndexPattern();
    this._listenToAutoCloseNotification();
    this._listenToNewNotifications();
  }

  public onClose(id: number, triggerId: number): void {
    this._notiService.emitCloseNotificationById$.next(triggerId);

    this.notificationsService.remove(this.notifications[id].id);
    this.store.dispatch(new RemoveCurrentlyShownNotification(triggerId));

    this._sendEvent(id, triggerId, 'close');
    this._showNextNotification();
  }

  public onAction(id: number, triggerId: number, linkTo?: string): void {
    this.notificationsService.remove(this.notifications[id].id);
    this.store.dispatch(new RemoveCurrentlyShownNotification(triggerId));

    this._sendEvent(id, triggerId, 'linkClick');
    this._showNextNotification();
    if (linkTo) this.router.navigate([linkTo]);
  }

  private _listenToAutoCloseNotification(): void {
    this._notiService.removeNotification$
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((data: NotiParams) => {
        this.onClose(data.id, data.triggerId);
      });
  }

  private _listenToNewNotifications(): void {
    this._notiService.showNotification$
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((data: NotiParams) => {
        if(data?.trigger === 'checkoutDone') {
          this.isCheckoutDoneTrigger = true;
        }
        if (data?.linkTemplates) {
          this.isCheckoutDoneTrigger = false;
          this.msgChunks = this._notiService.getMessageChunks(data.message);
        }

        const context: any = {
          id: data.id,
          triggerId: data.triggerId,
          title: data.title,
          message: data.message,
          icon: data.icon,
          cancelButton: data.cancelButton,
          actionButton: data.actionButton,
          linkTemplates: data.linkTemplates,
        };

        const timeOut = data.timer ? data.timer * 1000 : 0;
        const options = {
          timeOut,
        };

        const id = Object.keys(this.notifications).find((key) => key && +key === data.id);
        if (id) {
          this.notificationsService.remove(this.notifications[id].id);
        }

        this.notifications[data.id] = this.notificationsService.html(
          this.notification,
          NotificationType.Bare,
          options,
          null,
          context,
        );

        this._sendEvent(data.id, data.triggerId, 'shown');

        this.store.dispatch(new AddCurrentlyShownNotification(context));

        of(null).pipe(
          delay(timeOut + 500),
          takeUntil(this.ngUnsubscribe$),
        ).subscribe(() => {
          this.store.dispatch(new RemoveCurrentlyShownNotification(data.triggerId));
          this._sendEvent(data.id, data.triggerId, 'close');
          this._showNextNotification();
        });
      });
  }

  private _sendEvent(id, triggerId, event: NotificationEventType): void {
    this._notiService.trackEvent(id, triggerId, event).subscribe();
  }

  private _showNextNotification(): void {
    const notifications = [...this.store.selectSnapshot(state => state.notifications.remainingNotifications)];

    this._notiService.showNotifications(notifications, 1);
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
