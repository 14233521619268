export class HideHeader {
  static readonly type = '[Router Service] HideHeader';

  constructor(public payload: boolean) { }
}

export class SetIsCenteringPage {
  static readonly type = '[Router Service] SetIsCenteringPage';

  constructor(public payload: boolean) { }
}

export class SetToken {
  static readonly type = '[Response Interceptor] SetToken';

  constructor(public payload: string) { }
}

export class SetShopParams {
  static readonly type = '[Router Service] SetShopParams';

  constructor(public payload: any) { }
}

export class SetSavingProgress {
  static readonly type = '[Editor] SetSavingProgress';

  constructor(public payload: boolean) { }
}

export class SetLastLoadedChild {
  static readonly type = '[Router Service] SetLastLoadedChild';

  constructor(public payload: string) { }
}
