import { Directive, ElementRef, HostListener, Input } from '@angular/core';

interface IToggleClassDirective {
  className: string;
  parentLevel?: number;
}

@Directive({
  selector: '[kToggleClass]',
})
export class ToggleClassDirective {
  @Input() kToggleClass: IToggleClassDirective;

  constructor(private el: ElementRef) { }

  @HostListener('click')
  public onToggle(): void {
    const element = this.el.nativeElement;
    element.classList.toggle(this.kToggleClass.className);

    const parentLevel = this.kToggleClass.parentLevel;

    if (parentLevel) {
      let parentElement = element;

      for (let i = 0; i < parentLevel; ++i) {
        parentElement = parentElement.parentNode;
      }

      parentElement.classList.toggle(this.kToggleClass.className);
    }
  }
}
