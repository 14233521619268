import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxSmartModalComponent }                         from 'ngx-smart-modal';

@Component({
  selector: 'k-modal-dialog',
  templateUrl: './modal-dialog.component.html',
  styleUrls: ['./modal-dialog.component.scss'],
})
export class ModalDialogComponent implements OnInit {
  @Input() modalTitle: string;
  @Input() identifier: string;
  @Input() padding: boolean = true;
  @Input() hasFooter: boolean = true;
  @Input() closeBtnStyle: boolean = true;

  @Output() openingModal: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() dismiss: EventEmitter<any> = new EventEmitter<any>();
  @Output() closed: EventEmitter<any> = new EventEmitter<any>();
  @Output() anyClose: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() { }

  public onOpeningModal(): void {
    this.openingModal.emit({});
  }

  public onClose(modal: NgxSmartModalComponent): void {
    modal.close();
    this.cancel.emit({});
  }

  public onAnyClose(): void {
    this.anyClose.emit({});
  }

  public onDismiss(): void {
    this.dismiss.emit({});
  }

  public onCloseFinished(): void {
    this.closed.emit();
  }
}
