export const SENDING_TIME_TABS_NAME = {
  CUSTOM_DATE_NAME: 'Custom Date',
  CUSTOM_DATE_ID: 'custom',

  NOW_DATE_NAME: 'Send Now',
  NOW_DATE_ID: 'now',
};

export const BUTTON_NAME = {
  SCHEDULE: 'Schedule Campaign',
  NOW: 'Send Campaign',
};

export const sendingTimeTabsData =
  [
    {
      id: SENDING_TIME_TABS_NAME.CUSTOM_DATE_ID,
      name: SENDING_TIME_TABS_NAME.CUSTOM_DATE_NAME,
    },
    {
      id: SENDING_TIME_TABS_NAME.NOW_DATE_ID,
      name: SENDING_TIME_TABS_NAME.NOW_DATE_NAME,
    },
  ];

export const CAMPAIGN_TYPES = {
  IN_PROGRESS: 'In Progress',
  SCHEDULED: 'Scheduled',
  DRAFT: 'Draft',
  COMPLETED: 'Completed',
};

export const CAMPAIGNS_ERRORS = {
  name_whitespaces: `Message shouldn't contain only whitespaces`,
  name_required: 'This field is required or has an invalid value',
  already_exists: 'A Campaign with the same name already exists. Please choose a different name.',
  whitespaces: `Field shouldn't contain only whitespaces`,
};
