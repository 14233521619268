export enum Breadcrumbs {
  HOME = 'Home',
  KEYWORDS = 'Keywords',
  FLOWS = 'Flows',
  FLOW_ADD = 'Add Flow',
  FLOW_TEMPLATE_LIBRARY = 'Template Library',
  FLOW_EDITOR = 'Editor',
  MOBILE_POPUPS = 'Mobile Popups',
  MOBILE_POPUP_EDIT = 'Editor',
  INBOX = 'Inbox',
  REPLY_RESPONSE = 'Reply',
  CHECKOUT = 'Checkout',
  SHAREABLE_LINK = 'Shareable Links',
  SHAREABLE_LINK_WIZARD = 'Wizard',
  SHAREABLE_LINK_EDITOR = 'Editor',
  SHOPIFY_CHECKOUT = 'Shopify Checkout',
  EXAMPLES = 'Examples',
  SETTINGS = 'Settings',
  SEGMENTS = 'Segments',
  SEGMENTS_EDIT = 'Editor',
  CAMPAIGNS = 'Campaigns',
  CAMPAIGNS_EDIT = 'Editor',
  CAMPAIGNS_DETAILS = 'Details',
}
