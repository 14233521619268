import {
  Component,
  OnInit,
  AfterViewInit,
  ElementRef,
  forwardRef,
  Input,
  Renderer2,
  ViewChild,
}                            from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { EnteringType, InputPadding, InputSize, InputType } from './input.model';
import { CustomControlValueAccessor }                       from '../custom-control-value-accessor';

@Component({
  selector: 'k-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
  ],
})
export class InputComponent extends CustomControlValueAccessor implements OnInit, AfterViewInit {
  @Input() type: InputType = 'text';
  @Input() allowType: EnteringType = 'any';
  @Input() min: number;
  @Input() max: number;
  @Input() size: InputSize = 'normal';
  @Input() label: string;
  @Input() helper: string;
  @Input() iconName: string;
  @Input() iconSize: number = 16;
  @Input() iconSymbol: string;
  @Input() placeholder: string;
  @Input() placeholderEnd: string;
  @Input() padding: InputPadding;
  @Input() showHide: boolean = false; // todo toggle eye icon
  @Input() readonly: boolean = false;
  @Input() prepend: string = '';
  @Input() digitsOnly: boolean = false;

  @ViewChild('input') input: ElementRef;

  public id: string;

  public get hasIcon() {
    return this.iconName || this.iconSymbol || this.showHide;
  }

  constructor(private renderer: Renderer2) {
    super();
  }

  ngOnInit() {
    this.id = this._genId();
  }

  ngAfterViewInit() {
    this.renderer.addClass(this.input.nativeElement, `k-input--${this.size}`);

    if (this.padding) {
      this.renderer.addClass(this.input.nativeElement, `k-input--padding-${this.padding}`);
    }
  }

  public focus(): void {
    this.input.nativeElement.focus();
  }

  public onInput(value): void {
    let updatedValue = value;

    if (this.type === 'number') {
      updatedValue = value.replace(',', '');
    }

    this.value = updatedValue.trim();
  }

  public onKeydown(event) {
    if (this.digitsOnly === false) {
      return true;
    }
    const key = event.key;
    return this.digitsOnly && (key.length === 1 && /\d/.test(key) || key.length > 1);
  }

  public toggleValue() {
    // todo
  }

  private _genId() {
    return `iid-${Math.round(Math.random() * 10e5)}`;
  }
}
