import { Injectable } from '@angular/core';
import { Store }      from '@ngxs/store';
import { Observable } from 'rxjs';
import { map, tap }   from 'rxjs/operators';

import { ApiService }                         from '@core/services';
import { PER_PAGE }                           from '@config';
import { SetResponsesData, SetResponsesList } from '@store/actions';
import { ResponseStatuses }                   from '../models';
import moment                                 from 'moment';
import { BaseTableService, TableColumnSort }  from '@shared/models';

@Injectable()
export class ResponsesListService implements BaseTableService {
  constructor(private store: Store, private _apiService: ApiService) { }

  public getList(
    page: number = 1,
    perPage: number = PER_PAGE,
    field: string = 'name',
    sort: TableColumnSort = 'ASC',
    filter: ResponseStatuses,
  ): Observable<any> {
    const skip: number = 0;
    const limit: number = page * perPage;
    const params = { skip, limit, order: field, sort };

    if (filter) {
      params['filter'] = filter;
    }

    return this._apiService.get('responses', params)
      .pipe(
        map((data) => {
          return {
            ...data,
            items: data.items.map(response => {
              const statuses = [response.status];
              if (response.subscribed === 'UNSUBSCRIBED') {
                statuses.push('Opted out');
              }

              response['responseStatuses'] = statuses;
              if (response.scheduledResponseDate) {
                response.scheduledResponseDate = moment(response.scheduledResponseDate).format('MMM DD h:mm A');
              }
              return response;
            }),
          };
        }),
        tap(data => {
          const meta = {
            totalCount: data.totalCount,
            unresolvedResponses: data.unresolvedResponses,
          };

          this.store.dispatch(new SetResponsesList(data.items));
          this.store.dispatch(new SetResponsesData(meta));
        }),
      );
  }
}
