import {
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable }             from '@angular/core';
import { Store }                  from '@ngxs/store';
import { Observable, throwError } from 'rxjs';
import { catchError, tap }        from 'rxjs/operators';

import { SetToken }         from '@store/actions';
import { NotiService }      from '@notifications/services';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
  constructor(private store: Store, private _notiService: NotiService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        tap((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            const token = this.store.selectSnapshot(state => state.app.token);
            if (!token) this.store.dispatch(new SetToken(event.headers.get('x-auth-token')));
          }

          if (navigator.onLine) {
            this._notiService.removeOfflineAlert();
          }
        }),
        catchError(this._formatError.bind(this)),
      );
  }

  private _formatError(errorResponse) {
    let errMsg: string;

    if (errorResponse instanceof HttpErrorResponse) {
      errMsg = errorResponse.error.errorMessage || errorResponse.error.error;
    } else {
      errMsg = errorResponse.message ? errorResponse.message : errorResponse.toString();
    }

    if (!errMsg) errMsg = 'Server is not responding';

    navigator.onLine
      ? this._notiService.error(errMsg)
      : this._notiService.showOfflineAlert();

    return throwError(errMsg);
  }
}

export const ResponseInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: ResponseInterceptor,
  multi: true,
};
