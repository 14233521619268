import { CUSTOM_ELEMENTS_SCHEMA, NgModule }        from '@angular/core';
import { CommonModule, CurrencyPipe, DecimalPipe } from '@angular/common';
import { A11yModule }                              from '@angular/cdk/a11y';
import { RouterModule }                            from '@angular/router';
import { FormsModule, ReactiveFormsModule }        from '@angular/forms';
import { CdkStepperModule }                        from '@angular/cdk/stepper';
import { NgSelectModule }                          from '@ng-select/ng-select';
import { MatTooltipModule }                        from '@angular/material/tooltip';

import { NgxSmartModalModule }  from 'ngx-smart-modal';
import { NgScrollbarModule }    from 'ngx-scrollbar';
import { NgxEmojiPickerModule } from 'ngx-emoji-moc';
import { NgxFileHelpersModule } from 'ngx-file-helpers';
import { AutosizeModule }       from 'ngx-autosize';
import { ColorPickerModule }    from 'ngx-color-picker';
import { ToastrModule }         from 'ngx-toastr';

import { toastrConfig } from '@config';

import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule }     from '@angular/material/dialog';

import { components } from './components';
import { directives } from './directives';
import { pipes }      from './pipes';
import { services }   from './service';

@NgModule({
  declarations: [
    ...components,
    ...directives,
    ...pipes,
  ],
  imports: [
    NgxEmojiPickerModule,
    CommonModule,
    A11yModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    NgxFileHelpersModule,
    NgxSmartModalModule.forRoot(),
    ToastrModule.forRoot(toastrConfig),
    NgSelectModule,
    AutosizeModule,
    ColorPickerModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatDialogModule,
    NgScrollbarModule.withConfig({
      visibility: 'hover',
    }),
    CdkStepperModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatDialogModule,
    MatTooltipModule,
  ],
  providers: [
    ...services,
    CurrencyPipe,
    DecimalPipe,
  ],
  exports: [
    ...components,
    ...directives,
    ...pipes,
    A11yModule,
    ReactiveFormsModule,
    FormsModule,
    NgxEmojiPickerModule,
    NgxFileHelpersModule,
    NgSelectModule,
    AutosizeModule,
    ColorPickerModule,
    NgScrollbarModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatDialogModule,
    CdkStepperModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatDialogModule,
    MatTooltipModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule { }
