import { Injectable } from '@angular/core';

import { Store }                  from '@ngxs/store';
import { LoaModel }               from '@legal-agreements/models';
import { HelperService }          from '../../core/services';
import { HideAlert, UnhideAlert } from '@store/actions';

@Injectable()
export class LegalService {

  constructor(private store: Store,
              private _helperService: HelperService) { }

  public updateTerms(loa: LoaModel, terms: any): any {
    const merchantInfo = this.store.selectSnapshot(state => state.merchant);
    const merchantStore = this.store.selectSnapshot(state => state.merchant.shop);
    const storeUrl = this._helperService.getMerchantDomain(merchantInfo);

    if (loa) {
      const storeLegalName: string = loa.legalName;
      const storeName: string = merchantStore.name;
      const themeId: string = merchantInfo.themeId;
      const themeEditCodeLink: string = `/admin/themes/${themeId}`;
      const storeInsertNumber: string = '12';
      const supportEmail: string = loa.email;
      const privacyLink: string = `and our <a href="${storeUrl}/policies/privacy-policy" target="_blank">Privacy Policy</a>`;
      const termsLink: string = `${storeUrl}/policies/terms-of-service`;
      const privacyPolicyLink: string = `${storeUrl}/policies/privacy-policy`;

      Object.keys(terms).map(key => {
        if (terms[key].copyText) {
          terms[key].copyText = terms[key].copyText
            .replaceAll('and our Privacy Policy', privacyLink)
            .replaceAll('[TermsAndConditions]', termsLink)
            .replaceAll('[PrivacyPolicy]', privacyPolicyLink)
            .replaceAll('[Company]', storeLegalName)
            .replaceAll('[StoreName]', storeName)
            .replaceAll('[insert number]', storeInsertNumber)
            .replaceAll('[support email]', supportEmail);
        }

        if (terms[key].form) {
          const controls = Object.entries(terms[key].form);

          controls.forEach(item => {
            item[1]['value'] = item[1]['value']
              .replaceAll('[TermsOfServiceUrl]', termsLink)
              .replaceAll('[PrivacyPolicyUrl]', privacyPolicyLink)
              .replaceAll('[StoreName]', storeName);
          });
        }

        if (terms[key].link?.href && themeId) {
         terms[key].link.href = terms[key].link.href
            .replaceAll('[ThemeEditCode]', themeEditCodeLink);
        }
      });
    }

    return terms;
  }

  public hideAlerts(alerts): void {
    const numberInProvisioning = alerts.find(alert => alert.trigger === 'numberInProvisioning');
    const numberReady = alerts.find(alert => alert.trigger === 'numberReady');

    if (numberInProvisioning && !numberReady && !numberInProvisioning.hasOwnProperty('hidden')) {
      this.store.dispatch(new HideAlert(numberInProvisioning.triggerId));
    }

    if (numberReady && !numberReady.hasOwnProperty('hidden')) {
      this.store.dispatch(new HideAlert(numberReady.triggerId));
    }
  }

  public unhideAlerts(): void {
    const alertsData = this.store.selectSnapshot(state => state.notifications.shownAlerts);
    const numberInProvisioning = alertsData.find(item => item.trigger === 'numberInProvisioning');
    const numberReady = alertsData.find(item => item.trigger === 'numberReady');

    if (numberInProvisioning && !numberReady) {
      if (numberInProvisioning?.hidden) {
        this.store.dispatch(new UnhideAlert(numberInProvisioning.triggerId));
      }
    }

    if (numberReady?.hidden) {
      this.store.dispatch(new UnhideAlert(numberReady.triggerId));
    }

  }

}
