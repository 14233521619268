import { Component, OnDestroy, OnInit }              from '@angular/core';
import { FormBuilder, FormGroup, Validators }        from '@angular/forms';
import { Select, Store }                             from '@ngxs/store';
import { Observable, of, Subject }                   from 'rxjs';
import { debounceTime, first, switchMap, takeUntil } from 'rxjs/operators';

import { UpdateMerchantShortenerSettings }   from '@store/actions';
import { SAVED_SUCCESSFULLY }                from '@core/texts';
import { MerchantInfo }                      from '@core/models';
import { HelperService, ProgressBarService } from '@core/services';
import { NotiService }                       from '@notifications/services';
import { SettingsService }                   from '@settings/services';

@Component({
  selector: 'k-settings-general',
  templateUrl: './settings-general.component.html',
  styleUrls: ['./settings-general.component.scss'],
})
export class SettingsGeneralComponent implements OnInit, OnDestroy {
  @Select(state => state.merchant.appEnabled) isAppEnabled$: Observable<MerchantInfo>;
  @Select(state => state.merchant) merchant$: Observable<MerchantInfo>;

  public settingsForm: FormGroup;
  public changedField: string = '';
  public loading: boolean = true;
  public totalCharacters: number;
  public isAppEnabling: boolean = false;
  public urlTypes: any[] = [];
  public countries = [
    {
      value: 'CA-US',
      name: 'United States & Canada',
    },
    {
      value: 'CA',
      name: 'Canada',
    },
    {
      value: 'US',
      name: 'United States',
    },
  ];

  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(private fb: FormBuilder,
              private _notiService: NotiService,
              private store: Store,
              private _helperService: HelperService,
              private _settingsService: SettingsService,
              private _progressBarService: ProgressBarService) { }

  ngOnInit() {
    this._progressBarService.initLoading();
    this._initForm();
    this._trackMerchantChange();
  }

  public onChange(field: string): void {
    this.changedField = field;
  }

  public toggleApp(value: boolean): void {
    this._progressBarService.initLoading();
    this._settingsService.toggleApp(value)
      .pipe(debounceTime(500), first())
      .subscribe(
        () => {
          this._progressBarService.finishLoading();
          this._notiService.success(`App ${value ? 'enabled' : 'disabled'}`);
        },
      );
  }

  private _initForm(): void {
    this.settingsForm = this.fb.group({
      connectedCountries: ['CA-US', Validators.required],
      globalShortUrl: ['default'],
      useCustomDomain: [false],
    });

    this._fillForm();
  }

  private _fillForm(): void {
    this._settingsService.getSettings('general')
      .pipe(first())
      .subscribe(
        (data) => {
          this.settingsForm.setValue({
            connectedCountries: data.connectedCountries.sort().join('-'),
            globalShortUrl: data.globalShortUrl,
            useCustomDomain: false,
          });

          this._listenFormChanges();
          this._calcTotalCharacters();

          this._progressBarService.finishLoading();
          this.loading = false;
        },
        () => {
          this._progressBarService.finishLoading();
          this.loading = false;
        },
      );
  }

  private _listenFormChanges(): void {
    this.settingsForm.valueChanges
      .pipe(
        debounceTime(500),
        switchMap((values) => {
          if (this.settingsForm.invalid) return of(null);

          this._calcTotalCharacters();

          this._updateStore(values[this.changedField]);
          return this._settingsService
            .updateSettings('general', { [this.changedField]: this._prepareValue(values[this.changedField]) });
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe((values) => {
        this._notiService.success(SAVED_SUCCESSFULLY);
      });
  }

  private _prepareValue(value: string): any {
    switch (this.changedField) {
      case 'connectedCountries':
        return value.split('-');
      default:
        return value;
    }
  }

  private _updateStore(value: string): void {
    switch (this.changedField) {
      case 'globalShortUrl':
        this.store.dispatch(
          new UpdateMerchantShortenerSettings({
            key: this.changedField,
            value,
          }),
        );
        break;
    }
  }

  private _calcTotalCharacters(): void {
    if (!this.urlTypes.length) return;

    const urlType = this.urlTypes.find(type => type.value === this.settingsForm.value.globalShortUrl).name;
    this.totalCharacters = urlType.length;
  }

  private _trackMerchantChange(): void {
    this.merchant$
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((merchantInfo: MerchantInfo) => {
        this.isAppEnabling = merchantInfo.appStatusChangingTo !== null;
        const primaryDomainName = this._helperService.getMerchantStore(merchantInfo.domain);

        if (!primaryDomainName) return;

        this.urlTypes = [
          {
            value: 'short',
            name: 'https://knkt.cc/xxxxxx',
          },
          {
            value: 'default',
            name: `https://${primaryDomainName}.knkt.cc/xxxxxx`,
          },
        ];

        this._calcTotalCharacters();
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
