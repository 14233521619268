import { Injectable } from '@angular/core';

import { Action, State, StateContext } from '@ngxs/store';

import {
  ClearCampaign,
  ClearCampaignList,
  ClearCampaignOriginal,
  DeleteCampaignListItem,
  SetCampaignsList,
  SetCampaignOriginal,
  SetCampaignsData,
  SetScheduledCampaignsDates,
  UpdateCampaignStatuses,
  SetCampaignsStatistic,
  StopCampaignListItem,
  SetCampaignSegmentsList,
  AddCampaignListItem,
  UpdateCampaignListItem,
  SetCampaignSortingData,
} from '@store/actions';
import {
  CampaignEditItem,
  CampaignListItem,
  CampaignSegmentItem,
  CampaignsStatistic,
} from '@campaigns/models';
import { TableSortData } from '@shared/models';

export interface CampaignsStateModel {
  campaignOriginal: CampaignEditItem;
  campaign: {
    model?: CampaignEditItem,
    dirty: boolean,
    status: string,
    errors: {},
  };
  campaignsData: {
    totalCount: number;
  };
  campaignsList: CampaignListItem[];
  campaignsStatistic: CampaignsStatistic;
  campaignSegments: CampaignSegmentItem[];
  campaignsScheduledDates: [];
  sortingData: TableSortData;
}

@State<CampaignsStateModel>({
  name: 'campaigns',
  defaults: {
    campaignOriginal: null,
    campaign: {
      model: null,
      dirty: false,
      status: '',
      errors: {},
    },
    campaignsData: {
      totalCount: 0,
    },
    campaignsList: null,
    campaignsStatistic: {
      sent: 0,
      clicks: 0,
      orders: 0,
      revenue: 0,
    },
    campaignSegments: [],
    campaignsScheduledDates: [],
    sortingData: {
      sort: 'DESC',
      field: 'createdAt',
    },
  },
})
@Injectable()
export class CampaignsState {

  @Action(SetCampaignSortingData)
  setCampaignSortingData({ patchState }: StateContext<CampaignsStateModel>, { sorting }: SetCampaignSortingData) {
    patchState({ sortingData: sorting });
  }

  @Action(SetCampaignsData)
  setCampaignsData({ patchState }: StateContext<CampaignsStateModel>, { payload }: SetCampaignsData) {
    patchState({
      campaignsData: payload,
    });
  }

  @Action(SetCampaignsList)
  setAutomationsList({ patchState }: StateContext<CampaignsStateModel>, { payload }: SetCampaignsList) {
    patchState({ campaignsList: payload });
  }

  @Action(AddCampaignListItem)
  addCampaignListItem({ getState, setState }: StateContext<CampaignsStateModel>, { item }: AddCampaignListItem) {
    const state = getState();
    const campaignsList = state.campaignsList.map(obj => ({ ...obj }));

    campaignsList.unshift(item);

    setState({ ...getState(), campaignsList });
  }

  @Action(UpdateCampaignListItem)
  updateCampaignListItem({ getState, setState }: StateContext<CampaignsStateModel>, { item }: AddCampaignListItem) {
    const state = getState();
    const campaignsList = state.campaignsList.map(obj => ({ ...obj }));
    const campaignIndex = campaignsList.findIndex(campaign => campaign.id === item.id);

    campaignsList.splice(campaignIndex, 1, item);
    setState({ ...getState(), campaignsList });
  }

  @Action(SetCampaignSegmentsList)
  setCampaignSegmentsList({patchState}: StateContext<CampaignsStateModel>, { payload }: SetCampaignSegmentsList) {
    patchState({ campaignSegments: payload });
  }

  @Action(UpdateCampaignStatuses)
  updateCampaignStatuses({ getState, setState }: StateContext<CampaignsStateModel>, { statusUpdates }: UpdateCampaignStatuses) {
    const state = getState();
    const campaignsList = state.campaignsList.map(obj => ({ ...obj }));

    statusUpdates.forEach( campaignStatus => {
      const campaignIndex = campaignsList.findIndex(elem => elem.id === campaignStatus.id);
      if (~campaignIndex) campaignsList[campaignIndex].status = campaignStatus.status;
    });

    setState({
      ...getState(),
      campaignsList,
    });
  }

  @Action(DeleteCampaignListItem)
  deleteCampaignListItem({ getState, setState }: StateContext<CampaignsStateModel>, { campaignID }: DeleteCampaignListItem) {
    const state = getState();
    const campaignsList = state.campaignsList.map(obj => ({ ...obj }));

    const campaignIndex = campaignsList.findIndex(campaign => campaign.id === campaignID);

    campaignsList.splice(campaignIndex, 1);

    setState({
      ...getState(),
     campaignsList,
    });
  }

  @Action(StopCampaignListItem)
  stopCampaignListItem({ getState, setState }: StateContext<CampaignsStateModel>, { index }: StopCampaignListItem ) {
    const state = getState();
    const campaignsList = state.campaignsList.map(obj => ({ ...obj }));
    campaignsList[index].type = 'Completed';

    setState({
      ...getState(),
      campaignsList,
    });
  }

  @Action(ClearCampaignList)
  clearCampaignList({ patchState }: StateContext<CampaignsStateModel>) {
    patchState({
      campaignsList: null,
      sortingData: {
        sort: 'DESC',
        field: 'createdAt',
      },
    });
  }

  @Action(SetCampaignOriginal)
  setCampaignOriginal({ patchState }: StateContext<CampaignsStateModel>, { payload }: SetCampaignOriginal) {
    patchState({
      campaignOriginal: payload,
    });
  }

  @Action(ClearCampaignOriginal)
  clearCampaignOriginal({ patchState }: StateContext<CampaignsStateModel>) {
    patchState({
      campaignOriginal: null,
    });
  }

  @Action(SetCampaignsStatistic)
  setCampaignsStatistic({ patchState }: StateContext<CampaignsStateModel>, { payload }: SetCampaignsStatistic) {
    patchState({
      campaignsStatistic: payload,
    });
  }

  @Action(ClearCampaign)
  clearCampaign({ getState, setState }: StateContext<CampaignsStateModel>) {
    setState({
      ...getState(),
      campaign: {
        model: null,
        dirty: false,
        status: '',
        errors: {},
      },
    });
  }

  @Action(SetScheduledCampaignsDates)
  setScheduledCampaignsDates({ patchState }: StateContext<CampaignsStateModel>, { payload }: SetScheduledCampaignsDates) {
    patchState({
      campaignsScheduledDates: payload,
    });
  }
}
