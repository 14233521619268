export interface SubscriptionFlowItem {
  id: number;
  name: string;
  keywordName: string;
  valid: boolean;
  status: boolean;
  response?: SubscriptionFlowItemResponse;
  value?: number;
}

export interface SubscriptionFlowItemResponse {
  text: string;
  discountRedirect?: string;
  discountSummary?: string;
  discountType?: string;
  discountView?: SubscriptionFlowItemResponseDiscount;
  image: SubscriptionFlowItemResponseImage | null;
}

export interface SubscriptionFlowItemResponseImage {
  url: string;
  name: string;
  size: any;
}

export interface SubscriptionFlowItemResponseDiscount {
  code: string;
  link: string;
  type: string;
  value: string;
}
