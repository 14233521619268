import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'kCamelCaseToDashes',
})
export class CamelCaseToDashesPipe implements PipeTransform {

  transform(camel: string): string {
    return camel.replace(/[A-Z]/g, m => '-' + m.toLowerCase());
  }

}
