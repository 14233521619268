import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators }                        from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE }            from '@angular/material/core';
import { MomentDateAdapter }                                         from '@angular/material-moment-adapter';
import { MatCalendarCellCssClasses }                                 from '@angular/material/datepicker';
import { ActivatedRoute }                                            from '@angular/router';
import { Subject }                                                   from 'rxjs';
import { debounceTime, takeUntil }                                   from 'rxjs/operators';
import moment, { Moment }                                            from 'moment';
import { Store }                                                     from '@ngxs/store';

import { TimeService }        from '@core/services';
import { QuietHoursInfo }      from '@core/models';
import { DateTimeService }    from '@shared/service/date-time.service';
import { DropdownNumberData } from '@shared/models';

import { SettingsService } from '@settings/services';
import { CampaignService } from '@campaigns/services';

export const MATERIAL_DATEPICKER_FORMATS = {
  parse: {
    dateInput: 'MMM D, y',
  },
  display: {
    dateInput: 'MMM D, y',
    monthYearLabel: 'MMMM y',
    dateA11yLabel: 'MMM D, y',
    monthYearA11yLabel: 'MMMM y',
  },
};

@Component({
  selector: 'k-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: DateTimePickerComponent, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MATERIAL_DATEPICKER_FORMATS},
  ],
})

export class DateTimePickerComponent extends MomentDateAdapter implements OnInit, OnDestroy {

  @Input()
  get datesArray(): string[] { return this.datesArrayList; }
  set datesArray(d: string[]) {
    this.datesArrayList = d;
  }

  @Input() date: string;
  @Input() campaignErrors: { isScheduledCampaignExist: boolean, isWrongScheduledCampaignStatus: boolean };

  @Output() setDate: EventEmitter<any> = new EventEmitter<any>();
  @Output() hasError: EventEmitter<boolean> = new EventEmitter<false>();

  public datesArrayList: string[];
  public dateForm: FormGroup;
  public selectedOption: any;
  public isDatepickerActive: boolean = false;
  public todayDate: any = new Date();
  public oneYearFromNowMin: any;
  public oneYearFromNowMax: any;
  public availableHours: DropdownNumberData[];
  public availableHoursFrom: number;
  public availableHoursTo: number;

  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  /*
   * Change days name
   */
  getDayOfWeekNames(style: 'long' | 'short' | 'narrow') {
    return ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
  }

  /*
   * Overrides date creating to skip time zone
   */
  createDate(year: number, month: number, date: number): Moment {
    const result = moment.utc({ year, month, date }).locale(this.locale);

    if (!result.isValid()) {
      console.error(`Invalid date "${date}" for month with index "${month}".`);
    }

    return result;
  }

  /*
   * Disabled past dates
   */
  dateFilter = (d: Moment | null): boolean => {
    const now = moment().utc();
    now.set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });

    if (d) {
      const hoursFrom = this._getRoundTimeInMinutes(moment());

      if (this.availableHoursTo > hoursFrom) {
        return d >= now;
      } else {
        return d > now;
      }
    } else {
      return true;
    }
  }

  public get isCreatePage(): boolean {
    return this.route.snapshot.data.createPage;
  }

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private _timeService: TimeService,
    private _settingsService: SettingsService,
    private _dateTimeService: DateTimeService,
    private _campaignService: CampaignService) {
    super('en-US');
  }

  ngOnInit(): void {
    const quietHours: QuietHoursInfo = this.store.selectSnapshot(state => state.quietHours);
    if (quietHours.active) {
      this.availableHoursFrom = quietHours.to;
      this.availableHoursTo = quietHours.from;
    } else {
      this.availableHoursFrom = 0;
      this.availableHoursTo = 1440;
    }
    this.oneYearFromNowMin = this._dateTimeService.getPreviousYearDate();
    this.oneYearFromNowMax = this._dateTimeService.getNextYearDate();

    this._buildHoursOptions(this.date ? moment(this.date) : moment());
    this._initForm();
    this._trackFromChanges();
    if (this.date) {
      this._fillForm(this.date);
    }

    this._checkError();
  }

  /*
  * Add class and style for scheduled campaign on datepicker
   */
  public dateClass() {
    return (date: Date): MatCalendarCellCssClasses => {
      const highlightDate = this.datesArrayList
        .map(strDate => new Date(strDate))
        .some(d => d.getDate() === date['_i'].date && d.getMonth() === date['_i'].month && d.getFullYear() === date['_i'].year);

      return highlightDate ? 'scheduled-campaigns' : '';
    };
  }

  public openDatepicker(element): void {
    element.open();
    this.isDatepickerActive = !this.isDatepickerActive;
  }

  public close(): void {
    this.isDatepickerActive = false;
  }

  public detectDropListChanges(data): void {
    if (data) {
      this.dateForm.get('time').setValue(data);
    }
  }

  private _checkError(): void {
    this.hasError
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(data => {
        this.campaignErrors = { isScheduledCampaignExist: data, isWrongScheduledCampaignStatus: false };
      });
  }

  private _initForm(): void {
    const now = moment();
    const hoursFrom = this._getRoundTimeInMinutes(now);
    const date = this.availableHoursTo <= hoursFrom ? now.add(1,'days') : now;
    this.dateForm = this.fb.group({
      date: [date, Validators.required],
      time: [this.availableHours[0], Validators.required],
    });
    this.selectedOption = this.availableHours[0].name;
    this._setScheduleTime(date, this.availableHours[0]);
  }

  private _fillForm(data: string): void {
    const scheduledDate = moment.utc(data);
    const scheduledTime: DropdownNumberData = this.availableHours.find(
      (option: DropdownNumberData) => option.value === scheduledDate.hours() * 60 + scheduledDate.minutes(),
      ) || { value: scheduledDate.hours() * 60 + scheduledDate.minutes(), name: scheduledDate.format('h:mm A') };
    this.dateForm.get('time').setValue(scheduledTime);
    this.dateForm.get('date').setValue(scheduledDate);
    this.selectedOption = scheduledTime.name;
  }

  private _trackFromChanges(): void {
    this.dateForm.valueChanges
      .pipe(debounceTime(50), takeUntil(this.ngUnsubscribe$))
      .subscribe((data) => {
        this._setScheduleTime(data.date, data.time);
      });

    this.dateForm.get('date').valueChanges
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((date) => {
        this._buildHoursOptions(date);
      });
  }

  private _setScheduleTime(date: Moment, time: DropdownNumberData): void {
    const formData = { date, time };
    const sentAt = this._setCombinedDate(formData);
    if (formData.date && typeof formData.time?.value === 'number') {
      this.setDate.emit(sentAt);
    }
  }

  private _setCombinedDate(data: { date: Moment, time: DropdownNumberData }): string {
    const sentAt = moment.utc(data.date);
    sentAt.set({ hours: Math.floor( data.time.value / 60), minutes: data.time.value % 60, seconds: 0, milliseconds:0 });
    return sentAt.toJSON();
  }

  private _buildHoursOptions(date: Moment): void {
    const now = moment();
    const today = moment().utc().set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
    const someDate = moment(date).utc().set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
    if (+today === +someDate) {
      this._buildTodayHoursOptions(now);
    } else {
      this._buildDefaultHoursOptions();
    }
  }

  private _buildTodayHoursOptions(now: Moment): void {
    const hoursFrom = this._getRoundTimeInMinutes(now);
    const isCurrentTimeAvailable = this.availableHoursFrom < hoursFrom && hoursFrom < this.availableHoursTo;
    this.availableHours = this._getAvailableHours(isCurrentTimeAvailable ? hoursFrom : this.availableHoursFrom);
  }

  private _buildDefaultHoursOptions(): void {
    this.availableHours = this._getAvailableHours(this.availableHoursFrom);
  }

  private _getAvailableHours(hoursFrom: number): DropdownNumberData[] {
    return this._timeService.getQuietHours(hoursFrom, this.availableHoursTo - 30);
  }

  private _getRoundTimeInMinutes(date: Moment): number {
    const currentHours = date.hours() * 60;
    const roundMinutes = date.minutes() ? (Math.ceil(date.minutes() / 30) * 30) : 0;
    const additionalHour = 60;
    return currentHours + additionalHour + roundMinutes;
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
