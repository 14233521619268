import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '@core/services';

@Injectable()
export class MessagePreviewService {

  constructor(private _apiService: ApiService) { }

  public setPhoneMask(value, backspace) {
    let newVal = value.replace(/\D/g, '');
    if (newVal.length >= 12) {
      newVal = value;
      return newVal;
    }
    if (backspace && newVal.length <= 6) {
      newVal = newVal.substring(0, newVal.length - 1);
    }
    if (newVal.length === 0) {
      newVal = '';
    } else if (newVal.length <= 4) {
      newVal = newVal.replace(/^(\d{0,1})(\d{0,3})/, '+$1 ($2)');
    } else if (newVal.length <= 7) {
      newVal = newVal.replace(/^(\d{0,1})(\d{0,3})(\d{0,3})/, '+$1 ($2) $3');
    } else if (newVal.length <= 11) {
      newVal = newVal.replace(/^(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,4})/, '+$1 ($2) $3-$4');
    } else {
      newVal = newVal.substring(0, 16);
      newVal = newVal.replace(/^(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,4})/, '+$1 ($2) $3-$4');
    }
    return newVal;
  }

  public removePhoneMask(value) {
    return value.replace(/[\s()-]+/gi, '');
  }

  public sendTestMessage(phone: string, templateString: string): Observable<any> {
    return this._apiService.post('testTemplate', { phone, templateString });
  }
}
