import { Component, OnDestroy, OnInit }       from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router }                             from '@angular/router';

import {
  ApiService,
  FormService,
  HelperService,
  MerchantService,
  ShopifyService,
}                                from '@core/services';
import { MerchantInfo }          from '@core/models';
import { MessagePreviewService } from '@shared/service';
import { ValidatorService }      from '@shared/service/validator.service';
import { Select, Store }         from '@ngxs/store';
import { NotiService }           from '@notifications/services';
import { first, takeUntil }      from 'rxjs/operators';
import { Observable, Subject }   from 'rxjs';
import {
  CANADA_PROVINCES,
  INSTALL_PAGE,
  USA_STATES,
}                                from '@legal-agreements/texts';
import { LEGAL_ROUTS }           from '@core/texts';
import { LegalService }          from '@shared/service/legal.service';
import { NotificationData }      from '@notifications/models';

@Component({
  selector: 'k-install-page',
  templateUrl: './install-page.component.html',
  styleUrls: ['./install-page.component.scss'],
})
export class InstallPageComponent implements OnInit, OnDestroy {
  @Select(state => state.merchant) merchant$: Observable<MerchantInfo>;
  @Select(state => state.notifications.shownAlerts) shownAlerts$: Observable<NotificationData[]>;

  public loading: boolean = true;
  public stateOrProvince: string = 'State';
  public legalForm: FormGroup;
  public reservedNumber: string;
  public countryList: { [key: string]: string }[];
  public stateList: { [key: string]: string }[];
  public USA_STATES = USA_STATES;
  public CANADA_PROVINCES = CANADA_PROVINCES;
  public installPageTexts = INSTALL_PAGE;

  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(private fb: FormBuilder,
              private router: Router,
              private store: Store,
              private _formService: FormService,
              private _apiService: ApiService,
              private _notiService: NotiService,
              private _shopifyService: ShopifyService,
              private _helperService: HelperService,
              private _messagePreviewService: MessagePreviewService,
              private _validatorService: ValidatorService,
              private _merchantService: MerchantService,
              private _legalService: LegalService) {
  }

  ngOnInit() {
    this._showLoaders();

    this.shownAlerts$
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(alerts => {
        this._legalService.hideAlerts(alerts);
      });

    this.countryList = [
      { name: 'United States', value: 'United States' },
      { name: 'Canada', value: 'Canada' },
    ];

    this.merchant$
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((data: MerchantInfo) => {
        this.reservedNumber = data.reservedNumber ? this._messagePreviewService.setPhoneMask(data.reservedNumber.trim(), false) : '';
        if (data.shop.id) {
          if (data.instalationProcess < LEGAL_ROUTS.DONE) {
            this._hideLoaders();
            this._initForm();

            if (data.instalationProcess <= LEGAL_ROUTS.INSTALL) {
              this._fillForm(data);
            }
          }
        }
      });
  }

  public get currentCityType() {
    return this.stateOrProvince;
  }

  public onChangeCountry(field: string): void {
    const country = this.legalForm.get(field).value;
    this.stateOrProvince = country === 'Canada' ? 'Province' : 'State';
    this._setStateList(country);
  }

  public fieldValidationError(field: string): string {
    const fieldControl = this.legalForm.get(field);

    // TODO: Need to refactor this recording to the global changes validation in the app
    if (!fieldControl.errors || fieldControl.untouched) return '';

    if (
      ( field === 'firstName' || field === 'lastName' || field === 'jobTitle' )
      &&
      ( fieldControl.errors.required || fieldControl.errors.minlength )
    ) {
      return this.installPageTexts.ERRORS.REQUIRED;
    }

    if (fieldControl.errors.htmlTagsInvalid) {
      return `Field shouldn't contain html tags`;
    }

    if (field === 'email' && ( fieldControl.errors.templateWhiteSpaceInvalid || fieldControl.errors.emailPatternInvalid )) {
      return this.installPageTexts.ERRORS.EMAIL;
    }

    if(field === 'phone' && fieldControl.errors) {
      return this.installPageTexts.ERRORS.PHONE;
    }

    if (field === 'zipcode' && ( fieldControl.errors.minlength || fieldControl.errors.maxlength || fieldControl.errors.pattern )) {
      return this.installPageTexts.ERRORS.ZIPCODE;
    }

    return this._formService.getError(this.legalForm, field);

  }

  public onSubmit() {
    if (this.legalForm.invalid) {
      this._notiService.warning();
      return;
    }
    this._showLoaders();
    this.legalForm.value.phone = this._messagePreviewService.removePhoneMask(this.legalForm.value.phone);

    this._merchantService.addLegalAgreements(this.legalForm.value)
      .pipe(first())
      .subscribe(
        () => {
          this._merchantService.loadMerchantInfo().then(() => {});
        },
      );
  }

  private _initForm() {
    this.legalForm = this.fb.group({
      firstName: ['', [ ...this._validatorService.textFiledValidators() ]],
      lastName: ['', [ ...this._validatorService.textFiledValidators() ]],
      jobTitle: ['', [ ...this._validatorService.textFiledValidators() ]],
      email: ['',
        [
          Validators.required,
          this._validatorService.whiteSpaceValidator(),
          this._validatorService.emailValidator(),
          this._validatorService.htmlTagsValidator(),
        ],
      ],
      phone: ['',
        [
          Validators.required,
          this._validatorService.minLengthDigitsOnlyValidator(8),
          this._validatorService.maxLengthDigitsOnlyValidator(16),
          this._validatorService.htmlTagsValidator(),
        ],
      ],
      legalName: ['', [ Validators.required, this._validatorService.htmlTagsValidator() ] ],
      address: ['', [ Validators.required, this._validatorService.htmlTagsValidator() ] ],
      city: ['', [ Validators.required, this._validatorService.htmlTagsValidator() ] ],
      country: ['', [ Validators.required ] ],
      state: ['', [ Validators.required ] ],
      zipcode: ['',
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(11),
          Validators.pattern(/^[a-zA-Z0-9_ -]*$/),
          this._validatorService.htmlTagsValidator(),
        ],
      ],
      signature: ['', [ ...this._validatorService.textFiledValidators() ]],
      accepted: [false, [ Validators.requiredTrue ] ],
    });

    this._validateNotEmptyFields();
    this._hideLoaders();
  }

  private _fillForm(data: MerchantInfo) {
    const activeLOA = data.activeLOA;
    const billingAddress = data.shop.billingAddress;

    activeLOA
      ? this._setLegalData(data.shop?.email || '', activeLOA)
      : this._setLegalData(data.shop?.email || '', billingAddress);

    this._validateNotEmptyFields();
  }

  private _setStateList(country): void {
    const selectedCountry = country === 'Canada' ? this.CANADA_PROVINCES : this.USA_STATES;
    this._createStateList(selectedCountry);
  }

  private _validateNotEmptyFields(): void {
    Object.keys(this.legalForm.controls).forEach(key => {
      const control = this.legalForm.controls[key];
      const controlValue = control.value;

      if (controlValue.length) {
        this.legalForm.get(key).markAsTouched();
      }
    });
  }

  private _createStateList(country): void {
    this.stateList = [...country.map(item => {
      return { name: item, value: item};
    })];
  }

  private _setLegalData(emailField, data): void {
    this.stateOrProvince = data?.country === 'Canada' ? 'Province' : 'State';
    if (data?.country) {
      this._setStateList(data.country);
    }

    this.legalForm.setValue({
      firstName: data?.firstName || '',
      lastName: data?.lastName || '',
      jobTitle: '',
      email: emailField,
      phone: data?.phone ? this._messagePreviewService.setPhoneMask(`1${data?.phone.trim()}`, false) : '',
      legalName: data?.company || '',
      address: data?.address2 ? data.address1.concat(', ', data.address2.trim()) : data?.address1,
      city: data?.city || '',
      country: data?.country || '',
      state: data?.province || '',
      zipcode: data?.zip ||  '',
      signature: data?.signature ||  '',
      accepted: false,
    });
  }

  private _showLoaders() {
    this.loading = true;
  }

  private _hideLoaders() {
    this.loading = false;
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
