import { HeaderComponent }       from './header/header.component';
import { MenuComponent }         from './header/menu/menu.component';
import { FooterComponent }       from './footer/footer.component';
import { IconsComponent }        from './icons/icons.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ProgressBarComponent }  from './progress-bar/progress-bar.component';

export {
  HeaderComponent,
  FooterComponent,
  IconsComponent,
  PageNotFoundComponent,
  ProgressBarComponent,
};

export const components = [
  HeaderComponent,
  MenuComponent,
  FooterComponent,
  IconsComponent,
  PageNotFoundComponent,
  ProgressBarComponent,
];
