
import { LegalAgreementsComponent } from './legal-agreements/legal-agreements.component';

import { InstallPageComponent }     from './legal-agreements/install-page/install-page.component';
import { TermsComponent }           from './legal-agreements/terms/terms.component';
import { TermInstructionComponent } from './legal-agreements/terms/term-instruction/term-instruction.component';

export {
  LegalAgreementsComponent,
  InstallPageComponent,
  TermsComponent,
  TermInstructionComponent,
};

export const components = [
  LegalAgreementsComponent,
  InstallPageComponent,
  TermsComponent,
  TermInstructionComponent,
];
