import { SettingsService }                    from './settings.service';
import { SettingsSendingLogicChangesService } from '@settings/services/settings-sending-logic-changes.service';

export {
  SettingsService,
  SettingsSendingLogicChangesService,
};

export const services = [
  SettingsService,
  SettingsSendingLogicChangesService,
];
