import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'showNewLines',
})
export class ShowNewLinesPipe implements PipeTransform {
  constructor() { }

  transform(content: any): string {
    return content ? content.replace(/\n\r?/g, '<br>') : '';
  }
}
