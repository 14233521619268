import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { Router }                                     from '@angular/router';

import { NotiParams }  from '@notifications/models';
import { NotiService } from '@notifications/services';

@Directive({
  selector: '[kLinkRouter]',
})
export class LinkRouterDirective {
  @Input() kLinkRouter: NotiParams;

  constructor(private el: ElementRef, private router: Router, private _notiService: NotiService) { }

  @HostListener('click', ['$event'])
  public onClick(event: Event): void {
    event.preventDefault();

    const target = event.target as HTMLElement;

    if (target.tagName !== 'A') return;

    if (this.kLinkRouter) {
      this._notiService.trackEvent(this.kLinkRouter.id, this.kLinkRouter.triggerId, 'linkClick').subscribe();
    }

    this.router.navigate([target.getAttribute('href')]);
  }
}
