import { Component, Injectable }                                      from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { Observable }                                                 from 'rxjs';

import { UnsavedChangesService } from '@features/services/unsaved-changes.service';

export interface CanComponentDeactivate {
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {
  constructor(private _unsavedChangesService: UnsavedChangesService) {}

  canDeactivate(component: Component & CanComponentDeactivate,
                currentRoute: ActivatedRouteSnapshot,
                currentState: RouterStateSnapshot,
                nextState?: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return component.canDeactivate() || this._unsavedChangesService.getActionStreamAndOpenDialog();
  }
}
