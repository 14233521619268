import { LEGAL_TEXTS } from '@shared/texts';

export const INSTRUCTIONS = {
  STEP_1: {
    title: LEGAL_TEXTS.termsOfService.title,
    descriptionFirst: LEGAL_TEXTS.termsOfService.descriptionFirst,
    copyText: LEGAL_TEXTS.termsOfService.copyText,
    descriptionLast: '',
    link: {
      text: LEGAL_TEXTS.termsOfService.link.text,
      href: LEGAL_TEXTS.termsOfService.link.href,
    },
    checkbox: {
      key: 'termsOfService',
      title: LEGAL_TEXTS.termsOfService.checkbox.title,
    },
  },
  STEP_2: {
    title: LEGAL_TEXTS.privacyPolicy.title,
    descriptionFirst: LEGAL_TEXTS.privacyPolicy.descriptionFirst,
    copyText: LEGAL_TEXTS.privacyPolicy.copyText,
    descriptionLast: '',
    link: {
      text: LEGAL_TEXTS.privacyPolicy.link.text,
      href: LEGAL_TEXTS.privacyPolicy.link.href,
    },
    checkbox: {
      key: 'privacyPolicy',
      title: LEGAL_TEXTS.privacyPolicy.checkbox.title,
    },
  },
  STEP_3: {
    title: LEGAL_TEXTS.TCPACompliance.title,
    descriptionFirst: LEGAL_TEXTS.TCPACompliance.descriptionFirst,
    checkbox: {
      key: 'TCPACompliance',
      title: LEGAL_TEXTS.TCPACompliance.checkbox.title,
    },
  },
};
