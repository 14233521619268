import { FLOW_TRIGGERS_TYPE } from '@flows/texts';

export const RULE_TYPES_OPTIONS = {
  SOURCE_OF_SUBSCRIPTION_NAME: 'Source of subscription',
  SOURCE_OF_SUBSCRIPTION_VALUE: 'ContactSource',
  MONEY_SPENT_NAME: 'Money spent',
  MONEY_SPENT_VALUE: 'MoneySpent',
  NUMBER_OF_ORDERS_NAME: 'Number of orders',
  NUMBER_OF_ORDERS_VALUE: 'OrdersCount',
  AVERAGE_MONEY_AMOUNT_NAME: 'Average money amount',
  AVERAGE_MONEY_AMOUNT_VALUE: 'AverageMoney',
  TOTAL_DISCOUNTS_NAME: 'Total discounts',
  TOTAL_DISCOUNTS_VALUE: 'TotalDiscounts',

  ORDER_SPECIFIC_PRODUCTS_NAME: 'Ordered specific products',
  ORDER_SPECIFIC_PRODUCTS_VALUE: 'OrderedProducts',

  ORDER_SPECIFIC_COLLECTIONS_NAME: 'Ordered from a specific collection',
  ORDER_SPECIFIC_COLLECTIONS_VALUE: 'OrderedCollections',

  LAST_ORDER_CREATED_NAME: 'Last order created',
  LAST_ORDER_CREATED_VALUE: 'LastOrderCreated',

  CUSTOMER_CREATED_RULE_NAME: 'Customer created',
  CUSTOMER_CREATED_RULE_VALUE: 'CustomerCreated',

  FULFILLMENT_STATUS_NAME: 'Fulfillment status',
  FULFILLMENT_STATUS_VALUE: 'FulfillmentStatus',
  PAYMENT_STATUS_NAME: 'Payment status',
  PAYMENT_STATUS_VALUE: 'PaymentStatus',
  ORDER_CANCELLED_NAME: 'Order cancelled',
  ORDER_CANCELLED_VALUE: 'OrderCancelled',

  LINE_ITEMS_NAME: 'Line items',
  LINE_ITEMS_VALUE: 'LineItems',
};

export const RULE_TYPE_GROUPS = {
  SOURCE_OF_SUBSCRIPTION: 'Source',
  CUSTOMER: 'Customer',
  ORDER_STATUS: 'Order Status',
};

export const RULE_TYPES_VALUE_TO_NAME_OPTIONS = {
  [RULE_TYPES_OPTIONS.SOURCE_OF_SUBSCRIPTION_VALUE]: RULE_TYPES_OPTIONS.SOURCE_OF_SUBSCRIPTION_NAME,
  [RULE_TYPES_OPTIONS.MONEY_SPENT_VALUE]: RULE_TYPES_OPTIONS.MONEY_SPENT_NAME,
  [RULE_TYPES_OPTIONS.NUMBER_OF_ORDERS_VALUE]: RULE_TYPES_OPTIONS.NUMBER_OF_ORDERS_NAME,
  [RULE_TYPES_OPTIONS.AVERAGE_MONEY_AMOUNT_VALUE]: RULE_TYPES_OPTIONS.AVERAGE_MONEY_AMOUNT_NAME,
  [RULE_TYPES_OPTIONS.TOTAL_DISCOUNTS_VALUE]: RULE_TYPES_OPTIONS.TOTAL_DISCOUNTS_NAME,
  [RULE_TYPES_OPTIONS.LAST_ORDER_CREATED_VALUE]: RULE_TYPES_OPTIONS.LAST_ORDER_CREATED_NAME,
  [RULE_TYPES_OPTIONS.CUSTOMER_CREATED_RULE_VALUE]: RULE_TYPES_OPTIONS.CUSTOMER_CREATED_RULE_NAME,
  [RULE_TYPES_OPTIONS.ORDER_SPECIFIC_PRODUCTS_VALUE]: RULE_TYPES_OPTIONS.ORDER_SPECIFIC_PRODUCTS_NAME,
  [RULE_TYPES_OPTIONS.ORDER_SPECIFIC_COLLECTIONS_VALUE]: RULE_TYPES_OPTIONS.ORDER_SPECIFIC_COLLECTIONS_NAME,
  [RULE_TYPES_OPTIONS.FULFILLMENT_STATUS_VALUE]: RULE_TYPES_OPTIONS.FULFILLMENT_STATUS_NAME,
  [RULE_TYPES_OPTIONS.PAYMENT_STATUS_VALUE]: RULE_TYPES_OPTIONS.PAYMENT_STATUS_NAME,
  [RULE_TYPES_OPTIONS.ORDER_CANCELLED_VALUE]: RULE_TYPES_OPTIONS.ORDER_CANCELLED_NAME,
  [RULE_TYPES_OPTIONS.LINE_ITEMS_VALUE]: RULE_TYPES_OPTIONS.LINE_ITEMS_NAME,
};

export const RULE_PERIOD = {
  ALL_TIME_NAME: 'All time',
  ALL_TIME_VALUE: 'All',
  TODAY_NAME: 'Today',
  TODAY_VALUE: 'Today',
  YESTERDAY_NAME: 'Yesterday',
  YESTERDAY_VALUE: 'Yesterday',
  LAST_7_DAYS_NAME: 'Last 7 Days',
  LAST_7_DAYS_VALUE: 'Last7Days',
  LAST_30_DAYS_NAME: 'Last 30 Days',
  LAST_30_DAYS_VALUE: 'Last30Days',
  RANGE_NAME: 'Custom Range',
  RANGE_VALUE: 'Range',
};

export const ORDER_CREATED_CUSTOMER_CREATED_RULE = [
  { name: RULE_PERIOD.ALL_TIME_NAME, value: RULE_PERIOD.ALL_TIME_VALUE },
  { name: RULE_PERIOD.TODAY_NAME, value: RULE_PERIOD.TODAY_VALUE },
  { name: RULE_PERIOD.YESTERDAY_NAME, value: RULE_PERIOD.YESTERDAY_VALUE },
  { name: RULE_PERIOD.LAST_7_DAYS_NAME, value: RULE_PERIOD.LAST_7_DAYS_VALUE },
  { name: RULE_PERIOD.LAST_30_DAYS_NAME, value: RULE_PERIOD.LAST_30_DAYS_VALUE },
  { name: RULE_PERIOD.RANGE_NAME, value: RULE_PERIOD.RANGE_VALUE },
];

export const ORDER_CREATED_CUSTOMER_CREATED_VALUE_TO_NAME_OPTION = {
  [RULE_PERIOD.ALL_TIME_VALUE]: RULE_PERIOD.ALL_TIME_NAME,
  [RULE_PERIOD.TODAY_VALUE]: RULE_PERIOD.TODAY_NAME,
  [RULE_PERIOD.YESTERDAY_VALUE]: RULE_PERIOD.YESTERDAY_NAME,
  [RULE_PERIOD.LAST_7_DAYS_VALUE]: RULE_PERIOD.LAST_7_DAYS_NAME,
  [RULE_PERIOD.LAST_30_DAYS_VALUE]: RULE_PERIOD.LAST_30_DAYS_NAME,
  [RULE_PERIOD.RANGE_VALUE]: RULE_PERIOD.RANGE_NAME,
};

export const RULE_SECONDARY_OPTIONS = {
  FULFILLED_NAME: 'Fulfilled',
  FULFILLED_VALUE: 'Fulfilled',
  UNFULFILLED_NAME: 'Unfulfilled',
  UNFULFILLED_VALUE: 'Unfulfilled',
  PARTIALLY_FULFILLED_NAME: 'Partially fulfilled',
  PARTIALLY_FULFILLED_VALUE: 'PartiallyFulfilled',

  AUTHORIZED_NAME: 'Authorized',
  AUTHORIZED_VALUE: 'Authorized',
  PAID_NAME: 'Paid',
  PAID_VALUE: 'Paid',
  PARTIALLY_REFUNDED_NAME: 'Partially refunded',
  PARTIALLY_REFUNDED_VALUE: 'PartiallyRefunded',
  PARTIALLY_PAID_NAME: 'Partially paid',
  PARTIALLY_PAID_VALUE: 'PartiallyPaid',
  PENDING_NAME: 'Pending',
  PENDING_VALUE: 'Pending',
  REFUNDED_NAME: 'Refunded',
  REFUNDED_VALUE: 'Refunded',
  UNPAID_NAME: 'Unpaid',
  UNPAID_VALUE: 'Unpaid',
  VOIDED_NAME: 'Voided',
  VOIDED_VALUE: 'Voided',

  YES_NAME: 'Yes',
  YES_VALUE: true,
  NO_NAME: 'No',
  NO_VALUE: false,
};

export const FULFILLMENT_STATUS = [
  { name: RULE_SECONDARY_OPTIONS.FULFILLED_NAME, value: RULE_SECONDARY_OPTIONS.FULFILLED_VALUE },
  { name: RULE_SECONDARY_OPTIONS.UNFULFILLED_NAME, value: RULE_SECONDARY_OPTIONS.UNFULFILLED_VALUE },
  { name: RULE_SECONDARY_OPTIONS.PARTIALLY_FULFILLED_NAME, value: RULE_SECONDARY_OPTIONS.PARTIALLY_FULFILLED_VALUE },
];

export const PAYMENT_STATUS = [
  { name: RULE_SECONDARY_OPTIONS.AUTHORIZED_NAME, value: RULE_SECONDARY_OPTIONS.AUTHORIZED_VALUE },
  { name: RULE_SECONDARY_OPTIONS.PAID_NAME, value: RULE_SECONDARY_OPTIONS.PAID_VALUE },
  { name: RULE_SECONDARY_OPTIONS.PARTIALLY_REFUNDED_NAME, value: RULE_SECONDARY_OPTIONS.PARTIALLY_REFUNDED_VALUE },
  { name: RULE_SECONDARY_OPTIONS.PARTIALLY_PAID_NAME, value: RULE_SECONDARY_OPTIONS.PARTIALLY_PAID_VALUE },
  { name: RULE_SECONDARY_OPTIONS.PENDING_NAME, value: RULE_SECONDARY_OPTIONS.PENDING_VALUE },
  { name: RULE_SECONDARY_OPTIONS.REFUNDED_NAME, value: RULE_SECONDARY_OPTIONS.REFUNDED_VALUE },
  { name: RULE_SECONDARY_OPTIONS.UNPAID_NAME, value: RULE_SECONDARY_OPTIONS.UNPAID_VALUE },
  { name: RULE_SECONDARY_OPTIONS.VOIDED_NAME, value: RULE_SECONDARY_OPTIONS.VOIDED_VALUE },
];

export const ORDER_CANCELLED = [
  { name: RULE_SECONDARY_OPTIONS.YES_NAME, value: RULE_SECONDARY_OPTIONS.YES_VALUE },
  { name: RULE_SECONDARY_OPTIONS.NO_NAME, value: RULE_SECONDARY_OPTIONS.NO_VALUE },
];

export const ORDER_STATUS = {
  [RULE_TYPES_OPTIONS.FULFILLMENT_STATUS_VALUE]: FULFILLMENT_STATUS,
  [RULE_TYPES_OPTIONS.PAYMENT_STATUS_VALUE]: PAYMENT_STATUS,
  [RULE_TYPES_OPTIONS.ORDER_CANCELLED_VALUE]: ORDER_CANCELLED,
};

export const ORDER_STATUS_VALUE_TO_NAME_OPTION = {
  [RULE_SECONDARY_OPTIONS.FULFILLED_VALUE]: RULE_SECONDARY_OPTIONS.FULFILLED_NAME,
  [RULE_SECONDARY_OPTIONS.UNFULFILLED_VALUE]: RULE_SECONDARY_OPTIONS.UNFULFILLED_NAME,
  [RULE_SECONDARY_OPTIONS.PARTIALLY_FULFILLED_VALUE]: RULE_SECONDARY_OPTIONS.PARTIALLY_FULFILLED_NAME,

  [RULE_SECONDARY_OPTIONS.AUTHORIZED_VALUE]: RULE_SECONDARY_OPTIONS.AUTHORIZED_NAME,
  [RULE_SECONDARY_OPTIONS.PAID_VALUE]: RULE_SECONDARY_OPTIONS.PAID_NAME,
  [RULE_SECONDARY_OPTIONS.PARTIALLY_REFUNDED_VALUE]: RULE_SECONDARY_OPTIONS.PARTIALLY_REFUNDED_NAME,
  [RULE_SECONDARY_OPTIONS.PARTIALLY_PAID_VALUE]: RULE_SECONDARY_OPTIONS.PARTIALLY_PAID_NAME,
  [RULE_SECONDARY_OPTIONS.PENDING_VALUE]: RULE_SECONDARY_OPTIONS.PENDING_NAME,
  [RULE_SECONDARY_OPTIONS.REFUNDED_VALUE]: RULE_SECONDARY_OPTIONS.REFUNDED_NAME,
  [RULE_SECONDARY_OPTIONS.UNPAID_VALUE]: RULE_SECONDARY_OPTIONS.UNPAID_NAME,
  [RULE_SECONDARY_OPTIONS.VOIDED_VALUE]: RULE_SECONDARY_OPTIONS.VOIDED_NAME,

  ['false']: RULE_SECONDARY_OPTIONS.NO_NAME,
  ['true']: RULE_SECONDARY_OPTIONS.YES_NAME,
};

export const RULE_SOURCE_TYPE_OPTIONS = {
  ALL_NAME: 'All',
  ALL_VALUE: 'All',
  KEYWORD_NAME: 'Keyword',
  KEYWORD_VALUE: 'Keyword',
  POPUP_NAME: 'Mobile Popup',
  POPUP_VALUE: 'Popup',
  CHECKOUT_NAME: 'Checkout',
  CHECKOUT_VALUE: 'Checkout',
  PRODUCT_NAME: 'Product',
  PRODUCT_VALUE: 'Product',
  COLLECTION_NAME: 'Collection',
  COLLECTION_VALUE: 'Collection',
  LAST_ORDER_CREATED_NAME: 'Last Order Created',
  LAST_ORDER_CREATED_VALUE: 'LastOrderCreated',
  CUSTOMER_CREATED_RULE_NAME: 'Customer Created',
  CUSTOMER_CREATED_RULE_VALUE: 'CustomerCreated',
};

export const SOURCE_TYPE_OPTIONS = [
  { name: RULE_SOURCE_TYPE_OPTIONS.ALL_NAME, value: RULE_SOURCE_TYPE_OPTIONS.ALL_VALUE },
  { name: RULE_SOURCE_TYPE_OPTIONS.KEYWORD_NAME, value: RULE_SOURCE_TYPE_OPTIONS.KEYWORD_VALUE },
  { name: RULE_SOURCE_TYPE_OPTIONS.POPUP_NAME, value: RULE_SOURCE_TYPE_OPTIONS.POPUP_VALUE },
  { name: RULE_SOURCE_TYPE_OPTIONS.CHECKOUT_NAME, value: RULE_SOURCE_TYPE_OPTIONS.CHECKOUT_VALUE },
];

export const RULE_SOURCE_TYPE_VALUE_TO_NAME_OPTIONS = {
  [RULE_SOURCE_TYPE_OPTIONS.ALL_VALUE]: RULE_SOURCE_TYPE_OPTIONS.ALL_NAME,
  [RULE_SOURCE_TYPE_OPTIONS.KEYWORD_VALUE]: RULE_SOURCE_TYPE_OPTIONS.KEYWORD_NAME,
  [RULE_SOURCE_TYPE_OPTIONS.POPUP_VALUE]: RULE_SOURCE_TYPE_OPTIONS.POPUP_NAME,
  [RULE_SOURCE_TYPE_OPTIONS.CHECKOUT_VALUE]: RULE_SOURCE_TYPE_OPTIONS.CHECKOUT_NAME,
  [RULE_SOURCE_TYPE_OPTIONS.PRODUCT_NAME]: RULE_SOURCE_TYPE_OPTIONS.PRODUCT_VALUE,
  [RULE_SOURCE_TYPE_OPTIONS.COLLECTION_NAME]: RULE_SOURCE_TYPE_OPTIONS.COLLECTION_VALUE,
  [RULE_SOURCE_TYPE_OPTIONS.LAST_ORDER_CREATED_NAME]: RULE_SOURCE_TYPE_OPTIONS.LAST_ORDER_CREATED_VALUE,
  [RULE_SOURCE_TYPE_OPTIONS.CUSTOMER_CREATED_RULE_NAME]: RULE_SOURCE_TYPE_OPTIONS.CUSTOMER_CREATED_RULE_VALUE,
};

export const RULE_LINE_ITEMS_OPTIONS = {
  PRODUCT_ID_NAME: 'Product ID',
  PRODUCT_ID_VALUE: 'ProductId',
  VARIANT_ID_NAME: 'Variant ID',
  VARIANT_ID_VALUE: 'VariantId',
  PRODUCT_TITLE_NAME: 'Product Title',
  PRODUCT_TITLE_VALUE: 'ProductTitle',
  VARIANT_TITLE_NAME: 'Variant Title',
  VARIANT_TITLE_VALUE: 'VariantTitle',
  QUANTITY_NAME: 'Quantity',
  QUANTITY_VALUE: 'Quantity',
  PRICE_NAME: 'Price',
  PRICE_VALUE: 'Price',
  DISCOUNT_NAME: 'Discount',
  DISCOUNT_VALUE: 'Discount',
};

export const LINE_ITEMS_OPTIONS = [
  { name: RULE_LINE_ITEMS_OPTIONS.PRODUCT_ID_NAME, value: RULE_LINE_ITEMS_OPTIONS.PRODUCT_ID_VALUE},
  { name: RULE_LINE_ITEMS_OPTIONS.VARIANT_ID_NAME, value: RULE_LINE_ITEMS_OPTIONS.VARIANT_ID_VALUE},
  { name: RULE_LINE_ITEMS_OPTIONS.PRODUCT_TITLE_NAME, value: RULE_LINE_ITEMS_OPTIONS.PRODUCT_TITLE_VALUE},
  { name: RULE_LINE_ITEMS_OPTIONS.VARIANT_TITLE_NAME, value: RULE_LINE_ITEMS_OPTIONS.VARIANT_TITLE_VALUE},
  { name: RULE_LINE_ITEMS_OPTIONS.QUANTITY_NAME, value: RULE_LINE_ITEMS_OPTIONS.QUANTITY_VALUE},
  { name: RULE_LINE_ITEMS_OPTIONS.PRICE_NAME, value: RULE_LINE_ITEMS_OPTIONS.PRICE_VALUE},
  // { name: RULE_LINE_ITEMS_OPTIONS.DISCOUNT_NAME, value: RULE_LINE_ITEMS_OPTIONS.DISCOUNT_VALUE},
];

export const RULE_LINE_ITEMS_VALUE_TO_NAME_OPTIONS = {
  [RULE_LINE_ITEMS_OPTIONS.PRODUCT_ID_VALUE]: RULE_LINE_ITEMS_OPTIONS.PRODUCT_ID_NAME,
  [RULE_LINE_ITEMS_OPTIONS.VARIANT_ID_VALUE]: RULE_LINE_ITEMS_OPTIONS.VARIANT_ID_NAME,
  [RULE_LINE_ITEMS_OPTIONS.PRODUCT_TITLE_VALUE]: RULE_LINE_ITEMS_OPTIONS.PRODUCT_TITLE_NAME,
  [RULE_LINE_ITEMS_OPTIONS.VARIANT_TITLE_VALUE]: RULE_LINE_ITEMS_OPTIONS.VARIANT_TITLE_NAME,
  [RULE_LINE_ITEMS_OPTIONS.QUANTITY_VALUE]: RULE_LINE_ITEMS_OPTIONS.QUANTITY_NAME,
  [RULE_LINE_ITEMS_OPTIONS.PRICE_VALUE]: RULE_LINE_ITEMS_OPTIONS.PRICE_NAME,
  // [RULE_LINE_ITEMS_OPTIONS.DISCOUNT_VALUE]: RULE_LINE_ITEMS_OPTIONS.DISCOUNT_NAME,
};

export const RULE_ACTIONS_OPTIONS = {
  EQUAL_NAME: 'Equal to',
  EQUAL_VALUE: 'EqualTo',
  NOT_EQUAL_NAME: 'Not equal to',
  NOT_EQUAL_VALUE: 'NotEqualTo',
  LESS_THAN_NAME: 'Less than',
  LESS_THAN_VALUE: 'Less',
  LESS_THAN_OR_EQUAL_TO_NAME: 'Less than or equal to',
  LESS_THAN_OR_EQUAL_TO_VALUE: 'LessEqualTo',
  GREATER_THAN_NAME: 'Greater than',
  GREATER_THAN_VALUE: 'Greater',
  GREATER_THAN_OR_EQUAL_TO_NAME: 'Greater than or equal to',
  GREATER_THAN_OR_EQUAL_TO_VALUE: 'GreaterEqualTo',
  GREATER_THAN_AND_LESS_THAN_NAME: 'Greater than and less than',
  GREATER_THAN_AND_LESS_THAN_VALUE: 'GreaterLess',
};

export const EQUAL_NOT_EQUAL = [
  { name: RULE_ACTIONS_OPTIONS.EQUAL_NAME, value: RULE_ACTIONS_OPTIONS.EQUAL_VALUE },
  { name: RULE_ACTIONS_OPTIONS.NOT_EQUAL_NAME, value: RULE_ACTIONS_OPTIONS.NOT_EQUAL_VALUE },
];

export const GREATER_LESS = [
  { name: RULE_ACTIONS_OPTIONS.LESS_THAN_NAME, value: RULE_ACTIONS_OPTIONS.LESS_THAN_VALUE },
  { name: RULE_ACTIONS_OPTIONS.LESS_THAN_OR_EQUAL_TO_NAME, value: RULE_ACTIONS_OPTIONS.LESS_THAN_OR_EQUAL_TO_VALUE },
  { name: RULE_ACTIONS_OPTIONS.GREATER_THAN_NAME, value: RULE_ACTIONS_OPTIONS.GREATER_THAN_VALUE },
  { name: RULE_ACTIONS_OPTIONS.GREATER_THAN_OR_EQUAL_TO_NAME, value: RULE_ACTIONS_OPTIONS.GREATER_THAN_OR_EQUAL_TO_VALUE },
  { name: RULE_ACTIONS_OPTIONS.GREATER_THAN_AND_LESS_THAN_NAME, value: RULE_ACTIONS_OPTIONS.GREATER_THAN_AND_LESS_THAN_VALUE },
];

export const EQUAL_NOT_EQUAL_GREATER_LESS = [
  ...EQUAL_NOT_EQUAL,
  ...GREATER_LESS,
];

export const SOURCE_OF_SUBSCRIPTION_TO_ACTION = {
  [RULE_TYPES_OPTIONS.SOURCE_OF_SUBSCRIPTION_VALUE]: EQUAL_NOT_EQUAL,
};

export const MONEY_ORDERS_AND_DISCOUNTS_TO_ACTION = {
  [RULE_TYPES_OPTIONS.MONEY_SPENT_VALUE]: EQUAL_NOT_EQUAL_GREATER_LESS,
  [RULE_TYPES_OPTIONS.NUMBER_OF_ORDERS_VALUE]: EQUAL_NOT_EQUAL_GREATER_LESS,
  [RULE_TYPES_OPTIONS.AVERAGE_MONEY_AMOUNT_VALUE]: EQUAL_NOT_EQUAL_GREATER_LESS,
  [RULE_TYPES_OPTIONS.TOTAL_DISCOUNTS_VALUE]: EQUAL_NOT_EQUAL_GREATER_LESS,
};

export  const ORDER_CREATED_CUSTOMER_CREATED_RULE_TO_ACTION = {
  [RULE_TYPES_OPTIONS.LAST_ORDER_CREATED_VALUE]: EQUAL_NOT_EQUAL,
  [RULE_TYPES_OPTIONS.CUSTOMER_CREATED_RULE_VALUE]: EQUAL_NOT_EQUAL,
};

export  const UPSELL_PARAMS_TO_ACTION = {
  [RULE_TYPES_OPTIONS.ORDER_SPECIFIC_PRODUCTS_VALUE]: EQUAL_NOT_EQUAL,
  [RULE_TYPES_OPTIONS.ORDER_SPECIFIC_COLLECTIONS_VALUE]: EQUAL_NOT_EQUAL,
};

export  const LINE_ITEMS_PRODUCT_VARIANT_TO_ACTION = {
  [RULE_LINE_ITEMS_OPTIONS.PRODUCT_ID_VALUE]: EQUAL_NOT_EQUAL,
  [RULE_LINE_ITEMS_OPTIONS.VARIANT_ID_VALUE]: EQUAL_NOT_EQUAL,
  [RULE_LINE_ITEMS_OPTIONS.PRODUCT_TITLE_VALUE]: EQUAL_NOT_EQUAL,
  [RULE_LINE_ITEMS_OPTIONS.VARIANT_TITLE_VALUE]: EQUAL_NOT_EQUAL,
};

export  const LINE_ITEMS_QUANTITY_PRICE_DISCOUNT_TO_ACTION = {
  [RULE_LINE_ITEMS_OPTIONS.QUANTITY_VALUE]: EQUAL_NOT_EQUAL_GREATER_LESS,
  [RULE_LINE_ITEMS_OPTIONS.PRICE_VALUE]: EQUAL_NOT_EQUAL_GREATER_LESS,
  [RULE_LINE_ITEMS_OPTIONS.DISCOUNT_VALUE]: EQUAL_NOT_EQUAL_GREATER_LESS,
};

export const RULE_NAME_TO_SECONDARY_OPTIONS = {
  ...SOURCE_OF_SUBSCRIPTION_TO_ACTION,
  ...MONEY_ORDERS_AND_DISCOUNTS_TO_ACTION,
  ...UPSELL_PARAMS_TO_ACTION,
  ...ORDER_CREATED_CUSTOMER_CREATED_RULE_TO_ACTION,

  ...LINE_ITEMS_PRODUCT_VARIANT_TO_ACTION,
  ...LINE_ITEMS_QUANTITY_PRICE_DISCOUNT_TO_ACTION,
};

export const RULE_UTILS = {
  GROUP: 'RulesGroup',
  RULE: 'rule',
  AND: 'And',
};

export const RULE_TYPE_OPTIONS = [
  {
    name: RULE_TYPES_OPTIONS.SOURCE_OF_SUBSCRIPTION_NAME,
    value: RULE_TYPES_OPTIONS.SOURCE_OF_SUBSCRIPTION_VALUE,
    group: RULE_TYPE_GROUPS.SOURCE_OF_SUBSCRIPTION,
    conditionType: [], // [FLOW_TRIGGERS_TYPE.SHOPIFY_DATA_SPLIT]
  },
  {
    name: RULE_TYPES_OPTIONS.MONEY_SPENT_NAME,
    value: RULE_TYPES_OPTIONS.MONEY_SPENT_VALUE,
    group: RULE_TYPE_GROUPS.CUSTOMER,
    conditionType: [], // [FLOW_TRIGGERS_TYPE.SHOPIFY_DATA_SPLIT, FLOW_TRIGGERS_TYPE.TRIGGERED_EVENT_SPLIT]
  },
  {
    name: RULE_TYPES_OPTIONS.AVERAGE_MONEY_AMOUNT_NAME,
    value: RULE_TYPES_OPTIONS.AVERAGE_MONEY_AMOUNT_VALUE,
    group: RULE_TYPE_GROUPS.CUSTOMER,
    conditionType: [], // [FLOW_TRIGGERS_TYPE.SHOPIFY_DATA_SPLIT]
  },
  {
    name: RULE_TYPES_OPTIONS.NUMBER_OF_ORDERS_NAME,
    value: RULE_TYPES_OPTIONS.NUMBER_OF_ORDERS_VALUE,
    group: RULE_TYPE_GROUPS.CUSTOMER,
    conditionType: [], // [FLOW_TRIGGERS_TYPE.SHOPIFY_DATA_SPLIT]
  },
  {
    name: RULE_TYPES_OPTIONS.TOTAL_DISCOUNTS_NAME,
    value: RULE_TYPES_OPTIONS.TOTAL_DISCOUNTS_VALUE,
    group: RULE_TYPE_GROUPS.CUSTOMER,
    conditionType: [], // [FLOW_TRIGGERS_TYPE.SHOPIFY_DATA_SPLIT, FLOW_TRIGGERS_TYPE.TRIGGERED_EVENT_SPLIT]
  },
  {
    name: RULE_TYPES_OPTIONS.LAST_ORDER_CREATED_NAME,
    value: RULE_TYPES_OPTIONS.LAST_ORDER_CREATED_VALUE,
    group: RULE_TYPE_GROUPS.CUSTOMER,
    conditionType: [], // [FLOW_TRIGGERS_TYPE.SHOPIFY_DATA_SPLIT, FLOW_TRIGGERS_TYPE.TRIGGERED_EVENT_SPLIT]
  },
  {
    name: RULE_TYPES_OPTIONS.CUSTOMER_CREATED_RULE_NAME,
    value: RULE_TYPES_OPTIONS.CUSTOMER_CREATED_RULE_VALUE,
    group: RULE_TYPE_GROUPS.CUSTOMER,
    conditionType: [], // [FLOW_TRIGGERS_TYPE.SHOPIFY_DATA_SPLIT]
  },
  {
    name: RULE_TYPES_OPTIONS.LINE_ITEMS_NAME,
    value: RULE_TYPES_OPTIONS.LINE_ITEMS_VALUE,
    group: RULE_TYPE_GROUPS.CUSTOMER,
    conditionType: [], // [FLOW_TRIGGERS_TYPE.SHOPIFY_DATA_SPLIT, FLOW_TRIGGERS_TYPE.TRIGGERED_EVENT_SPLIT]
  },
  {
    name: RULE_TYPES_OPTIONS.ORDER_SPECIFIC_PRODUCTS_NAME,
    value: RULE_TYPES_OPTIONS.ORDER_SPECIFIC_PRODUCTS_VALUE,
    group: RULE_TYPE_GROUPS.CUSTOMER,
    conditionType: [], // [FLOW_TRIGGERS_TYPE.SHOPIFY_DATA_SPLIT, FLOW_TRIGGERS_TYPE.TRIGGERED_EVENT_SPLIT]
  },
  {
    name: RULE_TYPES_OPTIONS.ORDER_SPECIFIC_COLLECTIONS_NAME,
    value: RULE_TYPES_OPTIONS.ORDER_SPECIFIC_COLLECTIONS_VALUE,
    group: RULE_TYPE_GROUPS.CUSTOMER,
    conditionType: [], // [FLOW_TRIGGERS_TYPE.SHOPIFY_DATA_SPLIT, FLOW_TRIGGERS_TYPE.TRIGGERED_EVENT_SPLIT]
  },
  {
    name: RULE_TYPES_OPTIONS.FULFILLMENT_STATUS_NAME,
    value: RULE_TYPES_OPTIONS.FULFILLMENT_STATUS_VALUE,
    group: RULE_TYPE_GROUPS.ORDER_STATUS,
    conditionType: [FLOW_TRIGGERS_TYPE.SHOPIFY_DATA_SPLIT, FLOW_TRIGGERS_TYPE.TRIGGERED_EVENT_SPLIT],
  },
  {
    name: RULE_TYPES_OPTIONS.PAYMENT_STATUS_NAME,
    value: RULE_TYPES_OPTIONS.PAYMENT_STATUS_VALUE,
    group: RULE_TYPE_GROUPS.ORDER_STATUS,
    conditionType: [FLOW_TRIGGERS_TYPE.SHOPIFY_DATA_SPLIT, FLOW_TRIGGERS_TYPE.TRIGGERED_EVENT_SPLIT],
  },
  {
    name: RULE_TYPES_OPTIONS.ORDER_CANCELLED_NAME,
    value: RULE_TYPES_OPTIONS.ORDER_CANCELLED_VALUE,
    group: RULE_TYPE_GROUPS.ORDER_STATUS,
    conditionType: [FLOW_TRIGGERS_TYPE.SHOPIFY_DATA_SPLIT, FLOW_TRIGGERS_TYPE.TRIGGERED_EVENT_SPLIT],
  },
];
