import { Injectable } from '@angular/core';
import { Store }      from '@ngxs/store';
import { tap }        from 'rxjs/operators';

import { ApiService }        from './api.service';
import { QuietHoursInfo }    from '@core/models';
import { SetQuietHoursInfo } from '@store/actions';

@Injectable()
export class QuietHoursService {
  constructor(private store: Store, private _apiService: ApiService) { }

  public getQuietHours() {
    return this._apiService.get('merchant/qh')
      .pipe(
        tap((quietHoursInfo: QuietHoursInfo) => {
          this.store.dispatch(new SetQuietHoursInfo(quietHoursInfo));
        }),
      ).toPromise();
  }
}
