import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR }                    from '@angular/forms';

import { CustomControlValueAccessor } from '../custom-control-value-accessor';

@Component({
  selector: 'k-colorpicker',
  templateUrl: './colorpicker.component.html',
  styleUrls: ['./colorpicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ColorpickerComponent),
      multi: true,
    },
  ],
})
export class ColorpickerComponent extends CustomControlValueAccessor implements OnInit {
  @Input() position: string = 'auto';
  @Input() set pickerDisabled(state: boolean) {
      this.isDisabled = state;
  }

  public isDisabled: boolean = false;

  constructor() {
    super();
  }

  ngOnInit() { }

  public onChangeColor(value): void {
    this.value = value;
  }
}
