import { Injectable }                  from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { SetQuietHoursInfo }           from '@store/actions';


export interface QuietHoursStateModel {
  from: number;
  to: number;
}

@State<QuietHoursStateModel>({
  name: 'quietHours',
  defaults: {
    from: 0,
    to: 0,
  },
})
@Injectable()
export class QuietHoursState {

  @Action(SetQuietHoursInfo)
  setQuietHoursInfo({ setState }: StateContext<QuietHoursStateModel>, { payload }: SetQuietHoursInfo) {
    setState(payload);
  }

}
