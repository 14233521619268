import { Injectable } from '@angular/core';
import { Store }      from '@ngxs/store';
import { Clipboard }  from '@angular/cdk/clipboard';

import {
  DropdownStringData,
  MessageStrengthHint,
  MessageStrengthMetrics,
} from '@shared/models';
import { CustomImage, MerchantInfo } from '@core/models';
import { URL_REG_EXP }               from '@core/texts';
import { ShortCodeInfo }             from '@shared/components/message-builder/short-codes/short-codes.model';
import { SHORT_CODES }               from '@shared/texts';

import { FlowDataParams } from '@flows/models';

@Injectable()
export class HelperService {
  public readonly maxFileSize = 4.8 * 1024 * 1024; // 4,8 Mb -  1Mb = 1024b * 1024b
  public readonly fqdnRegExp = /(?=^.{4,253}$)(^((?!-)[a-zA-Z0-9-]{1,63}\.)+[a-zA-Z]{2,63}\/?$)/;
  public readonly urlRegExp = URL_REG_EXP;
  public readonly urlMatchRegExp = new RegExp(
    /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gim,
  );
  public readonly optOutStrength = {
    'automation-abandoned': 25,
    'automation-winback': 25,
    'automation-upsell': 25,
    'automation-thank-you': 25,
    'automation-existing-contact': 25,
    'keyword': 25,
    'response': 25,
  };

  private readonly commonShortCodeUrls = [
    SHORT_CODES.SITE_URL,
    SHORT_CODES.DISCOUNT_CODE_URL,
  ];

  private readonly specificShortCodeUrls = [
    SHORT_CODES.RECOMMENDED_PRODUCT_URL,
    SHORT_CODES.ABANDONED_CHECKOUT_URL,
  ];

  constructor(private store: Store, private clipboard: Clipboard) { }

  public isGSMAlphabet(text): boolean {
    const regexp = new RegExp(
      '^[A-Za-z0-9 \\r\\n@£$¥èéùìòÇØøÅå\u0394_\u03A6\u0393\u039B\u03A9\u03A0\u03A8\u03A3\u0398\u039EÆæßÉ!"¤#$%&\'()*+,\\-./:;<=>?¡ÄÖÑÜ§¿äöñüà^{}\\\\\\[~\\]|\u20AC]*$',
    );
    return regexp.test(text);
  }

  public updateMessageStrengthMetrics(
    placeholders: ShortCodeInfo[],
    messageHints,
    templateString,
    shortCodesSubstitutes,
  ): MessageStrengthMetrics {
    const messageStrengthHints: MessageStrengthHint[] = [];
    const shortCodeGroups: string[] = [];
    let messageStrengthWeight = 0;

    placeholders.forEach((placeholderElem: ShortCodeInfo) => {
      const substitutedValue = templateString.includes(shortCodesSubstitutes[placeholderElem.name]);

      const shortCodesExpression = templateString.includes(placeholderElem.name) || substitutedValue;

      if (shortCodesExpression) {
        if (!shortCodeGroups.includes(placeholderElem.group)) {
          messageStrengthWeight += placeholderElem.strength;
          shortCodeGroups.push(placeholderElem.group);
        }
      } else {
        if (messageHints[placeholderElem.name] && placeholderElem.strength) {
          messageStrengthHints.push({ hint: messageHints[placeholderElem.name], name: placeholderElem.name });
        }
      }
    });

    return { messageStrengthWeight, messageStrengthHints };
  }

  public isMessageUrlHintRequired(templateString: string, shortCodes: ShortCodeInfo[]): boolean {
    if (this._getMessageShortCode(shortCodes, this.specificShortCodeUrls)) {
      return false;
    }
    const isShortCodeMatched = this._checkMessageShortCodes(templateString, this.commonShortCodeUrls);
    return !isShortCodeMatched && !templateString.match(this.urlMatchRegExp);
  }

  public updateMessageStrength(messageStrengthWeight: number): string {
    if (messageStrengthWeight < 40) return 'weak';
    if (messageStrengthWeight >= 40 && messageStrengthWeight < 75) return 'strong';
    if (messageStrengthWeight >= 75) return 'powerful';
  }

  public getNumberOfSMS(isUnicode, templateString): number {
    let textLength = templateString.length;
    if (!textLength) {
      return 0;
    }

    if (isUnicode) {
      textLength += this._getMessageLength(templateString);
    }

    if (isUnicode) {
      return textLength > 160 ? Math.ceil(textLength / 153) : 1; //160  153 to default config
    } else {
      return textLength > 70 ? Math.ceil(textLength / 67) : 1; //70 67 to default config
    }
  }

  public getSizeOfSMS(templateString, numberOfSMS, smsLimit): number {
    let templateLength = templateString.length;

    if (smsLimit > 70) {
      templateLength += this._getMessageLength(templateString);
    }

    return templateLength > smsLimit ? templateLength - (numberOfSMS - 1) * smsLimit : templateLength;
  }

  public getLimitOfSMS(isUnicode, templateString): number {
    let templateLength = templateString.length;

    if (isUnicode) {
      templateLength += this._getMessageLength(templateString);
    }

    let limit;
    if (isUnicode) {
      limit = templateLength > 160 ? 153 : 160;
    } else {
      limit = templateLength > 70 ? 67 : 70;
    }

    return limit;
  }

  public convertFileToImageUrl(
    image: CustomImage | File | string,
    setImage: (imageUrl: string | null, imageName: string | null) => void,
  ): void {
    if (!image) {
      setImage(null, null);
      return;
    }

    if (image instanceof CustomImage) {
      setImage(image.url, image.name);
      return;
    }

    if (image instanceof File) {
      const fileReader: FileReader = new FileReader();
      fileReader.onloadend = () => {
        setImage(fileReader.result as string, null);
      };
      fileReader.readAsDataURL(image);
    }
  }

  public toggleShowMore(el): void {
    const initialText = 'Show more';
    const nextText = 'Show less';

    const targetEl = el.querySelector('.k-link__text');

    targetEl.textContent = targetEl.textContent === initialText ? nextText : initialText;
  }

  public checkForImage(formValue, imageFormGroup) {
    const updateFormValue = formValue;

    if (!formValue[imageFormGroup].image) {
      updateFormValue[imageFormGroup].image = null;
      return updateFormValue;
    }

    return updateFormValue;
  }

  public isFileExtentionCorrect(file: File) {
    return !!file.name.match(/\.(gif|jpe?g|png)$/i);
  }

  public substituteShortCodes(templateString, substitutes): string {
    let updatedString = this.replaceExternalUrls(templateString);

    if (!substitutes) return templateString;

    substitutes.forEach(elem => {
      const key = Object.keys(elem)[0];
      if (templateString.includes(key)) {
        const regExp = new RegExp(key, 'g');
        if (elem[key]) updatedString = updatedString.replace(regExp, elem[key]);
      }
    });

    return updatedString;
  }

  public secondsConverter(delays, time, type, isConvertingToSeconds: boolean): number {
    const sec = delays.find((delay) => delay.value === type).seconds;
    return Math.floor(time * (isConvertingToSeconds ? sec : 1/sec));
  }

  public replaceExternalUrls(templateString: string): string {
    const regExpUrls = templateString.match(this.urlMatchRegExp);

    if (!regExpUrls) {
      return templateString;
    }

    regExpUrls.forEach(url => {
      const merchantInfo = this.store.selectSnapshot(state => state.merchant);
      const shortDomain = this.getShortDomain(merchantInfo);

      templateString = templateString.replace(url, shortDomain);
    });

    return templateString;
  }

  public getMerchantStore(domain: string): string {
    return domain.replace('.myshopify.com', '');
  }

  public getMerchantDomain(merchantInfo: MerchantInfo): string {
    return merchantInfo.isSubscriptionFree ? `https://${merchantInfo.domain}` : `https://${merchantInfo.domain}`;
  }

  public getShortDomain(merchantInfo: MerchantInfo): string {
    const storeName = this.getMerchantStore(merchantInfo.domain);

    switch (merchantInfo.shortenerSettings?.globalShortUrl) {
      case 'default':
        return `https://${storeName}.knkt.cc/xxxxxx`;
      case 'short':
        return 'https://knkt.cc/xxxxxx';
      case 'custom':
        return '';
    }
  }

  public getMerchantCheckoutURL(merchantInfo: MerchantInfo, isTestPreview: boolean = false): string {
    return isTestPreview
      ? `https://${merchantInfo.domain}`
      : `https://${merchantInfo.domain}/AbandonedCart`;
  }

  public getDataForDropdown(dataCollection: string[]): DropdownStringData[] {
    return dataCollection.map(elem => ({ name: elem, value: elem }));
  }

  public copyText(text: string, callback?): void {
    this.clipboard.copy(text);

    if (callback instanceof Function) callback();
  }

  public getFirstStepId(id: string, steps: FlowDataParams, type: string): string {
    if (!steps[id]) return null;
    if (steps[id].type === type) return id;
    const next = steps[id].next;
    if (!next) return null;
    if (Array.isArray(next)) return null;
    return this.getFirstStepId(next, steps, type);
  }

  private _checkMessageShortCodes(templateString: string, codes: string[]): boolean {
    return codes.some((code) => templateString.includes(code));
  }

  private _getMessageShortCode(shortCodes: ShortCodeInfo[], strengthHintShortCodes: string[]): string | undefined {
    return strengthHintShortCodes.find((shortCodeUrl) =>
      shortCodes.some((shortCode) => shortCode.name === shortCodeUrl));
  }

  public getSoftHyphens(text: string): string {
    return text.replace(/([\/~.,\-_?#%\s])/gi, '<wbr>$1');
  }

  private _getMessageLength(templateString) {
    const specialSymbols = templateString.match(/[|\[\]}{\\~^€]/g);
    return specialSymbols ? specialSymbols.length : 0;
  }
}
