import { NgModule }             from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {
  SettingsBrandingComponent,
  SettingsGeneralComponent,
  SettingsIntegrationsComponent,
  SettingsLayoutComponent,
  SettingsNotificationsComponent,
  SettingsPaymentsComponent,
  SettingsSendingLogicComponent,
  SettingsTcpaComponent,
} from '@settings/components';

import { CanDeactivateGuard } from '@core/guards';

const routes: Routes = [
  {
    path: 'settings',
    component: SettingsLayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'general',
      },
      {
        path: 'general',
        component: SettingsGeneralComponent,
        data: { isCenteringPage: true, intercomTag: 'settings' },
      },
      {
        path: 'sending-logic',
        component: SettingsSendingLogicComponent,
        canDeactivate: [CanDeactivateGuard],
        data: { isCenteringPage: true, intercomTag: 'settings' },
      },
      {
        path: 'payments',
        component: SettingsPaymentsComponent,
        data: { isCenteringPage: true, intercomTag: 'settings/payments' },
      },
      {
        path: 'notifications',
        component: SettingsNotificationsComponent,
        data: { isCenteringPage: true },
      },
      {
        path: 'tcpa',
        component: SettingsTcpaComponent,
        data: { isCenteringPage: true },
      },
      {
        path: 'branding',
        component: SettingsBrandingComponent,
        data: { isCenteringPage: true },
      },
      {
        path: 'integrations',
        component: SettingsIntegrationsComponent,
        data: { isCenteringPage: true, intercomTag: 'settings' },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  providers: [],
  exports: [RouterModule],
})
export class SettingsRoutingModule { }
