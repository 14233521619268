export interface RuleItem {
  type: string;
  action: string;
  sourceType: string;
  sourceId?: number;
  value?: string | boolean;
  products?: any[];
  collections?: any[];
  period?: string;
  range?: string[] | null;
  values?: string[];
  parameter?: string;
}
