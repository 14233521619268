import { AfterViewInit, Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';

import { Select }                   from '@ngxs/store';
import { Observable }               from 'rxjs';

import { ButtonType } from '@shared/components/base/back-button/back-button.model';

@Component({
  selector: 'k-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
})
export class EditorComponent implements OnInit, AfterViewInit {
  @Select(state => state.app.savingProgress) savingProgress$: Observable<boolean>;

  @Input() isHeaderVisible: boolean = true;
  @Input() formId: string;
  @Input() backUrl: string;
  @Input() hasTabs: boolean = false;
  @Input() hasTitles: boolean = false;
  @Input() hasNav: boolean = false;
  @Input() loading: boolean = false;
  @Input() submitBtnName = 'Save';
  @Input() whiteFormBg: boolean = false;
  @Input() showDefaultSubmitBtn: boolean = true;
  @Input() previewBackground: 'default' | 'none' | 'circle' = 'default';
  @Input() dirtyState: boolean = false;
  @Input() isQuietHours: boolean = false;
  @Input() inBotConversation: boolean;
  @Input() headerOnly: boolean = false;
  @Input() minimizeHeader: boolean = false;
  @Input() backButtonType: ButtonType = 'normal';
  @Input() backButtonName: string = 'Back to list';

  @ViewChild('form') form: ElementRef;

  constructor(private renderer: Renderer2) { }

  ngOnInit() { }

  ngAfterViewInit() {
    if (this.whiteFormBg) {
      this.renderer.addClass(this.form.nativeElement, 'white-bg');
    }
  }
}
