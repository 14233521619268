import { Injectable } from '@angular/core';
import { Store }      from '@ngxs/store';

import { NotiService } from '@notifications/services';

@Injectable()
export class EntitySaveNotificationService {
  public currentlyShownNotifications = this.store.selectSnapshot(state => state.notifications.currentlyShownNotification);

  constructor( private store: Store,
               private _notiService: NotiService) { }

  public onSavedKeywordSegment(): void {
    const notificationTriggerId: number = this.store.selectSnapshot(state => state.merchant.notifications.savedKeywordSegment.triggerId);
    const currentNotification: number = this.currentlyShownNotifications.find((notification) => {
      return notification.triggerId === notificationTriggerId;
    });

    if (!currentNotification) {
      this._notiService.savedKeywordSegment();
    }
  }

  public onSavedMessaging(): void {
    const notificationTriggerId: number = this.store.selectSnapshot(state => state.merchant.notifications.savedMessaging.triggerId);
    const currentNotification: number = this.currentlyShownNotifications.find((notification) => {
      return notification.triggerId === notificationTriggerId;
    });

    if (!currentNotification) {
      this._notiService.savedMessaging();
    }
  }

  public onSavedSubscription(): void {
    const notificationTriggerId: number = this.store.selectSnapshot(state => state.merchant.notifications.savedSubscription.triggerId);
    const currentNotification: number = this.currentlyShownNotifications.find((notification) => {
      return notification.triggerId === notificationTriggerId;
    });

    if (!currentNotification) {
      this._notiService.savedSubscription();
    }
  }
}
