import { Injectable }                  from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { SetNonGSMSymbols } from '@store/actions';

import { NonGSMSymbols } from '@shared/models';

export interface NonGSMSymbolsStateModel {
  symbols: NonGSMSymbols;
}

@State<NonGSMSymbolsStateModel>({
  name: 'nonGSMSymbols',
  defaults: {
    symbols: {
      regex: '',
      replacement: '',
    },
  },
})
@Injectable()
export class SetNonGSMSymbolsState {

  @Action(SetNonGSMSymbols)
  SetNonGSMSymbols({ patchState }: StateContext<NonGSMSymbolsStateModel>, { payload }: SetNonGSMSymbols) {
    patchState({ symbols: payload });
  }
}
