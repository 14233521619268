import {
  Directive,
  ElementRef,
  HostListener,
  Input } from '@angular/core';

import { EnteringType } from '@shared/components/base/input/input.model';

@Directive({
  selector: '[kNumbersOnly]',
})
export class NumbersOnlyDirective {
  @Input('kNumbersOnly') status: EnteringType;

  private readonly regexNumberOnly: RegExp = new RegExp('\\d*,*');
  private readonly regexNumberDotValue: RegExp = new RegExp('^\\d*(,*\\d*)*\\.?\\d{0,2}?$');
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'Clear',
    'Copy', 'Control', 'Del', 'Shift', 'ArrowLeft', 'ArrowLeft'];

  constructor(private el: ElementRef) { }

  @HostListener('keypress', ['$event'])
  onKeyPress(event) {
    switch (this.status) {
      case 'numberOnly':
        return this.regexNumberOnly.test(event.key);
      case 'numberOneDotOnly':
        if (this.specialKeys.indexOf(event.key) !== -1
          || event.ctrlKey
          || event.altKey
          || event.metaKey
        ) {
          return true;
        }

        const current = this.el.nativeElement.value;
        const next = current.substring(0, this.el.nativeElement.selectionStart)
          + event.key
          + current.substring(this.el.nativeElement.selectionEnd);

        return next ? this.regexNumberDotValue.test(next) : true;
      case 'any':
      default:
        return true;
    }
  }

  @HostListener('paste', ['$event'])
  blockPaste(event: ClipboardEvent) {
    switch (this.status) {
      case 'numberOnly':
        event.preventDefault();
        this._pasteDate(
          this._getStringFromClipboard(event)
            .replace(/[^0-9]/g, ''),
        );
        break;
      case 'numberOneDotOnly':
        event.preventDefault();
        const str = this._getStringFromClipboard(event);
        const numberDotOnlyStr = str.replace(/[^\d.?]/g, '');
        let strArr = numberDotOnlyStr.split('.');
        if (strArr.length > 1) {
          let last = strArr[strArr.length - 1];
          if (last.length > 2) {
            last = last.substr(0, 2);
          }
          const numberOnlyStr = strArr.slice(0, -1).join('');
          strArr = [numberOnlyStr, last];
        }
        this._pasteDate(strArr.join('.'));
        break;
    }
  }

  private _getStringFromClipboard(event: ClipboardEvent): string {
    return event.clipboardData
      .getData('text/plain');
  }

  private _pasteDate(pasteData: string): void {
    document.execCommand('insertHTML', false, pasteData);
  }
}
