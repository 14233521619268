import { NgModule }              from '@angular/core';
import { Routes, RouterModule }  from '@angular/router';

import {
  InstallPageComponent,
  LegalAgreementsComponent,
  TermsComponent,
} from '@legal-agreements/components';


const routes: Routes = [
  {
    path: 'legal',
    component: LegalAgreementsComponent,
    data: { hideHeader: true },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'install',
      },
      {
        path: 'install',
        component: InstallPageComponent,
        data: { hideHeader: true },
      },
      {
        path: 'terms',
        component: TermsComponent,
        data: { hideHeader: true },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  providers: [],
  exports: [RouterModule],
})
export class LegalAgreementsRoutingModule { }
