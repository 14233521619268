import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Table }                               from '@shared/models';

@Component({
  selector: 'k-table-loader',
  templateUrl: './table-loader.component.html',
  styleUrls: ['./table-loader.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TableLoaderComponent {

  @Input() count: number = 4;
  @Input() columns: Table[];


  public get items() {
    return Array.from(Array(this.count).keys());
  }
}
