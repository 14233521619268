import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'k-editor-dialog',
  templateUrl: './editor-dialog.component.html',
  styleUrls: ['./editor-dialog.component.scss'],
})
export class EditorDialogComponent implements OnInit, AfterViewInit {

  @Input() modalTitle: string;
  @Input() modalType: string;
  @Input() identifier: string;
  @Input() cancelBtnName: string = '';
  @Input() padding: boolean = true;
  @Input() hideFooterBorder: boolean = false;
  @Input() visibleIcon: boolean = true;
  @Input() isCancelBtnHidden: boolean = false;
  @Input() isCancelBtnDisabled: boolean = false;
  @Input() isFullWidthBtn: boolean = false;
  @Input() loading: boolean = false;

  @Output() openingModal: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() closed: EventEmitter<any> = new EventEmitter<any>();
  @Output() anyClose: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('editorDialog') editorDialog: ElementRef;

  constructor(private renderer: Renderer2) { }

  ngOnInit() {
    this.onOpeningModal();
  }
  ngAfterViewInit() {
    if (this.modalType) {
      this.renderer.addClass(this.editorDialog.nativeElement, `k-editor-dialog-type-${this.modalType}`);
    }
  }

  public onOpeningModal(): void {
    this.openingModal.emit({});
  }

  public onClose(isCloseButton?: boolean): void {
    isCloseButton ? this.anyClose.emit({}) : this.cancel.emit({});
  }

  public onAnyClose(): void {
    this.anyClose.emit({});
  }

  public onCloseFinished(): void {
    this.closed.emit();
  }
}
