import { Component, Input, OnInit } from '@angular/core';
import { NgxSmartModalService }     from 'ngx-smart-modal';
import { Select }                   from '@ngxs/store';
import { Observable }               from 'rxjs';

import { MerchantService } from '@core/services';

@Component({
  selector: 'k-balance',
  templateUrl: './balance.component.html',
  styleUrls: ['./balance.component.scss'],
})
export class BalanceComponent implements OnInit {
  @Select(state => state.merchant.balance) balance$: Observable<number>;

  @Input() isExpanded: boolean;

  constructor(private modal: NgxSmartModalService,
              private _merchantService: MerchantService) { }

  ngOnInit() { }

  public onShowPaymentDialog(): void {
    this._merchantService.loadMerchantInfo().then(() => {
      this.modal.setModalData({
        paymentType: 'autoRecharge',
        modalTitle: 'Add funds',
        showToggleButtons: true,
      }, 'paymentDialog', true);

      this.modal.getModal('paymentDialog').open();
    });
  }
}
