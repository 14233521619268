import { Injectable } from '@angular/core';

import { Button, Redirect, TitleBar } from '@shopify/app-bridge/actions';

import { ShopifyService }     from '@core/services/shopify.service';
import { Breadcrumbs }        from '@core/models';

@Injectable()
export class TitleBarService {
  private shopifyTitleBar: TitleBar.TitleBar;
  private shopifyButton: Button.Button;
  private shopifyRedirect: Redirect.Redirect;

  constructor(private _shopifyService: ShopifyService) {
    this.shopifyTitleBar = this._shopifyService.titleBar;
    this.shopifyButton = this._shopifyService.button;
    this.shopifyRedirect = this._shopifyService.redirect;
  }


  public setSectionBreadcrumb(title: Breadcrumbs): void {
    this.shopifyTitleBar.set({ title, breadcrumbs: undefined });
  }

  public setEditorBreadcrumb(title: Breadcrumbs, parentTitle: Breadcrumbs, parentRoute: string): void {
    const breadcrumb = this.shopifyButton.set({ label: parentTitle });

    breadcrumb.subscribe(Button.Action.CLICK, () => {
      this.shopifyRedirect.dispatch(Redirect.Action.APP, parentRoute);
      breadcrumb.unsubscribe();
    });

    this.shopifyTitleBar.set({ title, breadcrumbs: breadcrumb });
  }

  public updateEditorBreadcrumbTitle(title: Breadcrumbs): void {
    this.shopifyTitleBar.set({ title });
  }
}

