import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer }        from '@angular/platform-browser';

@Pipe({
  name: 'trustHTML',
})
export class TrustHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }

  transform(content) {
    return this.sanitizer.bypassSecurityTrustHtml(content);
  }
}
