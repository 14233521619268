import { Injectable } from '@angular/core';
import { Store }      from '@ngxs/store';
import { Observable } from 'rxjs';
import { map, tap }   from 'rxjs/operators';
import moment         from 'moment';

import {
  SetResponseHistory,
  SetResponseOriginal,
  SetResponsesUnreadStatus,
  AddResponseHistoryMessage,
  AddResponseHistoryMessages,
  DeleteResponseHistoryMessage,
  UpdateResponseHistoryMessage,
} from '@store/actions';

import { ApiService, SocketService, TimeService } from '@core/services';
import { ResponseEditItem, ResponseHistoryItem }  from '@responses/models';
import { TableSortData }                          from '@shared/models';
import { HasChangesService }                      from '@features/models';

@Injectable()
export class ResponseService implements HasChangesService {
  constructor(private store: Store,
              private _apiService: ApiService,
              private _socketService: SocketService,
              private _timeService: TimeService) { }

  public getResponse(id: number): Observable<any> {
    return this._apiService.get(`responses/${id}`).pipe(
      tap(data => this.store.dispatch(new SetResponseOriginal(data))),
    );
  }

  public updateResponseStatus(status: 'Resolved' | 'Unresolved', button: boolean, id: number): Observable<any> {
    return this._apiService.post(`responses/${id}/resolved`, { status, button });
  }

  public optOutContact(id: number): Observable<any> {
    return this._apiService.post(`responses/${id}/opt-out`, {});
  }

  public sendReply(response: ResponseEditItem, isQH: boolean): Observable<ResponseEditItem> {
    return this._apiService.post(`responses/${response.id}/messages`, this._prepareFormData(response, ['id']))
      .pipe(
        tap(data => {
          const message: ResponseHistoryItem = {
            id: data.messageId,
            responseId: response.id,
            message: response.messageEditor.templateString,
            image: response.messageEditor.image?.url,
            type: 'outgoing',
            status: 'pending',
            date: moment(new Date()).format('h:mm A'),
            error: false,
            last: false,
            errorDescribing: null,
            scheduledResponseDate: isQH ? moment(new Date()).format('h:mm A') : null,
            badFeedback: data.badFeedback,
            messageType: 'Agent',
          };
          this.store.dispatch(new AddResponseHistoryMessage(message, response.id));
          this._socketService.responseMessageAdded.next({ isCustomerIncomingMsg: false });
        }),
      );
  }

  public resendMessage(responseId: number, message: ResponseHistoryItem): Observable<any> {
    return this._apiService.post(`responses/${responseId}/messages/${message.id}/resend`)
      .pipe(
        tap(() => {
          const messageObj: ResponseHistoryItem = {
            ...message,
            status: 'pending',
            error: false,
          };

          this.store.dispatch(new UpdateResponseHistoryMessage(messageObj, responseId));
        }),
      );
  }

  public sendScheduleMessageNow(responseId: number, message: ResponseHistoryItem): Observable<any> {
    return this._apiService.post(`responses/${responseId}/messages/${message.id}/send-now`)
      .pipe(
        tap(() => {
          const messageObj: ResponseHistoryItem = {
            ...message,
            type: 'outgoing',
            status: 'pending',
            date: moment(new Date()).format('h:mm A'),
            error: false,
            scheduledResponseDate: null,
          };

          this.store.dispatch(new UpdateResponseHistoryMessage(messageObj, responseId));
        }),
      );
  }

  public deleteMessage(responseId: number, messageId: number): Observable<any> {
    return this._apiService.delete(`responses/${responseId}/messages/${messageId}`)
      .pipe(
        tap(() => {
          this.store.dispatch(new DeleteResponseHistoryMessage(messageId));
        }),
      );
  }

  public resendScheduleMessage(responseId: number, message: ResponseHistoryItem): Observable<any> {
    return this._apiService.post(`responses/${responseId}/messages/${message.id}/resend-scheduled`)
      .pipe(
        tap(() => {
          const messageObj: ResponseHistoryItem = {
            ...message,
            status: 'pending',
            error: false,
          };

          this.store.dispatch(new UpdateResponseHistoryMessage(messageObj, responseId));
        }),
      );
  }

  public getResponsesUnreadStatus(): Observable<boolean> {
    return this._apiService.get('responses/not-read')
      .pipe(
        tap((res) => {
          this.store.dispatch(new SetResponsesUnreadStatus(res.status));
        }),
      );
  }

  public stopBot(id: number): Observable<boolean> {
    return this._apiService.post(`responses/${id}/stop-conversation`);
  }

  public hasChanges(): boolean {
    const responseOriginal = this.store.selectSnapshot(state => state.responses.responseOriginal);
    const responseForm = this.store.selectSnapshot(state => state.responses.response.model);

    console.log('responseOriginal', responseOriginal);
    console.log('responseForm', responseForm);

    return ( // create page
        responseForm.id === null &&
        !responseOriginal &&
        (
          responseForm.name
          || responseForm.messageEditor.image
          || responseForm.messageEditor.templateString.replace(/\n?\r?/g, '') !== ''
          || responseForm.messageEditor.discountCode
          || responseForm.messageEditor.discountUrl
        )
      )
      ||
      ( // update page
        responseOriginal &&
        (
          (
            responseForm.id !== null
            && responseOriginal.image
            && (
              (responseForm.messageEditor.image && '' !== responseForm.messageEditor.image.url)
              || ('' && !responseForm.messageEditor.image)
            )
          )
          || '' !== responseForm.messageEditor.templateString.replace(/\n?\r?/g, '')
          || '' !== responseForm.messageEditor.discountCode
          || '' !== responseForm.messageEditor.discountUrl
        )
      );
  }

  public getHistory(response: ResponseEditItem): Observable<any> {
    const params = { 'contactId': response.id };

    return this._apiService.get(`responses/${response.id}/message-history`, params).pipe(
      map(data => {
        return {
          ...data,
          messages: this._prepareMessages(data.messages),
        };
      }),
      tap(data => {
        this.store.dispatch(new SetResponseHistory(data));
      }),
    );
  }

  public loadHistory(response: ResponseEditItem, last: number): Observable<any> {
    const params = { 'contactId': response.id, 'last': last };

    return this._apiService.get(`responses/${response.id}/message-history`, params).pipe(
      map(data => {
        return {
          ...data,
          messages: this._prepareMessages(data.messages),
        };
      }),
      tap(data => {
        this.store.dispatch(new AddResponseHistoryMessages(data.messages));
      }),
    );
  }

  public getUnresolvedState(sortingData: TableSortData, response: ResponseEditItem): Observable<any> {
    const params = {
      skip: 0,
      limit: 0,
      order: sortingData.field,
      sort: sortingData.sort,
      filter: 'Unresolved',
    };

    return this._apiService.get(`responses/${response.id}/unresolved-state`, params);
  }

  private _prepareMessages(messages: ResponseHistoryItem[]): ResponseHistoryItem[] {
    return messages.map((message: ResponseHistoryItem) => {
      return {
        ...message,
        date: this._timeService.prepareResponseTimeFormat(message.date),
      };
    });
  }

  private _prepareFormData(response: ResponseEditItem, excludedKeys?: any[]) {
    const data = {
      ...response,
      ...response.messageEditor,
    };

    const { messageEditor, ...payload } = data;

    const formData: any = {};

    for (const field in payload) {
      if (payload.hasOwnProperty(field)) {
        if (excludedKeys && excludedKeys.includes(field)) continue;

        if (typeof payload[field] === 'string') payload[field] = payload[field].trim();

        formData[field] = payload[field];
      }
    }

    return formData;
  }
}
