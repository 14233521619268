import { Component, Input, OnInit } from '@angular/core';
import { Router }                   from '@angular/router';

import { IconColor } from '@shared/components/base/icon/icon.model';

@Component({
  selector: 'k-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  @Input() items: any[];
  @Input() isExpanded: boolean;

  constructor(private router: Router) { }

  ngOnInit() { }

  public iconColor(item): IconColor {
    return this.router.isActive(item.link, item.options.exact) ? 'white' : 'light-violet';
  }
}
