import { Directive, ElementRef, Renderer2 } from '@angular/core';
import { Store }                            from '@ngxs/store';

@Directive({
  selector: '[kLink]',
})
export class LinkDirective {
  constructor(private el: ElementRef<HTMLLinkElement>, private renderer: Renderer2, private store: Store) {
    setTimeout(() => {
      const shopParams = this.store.selectSnapshot(state => state.app.shopParams);
      const params = (new URLSearchParams(shopParams)).toString();

      renderer.setAttribute(el.nativeElement, 'href', el.nativeElement.href += `?${params}`);
    });
  }
}
