import { Component, AfterViewInit, ElementRef, Renderer2, ViewEncapsulation, Input, SimpleChanges, OnChanges } from '@angular/core';

import { CardSize, CardType } from './card.model';

@Component({
  selector: 'k-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CardComponent implements AfterViewInit, OnChanges {
  @Input() type: CardType = 'board';
  @Input() size: CardSize = 'normal';

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngAfterViewInit() {
    this.renderer.addClass(this.el.nativeElement, 'k-card');
    this.renderer.addClass(this.el.nativeElement, `k-card--${this.type}`);
    this.renderer.addClass(this.el.nativeElement, `k-card--${this.size}`);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.type) return;

    this.renderer.removeClass(this.el.nativeElement, `k-card--${changes.type.previousValue}`);
    this.renderer.addClass(this.el.nativeElement, `k-card--${this.type}`);
  }
}
