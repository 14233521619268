import { DiscountStateModel, DiscountTemplateModel } from '@shared/models';

export class SetDiscountData {
  static readonly type = '[DiscountService] setDiscountData';

  constructor(public payload: DiscountStateModel) {}
}

export class SetDiscountTemplateData {
  static readonly type = '[DiscountService] SetDiscountTemplateData';

  constructor(public payload: DiscountTemplateModel) {}
}

export class ClearDiscountTemplateData {
  static readonly type = '[DiscountService] ClearDiscountTemplateData';

  constructor() {}
}

export class ClearDiscountData {
  static readonly type = '[DiscountService] clearDiscountData';

  constructor() {}
}
