import { NgModule }     from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule }           from '@shared/shared.module';
import { DashboardRoutingModule } from './dashboard-routing.module';

import { DashboardComponent } from './dashboard.component';
import { TitleBarService }    from '@core/services';

@NgModule({
  declarations: [DashboardComponent],
  imports: [
    CommonModule,
    SharedModule,
    DashboardRoutingModule,
  ],
  providers: [
    TitleBarService,
  ],
})
export class DashboardModule { }
