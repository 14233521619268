import { Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

import { IconColor } from '../../icon/icon.model';

@Component({
  selector: 'k-spinner-rounded',
  templateUrl: './spinner-rounded.component.html',
  styleUrls: ['./spinner-rounded.component.scss'],
})
export class SpinnerRoundedComponent implements OnInit {

  @Input() color: IconColor = 'gray';
  @Input() size: number = 14;

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngOnInit() {
    if (this.size) {
      this.renderer.setStyle(this.el.nativeElement, 'width', `${this.size}px`);
      this.renderer.setStyle(this.el.nativeElement, 'height', `${this.size}px`);
    }
  }

}
