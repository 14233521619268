import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup }            from '@angular/forms';
import { combineLatest, Observable, Subject }                        from 'rxjs';
import { first, takeUntil, tap }                                     from 'rxjs/operators';
import { Select }                                                    from '@ngxs/store';

import {
  RULE_ACTIONS_OPTIONS, RULE_SECONDARY_OPTIONS,
  RULE_SOURCE_TYPE_OPTIONS,
  RULE_TYPES_OPTIONS,
  RULE_UTILS,
} from '@shared/components/rules/texts';
import { RuleItem }        from '@shared/models';
import { RulesService }    from '@shared/service/rules.service';
import { MerchantInfo }    from '@core/models';
import { MerchantService } from '@core/services';

import { ConditionType } from '@flows/models';

@Component({
  selector: 'k-rules-list',
  templateUrl: './rules-list.component.html',
  styleUrls: ['./rules-list.component.scss'],
})

export class RulesListComponent implements OnInit, OnDestroy {
  @Select(state => state.merchant) merchant$: Observable<MerchantInfo>;

  @Input() loading: boolean = true;
  @Input() isCreatePage: boolean;
  @Input() addButtonName: string = 'Add Rule';
  @Input() conditionType: ConditionType;
  @Input() set rulesDataToFill(data) {
    if (data?.rules) {
      this._getSourceTypeIdsStream()
        .pipe(first())
        .subscribe(() => {
          data.rules.forEach(rule => {
            this.addRule(rule);
          });
        });
    }
  }

  @Output() emitDeleteRule: EventEmitter<number> = new EventEmitter<number>();
  @Output() updateValidationOnAddRule: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() formChanged: EventEmitter<any> = new EventEmitter<any>();

  public ruleForm: FormGroup;
  public ruleTypes: RuleItem[] = [];

  public get rules(): FormGroup {
    return this.ruleForm as FormGroup;
  }

  public get subRules(): FormArray {
    return this.rules.get('rules') as FormArray;
  }

  public get ruleOperator(): string {
    return this.rules.get('operator').value;
  }

  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(private fb: FormBuilder,
              private _merchantService: MerchantService,
              private _rulesService: RulesService) { }

  ngOnInit(): void {
    this._merchantService.loadMerchantInfo();
    this._initForm();

    if (this.isCreatePage) {
      this.addRule();
      this._getSourceTypeIdsStream()
        .pipe(first())
        .subscribe();
    }

    this._updateRuleList();
    this._trackFormChange();
  }

  public trackByFn(index: number, item): number {
    return item;
  }

  public onAddRule(): void {
    this.addRule();
    this.updateValidationOnAddRule.emit(true);
  }

  public onDeleteRule(index: number): void {
    this.ruleTypes.splice(index, 1);
    this.subRules.removeAt(index);
  }

  public addRule(rule?: RuleItem): void {
    const initRule = this.conditionType ? {
        type: [rule ? rule.type : RULE_TYPES_OPTIONS.FULFILLMENT_STATUS_VALUE],
        value: [rule ? rule.value : RULE_SECONDARY_OPTIONS.FULFILLED_VALUE],
      } : {
        type: [rule ? rule.type : RULE_TYPES_OPTIONS.SOURCE_OF_SUBSCRIPTION_VALUE],
        action: [rule ? rule.action : RULE_ACTIONS_OPTIONS.EQUAL_VALUE],
        sourceType: [rule ? rule.sourceType : RULE_SOURCE_TYPE_OPTIONS.ALL_VALUE],
      };
    const ruleForm = this.fb.group(initRule);

    if (rule?.products) {
      this._rulesService.createRequiredControl(ruleForm, 'products', rule.products);
    }

    if (rule?.collections) {
      this._rulesService.createRequiredControl(ruleForm, 'collections', rule.collections);
    }

    if (rule?.sourceId) {
      ruleForm.addControl('sourceId', new FormControl(rule.sourceId));
    }

    if (rule?.period) {
      ruleForm.addControl('period', new FormControl(rule.period));
    }

    if (rule?.range) {
      this._rulesService.createRangeValuesControls(ruleForm, rule.range);
    }

    if (rule?.values) {
      this._rulesService.createRuleValuesControls(ruleForm, rule.type, rule.values, rule.parameter);
    }

    if (rule?.parameter) {
      ruleForm.addControl('parameter', new FormControl(rule.parameter));
    }

    if (rule?.value || typeof rule?.value === 'boolean') {
      ruleForm.removeControl('action');
      ruleForm.addControl('value', new FormControl(rule.value));
    }

    this.subRules.push(ruleForm);
    this.ruleTypes.push(ruleForm.value);
  }

  private _updateRuleList(): void {
    this.ruleTypes = this.subRules.value;
  }

  private _initForm(): void {
    this.ruleForm = this.fb.group({
      type: [RULE_UTILS.GROUP],
      operator: [RULE_UTILS.AND],
      rules: this.fb.array([]),
    });
  }

  private _trackFormChange(): void {
    this.ruleForm.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((value) => {
        this.formChanged.emit(value);
      });
  }

  private _getSourceTypeIdsStream(): Observable<any> {
    return combineLatest([
      this._rulesService.getKeywords(),
      this._rulesService.getPopups(),
      this.merchant$,
    ]).pipe(tap(() => { this.loading = false; }));
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
