export class ClearAppSectionList {
  static readonly type = '[Router Service] ClearAppSectionList';

  constructor(public payload: string) { }
}

export class SetAppSectionList {
  static readonly type = '[Table Service] GetAppSectionList';

  constructor(public payload: string, public meta, public list) { }
}
