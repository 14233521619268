import { Injectable }                  from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import {
  ClearSegment,
  ClearSegmentOriginal,
  SetSegmentOriginal,
  SetSegmentsData,
  SetSegmentsList,
  DeleteSegmentListItem,
  SetSegmentsStatistic,
  ClearSegmentList,
  SetSegmentSortingData,
} from '@store/actions';
import { TableSortData } from '@shared/models';

import { SegmentStatistics, SegmentItem, SegmentListItem } from '@segments/components/models';

export interface SegmentsStateModel {
  segmentOriginal: SegmentItem;
  segment: {
    model?: SegmentItem,
    dirty: boolean,
    errors: {},
  };
  segmentsData: {
    totalCount: number;
  };
  segmentsList: SegmentListItem[];
  segmentsStatistic: SegmentStatistics;
  sortingData: TableSortData;
}

@State<SegmentsStateModel>({
  name: 'segments',
  defaults: {
    segmentOriginal: null,
    segment: {
      model: null,
      dirty: false,
      errors: {},
    },
    segmentsData: {
      totalCount: 0,
    },
    segmentsList: null,
    segmentsStatistic: {
      totalContacts: 0,
      totalUnsubscribed: 0,
      revenuePerContact: 0,
    },
    sortingData: {
      sort: 'DESC',
      field: 'createdAt',
    },
  },
})
@Injectable()
export class SegmentsState {

  @Action(SetSegmentSortingData)
  SetSegmentSortingData({ patchState }: StateContext<SegmentsStateModel>, { sorting }: SetSegmentSortingData) {
    patchState({ sortingData: sorting });
  }

  @Action(SetSegmentsData)
  setSegmentsData({ patchState }: StateContext<SegmentsStateModel>, { payload }: SetSegmentsData) {
    patchState({
      segmentsData: payload,
    });
  }

  @Action(SetSegmentsList)
  setSegmentsList({ patchState }: StateContext<SegmentsStateModel>, { payload }: SetSegmentsList) {
    patchState({ segmentsList: payload });
  }

  @Action(DeleteSegmentListItem)
  deleteSegmentListItem({ getState, setState }: StateContext<SegmentsStateModel>, { index }: DeleteSegmentListItem) {
    const state = getState();
    const segmentsList = state.segmentsList.map(obj => ({ ...obj }));

    segmentsList.splice(index, 1);

    setState({
      ...getState(),
      segmentsList,
    });
  }

  @Action(SetSegmentOriginal)
  setSegmentOriginal({ patchState }: StateContext<SegmentsStateModel>, { payload }: SetSegmentOriginal) {
    patchState({
      segmentOriginal: payload,
    });
  }

  @Action(ClearSegmentOriginal)
  clearSegmentOriginal({ patchState }: StateContext<SegmentsStateModel>) {
    patchState({
      segmentOriginal: null,
    });
  }

  @Action(ClearSegment)
  clearSegment({ getState, setState }: StateContext<SegmentsStateModel>) {
    setState({
      ...getState(),
      segment: {
        model: null,
        dirty: false,
        errors: {},
      },
    });
  }

  @Action(SetSegmentsStatistic)
  setSegmentsStatistic({ patchState }: StateContext<SegmentsStateModel>, { payload }: SetSegmentsStatistic) {
    patchState({
      segmentsStatistic: payload,
    });
  }

  @Action(ClearSegmentList)
  clearSegmentList({ patchState }: StateContext<SegmentsStateModel>) {
    patchState({
      segmentsList: null,
      sortingData: {
        sort: 'DESC',
        field: 'createdAt',
      },
    });
  }
}
