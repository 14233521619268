import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators }           from '@angular/forms';
import { switchMap, takeUntil, tap }                                 from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable, Subject }       from 'rxjs';
import { NgxSmartModalService }                                      from 'ngx-smart-modal';

import { Select, Store } from '@ngxs/store';

import { Discount, Discounts, DiscountTypeNames, FreeShipping, MerchantInfo } from '@core/models';
import { DiscountService, HelperService }                                     from '@core/services';
import { DISCOUNT_HINTS, TRIGGER_TYPES_DATA }                                 from '@shared/texts';
import { DiscountStateModel, DiscountTemplateModel, DropdownStringData }      from '@shared/models';
import { ValidatorService }                                                   from '@shared/service/validator.service';
import { TriggerType }                                                        from '@flows/models/trigger-type';
import { DISCOUNT_TYPE }                                                      from '@core/texts';

@Component({
  selector: 'k-flow-add-discount-dialog',
  templateUrl: './flow-add-discount-dialog.component.html',
  styleUrls: ['./flow-add-discount-dialog.component.scss'],
})
export class FlowAddDiscountDialogComponent implements OnInit, OnDestroy {
  @Select(state => state.merchant) merchant$: Observable<MerchantInfo>;

  @Input() type: TriggerType;
  @Input() discountModalId: string;
  @Output() submitDiscount: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancelDiscount: EventEmitter<any> = new EventEmitter<any>();

  public loading: boolean = false;
  public discountForm: FormGroup;
  public discountTypes: DropdownStringData[] = [];
  public discountRegions: DropdownStringData[] = [];
  public actualDiscountTypes = {};
  public isEditDiscount: boolean = false;
  public templateTypeFormat: string;
  public discounts: DropdownStringData[] = [];
  public discountTemplateType: 'static' | 'dynamic' = 'dynamic';
  public valueType: '%' | 'USD' = '%';
  public isTypeValid = true;
  public flowDiscountHint: string = null;
  public flowDiscountTitle: string = null;
  public isDynamicOnly: boolean;
  public discountRedirect = new FormControl('', this._validatorService.urlValidators());
  public shortDomain: string = '';
  public merchantInfo: MerchantInfo;
  public discountTemplateTypes = [
    { value: 'dynamic', title: 'Kinnekt', description: `Dynamically generated discount code that's unique in every message sent` },
    { value: 'static', title: 'Shopify', description: `Use an existing Shopify discount code that\'s already created and active` },
  ];

  public get isEmbeddedDiscountSectionAvailable(): boolean {
    return ![
      TRIGGER_TYPES_DATA.CART_TRIGGERS_ABANDONED_CART_VALUE,
      TRIGGER_TYPES_DATA.PAID_TRIGGERS_UPSELL_CROSS_SELL_VALUE,
    ].includes(this.type);
  }

  public get isStaticDiscountInvalid(): boolean {
    return this.discountTemplateType === 'static' && this.discountForm.get('staticDiscount').value === false;
  }

  public get isDynamicDiscountInvalid(): boolean {
    return (
      this.discountTemplateType === 'dynamic' &&
      this.discountForm.get('discountValue').errors &&
      this.discountForm.get('discountType').value !== 'DiscountCodeFreeShipping'
    );
  }

  public get discountValueError(): string {
    const discountControl = this.discountForm.get('discountValue');

    if (!discountControl.errors || discountControl.untouched) {
      return '';
    }

    if (discountControl.errors.discountAmountInvalid) {
      return 'Amount should be greater than 0';
    }

    if (discountControl.errors.discountPercentageInvalid) {
      return 'Percentage should be between 1 and 100';
    }
  }

  public get discountRedirectError(): string {
    if (!this.discountRedirect.errors || this.discountRedirect.untouched) {
      return '';
    }

    if (this.discountRedirect.errors) {
      return 'Invalid URL. Please try again';
    }
  }

  public get isDiscountRedirectErrors(): boolean {
    return !!this.discountRedirect.errors;
  }

  private isFreeShippingUSA: boolean = true;
  private openingFinish$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(private fb: FormBuilder,
              private store: Store,
              private _discountService: DiscountService,
              private _modal: NgxSmartModalService,
              private _helperService: HelperService,
              private _validatorService: ValidatorService) { }

  ngOnInit() {
    this.loading = true;
    this._initForm();
    this._trackMerchantChange();

    this.isDynamicOnly = this.type === TRIGGER_TYPES_DATA.CART_TRIGGERS_ABANDONED_CART_VALUE;
    this.flowDiscountHint = DISCOUNT_HINTS[this.type] ? DISCOUNT_HINTS[this.type] : DISCOUNT_HINTS.defaultHint;
    this.flowDiscountTitle = DISCOUNT_HINTS.defaultTitle;
    this.actualDiscountTypes = this._discountService.actualDiscountTypes;
    const discountTypeNames = this._getActualDiscountTypeNames(this._discountService.discountTypeNames);
    this.discountTypes = this._getDiscountsForDropdown(discountTypeNames);


    if (!this.isTypeValid) {
      this.discountForm.get('embeddedDiscountLink').disable();
    }

    this._discountService.discountAction$
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(object => {
        if (object) {
          // Remove discount
          if (object.remove) {
            this._resetStates();
          }
          // Edit discount
          if (object.edit) {
            this.isEditDiscount = true;
          }
        }
      });

    combineLatest([this._getFreeShippingObservable(), this._getStaticDiscountsObservable()])
      .pipe(
        switchMap( () => this.openingFinish$ ),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe(isOpened => {
        if (!isOpened) return;
        if (this.isEditDiscount) {
          this.onDiscountEdit();
        } else {
          this.onDiscountTemplateChange('dynamic');
          this._prepareShortDomain();
        }
        this.loading = false;
      });

  }

  public onDiscountOpening(): void {
    this.openingFinish$.next(true);
  }

  public onDiscountEdit(): void {
    const discount: DiscountStateModel = this.store.selectSnapshot(state => state.discount.linkInfo);
    const discountTemplate: DiscountTemplateModel = this.store.selectSnapshot(state => state.discount.template);

    const isStatic = discount.type === 'static';
    const isDynamic = discount.type === 'dynamic';

    this.onDiscountTemplateChange(discount.type);
    this._prepareShortDomain();

    if (discountTemplate.discountRedirect) {
      this.discountRedirect.setValue(discountTemplate.discountRedirect);
    }

    if (isStatic) {
      this.discountForm.get('staticDiscount').patchValue(this._setStaticDiscount(discountTemplate.discountCode));
    }

    if (isDynamic) {
      this.discountForm.get('discountType').patchValue(discountTemplate.discountType);
      this._detectDynamicType(discountTemplate.discountType);


      if (discountTemplate.discountValue) {
        const discountValue = discountTemplate.discountType === 'DiscountCodeBasicPercentage'
          ? discountTemplate.discountValue * 100
          : discountTemplate.discountValue;
        this.discountForm.patchValue({ discountValue });
      } else {
        const discountView = JSON.parse(discountTemplate.discountView);
        this.discountForm.get('discountRegion').patchValue(this._getFreeShipping(discountView.value));
      }
    }
  }

  public onDiscountCancel(isClose = false): void {
    if (this.isEditDiscount) {
      this._modal.getModal(this.discountModalId).close();
    } else {
      this.cancelDiscount.emit();
    }
  }

  public addDiscount(): void {
    this.discountTemplateType === 'static' ? this._addStaticDiscount() : this._addDynamicDiscount();
  }

  public onDiscountTemplateChange(value: 'static' | 'dynamic'): void {
    this.discountTemplateType = value;
    this._resetForm();
    this.valueType = '%';
    this.discountForm.get('discountValue').markAsUntouched();
  }

  public onDiscountValueInput(event: KeyboardEvent): void {
    const target: HTMLInputElement = event.target as HTMLInputElement;
    const value = target.value;

    if ((target.selectionStart === target.selectionEnd && value.length >= 15) || event.key === ',') {
      event.preventDefault();
      return;
    }

    const discountType = this.discountForm.get('discountType').value;

    if (discountType === 'DiscountCodeBasicPercentage' && Number.isNaN(+event.key)) event.preventDefault();
  }

  public onDiscountValueChange(event): void {
    const value = event.target.value;
    const discountType = this.discountForm.get('discountType').value;

    if (discountType === 'DiscountCodeBasicAmount') {
      const discountValue = this.discountForm.get('discountValue');
      const a = value.replace(/\.(?=.*\.)/g, '');
      const updatedValue = Number(a.replace(/[,]/g, '')).toFixed(2);

      if (Number.isNaN(+updatedValue)) {
        discountValue.setValue(Number(0).toFixed(2));
        return;
      }

      discountValue.setValue(updatedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ','));
    }
  }

  public onDynamicDiscountChange(): void {
    const discountType = this.discountForm.get('discountType').value;
    const discountValue = this.discountForm.get('discountValue');

    discountValue.setValue('');
    discountValue.markAsUntouched();

    this._detectDynamicType(discountType);
  }

  public onDiscountClosed(): void {
    if (this.isEditDiscount) {
      this._modal.getModal(this.discountModalId).close();
    } else {
      this._resetStates();
    }
  }

  private _detectDynamicType(discountType) {
    switch (discountType) {
      case 'DiscountCodeBasicAmount':
        this.valueType = 'USD';
        break;

      case 'DiscountCodeBasicPercentage':
        this.valueType = '%';
        break;
    }
  }

  private _resetStates(): void {
    this.isEditDiscount = false;
    this._resetForm();
    this.valueType = '%';
    this.discountForm.get('discountValue').markAsUntouched();
    this.discountRedirect.setValue('');
  }

  private _trackMerchantChange(): void {
    this.merchant$
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((merchantInfo: MerchantInfo) => {
      this.merchantInfo = merchantInfo;
    });
  }

  private _prepareShortDomain() {
    this.shortDomain = this._helperService.getShortDomain(this.merchantInfo);
  }

  private _addStaticDiscount(): void {
    const currentDiscount = this._getCurrentDiscount();
    this._discountService.getDiscountLink(currentDiscount.gid, currentDiscount.type, currentDiscount.summary)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((data) => {
        this.submitDiscount.emit({ discount: {
          ...data,
          gid: currentDiscount.gid,
          isLinkAvailable: this.isTypeValid,
          discountRedirect: this.discountRedirect.value,
        }, type: this.discountTemplateType });
        this._modal.getModal(this.discountModalId).close();
      });
  }

  private _addDynamicDiscount(): void {
    const dynamicData = this._getDynamicDiscountData(this.discountForm.value);

    this._discountService.getDynamicDiscountLink(
      dynamicData.discountType,
      dynamicData.discountValue,
      dynamicData.discountRegion,
    ).pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((data) => {
        this.submitDiscount.emit({ 'discount': {
          ...data,
          isLinkAvailable: this.isTypeValid,
          discountRedirect: this.discountRedirect.value,
        }, 'type': this.discountTemplateType });

        this._modal.getModal(this.discountModalId).close();
      });
  }

  private _getDynamicDiscountData(formValues) {
    const updatedFormValues = formValues;

    if (formValues.discountType === this.actualDiscountTypes[DISCOUNT_TYPE.FREE_SHIPPING]) {
      updatedFormValues.discountValue = 0;
    } else {
      if (formValues.discountType === this.actualDiscountTypes[DISCOUNT_TYPE.PERCENTAGE]) {
        updatedFormValues.discountValue = formValues.discountValue / 100;
      }

      if (formValues.discountType === this.actualDiscountTypes[DISCOUNT_TYPE.FIXED_AMOUNT]) {
        updatedFormValues.discountValue = +formValues.discountValue.replace(/[,]/g, '');
      }
      updatedFormValues.discountRegion = '';
    }
    return updatedFormValues;
  }

  private _initForm(): void {
    this.discountForm = this.fb.group({
      staticDiscount: [false],
      discountType: ['DiscountCodeBasicPercentage'],
      discountValue: ['', this._validatorService.discountValueValidator()],
      discountRegion: ['USA'],
      embeddedDiscountLink: [true],
    });
  }

  private _setStaticDiscount(code) {
    if (!this.discounts.length) return;
    return this.discounts.find(discount => {
      return JSON.parse(discount.value).code === code;
    }).value;
  }

  private _getFreeShipping(value): string {
    const discountValue = value.replace('Free Shipping - ', '').trim();

    if (!this.discountRegions.length) return;
    return this.discountRegions.find(region => {
      return region.value === discountValue;
    }).value;
  }

  private _getFreeShippingObservable(): Observable<FreeShipping> {
    return this._discountService.getFreeShippingRegions()
      .pipe(
        tap(data => {
          const freeShippingRegions = data.regions;
          this.discountRegions = this._helperService.getDataForDropdown(freeShippingRegions);
          this.isFreeShippingUSA = !!freeShippingRegions.find(elem => elem === 'USA');
          this.discountForm.get('discountRegion').setValue(this.isFreeShippingUSA ? 'USA' : freeShippingRegions[0]);
        }),
      );
  }

  private _getStaticDiscountsObservable(): Observable<Discounts> {
    return this._discountService.getStaticDiscounts(this.type)
      .pipe(
        tap((staticDiscounts: Discounts) => {
          this.discounts = this._getDiscountTemplatesForDropdown(staticDiscounts.discounts);
        }),
      );
  }

  private _getActualDiscountTypeNames(discountTypeNames: DiscountTypeNames[]): DiscountTypeNames[] {
    if (this.type ===  TRIGGER_TYPES_DATA.CART_TRIGGERS_ABANDONED_CART_VALUE) {
      return discountTypeNames.filter((name) => name !== DISCOUNT_TYPE.FREE_SHIPPING);
    }

    return discountTypeNames;
  }

  private _getDiscountTemplatesForDropdown(discounts: Discount[]): DropdownStringData[] {
    return discounts.map((discount) => ({ value: JSON.stringify(discount), name: discount.summary }));
  }

  private _getDiscountsForDropdown(discountTypeNames: DiscountTypeNames[]): DropdownStringData[] {
    return discountTypeNames.map(discountTypeName => ({
      value: this.actualDiscountTypes[discountTypeName],
      name: discountTypeName,
    }));
  }

  private _getCurrentDiscount() {
    return JSON.parse(this.discountForm.get('staticDiscount').value || {});
  }

  private _resetForm() {
    this.discountForm.patchValue({
      staticDiscount: false,
      discountType: 'DiscountCodeBasicPercentage',
      discountValue: '',
      discountRegion: this.isFreeShippingUSA ? 'USA' : this.discountRegions[0]?.value,
      embeddedDiscountLink: true,
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
