import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stripProtocol'
})
export class StripProtocolPipe implements PipeTransform {

  transform(content: any): string {
    return content.replace(/^https?:\/\//, '');
  }

}
