import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ReadFile, ReadMode } from 'ngx-file-helpers';
import { Store }              from '@ngxs/store';

import { ApiService }              from '@core/services';
import { SetSavingProgress }       from '@store/actions';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { ImageService }            from '@shared/service/image.service';

@Component({
  selector: 'k-flow-file-picker',
  templateUrl: './flow-file-picker.component.html',
  styleUrls: ['./flow-file-picker.component.scss'],
})
export class FlowFilePickerComponent implements OnInit, AfterViewInit {
  @Input() set imageUrl(data) {
    this.image = data;
  }
  @Input() imageSize;
  @Input() isGifAllowed: boolean = true;
  @Input() isFileExist: boolean = false;
  @Input() filter: (file: File) => boolean;
  @Input() imageName: string;
  @Output() filePicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() fileDeleted: EventEmitter<any> = new EventEmitter<any>();

  public image: string;
  public size: string;
  public readMode = ReadMode.dataURL;
  public loading: boolean = false;

  private httpWithoutInterceptor: HttpClient;

  constructor(private store: Store,
              private httpBackend: HttpBackend,
              private _apiService: ApiService,
              private _imageService: ImageService) {
    this.httpWithoutInterceptor = new HttpClient(httpBackend);
  }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
  }

  public onFilePicked(file: ReadFile): void {
    this._setLoading(true);

    const formData = new FormData();
    formData.set('image', file.underlyingFile);
    this.size = null;

    this._apiService.post('images', formData)
      .subscribe(
        (data) => {
          this.image = data.url;
          this.filePicked.emit(data);
          this._setLoading(false);
        },
        () => {
          this._setLoading(false);
        },
      );
  }

  public deleteFile(): void {
    this.size = null;
    this.fileDeleted.emit();
  }

  public imageLoaded(data): void {
    this.size = this._imageService.formatBytes(data?.size);
  }

  private _setLoading(state: boolean): void {
    this.loading = state;
    this.store.dispatch(new SetSavingProgress(state));
  }
}
