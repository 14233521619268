import { Injectable }                 from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

@Injectable()
export class FormService {
  constructor() {}

  private patterns = {
    '/^\\+[1-9]\\d{6,14}$/': '+14151234567',
    '/^[0-9]+$/': '0-9',
    '/^[a-zA-Z0-9]+$/': 'a-zA-Z0-9',
    '/^[a-zA-Z0-9\\s]+$/': 'a-zA-Z0-9',
    '/^[a-zA-Z0-9_ -]*$/': 'a-zA-Z0-9 - spaces',
    '/^[a-zA-Z0-9\\s!?@#$%^&*()+-_.,\\/\']+$/': 'a-zA-Z0-9!?@#$%^&*()+-_.,\\/\'',
  };

  public templateWhiteSpaceInvalidRegExp = /^[a-zA-Z0-9\s!?@#$%^&*()+-_.,\/']+$/;

  //TODO: Discuss with BA customErrors messages and uniform them
  public getControlError(control: AbstractControl, customErrors?: { [key: string]: string }): string {
    const controlErrors = control.errors;

    if (control.untouched || !controlErrors) return null;

    if (controlErrors.required) return customErrors?.required ? customErrors.required : 'This field is required';
    if (controlErrors.templateWhiteSpaceInvalid) return customErrors?.templateWhiteSpaceInvalid ? customErrors.templateWhiteSpaceInvalid : 'This field is required';
    if (controlErrors.htmlTagsInvalid) return customErrors?.htmlTagsInvalid ? customErrors.htmlTagsInvalid : 'This field cannot contain HTML tags';
    if (controlErrors.min) return `Minimum value is ${controlErrors.min.min}`;
    if (controlErrors.max) return `Please enter no more than ${(controlErrors.max.max).toLocaleString(
      'en-US', {
        style: 'currency',
        currency: 'USD',
      })}`;
    if (controlErrors.minlength) return customErrors?.minlength ? customErrors.minlength : `Minimum length is ${controlErrors.minlength.requiredLength} characters`;
    if (controlErrors.maxlength) return customErrors?.maxlength ? customErrors.maxlength : `This field cannot contain more than ${controlErrors.maxlength.requiredLength} characters`;
    if (controlErrors.pattern) return customErrors?.pattern ? customErrors.pattern : `The following are accepted symbols: ${this.patterns[controlErrors.pattern.requiredPattern]}
IS THIS ACCURATE? Seems odd....`;
    if (controlErrors.dropdownInvalid) return `This field can't be empty`;
    if (controlErrors.currencyInvalid) return 'Field should contain valid currency format';
    if (controlErrors.protocolPatternInvalid) return 'Invalid URL. Please try again';
    if (controlErrors.alreadyExists) return customErrors?.alreadyExists ? customErrors.alreadyExists : 'This name already exists';
  }

  public getError(form: FormGroup, field: string, customErrors?: { [key: string]: string }): string {
    const control = form.controls[field];
    return this.getControlError(control, customErrors);
  }
}
