import { NgModule }                                  from '@angular/core';
import { CommonModule, CurrencyPipe, TitleCasePipe } from '@angular/common';
import { RouterModule }                              from '@angular/router';
import { HttpClientModule }                          from '@angular/common/http';

import { SharedModule }   from '@shared/shared.module';
import { FeaturesModule } from '@features/features.module';

import { guards }       from './guards';
import { services }     from './services';
import { interceptors } from './interceptors';
import { components }   from './components';

@NgModule({
  declarations: [
    ...components,
  ],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    SharedModule,
    FeaturesModule,
  ],
  providers: [
    ...guards,
    ...services,
    ...interceptors,
    CurrencyPipe,
    TitleCasePipe,
  ],
  exports: [
    ...components,
  ],
})
export class CoreModule { }
