import { Component, OnInit } from '@angular/core';
import { Select }            from '@ngxs/store';
import { Observable }        from 'rxjs';
import { filter, first }     from 'rxjs/operators';

import { IntercomService }   from '@shared/service/intercom.service';
import { IntercomUserModel } from '@features/components/intercom/models';

@Component({
  selector: 'k-intercom',
  templateUrl: './intercom.component.html',
  styleUrls: ['./intercom.component.scss'],
})
export class IntercomComponent implements OnInit {
  @Select(state => state.merchant.intercom) intercom$: Observable<IntercomUserModel>;

  constructor(private _intercomService: IntercomService) { }

  ngOnInit(): void {
    this.intercom$
      .pipe(
        filter((intercom) => !!intercom ),
        first(),
        )
      .subscribe((intercom) => {
        this._intercomService.initIntercom('intercom', intercom);
      });
  }
}
