import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { BOT_CHATTING_NOTIFICATION } from '@shared/texts';

@Component({
  selector: 'k-editor-preview',
  templateUrl: './editor-preview.component.html',
  styleUrls: ['./editor-preview.component.scss'],
})
export class EditorPreviewComponent implements OnInit {
  @Input() loading: boolean;
  @Input() isMinimizePreview: boolean;
  @Input() isPopupTabPreview: boolean;
  @Input() showShareableLinkPreview: boolean = false;
  @Input() keyboard: boolean = false;
  @Input() hideCancelBTN: boolean = false;
  @Input() popupStep: 'optin' | 'popup' | 'button';
  @Input() withoutPreviewTitle: boolean;
  @Input() isResponses: boolean = false;
  @Input() isQuietHours: boolean;
  @Input() inBotConversation: boolean;
  @Input() currentClientTime: string;
  @Input() contact: string;

  @Output() stopBot: EventEmitter<void> = new EventEmitter<void>();

  public get currentTime() {
    return new Intl
      .DateTimeFormat('default', {
        hour12: true,
        hour: 'numeric',
        minute: 'numeric',
      })
      .format(new Date());
  }

  public get shownPreviewTitle(): boolean {
    return !this.withoutPreviewTitle && !this.popupStep || !this.withoutPreviewTitle && this.popupStep === 'optin';
  }

  public get previewTitle(): string {
    return this.keyboard
      ? 'New Message' : 'Message Preview';
  }

  public botNotification: string = BOT_CHATTING_NOTIFICATION;

  constructor() { }

  ngOnInit() { }

  public onStopBot() {
    this.stopBot.emit();
  }
}
