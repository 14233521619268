
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { DecimalPipe }                                                                          from '@angular/common';
import { NG_VALUE_ACCESSOR }                                                                    from '@angular/forms';

import { Select }                        from '@ngxs/store';
import { interval, Observable, Subject } from 'rxjs';
import { first, skipWhile, takeUntil  }  from 'rxjs/operators';

import { CampaignSegmentItem } from '@campaigns/models';
import { CampaignService }     from '@campaigns/services';

import { CustomControlValueAccessor } from './../base/custom-control-value-accessor';


@Component({
  selector: 'k-recipients-dropdown',
  templateUrl: './recipients-dropdown.component.html',
  styleUrls: ['./recipients-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RecipientsDropdownComponent),
      multi: true,
    },
    DecimalPipe,
  ],
})
export class RecipientsDropdownComponent extends CustomControlValueAccessor implements AfterViewInit {
  @Select(state => state.campaigns.campaignSegments) segments$: Observable<CampaignSegmentItem[]>;

  @Input() isCreate: boolean;
  @Input() isScheduledCampaignExist: boolean = false;
  @Input() isZeroSubscribers: boolean = false;
  @Input() set campaignId(campaignId){
    if (campaignId) {
      this.campaignIdChanged$.next(campaignId);
    }
  }
  @Output() getSubscribersCount: EventEmitter<number> = new EventEmitter<number>();

  public segments: Array<any> = [];
  public subscribers: string = '';
  public selectedOption: any;

  private ngUnsubscribe$: Subject<void> = new Subject<void>();
  private segmentChanged$: Subject<void> = new Subject<void>();
  private campaignIdChanged$: Subject<number> = new Subject<number>();

  constructor(private decimalPipe: DecimalPipe,
              private _campaignService: CampaignService,
              private cd: ChangeDetectorRef) {
    super();
  }

  ngAfterViewInit() {
    this.isCreate ? this._listenSegmentsList() : this._listenCampaignId();
    this._preformCd();
  }

  public onOptionChange(option) {
    this.subscribers = `${this.decimalPipe.transform(option.subscribers)} Subscribers`;
    this.getSubscribersCount.emit(option.subscribers);
    this.writeValue(option.value);
    this.value = option.value;
  }

  private _preformCd(): void {
    this.cd.detectChanges();
  }

  private _listenSegmentsList(): void {
    this.segments$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(result => {
      if (result.length) {
        this.segments = this._getSegmentsForDropdown(result);
      }
    });
  }

  private _getSegmentListById(id: number): void {
    this._campaignService.getCampaignRecipients(id)
      .pipe(first())
      .subscribe(result => {
        this.segments = this._getSegmentsForDropdown(result);
        this._listenSegmentChange();
        this._trackForValueUpdate();
      });
  }

  private _listenCampaignId(): void {
    this.campaignIdChanged$
      .pipe(first())
      .subscribe(id => {
        this._getSegmentListById(id);
      });
  }

  private _listenSegmentChange(): void {
    this.segmentChanged$.pipe(first()).subscribe(() => {
        const selectedSegment = this.segments.find(segment => segment.value === this.value);
        this.selectedOption = selectedSegment.name;
        this.subscribers = `${this.decimalPipe.transform(selectedSegment.subscribers)} Subscribers`;
    });
  }

  private _trackForValueUpdate(): void {
    interval(100)
      .pipe(
        skipWhile(() => this.value === null),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe(() => {
        this.segmentChanged$.next();
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    });
  }

  private _getSegmentsForDropdown(segments: CampaignSegmentItem[]): Array<any> {
    return segments.map( segment => ({
      value: segment.id,
      name: segment.name,
      subscribers: segment.subscribers,
      deleted: segment.deleted,
    }));
  }
}

