import { Injectable }                  from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { PopupListItem, PopupEditItem } from '@popups/models';

import {
  SetPopupsList,
  ClearPopupList,
  SetPopupsData,
  SetPopupOriginal,
  ClearPopupFlow,
  ClearPopupOriginal,
  ClearPopup,
  UpdatePopupFormImage,
  DeletePopupListItem,
  UpdatePopupStatuses,
  SetPopupFlow,
  SetPopupSortingData,
} from '@store/actions';
import { TableSortData } from '@shared/models';

export interface PopupsStateModel {
  popupOriginal: PopupEditItem;
  popup: {
    model?: {},
    dirty: boolean,
    status: string,
    errors: {},
  };
  popupFlow: any;
  popupsData: any;
  popupsList: PopupListItem[];
  sortingData: TableSortData;
}

@State<PopupsStateModel>({
  name: 'popups',
  defaults: {
    popupOriginal: null,
    popup: {
      model: null,
      dirty: false,
      status: '',
      errors: {},
    },
    popupFlow: null,
    popupsData: {
      totalCount: 0,
    },
    popupsList: null,
    sortingData: {
      sort: 'DESC',
      field: 'createdAt',
    },
  },
})
@Injectable()
export class PopupsState {

  @Action(SetPopupSortingData)
  setPopupSortingData({ patchState }: StateContext<PopupsStateModel>, { sorting }: SetPopupSortingData) {
    patchState({ sortingData: sorting });
  }

  @Action(SetPopupsData)
  setPopupsData({ patchState }: StateContext<PopupsStateModel>, { payload }: SetPopupsData) {
    patchState({
      popupsData: payload,
    });
  }

  @Action(SetPopupsList)
  setPopupsList({ patchState }: StateContext<PopupsStateModel>, { payload }: SetPopupsList) {
    patchState({ popupsList: payload });
  }

  @Action(UpdatePopupStatuses)
  updatePopupStatuses({ getState, setState }: StateContext<PopupsStateModel>, { statusUpdates }: UpdatePopupStatuses) {
    const state = getState();
    if (!state.popupsList) return;
    const popupsList = state.popupsList.map(obj => ({ ...obj }));

    statusUpdates.forEach(popupStatus => {
      const popupIndex = popupsList.findIndex(elem => elem.id === popupStatus.id);
      if (~popupIndex) popupsList[popupIndex].status = popupStatus.status;
    });

    setState({
      ...getState(),
      popupsList,
    });
  }

  @Action(DeletePopupListItem)
  deletePopupListItem({ getState, setState }: StateContext<PopupsStateModel>, { index }: DeletePopupListItem) {
    const state = getState();
    const popupsList = state.popupsList.map(obj => ({ ...obj }));

    popupsList.splice(index, 1);

    setState({
      ...getState(),
      popupsList,
    });
  }

  @Action(ClearPopupList)
  clearPopupsList({ patchState }: StateContext<PopupsStateModel>) {
    patchState({
      popupsList: null,
      sortingData: {
        sort: 'DESC',
        field: 'createdAt',
      },
    });
  }

  @Action(SetPopupOriginal)
  setPopupOriginal({ patchState }: StateContext<PopupsStateModel>, { payload }: SetPopupOriginal) {
    patchState({
      popupOriginal: payload,
    });
  }

  @Action(UpdatePopupFormImage) // need for alert changes in form
  updatePopupFormImage({ getState, setState }: StateContext<PopupsStateModel>, { payload }: UpdatePopupFormImage) {
    const state = getState();
    const popup = JSON.parse(JSON.stringify(state.popup));

    popup.model.popup.image = payload;

    setState({
      ...getState(),
      popup,
    });
  }

  @Action(ClearPopupOriginal)
  clearPopupOriginal({ patchState }: StateContext<PopupsStateModel>) {
    patchState({
      popupOriginal: null,
    });
  }

  @Action(SetPopupFlow)
  setPopupFlow({ patchState }: StateContext<PopupsStateModel>, { payload }: SetPopupFlow) {
    patchState({
      popupFlow: payload,
    });
  }

  @Action(ClearPopupFlow)
  clearPopupFlow({ patchState }: StateContext<PopupsStateModel>) {
    patchState({
      popupFlow: null,
    });
  }

  @Action(ClearPopup)
  clearPopup({ patchState }: StateContext<PopupsStateModel>) {
    patchState({
      popup: {
        model: null,
        dirty: false,
        status: '',
        errors: {},
      },
    });
  }
}
