import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule }                     from '@angular/common';
import { RouterModule }                     from '@angular/router';

import { IntercomModule } from 'ng-intercom';

import { SharedModule } from '@shared/shared.module';

import { components } from './components';
import { services }   from './services';

@NgModule({
  declarations: [
    ...components,
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    IntercomModule,
  ],
  providers: [
    ...services,
  ],
  exports: [
    ...components,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class FeaturesModule { }
