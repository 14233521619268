import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { Redirect }                                   from '@shopify/app-bridge/actions';
import { Store }                                      from '@ngxs/store';
import { RouterLinkWithHref }                         from '@angular/router';

import { ShopifyService } from '@core/services';

@Directive({
  selector: 'a[kButton][routerLink]',
})
export class ExternalRedirectDirective {
  constructor(private el: ElementRef,
              private store: Store,
              private routerLinkWithHref: RouterLinkWithHref,
              private _shopifyService: ShopifyService) { }

  @HostListener('auxclick', ['$event'])
  private onClick(event) {
    event.preventDefault();
    const merchantInfo = this.store.selectSnapshot(state => state.merchant);
    this._shopifyService.redirect.dispatch(Redirect.Action.ADMIN_PATH, {
      path: `/apps/${merchantInfo.appName}${this.routerLinkWithHref.href}`,
      newContext: true,
    });
  }

  @HostListener('contextmenu')
  private onContextMenu() {
    const merchantInfo = this.store.selectSnapshot(state => state.merchant);
    this.el.nativeElement.href = `${merchantInfo.primaryDomain}/admin/apps/${merchantInfo.appName}${this.routerLinkWithHref.href}`;
  }
}
