import { AfterViewInit, Component, ElementRef, forwardRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR }                                                                     from '@angular/forms';

import { CustomControlValueAccessor } from '../custom-control-value-accessor';

import { SelectorSize } from './radio.model';

@Component({
  selector: 'k-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioComponent),
      multi: true,
    },
  ],
})
export class RadioComponent extends CustomControlValueAccessor implements OnInit, AfterViewInit {
  @Input() name: string;
  @Input() checked: boolean;
  @Input() disabled: boolean = false;
  @Input() big: boolean = false;
  @Input() selectorSize: SelectorSize;
  @Input() showIndicator: boolean = true;
  @Input() radioIndicatorMargin: SelectorSize = 'big';

  @ViewChild('radio') radio: ElementRef;

  constructor(private renderer: Renderer2) {
    super();
  }

  ngOnInit() { }

  ngAfterViewInit() {
    if (this.selectorSize) {
      this.renderer.addClass(this.radio.nativeElement, `k-radio__label--size-${this.selectorSize}`);
      this.renderer.addClass(this.radio.nativeElement, `k-radio__label--wrapper-space-${this.radioIndicatorMargin}`);
    }
  }

  get labelClass() {
    return `k-radio__label${this.big ? ' k-radio__label--big' : ''}`;
  }

  onRadioChange(value) {
    this.value = value;
  }
}
