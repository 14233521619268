import { Injectable } from '@angular/core';

import { Action, State, StateContext } from '@ngxs/store';

import { ShareableLinkEditItem, ShareableLinksListItem } from '@shareable-links/models';
import {
  ClearShareableLinkFlow,
  DeleteShareableLinkListItem,
  SetShareableLinkOriginal,
  SetShareableLinksData,
  SetShareableLinksList,
  UpdateShareableLinkStatus,
  ClearShareableLinkOriginal,
  SetShareableLinkFlow,
  ClearShareableLink,
  SetShareableLinkStatistic,
  ClearShareableLinkList,
  SetShareableLinkSortingData,
} from '@store/actions';
import { TableSortData } from '@shared/models';

import { ShareableLinksStatistics } from '@shareable-links/models/shareable-links-statistics';


export interface ShareableLinksStateModel {
  shareableLinkOriginal: ShareableLinkEditItem;
  shareableLink: {
    model?: {},
    dirty: boolean,
    status: string,
    errors: {},
  };
  shareableLinkFlow: any;
  shareableLinksData: any;
  shareableLinkList: ShareableLinksListItem[];
  shareableLinksStatistic: ShareableLinksStatistics;
  sortingData: TableSortData;
}

@State<ShareableLinksStateModel>({
  name: 'shareableLinks',
  defaults: {
    shareableLinkOriginal: null,
    shareableLink: {
      model: null,
      dirty: false,
      status: '',
      errors: {},
    },
    shareableLinkFlow: null,
    shareableLinksData: {
      totalCount: 0,
    },
    shareableLinkList: null,
    shareableLinksStatistic: {
      clicks: 0,
      subscribers: 0,
      revenue: 0,
    },
    sortingData: {
      sort: 'DESC',
      field: 'createdAt',
    },
  },
})


@Injectable()
export class ShareableLinksState {

  @Action(SetShareableLinkSortingData)
  setShareableLinkSortingData({ patchState }: StateContext<ShareableLinksStateModel>, { sorting }: SetShareableLinkSortingData) {
    patchState({ sortingData: sorting });
  }

  @Action(SetShareableLinksData)
  setShareableLinksData({ patchState }: StateContext<ShareableLinksStateModel>, { payload }: SetShareableLinksData) {
    patchState({
      shareableLinksData: payload,
    });
  }

  @Action(SetShareableLinksList)
  setShareableLinksList({ patchState }: StateContext<ShareableLinksStateModel>, { payload }: SetShareableLinksList) {
    patchState({ shareableLinkList: payload });
  }

  @Action(UpdateShareableLinkStatus)
  updateShareableLinkStatuses({ getState, setState }: StateContext<ShareableLinksStateModel>,
                              { id, status }: UpdateShareableLinkStatus) {
    const state = getState();
    const shareableLinkList = state.shareableLinkList.map(obj => ({ ...obj }));
    const shareableLinkItem = shareableLinkList.find((elem) => elem.id === id);

    if(shareableLinkItem) {
      shareableLinkItem.status = status;
    }
    setState({
      ...getState(),
      shareableLinkList,
    });
  }

  @Action(DeleteShareableLinkListItem)
  deleteShareableLinkListItem({ getState, setState }: StateContext<ShareableLinksStateModel>, { index }: DeleteShareableLinkListItem) {
    const state = getState();
    const shareableLinkList = state.shareableLinkList.map(obj => ({ ...obj }));

    shareableLinkList.splice(index, 1);

    setState({
      ...getState(),
      shareableLinkList,
    });
  }

  @Action(SetShareableLinkOriginal)
  setShareableLinkOriginal({ patchState }: StateContext<ShareableLinksStateModel>, { payload }: SetShareableLinkOriginal) {
    patchState({
      shareableLinkOriginal: payload,
    });
  }

  @Action(ClearShareableLinkOriginal)
  clearShareableLinkOriginal({ patchState }: StateContext<ShareableLinksStateModel>) {
    patchState({
      shareableLinkOriginal: null,
    });
  }

  @Action(SetShareableLinkFlow)
  setShareableLinkFlow({ patchState }: StateContext<ShareableLinksStateModel>, { payload }: SetShareableLinkFlow) {
    patchState({
      shareableLinkFlow: payload,
    });
  }

  @Action(ClearShareableLinkFlow)
  clearShareableLinkFlow({ patchState }: StateContext<ShareableLinksStateModel>) {
    patchState({
      shareableLinkFlow: null,
    });
  }

  @Action(ClearShareableLink)
  clearShareableLink({ patchState }: StateContext<ShareableLinksStateModel>) {
    patchState({
      shareableLink: {
        model: null,
        dirty: false,
        status: '',
        errors: {},
      },
    });
  }

  @Action(SetShareableLinkStatistic)
  setSegmentsStatistic({ patchState }: StateContext<ShareableLinksStateModel>, { payload }: SetShareableLinkStatistic) {
    patchState({
      shareableLinksStatistic: payload,
    });
  }

  @Action(ClearShareableLinkList)
  clearSegmentList({ patchState }: StateContext<ShareableLinksStateModel>) {
    patchState({
      shareableLinkList: null,
      sortingData: {
        sort: 'DESC',
        field: 'createdAt',
      },
    });
  }
}
