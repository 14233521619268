import {
  ResponseContactStatus,
  ResponseHistoryItem,
  ResponseListItem,
  ResponseListUpdatedItem,
  ResponseStatuses,
} from '@responses/models';
import { TableSortData }                                                                  from '@shared/models';

export class SetResponsesData {
  static readonly type = '[Responses List] SetResponsesData';

  constructor(public payload: any) { }
}

export class SetResponsesFilter {
  static readonly type = '[Responses List] SetResponsesFilter';

  constructor(public payload: ResponseStatuses) { }
}

export class SetResponsesList {
  static readonly type = '[Responses List] SetResponsesList';

  constructor(public payload: ResponseListItem[]) { }
}

export class SetResponsesSortingData {
  static readonly type = '[Sorting] SetResponsesSortingData';

  constructor(public sorting: TableSortData) { }
}

export class ClearResponseList {
  static readonly type = '[Response List] ClearResponseList';

  constructor() { }
}

export class SetResponsesUnreadStatus {
  static readonly type = '[Response List] SetResponseUnreadStatus';

  constructor(public payload: boolean) { }
}

export class UpdatedResponseItemSubscription {
  static readonly type = '[Response List] UpdatedResponseItemSubscription';

  constructor(public id: number) { }
}

export class UpdateBotConversationStatus {
  static readonly type = '[Response List] UpdateBotConversationStatus';

  constructor(public status: ResponseListUpdatedItem) { }
}

export class UpdatedResponsesList {
  static readonly type = '[Response List] UpdatedResponsesList';

  constructor(public payload: ResponseListItem) { }
}

export class UpdateUnresolvedResponsesList {
  static readonly type = '[Response List] UpdateUnresolvedResponsesList';

  constructor(public responseId: number) { }
}

export class SetCurrentResponsesTab {
  static readonly type = '[Responses List] SetCurrentResponsesTab';

  constructor(public responsesCurrentTab: string) { }
}

export class UpdateUnresolvedTabOnUnsubscribe {
  static readonly type = '[Response List] UpdateUnresolvedTabOnUnsubscribe';

  constructor(public responseId: number) { }
}

export class SetResponseOriginal {
  static readonly type = '[Response Form] SetResponseOriginal';

  constructor(public payload: any) { }
}

export class ClearResponseOriginal {
  static readonly type = '[Response Form] ClearResponseOriginal';

  constructor() { }
}

export class ClearResponse {
  static readonly type = '[Response Form] ClearResponse';

  constructor() { }
}

export class SetResponseHistory {
  static readonly type = '[Response Preview] SetResponseHistory';

  constructor(public payload: any) { }
}

export class AddResponseHistoryMessages {
  static readonly type = '[Response Preview] AddResponseHistoryMessages';

  constructor(public payload: ResponseHistoryItem[]) { }
}

export class AddResponseHistoryMessage {
  static readonly type = '[Response Form] AddResponseHistoryMessage';

  constructor(public payload: ResponseHistoryItem, public responseId: number) { }
}

export class UpdateResponseHistoryMessage {
  static readonly type = '[Response Form] UpdateResponseHistoryMessage';

  constructor(public payload: ResponseHistoryItem, public responseId: number) { }
}

export class MoveDownResentedScheduledMessage {
  static readonly type = '[Response Form] MoveDownResentedScheduledMessage';

  constructor(public payload: ResponseHistoryItem, public responseId: number) { }
}

export class DeleteResponseHistoryMessage {
  static readonly type = '[Response Form] DeleteResponseHistoryMessage';

  constructor(public messageId: number) { }
}

export class UpdateResponseContactStatus {
  static readonly type = '[Response Form or List] UpdateResponseContactStatus';

  constructor(public payload: ResponseContactStatus) { }
}
