import { Options } from 'angular2-notifications/lib/interfaces/options.type';

export const toastrConfig = {
  maxOpened: 3,
  timeOut: 3000,
  positionClass: 'toast-bottom-center',
};

export const notificationsConfig: Options = {
  position: ['top', 'right'],
  timeOut: 6000,
  maxStack: 4,
  showProgressBar: false,
  pauseOnHover: true,
  clickToClose: false,
  clickIconToClose: true,
};

export const PER_PAGE = 10;
export const SEGMENTS_PER_PAGE = 10;
export const CAMPAIGNS_PER_PAGE = 10;
export const RESPONSES_PER_PAGE = 10;
export const FLOWS_PER_PAGE = 10;
export const SHAREABLE_LINK_PER_PAGE = 10;

export const GOOGLE_FONT_FAMILIES = [
  'Anonymous Pro:400,400i,700,700i',
  'Arvo:400,400i,700,700i',
  'Bebas Neue:400,400i,700,700i',
  'Caveat:400,400i,700,700i',
  'Crimson Text:400,400i,700,700i',
  'Dancing Script:400,400i,700,700i',
  'Dosis:400,400i,700,700i',
  'Droid Serif:400,400i,700,700i',
  'Fira Sans:400,400i,700,700i',
  'Josefin Sans:400,400i,700,700i',
  'Josefin Slab:400,400i,700,700i',
  'Lato:400,400i,700,700i',
  'Merriweather:400,400i,700,700i',
  'Montserrat:400,400i,700,700i',
  'Open Sans:400,400i,700,700i',
  'PT Sans Narrow:400,400i,700,700i',
  'PT Serif:400,400i,700,700i',
  'Rajdhani:400,400i,700,700i',
  'Raleway:400,400i,700,700i',
  'Roboto:400,400i,700,700i',
  'Roboto Slab:400,400i,700,700i',
  'Scope One:400,400i,700,700i',
  'Source Sans Pro:400,400i,600,700,700i',
  'Ubuntu:400,400i,700,700i',
];

export const EDITOR_FONTS = {
  'Serif': [
    'Anonymous Pro',
    'Arvo',
    'Courier New',
    'Crimson Text',
    // 'Droid Serif',
    'Josefin Slab',
    // 'Merriweather',
    'PT Serif',
    'Roboto Slab',
    // 'Scope One',
    'Times New Roman',
  ],
  'Sans Serif': [
    // 'Arial',
    // 'Bebas Neue',
    // 'Comic Sans MS',
    'Dosis',
    'Fira Sans',
    'Helvetica',
    'Josefin Sans',
    'Lato',
    'Montserrat',
    'Open Sans',
    'PT Sans Narrow',
    'Rajdhani',
    'Raleway',
    'Roboto',
    'Source Sans Pro',
    // 'Tahoma',
    'Ubuntu',
    // 'Verdana',
  ],
  // 'Script': [
  //   'Caveat',
  //   'Dancing Script',
  // ],
};
