import { CampaignEditItem, CampaignListItem, CampaignSegmentItem, CampaignsStatistic } from '@campaigns/models';
import { TableSortData }                                                               from '@shared/models';

export class SetCampaignsData {
  static readonly type = '[Campaign List] SetCampaignsData';

  constructor(public payload: any) { }
}

export class SetCampaignsList {
  static readonly type = '[Campaign List] SetCampaignList';

  constructor(public payload: CampaignListItem[]) { }
}

export class AddCampaignListItem {
  static readonly type = '[Campaign List] AddCampaignListItem';

  constructor(public item: CampaignListItem) {
  }
}

export class UpdateCampaignListItem {
  static readonly type = '[Campaign List] UpdateCampaignListItem';

  constructor(public item: CampaignListItem) {
  }
}

export class DeleteCampaignListItem {
  static readonly type = '[Campaign List] DeleteCampaignListItem';

  constructor(public campaignID: number) { }
}

export class StopCampaignListItem {
  static readonly type = `[Campaign List] StopCampaignListItem`;

  constructor(public index: number) {}
}

export class ClearCampaignList {
  static readonly type = '[Campaign List] ClearCampaignList';

  constructor() { }
}

export class UpdateCampaignStatuses {
  static readonly type = '[Campaign List] UpdateCampaignStatuses';

  constructor(public statusUpdates: any[]) { }
}

export class SetCampaignsStatistic {
  static readonly type = '[Campaigns List] SetCampaignsStatistic';

  constructor(public payload: CampaignsStatistic) { }
}

export class SetCampaignOriginal {
  static readonly type = '[Campaign Form] SetCampaignOriginal';

  constructor(public payload: CampaignEditItem) { }
}

export class ClearCampaignOriginal {
  static readonly type = '[Campaign Form] ClearCampaignOriginal';

  constructor() { }
}

export class SetCampaignSegmentsList {
  static readonly type = '[Campaign Segments] SetCampaignSegments';

  constructor(public payload: CampaignSegmentItem[]) {}
}

export class ClearCampaign {
  static readonly type = '[Campaign Form] ClearCampaign';

  constructor() { }
}

export class SetScheduledCampaignsDates {
  static readonly type = '[Campaigns Scheduled Dates] SetScheduledCampaignsDates';

  constructor(public payload: any) { }
}

export class SetCampaignSortingData {
  static readonly type = '[Sorting] SetCampaignSortingData';

  constructor(public sorting: TableSortData) { }
}
