import { KeywordItem, PopupItem } from '@shared/models';

export class ClearRule {
  static readonly type = '[Rule Form] ClearRule';

  constructor() { }
}

export class SetRuleKeywords {
  static readonly type = '[Rules Form] SetRuleKeywords';

  constructor(public payload: KeywordItem[]) { }
}

export class SetRulePopups {
  static readonly type = '[Rules Form] SetRulePopups';

  constructor(public payload: PopupItem[]) { }
}
