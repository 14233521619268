import { Pipe, PipeTransform, QueryList, TemplateRef } from '@angular/core';

import { TableCellTemplateDirective } from '@shared/directives/table-cell-template.directive';
import { TableCellTemplateData }      from '@shared/models';

@Pipe({
  name: 'kTableCellTemplateFilter',
})
export class TableCellTemplateFilterPipe implements PipeTransform {

  transform(templates: QueryList<TableCellTemplateDirective>,
            cell: TableCellTemplateData,
            defaultTemplates: { cell: string, template: TemplateRef<any> }[],
  ): TemplateRef<any> {
    const template = templates.find((d) => d.cell === cell.value)?.template;
    if (template) return template;

    const defaultTemplate = defaultTemplates.find((d) => d.cell === cell.type)?.template;
    if (defaultTemplate) return defaultTemplate;

    return defaultTemplates.find((d) => d.cell === 'string').template;
  }

}
