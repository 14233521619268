import { SettingsSendingLogic } from '@settings/components/models';

export class SetSettingsSendingLogicOriginal {
  static readonly type = '[SetSettingsSendingLogic Form] SetSettingsSendingLogicOriginal';

  constructor(public payload: SettingsSendingLogic) { }
}

export class ClearSettingsSendingLogicOriginal {
  static readonly type = '[SetSettingsSendingLogic Form] ClearSettingsSendingLogicOriginal';

  constructor() { }
}

export class ClearSettingsSendingLogicData {
  static readonly type = '[SetSettingsSendingLogic Form] ClearSettingsSendingLogicData';

  constructor() { }
}
