import { Injectable }              from '@angular/core';
import { ActivatedRoute }          from '@angular/router';
import { Store }                   from '@ngxs/store';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map, tap }    from 'rxjs/operators';
import { io }                      from 'socket.io-client';

import {
  UpdateMerchantInfo,
  AddResponseHistoryMessage,
  SetResponsesUnreadStatus,
  UpdatedResponsesList,
  UpdateResponseHistoryMessage,
  UpdateResponseContactStatus,
  UpdateBotConversationStatus,
  UpdateUnresolvedTabOnUnsubscribe,
} from '@store/actions';

import {
  ResponseHistoryItem,
  ResponseHistoryItemStatus,
  ResponseListItem,
  ResponseStatuses,
  ResponseContactStatus,
  ResponseListUpdatedItem,
} from '@responses/models';

import { environment }      from '@environments/environment';
import { NotificationData } from '@notifications/models';
import { NotiService }      from '@notifications/services';
import { PaymentData }      from '@features/models';
import { ApiService }       from './api.service';
import { RouterService }    from './router.service';
import { TimeService }      from './time.service';

@Injectable()
export class SocketService {
  public responseMessageAdded: Subject<{ isCustomerIncomingMsg: boolean }> = new Subject<{ isCustomerIncomingMsg: boolean }>();
  public statusUpdate: Subject<boolean> = new Subject<boolean>();
  public botStatusUpdate: Subject<boolean> = new Subject<boolean>();

  private socket;

  constructor(private store: Store,
              private route: ActivatedRoute,
              private _apiService: ApiService,
              private _notiService: NotiService,
              private _routerService: RouterService,
              private _timeService: TimeService) { }

  public initSocket(): void {
    this.socket = io('', {
      reconnection: true,
      reconnectionDelay: 10000,
      reconnectionDelayMax : 12000,
      path: environment.socketUrl,
      transports: ['websocket', 'polling'],
    });
  }

  public sendShop(shop: string): void {
    this.socket.emit('startNotifications', shop);
  }

  public disconnect(): void {
    this.socket.disconnect();
  }

  public onDisconnect(): Observable<string> {
    return new Observable<string>(observer => {
      this.socket.on('disconnect', (data: string) => observer.next(data));
    })
      .pipe(
        tap((error: string) => {
          console.log('------- Socket Disconnect --------', error);

          if (error === 'io server disconnect') {
            this.socket.emit('connect');
            console.log('------- Socket Connected after: io server disconnect --------');
          }

          if (error === 'transport close') {
            this.socket.emit('connect');
            console.log('------- Socket Connected after: transport close --------');
          }
        }),
      );
  }

  public onNewNotification(): Observable<NotificationData> {
    return new Observable<NotificationData>(observer => {
      this.socket.on('Notification', (data: NotificationData) => observer.next(data));
    })
      .pipe(
        tap((notification: NotificationData) => {
          console.log('------- Notification Data --------', notification);

          if (notification.channel === 'alert') {
            this._notiService.showAlerts([notification], 1);
          }

          if (notification.channel === 'notification') {
            this._notiService.showNotifications([notification], 1);
          }
        }),
        catchError((e) => {
          console.log('------- Notification Data ERROR --------', e);
          return of(null);
        }),
      );
  }

  public onNewResponse(): Observable<ResponseListItem> {
    return new Observable<ResponseListItem>(observer => {
      this.socket.on('NewMessage', (data: ResponseListItem) => observer.next(data));
    })
      .pipe(
        map((response: ResponseListItem) => {
          const statuses: ResponseStatuses[] = [response.status];

          if (response.subscribed === 'UNSUBSCRIBED') {
            statuses.push('Opted out');
          }

          response['responseStatuses'] = statuses;

          return response;
        }),
        tap((response: ResponseListItem) => {
          console.log('------- NEW MESSAGE --------', response);

          if (!(response.type === 'outgoing' && response.message)) {
            this.store.dispatch(new SetResponsesUnreadStatus(true));
            this.store.dispatch(new UpdatedResponsesList(response));
          }

          const message = this._buildMessage(response);
          this.store.dispatch(new AddResponseHistoryMessage(message, response.responseId));

          this.responseMessageAdded.next({ isCustomerIncomingMsg: !response.inBotConversation });
        }),
        tap((response: ResponseListItem) => {
          const responseEditorRoute = this._routerService.getDeepestFirstChildSnapshot(this.route.snapshot);

          if (responseEditorRoute.data.responseEditor && response.type === undefined) {
            this._updateNewMessageState(response.id).subscribe();
          }
        }),
        catchError((e) => {
          console.log('------- NEW MESSAGE ERROR --------', e);
          return of(null);
        }),
      );
  }

  public onBotStatusConversation(): Observable<ResponseListUpdatedItem> {
    return new Observable<ResponseListUpdatedItem>(observer => {
      this.socket.on('IsInBotConversation', (data: ResponseListUpdatedItem) => observer.next(data));
    })
      .pipe(
        tap((status: ResponseListUpdatedItem) => {
          this.store.dispatch(new UpdateBotConversationStatus(status));
          console.log('------- IS IN BOT CONVERSATION --------', status);
          this.botStatusUpdate.next(status.inBotConversation);
        }),
        catchError((e) => {
          console.log('------- NEW MESSAGE ERROR --------', e);
          return of(null);
        }),
      );
  }

  public onAppStatusChangingTo(): Observable<{ appStatusChangingTo: boolean | null }> {
    return new Observable<{ appStatusChangingTo: boolean | null }>(observer => {
      this.socket.on('AppStatusChangingTo', (data: { appStatusChangingTo: boolean | null }) => observer.next(data));
    })
      .pipe(
        tap((data: { appStatusChangingTo: boolean | null }) => {
          this.store.dispatch(new UpdateMerchantInfo({
            key: 'appStatusChangingTo',
            value: data.appStatusChangingTo,
          }));
          console.log('------- APP STATUS CHANGING TO --------', data);
        }),
        catchError((e) => {
          console.log('------- NEW MESSAGE ERROR --------', e);
          return of(null);
        }),
      );
  }

  public onResponseMessageStatus(): Observable<ResponseHistoryItem> {
    return new Observable<ResponseHistoryItem>(observer => {
      this.socket.on('UpdateSMSStatus', (data: ResponseHistoryItem) => observer.next(data));
    })
      .pipe(
        tap((response: ResponseHistoryItem) => {
          console.log('------- MESSAGE STATUS UPDATED --------', response);

          this.statusUpdate.next(response.error);
          this.store.dispatch(new UpdateResponseHistoryMessage(response, response.responseId));
        }),
        catchError((e) => {
          console.log('------- MESSAGE STATUS UPDATED ERROR --------', e);
          return of(null);
        }),
      );
  }

  public onResponseMessageInit(): Observable<ResponseHistoryItem> {
    return new Observable<ResponseHistoryItem>(observer => {
      this.socket.on('ResponseInit', (data: ResponseHistoryItem) => observer.next(data));
    })
      .pipe(
        tap((response: ResponseHistoryItem) => {
          console.log('------- ResponseInit --------', response);

          this.store.dispatch(new UpdateResponseHistoryMessage(response, response.responseId));
        }),
        catchError((e) => {
          console.log('------- ResponseInit ERROR --------', e);
          return of(null);
        }),
      );
  }

  public onBalanceUpdate(): Observable<PaymentData> {
    return new Observable<PaymentData>(observer => {
      this.socket.on('UpdateBalance', (data: PaymentData) => observer.next(data));
    })
      .pipe(
        tap((data: PaymentData) => {
          console.log('------- Update Balance --------', data);

          this.store.dispatch(new UpdateMerchantInfo({ key: 'balance', value: data.balance }));
        }),
        catchError((e) => {
          console.log('------- Update Balance ERROR --------', e);
          return of(null);
        }),
      );
  }

  public onChangeContactStatus(): Observable<ResponseContactStatus> {
    return new Observable<ResponseContactStatus>(observer => {
      this.socket.on('ChangeContactStatus', (data: ResponseContactStatus) => observer.next(data));
    })
      .pipe(
        tap((contactStatus: ResponseContactStatus) => {
          console.log('------- Change Contact Status --------', contactStatus);

          this.store.dispatch(new UpdateResponseContactStatus(contactStatus));
          this.store.dispatch(new UpdateUnresolvedTabOnUnsubscribe(contactStatus.responseId));
        }),
        catchError((e) => {
          console.log('------- Change Contact Status ERROR --------', e);
          return of(null);
        }),
      );
  }

  private _updateNewMessageState(id: number): Observable<any> {
    return this._apiService.post(`responses/${id}/messages/read`);
  }

  private _buildMessage(response: ResponseListItem): ResponseHistoryItem {
    return {
      id: null,
      responseId: response.responseId,
      message: response.lastMessage || response.type === 'outgoing' && response.message,
      image: response.image || null,
      type: response.type || 'incoming',
      date: this._timeService.prepareResponseTimeFormat(response.date),
      status: response.status as ResponseHistoryItemStatus,
      error: false,
      last: false,
      errorDescribing: null,
      badFeedback: response.badFeedback,
      messageType: response.messageType,
    };
  }
}
