import { Component, OnDestroy, OnInit }       from '@angular/core';
import { Router }                             from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Observable, Subject }                   from 'rxjs';
import { first, takeUntil }                      from 'rxjs/operators';
import { Select, Store }                         from '@ngxs/store';
import { NotiService }                           from '@notifications/services';
import { ApiService, MerchantService }           from '@core/services';
import { LegalService }                          from '@shared/service/legal.service';
import { MerchantInfo }                          from '@core/models';
import { StoreLegalInformationType }             from '@legal-agreements/models';
import { INSTRUCTIONS, STORE_LEGAL_INFORMATION } from '@legal-agreements/texts';
import { NotificationData }                      from '@notifications/models';


@Component({
  selector: 'k-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss'],
})
export class TermsComponent implements OnInit, OnDestroy {
  @Select(state => state.merchant) merchant$: Observable<MerchantInfo>;
  @Select(state => state.notifications.shownAlerts) shownAlerts$: Observable<NotificationData[]>;

  public loading: boolean = true;
  public storeLegalInformationForm: FormGroup;
  public terms: { [key: string]: any };
  public steps: { [key: string]: boolean } = { 'termsOfService': false, 'privacyPolicy': false, 'TCPACompliance': false };
  public type: StoreLegalInformationType;
  public storeLegalInformation = STORE_LEGAL_INFORMATION;
  public alertsSnapshot;
  public numberReadyAlert;

  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(private fb: FormBuilder,
              private router: Router,
              private store: Store,
              private _notiService: NotiService,
              private _apiService: ApiService,
              private _merchantService: MerchantService,
              private _legalService: LegalService) {
  }

  ngOnInit() {
    this._showLoaders();

    this.shownAlerts$
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(alerts => {
        this._legalService.hideAlerts(alerts);
      });

    this.merchant$
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((merchant: MerchantInfo) => {
        if (merchant.shop.id) {
          if (merchant.activeLOA) {
            this.terms = this._legalService.updateTerms(merchant.activeLOA, INSTRUCTIONS);
            this._hideLoaders();
          }
        }
      });

    this._initForm();
  }

  public changeStep(state: any): void {
    this.storeLegalInformationForm.get(state.step).setValue(state.value);
  }

  public onSubmit() {
    if (this.storeLegalInformationForm.invalid) {
      this._notiService.warning();
      return;
    }

    this._merchantService.addTerms(this.storeLegalInformationForm.value)
      .pipe(first())
      .subscribe(
        () => {
          this._merchantService.loadMerchantInfo().then(() => {});
        },
        () => {
        },
      );
  }

  private _initForm() {
    this.storeLegalInformationForm = this.fb.group({
      termsOfService: [false, [ Validators.requiredTrue ] ],
      privacyPolicy: [false, [ Validators.requiredTrue ] ],
      TCPACompliance: [false, [ Validators.requiredTrue ] ],
    });
  }

  private _showLoaders() {
    this.loading = true;
  }

  private _hideLoaders() {
    this.loading = false;
  }

  ngOnDestroy() {
    this._legalService.unhideAlerts();
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
