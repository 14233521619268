import { Component, AfterViewInit, ElementRef, Input, Renderer2 } from '@angular/core';

import { IconColor } from '../icon/icon.model';

@Component({
  selector: 'k-hint',
  templateUrl: './hint.component.html',
  styleUrls: ['./hint.component.scss'],
})
export class HintComponent implements AfterViewInit {
  @Input() tip: string;
  @Input() size: number = 16;
  @Input() offset: number = 8;
  @Input() fill: boolean;
  @Input() color: IconColor = 'gray';

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngAfterViewInit() {
    if (this.fill) {
      this.renderer.addClass(this.el.nativeElement, `fill`);
    } else {
      this.renderer.addClass(this.el.nativeElement, `ml--${this.offset}`);
    }
  }
}
