import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isDeletedItem',
})
export class IsDeletedItemPipe implements PipeTransform {

  transform(value: boolean): string {
    if (value) return ' (deleted)';

    return '';
  }

}
