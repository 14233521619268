import { Injectable }     from '@angular/core';
import { Store }          from '@ngxs/store';

import { tap } from 'rxjs/operators';

import { SetNonGSMSymbols } from '@store/actions';
import { ApiService }       from './api.service';

@Injectable()
export class NonGsmSymbolsService {

  constructor(
    private store: Store,
    private _apiService: ApiService) { }

  public getNonGSMSymbols() {
      return this._apiService.get('builder/nonGSMSymbols')
        .pipe(
          tap((symbols) => {
            this.store.dispatch(new SetNonGSMSymbols(symbols));
          }),
        ).toPromise();
    }
}
