import { Injectable } from '@angular/core';

import moment from 'moment';

@Injectable()
export class DifferentTimeService {

  constructor() { }

  public diffDate(startDate, endDate, intervalsArray: string[]): any {
    const b = moment(startDate);
    const a = moment(endDate);
    const intervals: any = intervalsArray;
    const out = {};

    for (let i = 0; i < intervals.length; i++) {
      const diff = a.diff(b, intervals[i]);
      b.add(diff, intervals[i]);
      out[intervals[i]] = diff;
    }

    return out;
  }
}
