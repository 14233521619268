import { ShareableLinksListItem }   from '@shareable-links/models';
import { ShareableLinksStatistics } from '@shareable-links/models/shareable-links-statistics';
import { TableSortData }            from '@shared/models';

export class SetShareableLinksData {
  static readonly type = '[Shareable Links List] SetShareableLinksData';

  constructor(public payload: any) { }
}

export class SetShareableLinksList {
  static readonly type = '[Shareable Links List] SetShareableLinksList';

  constructor(public payload: ShareableLinksListItem[]) { }
}

export class DeleteShareableLinkListItem {
  static readonly type = '[Shareable Links List] DeleteShareableLinksListItem';

  constructor(public index: number) { }
}

export class UpdateShareableLinkStatus {
  static readonly type = '[Shareable Links List] UpdateShareableLinksStatuses';

  constructor(public id: number, public status: boolean) { }
}

export class SetShareableLinkStatistic {
  static readonly type = '[Shareable Link List] SetShareableLinkStatistic';

  constructor(public payload: ShareableLinksStatistics) { }
}

export class ClearShareableLinkList {
  static readonly type = '[Shareable Link List] ClearShareableLinkList';

  constructor() { }
}

export class SetShareableLinkOriginal {
  static readonly type = '[Shareable Link Form] SetShareableLinkOriginal';

  constructor(public payload: any) { }
}

export class SetShareableLinkFlow {
  static readonly type = '[Shareable Link Form] SetShareableLinkFlow';

  constructor(public payload: any) { }
}

export class ClearShareableLinkFlow {
  static readonly type = '[Shareable Link Form] ClearShareableLinkFlow';

  constructor() { }
}

export class ClearShareableLinkOriginal {
  static readonly type = '[Shareable Link Form] ClearShareableLinkOriginal';

  constructor() { }
}

export class ClearShareableLink {
  static readonly type = '[Shareable Link Form] ClearClearShareableLink';

  constructor() { }
}

export class SetShareableLinkSortingData {
  static readonly type = '[Sorting] SetShareableLinkSortingData';

  constructor(public sorting: TableSortData) { }
}
