import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormArray,
  FormControl,
  FormGroup,
  AbstractControl,
} from '@angular/forms';

import { ActivatedRoute } from '@angular/router';
import { CurrencyPipe }   from '@angular/common';

import { Observable, Subject } from 'rxjs';
import { takeUntil }           from 'rxjs/operators';
import { Select, Store }       from '@ngxs/store';

import { MerchantInfo }                                           from '@core/models';
import { DropdownData, DropdownRuleListData, DropdownStringData } from '@shared/models';

import {
  EQUAL_NOT_EQUAL, FULFILLMENT_STATUS,
  LINE_ITEMS_OPTIONS,
  MONEY_ORDERS_AND_DISCOUNTS_TO_ACTION,
  ORDER_CREATED_CUSTOMER_CREATED_RULE,
  ORDER_CREATED_CUSTOMER_CREATED_VALUE_TO_NAME_OPTION,
  ORDER_STATUS, ORDER_STATUS_VALUE_TO_NAME_OPTION,
  RULE_ACTIONS_OPTIONS, RULE_LINE_ITEMS_OPTIONS,
  RULE_LINE_ITEMS_VALUE_TO_NAME_OPTIONS,
  RULE_NAME_TO_SECONDARY_OPTIONS,
  RULE_PERIOD, RULE_SECONDARY_OPTIONS,
  RULE_SOURCE_TYPE_OPTIONS,
  RULE_SOURCE_TYPE_VALUE_TO_NAME_OPTIONS,
  RULE_TYPE_OPTIONS,
  RULE_TYPES_OPTIONS,
  RULE_TYPES_VALUE_TO_NAME_OPTIONS,
  SOURCE_TYPE_OPTIONS,
} from '../../texts';
import { RulesService }  from '@shared/service/rules.service';
import { RuleItem }      from '@shared/models/rules/rules-editor-rule';
import { DateRangeData } from '@shared/components/base/date-range/date-range.model';

import { ConditionType } from '@flows/models';

@Component({
  selector: 'k-rule',
  templateUrl: './rule.component.html',
  styleUrls: ['./rule.component.scss'],
})
export class RuleComponent implements OnInit, OnDestroy {
  @Select(state => state.merchant) merchant$: Observable<MerchantInfo>;

  @Input() ruleForm: FormGroup;
  @Input() rulesLength: number;
  @Input() index: number;
  @Input() conditionType: ConditionType;
  @Input() set rule(data: RuleItem) {
    if (data) {
      this._addUpsellControls(data.type, data.products || data.collections);
      this.selectedTypeOption = RULE_TYPES_VALUE_TO_NAME_OPTIONS[data.type];
      this._fillRule(data.type);
      this._updateSelectedSecondaryOption(data);
      this._fillPeriod(data);
      this._setSourceTypeSelectedOption(data);
      this._checkSourceType(data.sourceType, data.sourceId);
      this._updateActions(data.type);
      this.orderStatusOption = this._setOrderStatusName(data.value);
      this._updateOrderStatus(data.type);
      this._addLineItemControls(data.type, data);
    }
  }

  @Output() deleteRule: EventEmitter<void> = new EventEmitter<void>();

  public isCondition: boolean = false;
  public optionItems: DropdownStringData[];
  public options: DropdownRuleListData[];
  public actionOptions: DropdownData[] = RULE_NAME_TO_SECONDARY_OPTIONS[RULE_TYPES_OPTIONS.SOURCE_OF_SUBSCRIPTION_VALUE];
  public equalNotEqualOptions: DropdownData[] = EQUAL_NOT_EQUAL;
  public orderStatusOption: string | boolean = RULE_SECONDARY_OPTIONS.FULFILLED_VALUE;
  public orderStatusOptions: DropdownData[] = FULFILLMENT_STATUS;
  public lineItemOption: string = RULE_LINE_ITEMS_OPTIONS.PRODUCT_ID_NAME;
  public lineItemOptions: DropdownData[] = LINE_ITEMS_OPTIONS;
  public sourceType: DropdownStringData[] = SOURCE_TYPE_OPTIONS;
  public orderCustomerRuleType: DropdownStringData[] = ORDER_CREATED_CUSTOMER_CREATED_RULE;
  public selectedTypeOption: string;
  public isMaskEnabled: boolean = false;
  public ruleTypesOptions = RULE_TYPES_OPTIONS;
  public selectedActionOption: string = RULE_ACTIONS_OPTIONS.EQUAL_NAME;
  public selectedSourceTypeOption: string = RULE_SOURCE_TYPE_OPTIONS.ALL_NAME;
  public selectedOrderCustomerRuleTypeOption: string = RULE_PERIOD.ALL_TIME_NAME;
  public selectedSourceIdOption: string;
  public minWidth: number = 200;
  public maxWidth: number = 285;
  public actionDropdownWidth: number = 140;
  public upsellParametersType: string;
  public popupValue: string = RULE_SOURCE_TYPE_OPTIONS.POPUP_VALUE;
  public isCheckoutEnabled: boolean;
  public isWarning: boolean = false;
  public upsellParameters: any;
  public isValueSecondaryOption: boolean = false;
  public isTwoLineError: boolean = false;
  public isThreeLineError: boolean = false;

  public eventsSubject: Subject<string> = new Subject<string>();
  public sourceOfSubscription: string = RULE_TYPES_OPTIONS.SOURCE_OF_SUBSCRIPTION_NAME;
  public moneySpent: string = RULE_TYPES_OPTIONS.MONEY_SPENT_NAME;
  public numberOfOrders: string = RULE_TYPES_OPTIONS.NUMBER_OF_ORDERS_NAME;
  public averageMoneyAmount: string = RULE_TYPES_OPTIONS.AVERAGE_MONEY_AMOUNT_NAME;
  public totalDiscounts: string = RULE_TYPES_OPTIONS.TOTAL_DISCOUNTS_NAME;
  public orderSpecificProducts: string = RULE_TYPES_OPTIONS.ORDER_SPECIFIC_PRODUCTS_NAME;
  public orderSpecificCollections: string = RULE_TYPES_OPTIONS.ORDER_SPECIFIC_COLLECTIONS_NAME;
  public lastOrderCreated: string = RULE_TYPES_OPTIONS.LAST_ORDER_CREATED_NAME;
  public customCreatedRule: string = RULE_TYPES_OPTIONS.CUSTOMER_CREATED_RULE_NAME;
  public fulfillmentStatus: string = RULE_TYPES_OPTIONS.FULFILLMENT_STATUS_NAME;
  public paymentStatus: string = RULE_TYPES_OPTIONS.PAYMENT_STATUS_NAME;
  public orderCancelled: string = RULE_TYPES_OPTIONS.ORDER_CANCELLED_NAME;
  public lineItem: string = RULE_TYPES_OPTIONS.LINE_ITEMS_NAME;
  public upsellParams: string[] = [
    this.ruleTypesOptions.ORDER_SPECIFIC_PRODUCTS_VALUE,
    this.ruleTypesOptions.ORDER_SPECIFIC_COLLECTIONS_VALUE,
  ];
  public lastOrderCustomerParams: string[] = [
    this.ruleTypesOptions.LAST_ORDER_CREATED_VALUE,
    this.ruleTypesOptions.CUSTOMER_CREATED_RULE_VALUE,
  ];
  public smallActionWidthArray: string[] = [
    RULE_TYPES_OPTIONS.SOURCE_OF_SUBSCRIPTION_VALUE,
    RULE_TYPES_OPTIONS.ORDER_SPECIFIC_PRODUCTS_VALUE,
    RULE_TYPES_OPTIONS.ORDER_SPECIFIC_COLLECTIONS_VALUE,
    RULE_TYPES_OPTIONS.LAST_ORDER_CREATED_VALUE,
    RULE_TYPES_OPTIONS.CUSTOMER_CREATED_RULE_VALUE,
    RULE_TYPES_OPTIONS.ORDER_CANCELLED_VALUE,
  ];
  public averageActionWidthArray: string[] = [
    RULE_TYPES_OPTIONS.FULFILLMENT_STATUS_VALUE,
    RULE_TYPES_OPTIONS.PAYMENT_STATUS_VALUE,
  ];
  public rulesTypesArray = [
    RULE_TYPES_OPTIONS.MONEY_SPENT_VALUE,
    RULE_TYPES_OPTIONS.AVERAGE_MONEY_AMOUNT_VALUE,
    RULE_TYPES_OPTIONS.TOTAL_DISCOUNTS_VALUE,
  ];

  public get values(): FormArray {
    return this.ruleForm.get('values') as FormArray;
  }

  public get rangeControl(): FormArray {
    return this.ruleForm.get('range') as FormArray;
  }

  public get isRangeControlInvalid(): boolean {
    return this.isRangePickerClosed && this.rangeControl.touched && this.rangeControl.status === 'INVALID';
  }

  private ruleType: string;
  private isRangePickerClosed: boolean = true;

  private get sourceIdControl(): FormControl {
    return this.ruleForm.get('sourceId') as FormControl;
  }

  private sourceTypeValues = [
    { name: RULE_SOURCE_TYPE_OPTIONS.ALL_NAME, value: RULE_SOURCE_TYPE_OPTIONS.ALL_VALUE },
    { name: RULE_SOURCE_TYPE_OPTIONS.KEYWORD_NAME, value: RULE_SOURCE_TYPE_OPTIONS.KEYWORD_VALUE },
    { name: RULE_SOURCE_TYPE_OPTIONS.POPUP_NAME, value: RULE_SOURCE_TYPE_OPTIONS.POPUP_VALUE },
  ];

  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(private route: ActivatedRoute,
              private fb: FormBuilder,
              private _rulesService: RulesService,
              private store: Store,
              private currencyPipe: CurrencyPipe) { }

  ngOnInit(): void {
    this._prepareRuleBasedOnType();

    this._trackCheckoutState();
    if (this.isCondition) {
      this.minWidth = null;
      this.maxWidth = null;
      this.actionDropdownWidth = null;
    }
  }

  public get checkProductsError() {
    return this.ruleForm.get('products').touched && this.ruleForm.get('products').invalid;
  }

  public get checkCollectionsError() {
    return this.ruleForm.get('collections').touched && this.ruleForm.get('collections').invalid;
  }

  public onSourceTypeChange(option, presetValue?): void {
    this.onChange('sourceType', option);
    this._checkSourceType(option, presetValue);
  }

  public onOrderCustomerRuleTypeChange(option): void {
    this.onChange('period', option);
    this._checkRuleType(option);
  }

  public setSelectedDate(date: DateRangeData): void {
    this.rangeControl.at(0).setValue(date.from);
    this.rangeControl.at(1).setValue(date.to);
    this.rangeControl.markAsTouched();
  }

  public dateRangePickerOpened(): void {
    this.isRangePickerClosed = false;
  }

  public dateRangePickerClosed(): void {
    this.isRangePickerClosed = true;
  }

  public onSourceChange(controlName: string, value): void {
    this.ruleForm.removeControl('values');
    this.ruleForm.removeControl('products');
    this.ruleForm.removeControl('collections');
    this.ruleForm.removeControl('sourceType');
    this.ruleForm.removeControl('parameter');
    this.ruleForm.removeControl('period');
    this.ruleForm.removeControl('range');
    this.ruleForm.removeControl('value');
    this._removeSourceType(value);
    this._fillRule(value);
    this._addValuesFormArray(value);
    this._addUpsellControls(value);
    this._addLineItemControls(value);
    this._updateOrderStatus(value);
    this._addSourceTypeControl(value);

    if (!this.upsellParams.includes(value) && !this.isValueSecondaryOption) {
      this._setDefaultActionOption();
    }
    this._checkValueSecondaryOption(value);
    if (this.isValueSecondaryOption) {
      this.ruleForm.addControl('value', new FormControl(this.orderStatusOptions[0].value));
      this.orderStatusOption = this.orderStatusOptions[0].name;
      this.ruleForm.removeControl('action');
    } else {
      this.ruleForm.addControl('action', new FormControl(''));
      this._setDefaultActionOption();
    }

    this.onChange(controlName, value);

    if (this.lastOrderCustomerParams.includes(value)) {
      this.selectedOrderCustomerRuleTypeOption = RULE_PERIOD.ALL_TIME_NAME;
      this.ruleForm.addControl('period', new FormControl(RULE_PERIOD.ALL_TIME_VALUE));
    }
  }

  public onChange(controlName: string, value): void {
    this.ruleForm.get(controlName).setValue(value);
  }

  public onActionChange(value): void {
    this.onChange('action', value);
    this._updateValueControlBasedOnAction(value);
  }

  public onOrderStatusChange(value): void {
    this.onChange('value', value);
  }

  public onLineItemChange(value): void {
    this.onChange('parameter', value);
    this._updateActions(value);
    this._setDefaultActionOption();
    this._updateValueControlBasedOnAction(RULE_ACTIONS_OPTIONS.EQUAL_NAME);
    this.values.reset();
    const isProductTitleValue = value === RULE_LINE_ITEMS_OPTIONS.PRODUCT_TITLE_VALUE;
    this._updateProductTitleValidators(isProductTitleValue ? RULE_LINE_ITEMS_OPTIONS.PRODUCT_TITLE_VALUE : 'required');
  }

  public onDeleteRule(): void {
    this.deleteRule.emit();
  }

  public selectUpsellTypePurchased(type: string): void {
    this.eventsSubject.next(type);
  }

  public getValidationError(control): string {
    if (!control.errors || control.untouched) return '';

    this.isTwoLineError = false;
    this.isThreeLineError = false;

    if (control.errors.required) return 'This is a required field';

    if (control.errors.maxlength) {
      this.isTwoLineError = true;
      return `Title cannot contain more than ${control.errors.maxlength.requiredLength} characters`;
    }

    if (control.errors.max) {
      this.isTwoLineError = true;
      const max = this.currencyPipe.transform(control.errors.max.max, '', '', '1.0');
      if (control.errors.max.max > 999) {
        this.isTwoLineError = false;
        this.isThreeLineError = true;
      }
      return `The maximum amount allowed is ${max}. Please decrease your amount`;
    }

    if (control.errors.greaterLessValueInvalid)
      return 'Invalid values found';
  }

  private _prepareRuleBasedOnType(): void {
    this.isCondition = !!this.conditionType;

    if (!this.selectedTypeOption) {
      this.selectedTypeOption = this.isCondition
        ? RULE_TYPES_OPTIONS.FULFILLMENT_STATUS_NAME
        : RULE_TYPES_OPTIONS.SOURCE_OF_SUBSCRIPTION_NAME;
    }

    this.options = this.isCondition
      ? RULE_TYPE_OPTIONS.filter((option: DropdownRuleListData) => option.conditionType.includes(this.conditionType))
      : RULE_TYPE_OPTIONS;
  }

  private _updateProductTitleValidators(parameter: string): void {
    const validators = this._rulesService.getProductTitleValidators(parameter);
    this.values.controls.forEach((control: AbstractControl) => {
      control.clearValidators();
      control.setValidators(validators);
    });
  }

  private _setOrderStatusName(data: string | boolean): string {
    let name: string;

    if (typeof data === 'boolean') {
      name = data ? RULE_SECONDARY_OPTIONS.YES_NAME: RULE_SECONDARY_OPTIONS.NO_NAME;
    } else {
      name = ORDER_STATUS_VALUE_TO_NAME_OPTION[data];
    }

    return name;
  }

  private _updateOrderStatus(type: string): void {
    if (this._isOrderStatus(type)) {
      this.orderStatusOptions = ORDER_STATUS[type];

      if (!this.orderStatusOption) {
        this.orderStatusOption = this.orderStatusOptions[0].name;
      }
    }
  }

  private _isOrderStatus(value: string): boolean {
    return Object.keys(ORDER_STATUS).includes(value);
  }

  private _fillPeriod(data): void {
    if (data?.period) {
      this.selectedOrderCustomerRuleTypeOption = ORDER_CREATED_CUSTOMER_CREATED_VALUE_TO_NAME_OPTION[data.period];

      if (data?.range) {
        this._fillRange(data.range);
      }
    }
  }

  private _fillRange(range): void {
    this._setRangeValues(range);
  }

  private _updateActions(value): void {
    this.isMaskEnabled = this.rulesTypesArray.includes(value);
    this.actionOptions = RULE_NAME_TO_SECONDARY_OPTIONS[value];
    this._calculateActionWidth(value);
  }

  private _buildUpsellParams(upsellType: string, values: any[]): void {
    this.upsellParametersType = upsellType;
    this._rulesService.createRequiredControl(this.ruleForm, upsellType, values || []);
    this.upsellParameters = this.ruleForm.get(upsellType);
  }

  private _fillRule(type: string): void {
    this._checkValueSecondaryOption(type);
    this._setRuleType(type);
    this._updateActions(type);
    if (!this.isValueSecondaryOption) {
      this.ruleForm.addControl('action', new FormControl(this.selectedActionOption));
    }
    this._removeSourceType(type);
  }

  private _addSourceTypeControl(ruleType: string): void {
    if (ruleType === this.ruleTypesOptions.SOURCE_OF_SUBSCRIPTION_VALUE) {
      this.selectedSourceTypeOption = RULE_SOURCE_TYPE_OPTIONS.ALL_NAME;
      this.ruleForm.addControl('sourceType', new FormControl(RULE_SOURCE_TYPE_OPTIONS.ALL_VALUE));
    }
  }

  private _removeSourceType(type: string): void {
    if (type !== this.ruleTypesOptions.SOURCE_OF_SUBSCRIPTION_VALUE) {
      this.ruleForm.removeControl('sourceType');
    }
  }

  private _checkValueSecondaryOption(type: string) {
    this.isValueSecondaryOption = type === this.ruleTypesOptions.FULFILLMENT_STATUS_VALUE ||
      type === this.ruleTypesOptions.PAYMENT_STATUS_VALUE ||
      type === this.ruleTypesOptions.ORDER_CANCELLED_VALUE;
  }

  private _addUpsellControls(type: string, values?: any[]): void {
    if (this.upsellParams.includes(type)) {
      const upsellType = type === 'OrderedProducts' ? 'products' : 'collections';
      this._buildUpsellParams(upsellType, values);
    }
  }

  private _addLineItemControls(type: string, data?: RuleItem): void {
    if (this._isLineItem(type)) {
      this._rulesService.createRequiredControl(this.ruleForm, 'parameter', data?.parameter || RULE_LINE_ITEMS_OPTIONS.PRODUCT_ID_VALUE);

      if (data?.parameter) {
        this.lineItemOption = RULE_LINE_ITEMS_VALUE_TO_NAME_OPTIONS[data.parameter];
        this._updateActions(data.parameter);
        this._updateSelectedSecondaryOption(data);
      }
    }
  }

  private _calculateActionWidth(value: string): void {
    if (this.isCondition) return;
    if (this.smallActionWidthArray.includes(value)) {
      this.actionDropdownWidth = 140;
    } else if (this.averageActionWidthArray.includes(value)) {
      this.actionDropdownWidth = 180;
    } else {
      this.actionDropdownWidth = 240;
    }
  }

  private _isMoneyOrdersAndDiscounts(type: string): boolean {
    return Object.keys(MONEY_ORDERS_AND_DISCOUNTS_TO_ACTION).includes(type);
  }

  private _isLineItem(type: string): boolean {
    return RULE_TYPES_OPTIONS.LINE_ITEMS_VALUE === type;
  }

  private _isValuesAvailable(type: string): boolean {
    return this._isMoneyOrdersAndDiscounts(type) || this._isLineItem(type);
  }

  private _addValuesFormArray(type: string): void {
    if (this._isValuesAvailable(type)) {
      this._rulesService.createRuleValuesControls(this.ruleForm, type, [], this.lineItemOption);
    }
  }

  private _updateValueControlBasedOnAction(value: string): void {
    const isValuesAvailable = this._isValuesAvailable(this.ruleType);
    if (
      isValuesAvailable
      && this.values.length < 2
      && value === RULE_ACTIONS_OPTIONS.GREATER_THAN_AND_LESS_THAN_VALUE
    ) {
      this._addValueControl();
    }
    if (
      isValuesAvailable
      && this.values.length > 1
      && value !== RULE_ACTIONS_OPTIONS.GREATER_THAN_AND_LESS_THAN_VALUE
    ) {
      this.values.removeAt(this.values.length - 1);
    }
  }

  private _addValueControl(): void {
    this._rulesService.createSecondRuleValueControl(this.values, this.ruleType, this.lineItemOption);
  }

  private _setRuleType(type: string): void {
    this.ruleType = type;
  }

  private _checkSourceType(option, presetValue?): void {
    if (this.sourceIdControl) {
      this.sourceIdControl.reset();
    }
    this.optionItems = null;
    this.minWidth = this.isCondition ? null : 200;
    if (option === RULE_SOURCE_TYPE_OPTIONS.KEYWORD_VALUE) {
      const ruleKeywords = this.store.selectSnapshot(state => state.rules.ruleKeywords);
      this._prepareSourceIdOptions('All Keywords', ruleKeywords, RULE_SOURCE_TYPE_OPTIONS.KEYWORD_NAME, presetValue);
      this.minWidth = this.isCondition ? null : 200;
    } else if (option === RULE_SOURCE_TYPE_OPTIONS.POPUP_VALUE) {
      const rulePopups = this.store.selectSnapshot(state => state.rules.rulePopups);
      this._prepareSourceIdOptions('All Popups', rulePopups, RULE_SOURCE_TYPE_OPTIONS.POPUP_NAME, presetValue);
      this._calculateInputWidth(this.optionItems);
    } else {
      this.ruleForm.removeControl('sourceId');
    }
  }

  private _checkRuleType(option: string): void {
    if (option === RULE_PERIOD.RANGE_VALUE) {
      this._setRangeValues([]);
    } else {
      this.ruleForm.removeControl('range');
    }
  }

  private _setRangeValues(range: string[] = []): void {
    this._rulesService.createRangeValuesControls(this.ruleForm, range);
  }

  private _calculateInputWidth(optionItems): void {
    let nameLength: number = 0;
    optionItems.forEach(optionItem => {
      if (nameLength < optionItem.name?.length) {
        nameLength = optionItem.name.length;
      }
    });
    if (this.isCondition) {
      this.minWidth = null;
      return;
    }
    const width = nameLength * 8 + 36;
    this.minWidth = width > 450 ? 450 : width;
  }

  private _trackCheckoutState(): void {
    this.merchant$
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(merchantInfo => {
        this.isCheckoutEnabled = merchantInfo.isCheckoutActive;
        this._updateSourceType();
      });
  }

  private _updateSourceType(): void {
    if (this.isCheckoutEnabled) {
      this.sourceType = [...this.sourceTypeValues,
        { name: RULE_SOURCE_TYPE_OPTIONS.CHECKOUT_NAME, value: RULE_SOURCE_TYPE_OPTIONS.CHECKOUT_VALUE }];
    } else {
      this.sourceType = [...this.sourceTypeValues,
        { name: RULE_SOURCE_TYPE_OPTIONS.CHECKOUT_NAME + ' (Disabled)', value: RULE_SOURCE_TYPE_OPTIONS.CHECKOUT_VALUE,
          error: 'Checkout is not enabled' }];
    }
  }

  private _setSourceTypeSelectedOption(data): void {
    const isCheckoutActive = this.store.selectSnapshot(state => state.merchant.isCheckoutActive);
    if (RULE_SOURCE_TYPE_OPTIONS.CHECKOUT_NAME === data.sourceType) {
      this.selectedSourceTypeOption = isCheckoutActive ? RULE_SOURCE_TYPE_OPTIONS.CHECKOUT_NAME :
        RULE_SOURCE_TYPE_OPTIONS.CHECKOUT_NAME + ' (Disabled)';
    } else {
      this.selectedSourceTypeOption = RULE_SOURCE_TYPE_VALUE_TO_NAME_OPTIONS[data.sourceType];
    }
  }

  private _prepareSourceIdOptions(defaultOption, sourceIdOptions, name, presetValue): void {
    if (!this.sourceIdControl) {
      this.ruleForm.addControl('sourceId', new FormControl(presetValue || 0));
    } else {
      this.sourceIdControl.patchValue(presetValue);
    }
    this.selectedSourceIdOption = defaultOption;
    const options = [{ name: this.selectedSourceIdOption, id: null }, ...sourceIdOptions];
    this.optionItems = this._rulesService.transformInputOptions(options, name);
    if (presetValue && presetValue !== 0) {
      this.selectedSourceIdOption = this.optionItems?.find((presetOption) => presetValue === presetOption.value)?.name;
    }
  }

  private _updateSelectedSecondaryOption(data) {
    const item = this.actionOptions?.find((option) => {
      return option.value === (data.action || data.value);
    });
    if (item) {
      this.selectedActionOption = item.name;
    }
  }

  private _setDefaultActionOption(): void {
    this.selectedActionOption = RULE_ACTIONS_OPTIONS.EQUAL_NAME;
    this.ruleForm.get('action').setValue(RULE_ACTIONS_OPTIONS.EQUAL_VALUE);
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
