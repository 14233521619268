import { ShortUrl } from './settings';

import { IntercomUserModel } from '@features/components/intercom/models';

export interface MerchantInfo {
  activeLOA: null;
  appEnabled: boolean;
  appStatusChangingTo: boolean | null;
  botAssignedFeature: boolean;
  isCheckoutActive: boolean;
  isShopifyPlus: boolean;
  isSubscriptionActive: boolean;
  isSubscriptionFree: boolean;
  isOCUCheckoutAvailable: boolean;
  isOCUIntegrationEnabled: boolean;
  showTCPASettingsMessage: boolean;
  intercom: IntercomUserModel;
  domain: string;
  primaryDomain: string;
  phone: string;
  shortenerSettings: {
    globalShortUrl: ShortUrl;
  };
  shop: any;
  notifications: any;
  balance: number;
  autoRechargeActive: boolean;
  autoRechargeUsed: boolean;
  autoRechargeSpent: number;
  autoRechargeAmount: number;
  autoRechargeThreshold: number;
  reservedNumber: string;
  instalationProcess: number;
  numberProvisioningStatus: boolean;
  autoRechargeTotalAmountLimit: number;
  shareableLinksWizardViewed: boolean;
  hasInstalledOCU: boolean;
  themeId: number;
  appName: string;
}
