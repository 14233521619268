export interface Table {
  name: string;
  value: string;
  template?: string;
  align?: TableColumnAlignment;
  sort?: TableColumnSort;
  type?: TableColumnType;
  width?: string;
}

export interface TableCellTemplateData {
  value: string;
  type: TableColumnType;
}

export interface TableSortData {
  sort: TableColumnSort;
  field: string;
}

export type TableColumnType = 'string' | 'number' | 'money';

export type TableColumnAlignment = 'left' | 'center' | 'right';

export type TableColumnSort = 'ASC' | 'DESC';
