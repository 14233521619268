import { DisplayLinkPipe }             from '@shared/pipes/display-link.pipe';
import { PluralPipe }                  from '@shared/pipes/plural.pipe';
import { ShowNewLinesPipe }            from '@shared/pipes/show-new-lines.pipe';
import { ThumbnailPipe }               from '@shared/pipes/thumbnail.pipe';
import { TruncatePipe }                from '@shared/pipes/truncate.pipe';
import { TrustHtmlPipe }               from '@shared/pipes/trust-html.pipe';
import { UcfirstPipe }                 from '@shared/pipes/ucfirst.pipe';
import { StripProtocolPipe }           from '@shared/pipes/strip-protocol.pipe';
import { UpdateTimePipe }              from '@shared/pipes/update-time.pipe';
import { IsDeletedItemPipe }           from '@shared/pipes/is-deleted-item.pipe';
import { PhoneMaskPipe }               from '@shared/pipes/phone-mask.pipe';
import { TableCellTemplateFilterPipe } from '@shared/pipes/table-cell-template-filter.pipe';
import { TableCellAlignPipe }          from '@shared/pipes/table-cell-align.pipe';
import { CamelCaseToDashesPipe }       from '@shared/pipes/camel-case-to-dashes.pipe';
import { NumberOnlyStatusPipe }        from '@shared/components/rules/pipes';
import { IsArrayPipe }                 from '@shared/pipes/is-array.pipe';
import { IsMessagePriceErrorPipe }     from '@shared/pipes/is-message-price-error.pipe';
import { IsMessagePriceWarningPipe }   from '@shared/pipes/is-message-price-warning.pipe';

export const pipes = [
  DisplayLinkPipe,
  PluralPipe,
  ShowNewLinesPipe,
  ThumbnailPipe,
  TruncatePipe,
  TrustHtmlPipe,
  UcfirstPipe,
  StripProtocolPipe,
  UpdateTimePipe,
  IsDeletedItemPipe,
  PhoneMaskPipe,
  TableCellTemplateFilterPipe,
  TableCellAlignPipe,
  CamelCaseToDashesPipe,
  NumberOnlyStatusPipe,
  IsArrayPipe,
  IsMessagePriceErrorPipe,
  IsMessagePriceWarningPipe,
];
