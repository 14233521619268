import {
  Component,
  Input,
  OnInit,
  ElementRef,
  ViewEncapsulation,
  Renderer2,
  HostListener,
  Output,
  EventEmitter,
} from '@angular/core';

import { TagAlignment, TagRemovedData, TagColors } from './tag.model';

@Component({
  selector: 'k-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TagComponent implements OnInit {
  @Input() set tagColor(color: TagColors) {
    this.colorsSet.add(color);
    this._removeTagColors();
    this._updateTagColor(color);
  }
  @Input() disabled: boolean = false;
  @Input() displayHint: boolean = false;
  @Input() hint: string;
  @Input() alignment: TagAlignment = 'center';
  @Input() isCloseButtonEnabled: boolean = false;
  @Input() isUsedInEditor: boolean = false;
  @Input() isInteractive: boolean = true;
  @Output() tagClicked: EventEmitter<string> = new EventEmitter<string>();
  @Output() tagRemoved: EventEmitter<TagRemovedData> = new EventEmitter<TagRemovedData>();

  private colorsSet = new Set();

  @HostListener('click', ['$event'])
  onTagClick(event: Event) {
    this.disabled ? event.preventDefault() : this.tagClicked.emit(this.el.nativeElement.textContent.trim());
  }

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    if (!this.colorsSet.size) {
      this._updateTagColor('lightcyan');
    }
    if (this.isCloseButtonEnabled) {
      this.renderer.addClass(this.el.nativeElement, 'k-tag--closable');
    }
    this.renderer.addClass(this.el.nativeElement, `k-text--${this.alignment}`);
  }

  public remove(): void {
    if (!this.disabled) {
      this.tagRemoved.emit({ text: this.el.nativeElement.textContent.trim(), id: this.el.nativeElement.id });
    }
  }

  private _updateTagColor(color): void {
    this.renderer.addClass(this.el.nativeElement, 'k-tag');
    this.renderer.addClass(this.el.nativeElement, `k-tag--${color}`);

    if (this.isUsedInEditor) {
      this.renderer.addClass(this.el.nativeElement, `k-tag--editor-${color}`);
    }

    if (this.isInteractive) {
      this.renderer.addClass(this.el.nativeElement, 'k-tag--no-hover');
    }
  }

  private _removeTagColors(): void {
    this.colorsSet.forEach((color) => {
      this.renderer.removeClass(this.el.nativeElement, `k-tag--${color}`);
    });
  }
}
