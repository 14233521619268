import { Injectable }   from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Injectable()
export class CurrencyService {
  constructor(private currencyPipe: CurrencyPipe) {}

  public priceToNumber(price: string|number): number {
    const formattedPrice = typeof price === 'number' ? price.toString() : price;

    return +formattedPrice.replace(',', '');
  }

  public formatPrice(price: number): string {
    if (price % 1 === 0) return price.toString();

    return this.currencyPipe.transform(price, 'USD', 'symbol', '1.2-2').replace('$', '');
  }
}
