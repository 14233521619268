import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[kBlur]',
})
export class BlurDirective {
  constructor(private el: ElementRef) { }

  @HostListener('click')
  public onClick(): void {
    this.el.nativeElement.blur();
  }
}
