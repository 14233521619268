import { Component, OnInit, OnDestroy }                       from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Store, Select }                                      from '@ngxs/store';
import { filter, takeUntil }                                  from 'rxjs/operators';
import { Observable, Subject }                                from 'rxjs';

import { RouterService }                  from '@core/services';
import { NotificationData, NotiParams }   from '@notifications/models';
import { NotiService }                    from '@notifications/services';
import { MoveLastShownAlertsToRemaining } from '@store/actions';
import { FAKE_ALERT_TRIGGER_NAME }        from '@shared/texts';

@Component({
  selector: 'k-notification-alerts',
  templateUrl: './notification-alerts.component.html',
  styleUrls: ['./notification-alerts.component.scss'],
})
export class NotificationAlertsComponent implements OnInit, OnDestroy {
  @Select(state => state.app.hideHeader) hideHeader$: Observable<boolean>;
  @Select(state => state.app.isCenteringPage) isCenteringPage$: Observable<boolean>;
  @Select(state => state.notifications.shownAlerts) shownAlerts$: Observable<NotificationData[]>;

  public alerts: NotiParams[] = [];
  public fakeAlertTriggerName = FAKE_ALERT_TRIGGER_NAME;

  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(private store: Store,
              private route: ActivatedRoute,
              private router: Router,
              private _routerService: RouterService,
              private _notiService: NotiService) { }

  ngOnInit() {
    this._listenToNewAlerts();
    this._listenToAutoCloseAlert();
    this._listenToRouteChange();
  }

  public onCloseAlert(triggerId: number): void {
    this._removeAlert(triggerId);
    this._showNextAlert();
  }

  private _removeAlert(triggerId: number): void {
    const alertIndex = this.alerts.findIndex(alert => alert.triggerId === triggerId);
    this.alerts.splice(alertIndex, 1);
  }

  private _showNextAlert(count: number = 1): void {
    const alerts = [...this.store.selectSnapshot(state => state.notifications.remainingAlerts)];

    this._notiService.showAlerts(alerts, count, true);
  }

  private _listenToNewAlerts(): void {
    this.shownAlerts$
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(alerts => {
        this.alerts = [ ...alerts ];
        if (this._notiService.isNumberReady(alerts)) {
          const numberInProvisioning = alerts.find(alert => alert.trigger === 'numberInProvisioning');

          this._notiService.closeAlert(numberInProvisioning);
        }

        this.alerts.forEach(alert => {
          if (alert.name === 'offline') return;

          if (alert.trigger !== this.fakeAlertTriggerName) {
            this._notiService.trackEvent(alert.id, alert.triggerId, 'shown').subscribe();
          }
        });
      });
  }

  private _listenToAutoCloseAlert(): void {
    this._notiService.removeAlert$
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((data: NotificationData) => {
        this._removeAlert(data.triggerId);
      });
  }

  private _listenToRouteChange(): void {
    let navigationEndFired = false;

    this.router.events
      .pipe(
        filter((e: RouterEvent) => e instanceof NavigationEnd),
      )
      .subscribe(() => {
        // hack for NavigationEnd hook that gets called twice
        if (navigationEndFired) {
          return navigationEndFired = false;
        }

        navigationEndFired = true;

        const route = this._routerService.getDeepestFirstChildSnapshot(this.route.snapshot);

        if (route.data.hideHeader && this.alerts.length > 2) {
          this.store.dispatch(new MoveLastShownAlertsToRemaining(this.alerts.length - 2));
          this.alerts.splice(2, this.alerts.length - 2);
        } else {
          this._showNextAlert(2);
        }
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
