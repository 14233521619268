import { Component, OnInit, AfterViewInit, OnDestroy, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Select }                                from '@ngxs/store';
import { delay, takeUntil, tap }                 from 'rxjs/operators';
import { fromEvent, merge, Observable, Subject } from 'rxjs';

import { environment }                  from '@environments/environment';
import { RouterService, SocketService } from '@core/services';
import { MerchantInfo }                 from '@core/models';

@Component({
  selector: 'k-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  @Select(state => state.responses.responsesUnreadStatus) responsesUnreadStatus$: Observable<boolean>;
  @Select(state => state.merchant) merchant$: Observable<MerchantInfo>;
  @Select(state => state.responses.responsesData) meta$: Observable<any>;

  @Input() isExpanded: boolean = false;

  @Output() toggle: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('toggleButton') toggleButton: ElementRef;

  public isProduction: boolean = environment.production;

  public menuItems = [
    {
      title: 'Home',
      link: '/dashboard',
      iconName: 'dashboard',
      options: { exact: false },
      isHidden: this.isProduction,
    },
    {
      title: 'Keywords',
      link: '/keywords',
      iconName: 'key',
      iconSize: 24,
      options: { exact: false },
    },
    {
      title: 'Mobile Popups',
      link: '/popups',
      iconName: 'popup',
      isHidden: false,
      options: { exact: false },
    },
    {
      title: 'Shareable Links',
      link: '/shareable-links',
      iconName: 'link',
      isHidden: false,
      options: { exact: false },
    },
    {
      title: 'Segments',
      link: '/segments',
      iconName: 'person',
      options: { exact: false },
    },
    {
      title: 'Campaigns',
      link: '/campaigns',
      iconName: 'campaigns',
      options: { exact: false },
    },
    {
      title: 'Flows',
      link: '/flows',
      iconName: 'flow',
      options: { exact: false },
    },
    {
      title: 'Checkout',
      link: '/checkout',
      iconName: 'checkout',
      options: { exact: false },
    },
    {
      title: 'Inbox',
      link: '/inbox',
      iconName: 'inbox',
      options: { exact: false },
    },
    {
      title: 'Settings',
      link: '/settings',
      iconName: 'settings',
      options: { exact: false },
    },
    {
      title: 'Examples',
      link: '/examples',
      iconName: 'info',
      options: { exact: false },
      isHidden: this.isProduction,
    },
  ];

  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(private _routerService: RouterService,
              private _socketService: SocketService) { }

  ngOnInit() {
    this._updatedUnresolvedResponsesStatus();
  }

  ngAfterViewInit() {
    fromEvent(this.toggleButton.nativeElement, 'click')
      .pipe(
        tap(() => this.toggle.emit({ hasTransition: true, isExpanded: !this.isExpanded })),
        delay(200),
        tap(() => this.toggle.emit({ hasTransition: false, isExpanded: this.isExpanded })),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe();
  }

  private _updatedUnresolvedResponsesStatus(): void {
    merge(this.meta$, this.responsesUnreadStatus$, this.merchant$)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(status => {
        const responseMenuItem = this.menuItems.find(elem => elem.title === 'Inbox');
        responseMenuItem.iconName = status === true || status.unresolvedResponses === true ? 'inbox-new' : 'inbox';
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
