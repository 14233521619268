import { CampaignListService }    from './campaign-list.service';
import { CampaignService }        from './campaign.service';
import { CampaignChangesService } from './campaign-changes.service';

export {
  CampaignListService,
  CampaignService,
  CampaignChangesService,
};

export const services = [
  CampaignListService,
  CampaignService,
  CampaignChangesService,
];
