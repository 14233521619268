import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup }                                    from '@angular/forms';
import { NgxSmartModalService }                                      from 'ngx-smart-modal';
import { Subject }                                                   from 'rxjs';

import { ApiService, FormService }       from '@core/services';
import { ValidatorService }              from '@shared/service/validator.service';
import { KeywordService }                from '@shared/service/keyword.service';
import { EntitySaveNotificationService } from '@shared/service/entity-save-notification.service';
import { debounceTime, takeUntil }       from 'rxjs/operators';
import { KEYWORD_VALIDATION_ERRORS }     from '@keywords/texts';

@Component({
  selector: 'k-create-keyword-dialog',
  templateUrl: './create-keyword-dialog.component.html',
  styleUrls: ['./create-keyword-dialog.component.scss'],
})
export class CreateKeywordDialogComponent implements OnInit, OnDestroy {
  @Input() identifier: string;
  @Input() reset: boolean;

  @Output() keywordCreate: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();

  public keywordForm: FormGroup;
  public loading: boolean = false;
  public disabled: boolean = true;

  public get keywordNameValidationError(): string {
    const nameControl = this.keywordForm.get('keywordName');

    if (!nameControl.errors || nameControl.untouched) return '';

    if (nameControl.errors.maxlength) {
      return KEYWORD_VALIDATION_ERRORS.TO_MANY_SYMBOLS_ERROR;
    }

    if (nameControl.errors.pattern) {
      return KEYWORD_VALIDATION_ERRORS.SPECIAL_SYMBOLS_ERROR;
    }

    const nameError = this._formService.getError(this.keywordForm, 'keywordName');
    if (nameError) return nameError;

    if (nameControl.errors.hasSpecialKeywordError) {
      return KEYWORD_VALIDATION_ERRORS.OPT_OUT_SPECIAL_KEYWORDS_ERROR;
    }

    if (nameControl.errors.alreadyExists) {
      return KEYWORD_VALIDATION_ERRORS.ALREADY_EXISTS_ERROR;
    }
  }

  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private modal: NgxSmartModalService,
    private fb: FormBuilder,
    private _apiService: ApiService,
    private _keywordService: KeywordService,
    private _entitySaveNotificationService: EntitySaveNotificationService,
    private _formService: FormService,
    private _validatorService: ValidatorService,
  ) { }

  ngOnInit(): void {
    this._initForm();
    this._listenStatusChanges();
    this._listenValueChanges();
  }

  public onClosed(): void {
    this._close();
  }

  public onCancel(): void {
    this.keywordForm.reset();
    this.cancel.emit();
    this._close();
  }

  public onCreate(): void {
    if (this.keywordForm.valid) {
      this.loading = true;
      const name = this.keywordForm.get('keywordName').value;
      this._keywordService.addKeyword({ name })
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(({ id }) => {
          this.keywordCreate.emit({ id, name });
          this._entitySaveNotificationService.onSavedKeywordSegment();
          this._close();
          this.keywordForm.reset();
          this.loading = false;
        }, () => {
          this.loading = false;
        });
    }
  }

  private _initForm() {
    this.keywordForm = this.fb.group({
      keywordName: ['', this._validatorService.keywordNameValidators(), [
        this._validatorService.alreadyExistsValidator({ apiService: this._apiService, method: 'post', path: 'keyword-exists' }),
      ]],
    });
  }

  private _listenStatusChanges(): void {
    this.keywordForm.statusChanges
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((status) => {
        this.disabled = status !== 'VALID';
      });
  }

  private _listenValueChanges(): void {
    this.keywordForm.statusChanges
      .pipe(debounceTime(2000), takeUntil(this.ngUnsubscribe$))
      .subscribe(() => {
        if (this.keywordForm.untouched) {
          this.keywordForm.markAllAsTouched();
        }
      });
  }

  private _close(): void {
    if (this.reset) {
      this.keywordForm.reset();
    }

    this.loading = false;
    this.modal.getModal(this.identifier).close();
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
