import { Injectable }                  from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';


import {
  SetSettingsSendingLogicOriginal,
  ClearSettingsSendingLogicOriginal,
  ClearSettingsSendingLogicData,
} from '@store/actions';

import { SettingsSendingLogic } from '@settings/components/models';

export interface SettingsSendingLogicStateModel {
  settingsSendingLogicOriginal: SettingsSendingLogic;
  settings: {
    model?: SettingsSendingLogic,
    dirty: boolean,
    errors: {},
  };
}

@State<SettingsSendingLogicStateModel>({
  name: 'settingsSendingLogic',
  defaults: {
    settingsSendingLogicOriginal: null,
    settings: {
      model: null,
      dirty: false,
      errors: {},
    },
  },
})
@Injectable()
export class SetSettingsSendingLogicState {

  @Action(SetSettingsSendingLogicOriginal)
  setSegmentOriginal({ patchState }: StateContext<SettingsSendingLogicStateModel>, { payload }: SetSettingsSendingLogicOriginal) {
    patchState({
      settingsSendingLogicOriginal: payload,
    });
  }

  @Action(ClearSettingsSendingLogicOriginal)
  clearSegmentOriginal({ patchState }: StateContext<SettingsSendingLogicStateModel>) {
    patchState({
      settingsSendingLogicOriginal: null,
    });
  }

  @Action(ClearSettingsSendingLogicData)
  clearSegment({ getState, setState }: StateContext<SettingsSendingLogicStateModel>) {
    setState({
      ...getState(),
      settings: {
        model: null,
        dirty: false,
        errors: {},
      },
    });
  }
}
