import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
}                                       from '@angular/core';

import { Router }                       from '@angular/router';
import { MESSAGE_RECOMMENDATION_HINTS } from '@shared/texts';
import { MessageRecommendation }        from '@shared/models';
import { fromEvent, Subject }           from 'rxjs';
import { delay, takeUntil, tap }        from 'rxjs/operators';

@Component({
  selector: 'k-message-recommendation',
  templateUrl: './message-recommendation.component.html',
  styleUrls: ['./message-recommendation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageRecommendationComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() set messageRecommendationData(data: MessageRecommendation) {
    if (data) this._updateRecommendationsStates(data);
  }

  public isQuietHour: boolean = false;
  public isMerchantBalanceExpanded: boolean = false;
  public isMaxMessagePriceExpanded: boolean = false;
  public messageRecommendations: MessageRecommendation;
  public messageRecommendationHints = MESSAGE_RECOMMENDATION_HINTS;
  public expandedBalanceHint: string;

  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(private router: Router) { }

  ngOnInit() {
    this.expandedBalanceHint = this._getLinkForBalanceHint();
  }

  ngAfterViewInit() {
    fromEvent(document, 'click')
      .pipe(
        delay(1000),
        tap(() => {
          const balanceLink = document.querySelector('[data-balance]');

          if (balanceLink) {
            balanceLink.addEventListener('click', this._navigate.bind(this));
          }
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe();
  }

  private _navigate(): Promise<boolean> {
    return this.router.navigate(['/settings/payments']);
  }

  private _updateRecommendationsStates(data: MessageRecommendation): void {
    this.messageRecommendations = data;
    this.isQuietHour = this._isContactQuietHour(data.quietHoursFrom, data.quietHoursTo);
    this.isMerchantBalanceExpanded = data.merchantBalance < data.messagePrice;
    this.isMaxMessagePriceExpanded = data.maxMessagePrice && data.maxMessagePrice < data.messagePrice;
  }

  private _getLinkForBalanceHint(): string {
    const balanceText = this.messageRecommendationHints.MERCHANT_EXPENDED_BALANCE;

    return balanceText
      .replace('{%', '<a class="k-link" data-balance>')
      .replace('%}', '</a>');
  }

  private _isContactQuietHour(from: number, to: number): boolean {
    const currentDate = new Date();
    const utcMinutes = currentDate.getUTCMinutes() + currentDate.getUTCHours() * 60;

    return utcMinutes < from && utcMinutes > to;
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
