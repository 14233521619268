import { Observable } from 'rxjs';

import { TableColumnSort } from '@shared/models/table';

export interface BaseTableService {
  getList: (page: number, perPage: number, field?: string, order?: TableColumnSort, filter?: any) => Observable<any>;
  deleteItem?: (item: any) => Observable<any>;
}

export interface BaseTableServiceParams {
  skip: number;
  limit: number;
  order?: string;
  sort?: string;
}
