import { Injectable } from '@angular/core';
import moment         from 'moment';

import { DropdownNumberData } from '@shared/models';

@Injectable()
export class TimeService {
  constructor() { }

  public timeBorders(): { [key: string]: string }[] {
    let timeBordersArray = [];

    for (let h = 0; h < 24; h++) {
      const zeroPrefix = h / 10 >= 1 ? '' : 0;
      timeBordersArray = timeBordersArray.concat(
        { value: `${zeroPrefix}${h}:00`, name: moment(`${h}:00`, 'HH:mm').format('h:mm A') },
        { value: `${zeroPrefix}${h}:30`, name: moment(`${h}:30`, 'HH:mm').format('h:mm A') },
      );
    }

    return timeBordersArray;
  }

  public getQuietHours(from: number, to: number): DropdownNumberData[] {
    const hourOptions: DropdownNumberData[] = [];
    const fromHour = Math.floor(from / 30);
    const toHour = Math.floor(to / 30);

    for (let halfHourIncrement = fromHour; halfHourIncrement <= toHour; ++halfHourIncrement) {
      const minutes = halfHourIncrement * 30 % 60 ? '30' : '00';
      const hours = Math.floor(halfHourIncrement / 2);

      const hourItems = [
        {
          value: halfHourIncrement * 30,
          name: moment(`${hours}:${minutes}`, 'HH:mm').format('h:mm A'),
        },
      ];
      hourOptions.push(...hourItems);
    }

    return hourOptions;
  }

  public prepareResponseTimeFormat(data): string {
    const dateNow = moment(moment().format('MMMM DD YYYY'));
    const messageDate = moment(moment(data).format('MMMM DD YYYY'));

    return dateNow.diff(messageDate, 'days') >= 1
      ? moment(data).format('MMMM DD h:mm A')
      : moment(data).format('h:mm A');
  }
}
