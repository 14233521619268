import { Injectable } from '@angular/core';
import { Router }     from '@angular/router';
import { Store }      from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap }        from 'rxjs/operators';
import { Redirect }   from '@shopify/app-bridge/actions';

import { UpdateMerchantInfo }                          from '@store/actions';
import { ApiService, ShopifyService, CurrencyService } from '@core/services';

@Injectable()
export class PaymentService {
  constructor(private store: Store,
              private router: Router,
              private _apiService: ApiService,
              private _shopifyService: ShopifyService,
              private _currencyService: CurrencyService) { }

  public toggleAutoRecharge(autoRechargeActive: boolean): Observable<any>  {
    return this._apiService.patch('payments/auto-recharge-recurring', { autoRechargeActive })
      .pipe(
        tap(() => {
          this.store.dispatch(new UpdateMerchantInfo({ key: 'autoRechargeActive', value: autoRechargeActive }));
        })
      );
  }

  public createAutoRechargePurchase(amount: string, threshold: string, totalAmountLimit: string): Observable<any>  {
    const returnUrl = this.router.url.includes('?')
      ? this.router.url.split('?')[0]
      : this.router.url;
    const params = {
      amount: this._currencyService.priceToNumber(amount),
      threshold: this._currencyService.priceToNumber(threshold),
      totalAmountLimit: this._currencyService.priceToNumber(totalAmountLimit),
      returnUrl,
    };

    return this._apiService.put('payments/create-auto-recharge-recurring', params)
      .pipe(
        tap(data => {
          if (data.redirectUrl) {
            this._remoteRedirect(data.redirectUrl);
          }

          this.store.dispatch(new UpdateMerchantInfo({ key: 'autoRechargeAmount', value: amount }));
          this.store.dispatch(new UpdateMerchantInfo({ key: 'autoRechargeThreshold', value: threshold }));
        })
      );
  }

  public createOneTimePurchase(amount: number): Observable<any>  {
    const shop = this.store.selectSnapshot(state => state.merchant.domain);
    const returnUrl = this.router.url.includes('?')
      ? this.router.url.split('?')[0]
      : this.router.url;

    return this._apiService.get('create-one-time-purchase', { shop, amount, returnUrl }, 'isPublic')
      .pipe(
        tap(data => {
          if (data.redirectUrl) {
            this._remoteRedirect(data.redirectUrl);
          }
        })
      );
  }

  private _remoteRedirect(redirectUrl: string): void {
    this._shopifyService.redirect.dispatch(Redirect.Action.REMOTE, redirectUrl);
  }
}
