import { NgModule }                  from '@angular/core';
import { CommonModule }              from '@angular/common';
import { SimpleNotificationsModule } from 'angular2-notifications';

import { notificationsConfig } from '@config';
import { SharedModule }        from '@shared/shared.module';

import { components}    from './components';
import { services }     from './services';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    ...components,
  ],
  imports: [
    CommonModule,
    SimpleNotificationsModule.forRoot(notificationsConfig),
    SharedModule,
    RouterModule,
  ],
  providers: [
    ...services,
  ],
  exports: [
    ...components,
    SimpleNotificationsModule,
  ],
})
export class NotificationsModule { }
