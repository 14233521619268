import { NgModule }     from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgxsModule }                    from '@ngxs/store';
import { NgxsFormPluginModule }          from '@ngxs/form-plugin';
import { NgxsLoggerPluginModule }        from '@ngxs/logger-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';

import { environment } from '@environments/environment';

import { states } from '@store/states';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NgxsModule.forRoot([...states], { developmentMode: !environment.production }),
    NgxsFormPluginModule.forRoot(),
    //NgxsLoggerPluginModule.forRoot({ disabled: environment.production }),
    NgxsReduxDevtoolsPluginModule.forRoot(),
  ],
})
export class StoreModule { }
