import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayLink',
})
export class DisplayLinkPipe implements PipeTransform {

  transform(value: string, softBreak?: boolean): string {
    let updatedString = value;
    let urls;

    if (value) {
      urls = value.match(new RegExp(/(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gim));
    }

    if (!urls) {
      return value;
    }

    urls.forEach((url) => {
      updatedString = updatedString.replace(
        new RegExp(`\\b${url}(?!/)\\b`, 'gim'),
        `<span class="k-preview__link">${softBreak ? this._formatUrl(url) : url}</span>`,
      );
    });

    return updatedString;
  }

  private _formatUrl(url) {
    return url
      .split('//')
      .map(str => str
        .replace(/(:)/giu, '$1<wbr>')
        .replace(/([/~.,\-_?#%])/giu, '<wbr>$1')
        .replace(/([=&])/giu, '<wbr>$1<wbr>'))
      .join('//<wbr>');
  }

}

