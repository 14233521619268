import { FlowConnectionsData, FlowListItem } from '@flows/models';
import { FlowStatistic }                     from '@flows/models/flow-data';
import { TableSortData }                     from '@shared/models';

export class SetFlowsTriggerData {
  static readonly type = '[Flows Trigger] SetFlowsTriggerData';

  constructor(public payload: any) { }
}

export class SetFlowsStatistic {
  static readonly type = '[Flows List] SetFlowsStatistic';

  constructor(public payload: FlowStatistic) { }
}

export class SetFlowsOriginal {
  static readonly type = '[Flows Trigger] SetFlowsOriginal';

  constructor(public payload: any) { }
}

export class UpdateFlowsOriginalItemStatus {
  static readonly type = '[Flows Trigger] UpdateFlowsOriginalItemStatus';

  constructor(public status: boolean) { }
}

export class ClearFlowsOriginal {
  static readonly type = '[Flows Trigger] ClearFlowsOriginal';

  constructor() { }
}

export class SetFlowName {
  static readonly type = '[Flows Trigger] SetFlowName';

  constructor(public payload: any) { }
}

export class SetFlowTriggerOptionChannel {
  static readonly type = '[Flows Trigger] SetFlowTriggerOptionChannel';

  constructor(public payload: any) { }
}

export class SetFlowsData {
  static readonly type = '[Flows List] SetFlowsData';

  constructor(public payload: any) { }
}

export class UpdateUpsellParamsData {
  static readonly type = '[Flows Trigger] UpdateUpsellParamsData';

  constructor(public payload: any) { }
}

export class SetFlowsList {
  static readonly type = '[Flows List] SetFlowsList';

  constructor(public payload: FlowListItem[]) { }
}

export class DeleteFlowListItem {
  static readonly type = '[Flows List] DeleteFlowListItem';

  constructor(public index: number) { }
}

export class AddFlowListItem {
  static readonly type = '[Flows List] AddFlowListItem';

  constructor(public payload: AddFlowListItem) { }
}

export class UpdateFlowListItemStatus {
  static readonly type = '[Flows List] UpdateFlowListItemStatus';

  constructor(public id: number, public status: boolean) { }
}

export class ClearFlowList {
  static readonly type = '[Flows List] ClearFlowsList';

  constructor() { }
}

export class UpdateFlowStatuses {
  static readonly type = '[Flow List] UpdateFlowStatuses';

  constructor(public statusUpdates: any[]) { }
}

export class UpdateCancellations {
  static readonly type = '[Flow Trigger] UpdateCancellations';

  constructor(public cancellationsUpdate: any[]) {
  }
}

export class DeleteTriggerData {
  static readonly type = '[Flow Trigger] DeleteTriggerData';

  constructor() { }
}

export class UpdateStepParams {
  static readonly type = '[Flow Step] UpdateStepParams';

  constructor(public payload: any) { }
}

export class SetStepsData {
  static readonly type = '[Flow Step] SetStepsData';

  constructor(public payload: any) { }
}

export class DeleteFlowStep {
  static readonly type = '[Flows Step] DeleteFlowStep';

  constructor(public stepId: string, public branch?: string) { }
}

export class SetFirstStepId {
  static readonly type = '[Flow Step] SetFirstStepId';

  constructor(public payload: string) { }
}

export class SetCreateFromScratchSelectedTriggerId {
  static readonly type = '[Trigger Id] SetCreateFromScratchSelectedTriggerId';

  constructor(public payload: string) { }
}

export class UpdateFlowStatus {
  static readonly type = '[Flow Trigger] UpdateFlowStatus';

  constructor(public status: boolean) { }
}

export class SetFlowSortingData {
  static readonly type = '[Sorting] SetFlowSortingData';

  constructor(public sorting: TableSortData) { }
}

export class SetFlowConnectionsData {
  static readonly type = '[Flow Trigger] SetFlowConnectionsData';

  constructor(public payload: FlowConnectionsData[]) { }
}
