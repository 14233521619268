import { Component, Input, OnInit } from '@angular/core';

import { ContentLoaderType, ContentLoaderBgColor } from './content-loader.model';

@Component({
  selector: 'k-content-loader',
  templateUrl: './content-loader.component.html',
  styleUrls: ['./content-loader.component.scss'],
})
export class ContentLoaderComponent implements OnInit {
  @Input() type: ContentLoaderType = 'text';
  @Input() bgColor: ContentLoaderBgColor = 'white';
  @Input() padding: boolean = true;

  constructor() { }

  ngOnInit() {
  }
}
