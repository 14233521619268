import { Component, OnDestroy, OnInit }              from '@angular/core';
import { FormBuilder, FormGroup, Validators }        from '@angular/forms';
import { Subject, of, Observable }                   from 'rxjs';
import { debounceTime, first, switchMap, takeUntil } from 'rxjs/operators';
import { Select }                                    from '@ngxs/store';

import { HelperService, ProgressBarService } from '@core/services';
import { SettingsService }                   from '@settings/services';
import { MerchantInfo }                      from '@core/models';

@Component({
  selector: 'k-settings-integrations',
  templateUrl: './settings-integrations.component.html',
  styleUrls: ['./settings-integrations.component.scss'],
})
export class SettingsIntegrationsComponent implements OnInit, OnDestroy {
  @Select(state => state.merchant) merchant$: Observable<MerchantInfo>;

  public settingsForm: FormGroup;
  public changedField: string = '';
  public integrationType: string = '';
  public loading: boolean = true;

  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(private fb: FormBuilder,
              private _helperService: HelperService,
              private _settingsService: SettingsService,
              private _progressBarService: ProgressBarService) { }

  ngOnInit() {
    this._progressBarService.initLoading();
    this._initForm();
  }

  public onChange(integrationType: string, field: string): void {
    this.integrationType = integrationType;
    this.changedField = field;
  }

  private _initForm(): void {
    this.settingsForm = this.fb.group({
      ocuStatus: [false, Validators.required],
      zipifypagesStatus: [false, Validators.required],
    });

    this._fillForm();
  }

  private _fillForm(): void {
    this._settingsService.getSettings('integrations')
      .pipe(first())
      .subscribe(
        (data) => {
          this.settingsForm.setValue({
            ocuStatus: data.ocu.status,
            zipifypagesStatus: data.zipifypages.status,
          });

          this._listenFormChanges();

          this._progressBarService.finishLoading();
          this.loading = false;
        },
        () => {
          this._progressBarService.finishLoading();
          this.loading = false;
        },
      );
  }

  private _listenFormChanges(): void {
    this.settingsForm.valueChanges
      .pipe(
        debounceTime(500),
        switchMap((values) => {
          if (this.settingsForm.invalid) return of(null);

          if (this.integrationType === 'zipifypages') this._progressBarService.initLoading();

          return this._settingsService.updateIntegrationsSettings(
            this.integrationType,
            { [this.changedField]: values[this.changedField] },
          );
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe(res => this._progressBarService.finishLoading(),
          error => this._progressBarService.finishLoading());
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
