import { Component, OnInit } from '@angular/core';

import { ProgressBarService } from '@core/services';

@Component({
  selector: 'k-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent implements OnInit {
  public isVisible = true;
  public overlayWidth = 100;

  private currentValue = 0;
  private maxValue = 100;
  private step = 20;
  private interval: ReturnType<typeof setInterval> = null;
  private timeout:  ReturnType<typeof setTimeout> = null;

  constructor(private _progressBarService: ProgressBarService) {}

  ngOnInit(): void {
    this._listenInitLoading();
    this._listenFinishLoading();
  }

  private _listenInitLoading(): void {
    this._progressBarService.initLoadingObservable$.subscribe(() => {
      this._updateProgress();
      clearTimeout(this.timeout);
      this._initProgressBar();
    });
  }

  private _listenFinishLoading(): void {
    this._progressBarService.finishLoadingObservable$.subscribe(() => {
      clearInterval(this.interval);
      this.overlayWidth = 0;
      this.timeout = setTimeout(() => {
        this.overlayWidth = 100;
        this.isVisible = false;
      }, 1000);
    });
  }

  private _updateProgress(): void {
   this.interval = setInterval(() => {
     this.currentValue += this.step;
     this.overlayWidth = this.maxValue - this.currentValue;
     this.step /= 1.1;
     if (this.currentValue > 70) {
       clearInterval(this.interval);
     }
   });
  }

  private _initProgressBar() {
    this.currentValue = 0;
    this.step = 10;
    this.overlayWidth = 100;
    this.maxValue = 100;
    this.isVisible = true;
    this.interval = null;
    this.timeout = null;
  }
}
