import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators }                                   from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE }                       from '@angular/material/core';
import { MomentDateAdapter }                                                    from '@angular/material-moment-adapter';
import { MatCalendarCellCssClasses }                                            from '@angular/material/datepicker';
import { ActivatedRoute }                                                       from '@angular/router';

import { TimeService } from '@core/services';

import { Subject }              from 'rxjs';
import { Store }                from '@ngxs/store';
import { SettingsService }      from '@settings/services';

export const MATERIAL_DATEPICKER_FORMATS = {
  parse: {
    dateInput: 'MMM D, y',
  },
  display: {
    dateInput: 'MMM D, y',
    monthYearLabel: 'MMMM y',
    dateA11yLabel: 'MMM D, y',
    monthYearA11yLabel: 'MMMM y',
  },
};

@Component({
  selector: 'k-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: DatePickerComponent, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MATERIAL_DATEPICKER_FORMATS},
  ],
})

export class DatePickerComponent extends MomentDateAdapter implements OnInit, OnDestroy {
  @Input() width: number = 240;
  @Input() datePickerDatesFilter: any;
  @Input() placeholder: string = 'Choose a date';
  @Input()
  get datesArray(): string[] { return this.datesArrayList; }
  set datesArray(d: string[]) {
    this.datesArrayList = d;
  }
  @Input() date;
  @Input() isError: boolean;

  @Output() setSelectedDate: EventEmitter<any> = new EventEmitter<any>();
  @Output() hasError: EventEmitter<boolean> = new EventEmitter<false>();

  @ViewChild('picker') picker;

  public datesArrayList: string[];
  public dateForm: FormGroup;
  public selectedOption: any;
  public isDatepickerActive: boolean = false;
  public selectedDate: any;
  public isScheduledCampaignExist: boolean = false;

  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  /*
   * Change days name
   */
  getDayOfWeekNames(style: 'long' | 'short' | 'narrow') {
    return ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
  }

  public get isCreatePage(): boolean {
    return this.route.snapshot.data.createPage;
  }

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private _timeService: TimeService,
    private _settingsService: SettingsService) {
    super('en-US');
  }

  ngOnInit(): void {
    this._initForm();

    if (this.date) {
      this.selectedDate = this.date;
      this._fillForm(this.date);
    }
  }

  /*
  * Add class and style for scheduled campaign on datepicker
   */
  public dateClass() {
    if (this.datesArrayList) {
      return (date: Date): MatCalendarCellCssClasses => {
        const highlightDate = this.datesArrayList
          .map(strDate => new Date(strDate))
          .some(d => d.getDate() === date['_i'].date && d.getMonth() === date['_i'].month && d.getFullYear() === date['_i'].year);

        return highlightDate ? 'scheduled-campaigns' : '';
      };
    } else {
      return null;
    }
  }

  public openDatepicker(element): void {
    this.isDatepickerActive = !this.isDatepickerActive;
    if (this.isDatepickerActive) {
      element.open();
    } else {
      element.close();
    }
  }

  public close(): void {
    this.isDatepickerActive = false;
  }

  public onDateChange(event: any): void {
    this.setSelectedDate.emit(event.value);
  }

  private _initForm(): void {
    this.dateForm = this.fb.group({
      date: ['', Validators.required],
    });
  }

  private _fillForm(data: any): void {

    if (data) {
      this.dateForm.patchValue({
        date: new Date(data),
      });
    }

    this.dateForm.markAllAsTouched();
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
