import { Component, OnInit } from '@angular/core';

import { TitleBarService } from '@core/services';
import { Breadcrumbs }     from '@core/models';

@Component({
  selector: 'k-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {

  constructor(private _titleBarService: TitleBarService) { }

  ngOnInit() {
    this._titleBarService.setSectionBreadcrumb(Breadcrumbs.HOME);
  }

}
