import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isMessagePriceError',
})
export class IsMessagePriceErrorPipe implements PipeTransform {

  transform(settingsData, messagePrice: number): boolean {
    return messagePrice
      && settingsData
      && Object.keys(settingsData).length
      && settingsData?.maxMessagePriceActive
      && settingsData?.maxMessagePriceValue > 0
      && messagePrice >= settingsData.maxMessagePriceValue;
  }
}
