import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup }                         from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDateRangePicker }                             from '@angular/material/datepicker';
import { MomentDateAdapter }                              from '@angular/material-moment-adapter';
import { Subject }                                        from 'rxjs';
import { takeUntil }                                      from 'rxjs/operators';
import moment, { Moment }                                 from 'moment';

import { DateRangeData } from '@shared/components/base/date-range/date-range.model';

export const DATE_FORMATS = {
  parse: {
    dateInput: 'MMM D, y',
  },
  display: {
    dateInput: 'MMM D, y',
    monthYearLabel: 'MMMM y',
    dateA11yLabel: 'MMM D, y',
    monthYearA11yLabel: 'MMMM y',
  },
};

@Component({
  selector: 'k-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: DateRangeComponent, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS},
  ],
})
export class DateRangeComponent extends MomentDateAdapter implements OnInit, OnDestroy {
  @Input() width: number = 240;
  @Input() datePickerDatesFilter: any;
  @Input() placeholder: string = 'Choose a range';
  @Input() set from(data: string) { if (data) { this.range.get('start').setValue(data); } }
  @Input() set to(data: string) { if (data) { this.range.get('end').setValue(data); } }
  @Input() isError: boolean;

  @Output() selectedDate: EventEmitter<DateRangeData> = new EventEmitter<DateRangeData>();
  @Output() opened: EventEmitter<void> = new EventEmitter<void>();
  @Output() closed: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('picker') picker;

  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  public range: FormGroup = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  public isDatepickerActive: boolean = false;

  constructor() {
    super('en-US');
  }

  ngOnInit(): void {
    this._trackChanges();
  }

  public openDatepicker(element: MatDateRangePicker<any>): void {
    this.isDatepickerActive = true;
    this.opened.emit();
    element.open();
  }

  public close(): void {
    this.isDatepickerActive = false;
    this.closed.emit();
  }

  private _trackChanges(): void {
    this.range.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((range) => {
        this.selectedDate.emit({from: range.start, to: range.end});
      });
  }

  // Overrides date creating to skip time zone
  createDate(year: number, month: number, date: number): Moment {
    const result = moment.utc({ year, month, date }).locale(this.locale);

    if (!result.isValid()) {
      console.error(`Invalid date "${date}" for month with index "${month}".`);
    }

    return result;
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

}
