import { Injectable }                  from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { NotificationData } from '@notifications/models';
import {
  AddShownAlerts,
  AddRemainingAlerts,
  RemoveShownAlert,
  HideAlert,
  UnhideAlert,
  RemoveRemainingAlert,
  MoveLastShownAlertsToRemaining,
  SetShownNotifications,
  SetRemainingNotifications,
  AddCurrentlyShownNotification,
  RemoveCurrentlyShownNotification,
} from '@store/actions';

export interface NotificationsStateModel {
  shownAlerts: NotificationData[];
  remainingAlerts: NotificationData[];
  shownNotifications: NotificationData[];
  remainingNotifications: NotificationData[];
  currentlyShownNotification: NotificationData[];
}

@State<NotificationsStateModel>({
  name: 'notifications',
  defaults: {
    shownAlerts: [],
    remainingAlerts: [],
    shownNotifications: [],
    remainingNotifications: [],
    currentlyShownNotification: [],
  },
})
@Injectable()
export class NotificationsState {

  @Action(AddShownAlerts)
  addShownAlerts({ getState, patchState }: StateContext<NotificationsStateModel>, { payload }: AddShownAlerts) {
    const state = getState();

    patchState({
      shownAlerts: [
        ...state.shownAlerts,
        ...payload,
      ],
    });
  }

  @Action(RemoveCurrentlyShownNotification)
  removeCurrentlyShownNotification(
    { getState, patchState }: StateContext<NotificationsStateModel>,
    { triggerId }: RemoveCurrentlyShownNotification) {
    const state = getState();
    const currentlyShownNotification = state.currentlyShownNotification.map(obj => ({ ...obj }));
    const currentlyShownNotificationTriggerId = currentlyShownNotification.findIndex(notification => {
      return notification.triggerId === triggerId;
    });

    if (currentlyShownNotificationTriggerId === -1) return;

    currentlyShownNotification.splice(currentlyShownNotificationTriggerId, 1);

    patchState({ currentlyShownNotification });
  }

  @Action(RemoveShownAlert)
  removeShownAlert({ getState, patchState }: StateContext<NotificationsStateModel>, { triggerId, id }: RemoveShownAlert) {
    const state = getState();
    const shownAlerts = state.shownAlerts.map(obj => ({ ...obj }));
    const shownAlertIndex = shownAlerts.findIndex(alert => {
      if (id) {
        return alert.triggerId === triggerId && alert.userNotificationId === id;
      }
      return alert.triggerId === triggerId;
    });

    if (shownAlertIndex === -1) return;

    shownAlerts.splice(shownAlertIndex, 1);

    patchState({ shownAlerts });
  }

  @Action(HideAlert)
  hideAlert({ getState, patchState }: StateContext<NotificationsStateModel>, { triggerId, id }: HideAlert) {
    const state = getState();
    const shownAlerts = state.shownAlerts.map(obj => ({ ...obj }));
    const shownAlertIndex = shownAlerts.findIndex(alert => {
      return alert.triggerId === triggerId;
    });

    if (shownAlertIndex === -1) return;

    shownAlerts[shownAlertIndex].hidden = true;

    patchState({ shownAlerts });
  }

  @Action(UnhideAlert)
  unhideAlert({ getState, patchState }: StateContext<NotificationsStateModel>, { triggerId, id }: UnhideAlert) {
    const state = getState();
    const shownAlerts = state.shownAlerts.map(obj => ({ ...obj }));
    const shownAlertIndex = shownAlerts.findIndex(alert => {
      return alert.triggerId === triggerId;
    });

    if (shownAlertIndex === -1) return;

    shownAlerts[shownAlertIndex].hidden = false;

    patchState({ shownAlerts });
  }

  @Action(AddRemainingAlerts)
  addRemainingAlerts({ getState, patchState }: StateContext<NotificationsStateModel>, { payload }: AddRemainingAlerts) {
    const state = getState();

    patchState({
      remainingAlerts: [
        ...state.remainingAlerts,
        ...payload,
      ],
    });
  }

  @Action(RemoveRemainingAlert)
  removeRemainingAlert({ getState, patchState }: StateContext<NotificationsStateModel>, { triggerId }: RemoveRemainingAlert) {
    const state = getState();
    const remainingAlerts = state.remainingAlerts.map(obj => ({ ...obj }));
    const remainingAlertIndex = remainingAlerts.findIndex(alert => alert.triggerId === triggerId);

    if (remainingAlertIndex === -1) return;

    remainingAlerts.splice(remainingAlertIndex, 1);

    patchState({ remainingAlerts });
  }

  @Action(MoveLastShownAlertsToRemaining)
  moveLastShownAlertsToRemaining(
    { getState, patchState }: StateContext<NotificationsStateModel>,
    { count }: MoveLastShownAlertsToRemaining,
  ) {
    const state = getState();
    const shownAlerts = state.shownAlerts.map(obj => ({ ...obj }));
    let remainingAlerts = state.remainingAlerts.map(obj => ({ ...obj }));
    const remainingAlertsToAdd = shownAlerts.splice(2, count);

    remainingAlerts = [
      ...remainingAlertsToAdd,
      ...remainingAlerts,
    ];

    patchState({
      shownAlerts,
      remainingAlerts,
    });
  }

  @Action(SetShownNotifications)
  setShownNotifications({ patchState }: StateContext<NotificationsStateModel>, { payload }: SetShownNotifications) {
    patchState({ shownNotifications: payload });
  }

  @Action(SetRemainingNotifications)
  setRemainingNotifications({ patchState }: StateContext<NotificationsStateModel>, { payload }: SetRemainingNotifications) {
    patchState({ remainingNotifications: payload });
  }

  @Action(AddCurrentlyShownNotification)
  addCurrentlyShownNotification(
    { getState, patchState }: StateContext<NotificationsStateModel>,
    { payload }: AddCurrentlyShownNotification) {
    const state = getState();
    const shownNotification = state.currentlyShownNotification.map(obj => ({ ...obj }));

    patchState( {
      currentlyShownNotification: [
        ...shownNotification,
        payload,
      ],
    });
  }

}
