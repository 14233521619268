export interface Placeholder {
  name: string;
  strength: number;
  group: string;
  hint: string;
}

export interface PlaceholderStyle {
  style: 'default' | 'primary';
  name: string;
}

export interface PlaceholderStyleObj {
  [index: string]: 'default' | 'primary';
}

export interface KeywordPlaceholders {
  general: Placeholder[];
  discounts: Placeholder[];
}

export type PlaceholderType =
    'automation-abandoned'
  | 'automation-winback'
  | 'automation-upsell'
  | 'automation-thank-you'
  | 'automation-existing-contact'
  | 'keyword'
  | 'popup'
  | 'response';
