import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule }                     from '@angular/common';
import { NgxsFormPluginModule }             from '@ngxs/form-plugin';

import { SharedModule }          from '@shared/shared.module';
import { SettingsRoutingModule } from './settings-routing.module';

import { components } from './components';
import { services }   from './services';

@NgModule({
  declarations: [
    ...components,
  ],
  imports: [
    CommonModule,
    NgxsFormPluginModule,
    SharedModule,
    SettingsRoutingModule,
  ],
  providers: [
    ...services,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SettingsModule { }
