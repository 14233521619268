import { Injectable }                  from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ClearDiscountData, ClearDiscountTemplateData, SetDiscountData, SetDiscountTemplateData } from '@store/actions';

export interface DiscountModel {
  linkInfo: {
    code: string;
    link: string;
    type: 'static' | 'dynamic';
    value: string;
  };
  template: {
    discountRedirect: string;
    discountView: string;
    discountGid: string;
    discountId: number;
    discountCode: string;
    discountUrl: string;
    discountType: string;
    discountValue: number;
    discountRegion: string;
  };
}

@State<DiscountModel>({
  name: 'discount',
  defaults: {
    linkInfo: {
      code: '',
      link: '',
      type: null,
      value: '',
    },
    template: {
      discountRedirect: null,
      discountView: null,
      discountGid: null,
      discountId: null,
      discountCode: null,
      discountUrl: null,
      discountType: null,
      discountValue: null,
      discountRegion: null,
    },
  },
})
@Injectable()
export class DiscountState {

  @Action(SetDiscountData)
  setDiscountData({ patchState }: StateContext<DiscountModel>, { payload }: SetDiscountData) {
    patchState({
      linkInfo: payload,
    });
  }

  @Action(SetDiscountTemplateData)
  setDiscountTemplateData({ patchState }: StateContext<DiscountModel>, { payload }: SetDiscountTemplateData) {
    patchState({
      template: payload,
    });
  }

  @Action(ClearDiscountData)
  clearDiscountData({ patchState }: StateContext<DiscountModel>) {
    patchState({
      linkInfo: { code: '', link: '', type: null, value: '' },
    });
  }

  @Action(ClearDiscountTemplateData)
  clearDiscountTemplateData({ patchState }: StateContext<DiscountModel>) {
    patchState({
      template: {
        discountRedirect: null,
        discountView: null,
        discountGid: null,
        discountId: null,
        discountCode: null,
        discountUrl: null,
        discountType: null,
        discountValue: null,
        discountRegion: null,
      },
    });
  }
}
