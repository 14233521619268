import { Injectable }                  from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ClearRule, SetRuleKeywords, SetRulePopups } from '@store/actions';

import { KeywordItem, PopupItem, RuleItemModel } from '@shared/models';

export interface RulesStateModel {
  rule: {
    model?: RuleItemModel,
    dirty: boolean,
    errors: {},
  };
  ruleKeywords: KeywordItem[];
  rulePopups: PopupItem[];
}

@State<RulesStateModel>({
  name: 'rules',
  defaults: {
    rule: {
      model: null,
      dirty: false,
      errors: {},
    },
    ruleKeywords: [],
    rulePopups: [],
  },
})
@Injectable()
export class RulesState {

  @Action(ClearRule)
  clearRule({ getState, setState }: StateContext<RulesStateModel>) {
    setState({
      ...getState(),
      rule: {
        model: null,
        dirty: false,
        errors: {},
      },
    });
  }

  @Action(SetRuleKeywords)
  setRuleKeywords({ patchState }: StateContext<RulesStateModel>, { payload }: SetRuleKeywords) {
    patchState({ ruleKeywords: payload });
  }

  @Action(SetRulePopups)
  setRulePopups({ patchState }: StateContext<RulesStateModel>, { payload }: SetRulePopups) {
    patchState({ rulePopups: payload });
  }
}
