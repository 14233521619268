import {
  Component,
  ChangeDetectionStrategy,
  AfterViewInit,
  Input,
  OnDestroy,
  OnInit,
  ChangeDetectorRef,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router }                             from '@angular/router';
import { Observable, Subject }                from 'rxjs';
import { takeUntil }                          from 'rxjs/operators';
import { NgxSmartModalService }               from 'ngx-smart-modal';
import { Select, Store }                      from '@ngxs/store';

import { SAVED_SUCCESSFULLY } from '@core/texts';
import { MerchantInfo }                                 from '@core/models';
import { FormService, HelperService, CurrencyService }  from '@core/services';
import { UpdateMerchantInfo }                           from '@store/actions';
import { NotiService }                                  from '@notifications/services';
import { PaymentType }                                  from '@features/models';
import { PaymentService }                               from '@features/services';
import { PaymentAmountValidator }                       from '@features/validators';
import { ValidatorService }                             from '@shared/service/validator.service';

@Component({
  selector: 'k-payment-dialog',
  templateUrl: './payment-dialog.component.html',
  styleUrls: ['./payment-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  @Select(state => state.merchant) merchant$: Observable<MerchantInfo>;

  @Input() identifier: string;

  public merchantInfo: MerchantInfo;
  public paymentForm: FormGroup;
  public loading: boolean;
  public modalTitle: string = 'Add Funds';
  public showToggleButtons: boolean;
  public paymentOptions = [
    { value: '20',   name: '$20' },
    { value: '40',   name: '$40' },
    { value: '80',   name: '$80' },
    { value: '100',  name: '$100' },
    { value: '150',  name: '$150' },
    { value: '200',  name: '$200' },
    { value: '500',  name: '$500' },
    { value: '1000', name: '$1000' },
  ];

  public get isAutoRecharge(): boolean {
    return this.paymentType === 'autoRecharge';
  }

  public get isOneTime(): boolean {
    return this.paymentType === 'oneTime';
  }

  public get autoRechargeAmount(): string {
    const { invalid, value } = this.paymentForm.get('autoRechargeAmount');

    return invalid ? '0' : value;
  }

  public get autoRechargeThreshold(): string {
    const { invalid, value } = this.paymentForm.get('autoRechargeThreshold');

    return invalid ? '0' : value;
  }

  public get autoRechargeTotalAmountLimit(): string {
    const { invalid, value } = this.paymentForm.get('autoRechargeTotalAmountLimit');

    return invalid ? '0' : value;
  }

  public get totalAmountError(): string {
    const totalAmountControl = this.paymentForm.get('autoRechargeTotalAmountLimit');

    if (!this.paymentForm.errors && !totalAmountControl.errors) {
      return '';
    }

    const autoRechargeTotalAmountLimitError = this._formService.getError(this.paymentForm, 'autoRechargeTotalAmountLimit');
    if (autoRechargeTotalAmountLimitError) return autoRechargeTotalAmountLimitError;

    if (this.paymentForm.errors?.totalAmountInvalidDueToAutoRechargeSpent) {
      return `You've already spent ${this.paymentForm.errors.autoRechargeSpentValue}`;
    }

    if (this.paymentForm.errors?.totalAmountInvalidDueToAutoRechargeAmount) {
      return 'Value could not be less than Add to my account';
    }
  }

  private paymentType: PaymentType;
  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private cd: ChangeDetectorRef,
    private modal: NgxSmartModalService,
    private store: Store,
    private _notiService: NotiService,
    private _formService: FormService,
    private _helperService: HelperService,
    private _paymentService: PaymentService,
    private _currencyService: CurrencyService,
    private _validatorService: ValidatorService,
  ) { }

  ngOnInit() {
    this._initForm();
    this._trackMerchantChange();
  }

  ngAfterViewInit() {
    this.modal
      .getModal(this.identifier)
      .onAnyCloseEvent
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(() => {
        if (!this.merchantInfo.autoRechargeUsed) {
          this.store.dispatch(new UpdateMerchantInfo({ key: 'autoRechargeActive', value: false }));
        }
      });

    this.modal
      .getModal(this.identifier)
      .onOpen
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(() => {
        const data = this.modal.getModalData(this.identifier);

        if (data.modalTitle) {
          this.modalTitle = data.modalTitle;
        }

        this.paymentType = data.paymentType;
        this.showToggleButtons = data.showToggleButtons;

        // clear previous changes
        this._fillForm();
      });
  }

  public getFormControlError(field: string): string {
    return this._formService.getError(this.paymentForm, field);
  }

  public onSubmit(): void {
    if (this.paymentForm.invalid) {
      this._notiService.warning();
      return;
    }

    this.loading = true;

    if (this.isAutoRecharge) {
      this._paymentService.createAutoRechargePurchase(
        this.autoRechargeAmount,
        this.autoRechargeThreshold,
        this.autoRechargeTotalAmountLimit,
      ).subscribe(
        (data) => {
          if (data.success) {
            this._notiService.success(SAVED_SUCCESSFULLY);
            this.modal.close(this.identifier);

            if (!this.merchantInfo.autoRechargeActive) {
              this._enableAutoRecharge();
            }

            this.loading = false;
            this._preformCd();
          }
        },
        () => {
          this.loading = false;
          this._preformCd();
        },
      );
    }

    if (this.isOneTime) {
      this._paymentService.createOneTimePurchase(+this.paymentForm.get('oneTimeAmount').value)
        .subscribe(
          () => { },
          () => {
            this.loading = false;
            this._preformCd();
          },
        );
    }
  }

  public onChangeType(type: PaymentType): void {
    this.paymentType = type;
  }

  public goToPaymentSettings(): void {
    this.modal.getModal(this.identifier).close();
    this.router.navigate(['/settings/payments']);
  }

  private _enableAutoRecharge(): void {
    this._paymentService.toggleAutoRecharge(true)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(() => {
        this.store.dispatch(new UpdateMerchantInfo({ key: 'autoRechargeActive', value: true }));
      });
  }

  private _trackMerchantChange(): void {
    this.merchant$
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((merchantInfo: MerchantInfo) => {
        this.merchantInfo = merchantInfo;

        this._fillForm();
      });
  }

  private _initForm(): void {
    this.paymentForm = this.fb.group({
      autoRechargeAmount: [
        0,
        [...this._validatorService.autoRechargeValidators(), Validators.max(10000)],
      ],
      autoRechargeThreshold: [
        0,
        [...this._validatorService.autoRechargeValidators(), Validators.max(10000)],
      ],
      autoRechargeTotalAmountLimit: [
        0,
        [...this._validatorService.autoRechargeValidators(), Validators.max(1000000)],
      ],
      oneTimeAmount: [20, [Validators.required]],
    }, { validators: PaymentAmountValidator(this.store, this._currencyService) });
  }

  private _fillForm(): void {
    this.paymentForm.setValue({
      autoRechargeAmount: this._currencyService.formatPrice(this.merchantInfo.autoRechargeAmount),
      autoRechargeThreshold: this._currencyService.formatPrice(this.merchantInfo.autoRechargeThreshold),
      autoRechargeTotalAmountLimit: this._currencyService.formatPrice(this.merchantInfo.autoRechargeTotalAmountLimit),
      oneTimeAmount: 20,
    });
  }

  private _preformCd(): void {
    this.cd.detectChanges();
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
