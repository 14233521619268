import { MerchantInfo } from '@core/models';

export class SetMerchantInfo {
  static readonly type = '[MerchantService] SetMerchantInfo';

  constructor(public payload: MerchantInfo) { }
}

export class UpdateMerchantInfo {
  static readonly type = '[SettingsService] UpdateMerchantInfo';

  constructor(public payload: { key: string, value: any }) { }
}

export class UpdateMerchantShortenerSettings {
  static readonly type = '[General Settings] UpdateMerchantShortenerSettings';

  constructor(public payload: { key: string, value: string }) { }
}
