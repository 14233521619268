export interface DropdownData {
  value: string | number | boolean;
  name: string;
  group?: string;
  error?: string;
}

export interface DropdownBooleanData extends DropdownData {
  value: boolean;
}

export interface DropdownStringData extends DropdownData {
  value: string;
}

export interface DropdownNumberData extends DropdownData {
  value: number;
}

export interface DropdownRuleListData extends DropdownStringData {
  conditionType: string[];
}
