import { KeywordItem, KeywordsListItem } from '@keywords/models';
import { TableSortData }                 from '@shared/models';

export class SetKeywordsData {
  static readonly type = '[Keywords List] SetKeywordsData';

  constructor(public payload: any) { }
}

export class SetKeywordsList {
  static readonly type = '[Keywords List] SetKeywordsList';

  constructor(public payload: KeywordsListItem[]) { }
}

export class UpdateKeywordListItem {
  static readonly type = '[Keyword List] UpdateKeywordListItem';

  constructor(public index: number, public key: string, public value: any) { }
}

export class ClearKeywordList {
  static readonly type = '[Keywords List] ClearKeywordsList';

  constructor() { }
}

export class SetKeywordOriginal {
  static readonly type = '[Keyword Form] SetKeywordOriginal';

  constructor(public payload: KeywordItem) { }
}

export class ClearKeywordOriginal {
  static readonly type = '[Keyword Form] ClearKeywordOriginal';

  constructor() { }
}

export class UpdateKeywordFormImage {
  static readonly type = '[Keyword Form] UpdateKeywordFormImage';

  constructor(public payload: any) { }
}

export class ClearKeyword {
  static readonly type = '[Keyword Form] ClearKeyword';

  constructor() { }
}

export class SetKeywordSortingData {
  static readonly type = '[Sorting] SetKeywordSortingData';

  constructor(public sorting: TableSortData) { }
}
