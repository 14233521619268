import { Injectable } from '@angular/core';
import { UpsellErrorState, UpsellItem } from '@shared/models';

import { BehaviorSubject } from 'rxjs';

@Injectable()
export class UpsellItemValidatorService {

  constructor() { }

  public noQuantity(item: UpsellItem): boolean {
    return item.tracksInventory && !item.totalInventory && item.inventoryPolicy === 'DENY';
  }

  public isPublished(item: UpsellItem): boolean {
    return item.published;
  }

  public isCollectionEmpty(item: UpsellItem): boolean {
    return !item.includesProducts?.length;
  }

  public isCollectionProductsInvalid(item: UpsellItem): boolean {
    return item.includesProducts.some(product => {
      return (this.isDeleted(product) || this.noQuantity(product) || !this.isPublished(product));
    });
  }

  public isDeleted(item: UpsellItem): boolean {
    return item.deleted;
  }

  public triggerProductsError(items, isEdit: boolean): boolean {
    if (items && items.length) {
      for (const item of items) {
        if (this.isDeleted(item) || this.noQuantity(item) || !this.isPublished(item)) {
          return true;
        }
      }
    }

    if (items && !items.length && !isEdit) {
      return false;
    }

    return false;
  }

  public triggerCollectionsError(items, isEdit: boolean): boolean {
    if (items && items.length ) {
      for (const item of items) {
        if (this.isCollectionEmpty(item) || this.isDeleted(item) || this.isCollectionProductsInvalid(item)) {
          return true;
        }
      }
    }

    if (items && !items.length && !isEdit) {
      return false;
    }

    return false;
  }

  public recommendedProductsError(items, isEdit: boolean): boolean {
    if (items && items.length) {
     for (const item of items) {
       if (this.isDeleted(item) || (this.noQuantity(item) && this.isPublished(item)) || !this.isPublished(item)) {
         return true;
       }
     }
    }

    if (items && !items.length && !isEdit) {
      return false;
    }

    return false;
  }

  public checkAndValidity(options): UpsellErrorState {
    const errors: UpsellErrorState = {};

    if (!options.triggerProducts.length && !options.triggerCollections.length) {
      errors.isTriggerProductsError = true;
      errors.isTriggerCollectionsError = true;
    }

    if (!options.triggerProducts.length && options.triggerCollections.length
      || options.triggerProducts.length && !options.triggerCollections.length) {
      errors.isTriggerProductsError = false;
      errors.isTriggerCollectionsError = false;
    }

    if (!options.recommendedProducts.length) {
      errors.isRecommendedProductsError = true;
    }

    if (options.recommendedProducts.length) {
      errors.isRecommendedProductsError = false;
    }

    return errors;
  }
}
