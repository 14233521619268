import { RuleItem } from './rules-editor-rule';

export interface RuleItemModel {
  id: number;
  name: string;
  rules: RuleRules;
}

export interface RuleRules {
  operator: string;
  rules: RuleItem[];
  __type: string;
}
