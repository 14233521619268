import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Meta }       from '@angular/platform-browser';
import { Store }      from '@ngxs/store';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  constructor(private store: Store, private meta: Meta) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const requestClone = request.clone({ setHeaders: this._getHeaders(request) });

    return next.handle(requestClone);
  }

  private _getProdHeaders() {
    const token = this.meta.getTag('name="x-auth-token"');
    return { Authorization: `Bearer ${token.content}`};
  }

  private _getDevHeaders() {
    const token = this.store.selectSnapshot(state => state.app.token);
    const shopParams = this.store.selectSnapshot(state => state.app.shopParams);

    return token
      ? { Authorization: `Bearer ${token}`}
      : { 'x-auth-params': JSON.stringify(shopParams)};
  }

  private _getHeaders(req: HttpRequest<any>): any {
    let headers: any = environment.production ? this._getProdHeaders() : this._getDevHeaders();

    if (req.url.includes('google-analytics.com')) {
      headers = {
        'Content-Type': 'multipart/form-data',
        'Accept': '*/*',
      };
    }

    return headers;
  }
}

export const RequestInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: RequestInterceptor,
  multi: true,
};
