import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';

import baron from 'baron';

@Component({
  selector: 'k-baron',
  templateUrl: './baron.component.html',
  styleUrls: ['./baron.component.scss'],
})
export class BaronComponent implements AfterViewInit {
  @ViewChild('baronRoot') baronRoot: ElementRef;
  @ViewChild('baronScroller') baronScroller: ElementRef;
  @ViewChild('baronBar') baronBar: ElementRef;
  @ViewChild('baronTrack') baronTrack: ElementRef;

  constructor() { }

  ngAfterViewInit() {
    baron({
      root: this.baronRoot.nativeElement,
      scroller: this.baronScroller.nativeElement,
      bar: this.baronBar.nativeElement,
    })
    .controls({
      track: this.baronTrack.nativeElement,
    });
  }

}
