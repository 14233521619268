import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivationEnd,
  Router,
  RouterEvent,
} from '@angular/router';

import { Subject }   from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Intercom }  from 'ng-intercom';

import { RouterService }     from '@core/services';
import { IntercomUserModel } from '@features/components/intercom/models';

@Injectable()
export class IntercomService {
  private intercomTag: string;
  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(private _intercom: Intercom,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private _routerService: RouterService) { }

  public initIntercom(activator: string, intercom: IntercomUserModel): void {
    this._intercom.boot({
      widget: {
        'activator': `#${activator}`,
      },
      ...intercom,
      hide: true,
    });

    this._intercom.onShow(() => {
      const snapshot = this._routerService.getDeepestFirstChildSnapshot(this.activatedRoute.snapshot);
      this._updateIntercomParamsOnRouterChange();

      if (snapshot) {
        this.updateIntercomParams(snapshot.data.intercomTag);
      }
    });

    this._intercom.onHide(() => {
      this.ngUnsubscribe$.next();
      this.ngUnsubscribe$.complete();
    });
  }

  public updateIntercomParams(page?: string): void {
    const params = {
      last_request_at: parseInt(((new Date()).getTime() / 1000).toString(), null),
      current_page: page,
    };

    this._intercom.update(params);
  }

  private _updateIntercomParamsOnRouterChange(): void {
    this.router.events
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((event: RouterEvent) => {

        if (event instanceof ActivationEnd) {
          const page = this._routerService.getDeepestFirstChildSnapshot(event.snapshot);

        if (page.data?.intercomTag && this.intercomTag !== page.data.intercomTag) {
          this.intercomTag = page.data.intercomTag;
          this.updateIntercomParams(page.data.intercomTag);
        }
      }
    });
  }
}
