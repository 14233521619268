import { Injectable }                                                 from '@angular/core';
import { AbstractControl, AsyncValidatorFn, ValidatorFn, Validators } from '@angular/forms';
import { Observable, of }                                             from 'rxjs';
import { debounceTime, distinctUntilChanged, first, map, switchMap }  from 'rxjs/operators';

import { ApiService, HelperService }                           from '@core/services';
import { Validator }                                           from '@shared/models/validator.model';
import { CAMPAIGN_NAME, MMS, POPUP_NAME, SHAREABLE_LINK_NAME } from '@shared/texts';
import { OPT_OUT_SPECIAL_KEYWORDS }                            from '@keywords/texts';

export interface ApiData {
  apiService: ApiService;
  method: 'get' | 'post';
  path: string;
}

@Injectable()
export class ValidatorService {

  constructor(private _helperService: HelperService) { }

  public static whiteSpaceValidator(): Validator {
    return (control: AbstractControl | { value: string }) => {
      if (control.value && !control.value.replace(/\s/g, '').length) {
        return { templateWhiteSpaceInvalid: true };
      }
      return null;
    };
  }

  public static urlValidators(): Validator[] {
    return [
      Validators.maxLength(2048),
      ValidatorService.whiteSpaceValidator(),
    ];
  }

  public whiteSpaceValidator(): Validator {
    return ValidatorService.whiteSpaceValidator();
  }

  public urlValidators(): Validator[] {
    return [
      ...ValidatorService.urlValidators(),
      Validators.pattern(this._helperService.urlRegExp),
    ];
  }

  public getMessageValidators(msgType?: string, type?: string): Validator[] {
    switch (true) {
      case type === POPUP_NAME:
        return [
          this._emptyValidator('Message is required'),
          this._maxLengthMessageValidator('Maximum message size is 1600 characters'),
          this._whitespacesValidator(`Message shouldn't contain only whitespaces`),
          this._htmlValidator(`HTML Tags are not allowed`),
          this._keywordNameValidator('{KeywordName} short-code is required'),
          this._tcpaPopupValidator('TCPA language is required'),
        ];
      case type === SHAREABLE_LINK_NAME:
        return [
          this._emptyValidator('Message is required'),
          this._maxLengthMessageValidator('Maximum message size is 1600 characters'),
          this._whitespacesValidator(`Message shouldn't contain only whitespaces`),
          this._htmlValidator(`HTML Tags are not allowed`),
          this._keywordNameValidator('{KeywordName} short-code is required'),
          this._tcpaShareableLinkValidator('TCPA language is required'),
        ];
      case msgType === MMS:
        return [
          this._emptyValidator('Message is required'),
          this._maxLengthMessageValidator('Maximum message size is 1600 characters'),
          this._whitespacesValidator(`Message shouldn't contain only whitespaces`),
          this._htmlValidator(`HTML Tags are not allowed`),
        ];
      case type === CAMPAIGN_NAME:
        return [
          this._emptyValidator('Message is required'),
          this._maxLengthMessageValidator('Message cannot contain more than 1600 characters'),
          this._whitespacesValidator(`Message cannot contain whitespaces only`),
          this._htmlValidator(`Message cannot contain HTML tags`),
        ];
      default:
        return [
          this._emptyValidator('Message is required'),
          this._whitespacesValidator(`Message shouldn't contain only whitespaces`),
          this._htmlValidator(`HTML Tags are not allowed`),
        ];
    }
  }

  public autoRechargeValidators(): Validator[] {
    return [
      Validators.required,
      Validators.min(0),
      this.currencyValidator(),
    ];
  }

  public textFiledValidators(): Validator[] {
    return [
      Validators.required,
      Validators.minLength(1),
      Validators.maxLength(9999999999999999),
      this.htmlTagsValidator(),
    ];
  }

  public keywordNameValidators(): Validator[] {
    return [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(10),
      Validators.pattern(/^[a-zA-Z0-9]+$/),
      this.keywordSpecialWordValidator(),
    ];
  }

  public fieldNameValidators(): Validator[] {
    return [
      Validators.minLength(3),
      Validators.maxLength(50),
      this.whiteSpaceValidator(),
      this.htmlTagsValidator(),
    ];
  }

  public getTimeDelayValidators(): Validator[] {
    return [
      this._emptyValidator('Sorry, but delay could not be less than 1 minute' ),
      this._zeroValidator('Sorry, but delay could not be less than 1 minute' ),
    ];
  }

  public getTCPAValidators(): Validator[] {
    return [
      this._emptyValidator('Message is required'),
      this._patternValidator(this._helperService.urlRegExp, 'Invalid URL. Please try again'),
      this._whitespacesValidator('Please, fill in this field'),
    ];
  }

  public minLengthDigitsOnlyValidator(minLength: number): Validator {
    return (control: AbstractControl | { value: string }) => {
      if (control.value && control.value.replace(/\D/g, '').length < minLength) { return { minlength: { requiredLength: minLength } }; }
      return null;
    };
  }

  public maxLengthDigitsOnlyValidator(maxLength: number): Validator {
    return (control: AbstractControl | { value: string }) => {
      if (control.value && control.value.replace(/\D/g, '').length > maxLength) { return { maxlength: { requiredLength: maxLength } }; }
      return null;
    };
  }

  public currencyValidator(): Validator {
    return (control: AbstractControl | { value: string }) => {
      const isValid = /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/.test(control.value);
      return isValid ? null : { currencyInvalid: true };
    };
  }

  public templateLengthValidator(): Validator {
    return (control: AbstractControl | { value: string }) => {
      if (control.value.length > 1600) {
        return { templateLengthInvalid: true };
      }
      return null;
    };
  }

  public upsellValidator(upsellItems): Validator {
    return (): { [key: string]: boolean } | null => {
      if (!upsellItems?.triggerProducts.length && !upsellItems?.triggerCollections.length) {
        return { 'triggerError': true };
      }
      return null;

    };
  }

  public htmlTagsValidator(): Validator {
    return (control: AbstractControl | { value: string }) => {
      if (control.value && control.value.match(/<[^>]*>/m)) {
        return { htmlTagsInvalid: true };
      }
      return null;
    };
  }

  public keywordSpecialWordValidator(): Validator {
    return (control: AbstractControl | { value: string }) => {
      if (control.value && OPT_OUT_SPECIAL_KEYWORDS.includes(control.value.toUpperCase())) {
        return { 'hasSpecialKeywordError': true };
      }
      return null;
    };
  }

  public emailValidator(): Validator {
    return (control: AbstractControl | { value: string }) => {
      const regEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (control.value && !regEx.test(control.value)) {
        return { emailPatternInvalid: true };
      }
      return null;
    };
  }

  public discountValueValidator(): Validator {
    return (control: AbstractControl) => {
      if (control.parent) {
        let discountValue = control.parent.get('discountValue').value;

        if (control.parent.get('discountType').value === 'DiscountCodeBasicAmount') {
          discountValue = (`${discountValue}`).replace(/[,]/g, '');

          if (!( discountValue > 0)) {
            return { discountAmountInvalid: true };
          }
        }

        if (control.parent.get('discountType').value === 'DiscountCodeBasicPercentage') {
          if (!(discountValue > 0 && discountValue <= 100)) {
            return { discountPercentageInvalid: true };
          }
        }
      }

      return null;
    };
  }

  public alreadyExistsValidator(apiData: ApiData, oldValue?: string): AsyncValidatorFn {
    const { apiService, method, path } = apiData;
    // tslint:disable-next-line:no-any
    return (control: AbstractControl): Observable<any> => {
      if (oldValue && control.value === oldValue) return of(null);

      return control.valueChanges
        .pipe(
          debounceTime(500),
          distinctUntilChanged(),
          switchMap(value => apiService[method](path, { name: value })),
          map(res => (res.exists ? { alreadyExists: true } : null)),
          first(),
        );
    };
  }

  private _emptyValidator(message: string): Validator {
    return (control: AbstractControl | { value: string }) => {
      if (!control.value) { return { required: message }; }
      return null;
    };
  }

  private _patternValidator(patternType, message: string): Validator {
    return (control: AbstractControl | { value: string }) => {
      if (!patternType.test(control.value)) { return { pattern: message }; }
      return null;
    };
  }

  private _zeroValidator(message: string): Validator {
    return (control: AbstractControl | { value: string }) => {
      if (+control.value === 0) { return { required: message }; }
      return null;
    };
  }

  private _maxLengthMessageValidator(message: string): Validator {
    return (control: AbstractControl | { value: string }) => {
      if (control.value.length > 1601) { return { maxLength: message }; }
      return null;
    };
  }

  private _whitespacesValidator(message: string): Validator {
    return (control: AbstractControl | { value: string }) => {
      if (control.value?.length > 0 && control.value.trim()?.length === 0) { return { whitespaces: message }; }
      return null;
    };
  }

  private _htmlValidator(message: string): Validator {
    return (control: AbstractControl | { value: string }) => {
      if (/(<([^>]+)>)/g.test(control.value)) { return { htmlTags: message }; }
      return null;
    };
  }

  private _keywordNameValidator(message: string): ValidatorFn {
    return (control: AbstractControl) => {
      if (!/{KeywordName}/g.test(control.value)) { return {  keywordNamePlaceholderInvalid: message }; }
      return null;
    };
  }

  private _stopValidator(message: string): ValidatorFn {
    return (control: AbstractControl) => {
      if (!/STOP/g.test(control.value)) { return {  stopPlaceholderInvalid: message }; }
      return null;
    };
  }

  private _tcpaValidator(message: string): ValidatorFn {
    return (control: AbstractControl) => {
      if (!/Msg and Data Rates may apply/g.test(control.value)) { return {  tcpaPlaceholderInvalid: message }; }
      return null;
    };
  }

  private _tcpaPopupValidator(message: string): ValidatorFn {
    return (control: AbstractControl) => {
      if (!/subscribe to automated recurring personalized marketing alerts from {StoreName}/g.test(control.value)) {
        return {  tcpaPopupPlaceholderInvalid: message };
      }
      return null;
    };
  }

  private _tcpaShareableLinkValidator(message: string): ValidatorFn {
    return (control: AbstractControl) => {
      if (!/subscribe to automated recurring personalized marketing alerts from {StoreName} and agree to our Terms and Conditions {Link} even if your number is on a 'do not call' list. Consent is not a condition of any purchase/g.test(control.value)) {
        return {  tcpaPopupPlaceholderInvalid: message };
      }
      return null;
    };
  }
}
