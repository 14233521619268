import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Select, Store }                                             from '@ngxs/store';
import { Observable, Subject }                                       from 'rxjs';
import { delay, takeUntil }                                          from 'rxjs/operators';

import { MerchantInfo }              from '@core/models';
import { HelperService }             from '@core/services';
import { NotiService }               from '@notifications/services';
import { StoreLegalInformationType } from '@legal-agreements/models';
import { TermInstructionData }       from './term-instruction.model';

@Component({
  selector: 'k-instruction',
  templateUrl: './term-instruction.component.html',
  styleUrls: ['./term-instruction.component.scss'],
})
export class TermInstructionComponent implements OnInit, OnDestroy {
  @Select(state => state.merchant) merchant$: Observable<MerchantInfo>;

  @Input() type: StoreLegalInformationType;
  @Input() data: TermInstructionData;
  @Input() checked: boolean = false;

  @Output() changeStep: EventEmitter<any> = new EventEmitter<any>();

  public storeUrl: string;

  private toggleStep$: Subject<any> = new Subject<any>();
  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(private store: Store,
              private _notiService: NotiService,
              private _helperService: HelperService) { }

  ngOnInit() {
    this._getMerchantUrl();
    this._trackToggleStep();
  }

  public copyText(text: string): void {
    this._helperService.copyText(text, () => {
      this._notiService.success('Text copied to clipboard!');
    });
  }

  public toggleStep(step: string, value: boolean): void {
    this.toggleStep$.next({ step, value });
    this.changeStep.emit({ step, value });
  }

  private _getMerchantUrl(): void {
    this.merchant$
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((merchantInfo: MerchantInfo) => {
        this.storeUrl = this._helperService.getMerchantDomain(merchantInfo);
      });
  }

  private _trackToggleStep(): void {
    this.toggleStep$
      .pipe(
        delay(500),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe(() => {
        this.checked = !this.checked;
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
