import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter, forwardRef,
  Input,
  OnInit,
  OnDestroy,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';

import { NgSelectComponent }          from '@ng-select/ng-select';
import { CustomControlValueAccessor } from '@shared/components/base/custom-control-value-accessor';
import { NG_VALUE_ACCESSOR }          from '@angular/forms';
import { DropdownStringData }         from '@shared/models';

@Component({
  selector: 'k-droplist',
  templateUrl: './droplist.component.html',
  styleUrls: ['./droplist.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DroplistComponent),
      multi: true,
    },
  ],
})
export class DroplistComponent extends CustomControlValueAccessor implements OnInit, AfterViewInit, OnDestroy {
  @Input() items: any;
  @Input() clockIcon: boolean = false;
  @Input() hasError: boolean = false;
  @Input() placeholder: string;
  @Input() searchable: boolean = false;
  @Input() clearable: boolean = false;
  @Input() propToReturn: string;
  @Input() helper: string;
  @Input() groupBy: string;
  @Input() appendTo: string = null;
  @Input() set width(data: number) {
    this.widthParam = data;
    this._updateWidth();
  }
  @Input() set selectedOption(data: string) {
    this.selectedName = data;
    this.selectedItem = this.items?.find((option) => option.name === data);
    if (this.selectedItem) {
      this.value = this.selectedItem;
    }
  }

  @Output() selectedOptionChange: EventEmitter<void> = new EventEmitter<void>();
  @Output() changed: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('ngSelect') ngSelect: NgSelectComponent;

  public selectedItem: DropdownStringData;
  public selectedName: string;

  public isClicked: boolean = false;
  private widthParam: number;

  constructor(private el: ElementRef, private renderer: Renderer2) {
    super();
  }

  ngOnInit(): void {
    window.addEventListener('scroll', this.onScroll, true);
  }

  ngAfterViewInit(): void {
    this._updateWidth();
  }

  public onSelect(value): void {
    this.selectedItem = value;
    this.selectedName = value.name;
    if (this.propToReturn) {
      this.value = value[this.propToReturn];
      this.changed.emit(value[this.propToReturn]);
    } else {
      this.value = value;
      this.changed.emit(value);
    }
    this.selectedOptionChange.emit(value.name);
  }

  public isSelected(item): boolean {
    return item.value === this.value || item.value === this.value?.value;
  }

  public ngSelectClick() {
    this.isClicked = !this.isClicked;
    if (this.isClicked) {
      this.ngSelect.open();
    } else {
      this.ngSelect.close();
    }
  }

  private onScroll = (event: any) => {
    if (this.ngSelect && this.ngSelect.isOpen) {
      const isScrollingInScrollHost = (event.target.className as string).indexOf('ng-dropdown-panel-items') > -1;
      if (isScrollingInScrollHost) { return; }
      this.ngSelect.close();
    }
  }

  private _updateWidth(): void {
    if (this.widthParam && this.ngSelect) {
      this.renderer.setStyle(this.ngSelect.element, 'width', `${this.widthParam}px`);
    }
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.onScroll, true);
  }
}
