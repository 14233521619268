import {Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { NgControl }                                         from '@angular/forms';
import { CurrencyPipe }                                      from '@angular/common';
import { of }                                                from 'rxjs';
import { delay, first }                                      from 'rxjs/operators';

@Directive({
  selector: '[formControl][kUSFormat], [formControlName][kUSFormat]',
})
export class UsFormatDirective  implements OnInit {
  @Input('kUSFormat') enable: boolean;

  @HostListener('input', ['$event']) onInput(event) {
    if (event.data === '.') {
      return;
    }
    this.format(event.target.value);
  }

  @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
    event.preventDefault();
    this.format(event.clipboardData.getData('text/plain'));
  }

  constructor(public el: ElementRef,
              private currencyPipe: CurrencyPipe,
              private ngControl: NgControl) {}

  ngOnInit() {
    of(null).pipe(delay(0), first())
    .subscribe(() => {
      this.format(this.el.nativeElement.querySelector('input').value);
    });
  }

  public format(val: string): void {
    const numberFormat = val.replace(/,/g, '');
    if (!/\d/.test(numberFormat)) {
      return;
    }
    const usd = this.currencyPipe.transform(numberFormat, 'USD', 'symbol', '1.0-2').replace('$', '');

    if (this.enable) {
      this.ngControl.valueAccessor.writeValue(usd);
    }
  }

}
