import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

import { MessageStrengthHint } from '@shared/models';

@Component({
  selector: 'k-msg-strength',
  templateUrl: './msg-strength.component.html',
  styleUrls: ['./msg-strength.component.scss'],
  providers: [],
  encapsulation: ViewEncapsulation.None,
})
export class MsgStrengthComponent implements OnInit, AfterViewInit {
  @Input() set messageStrengthData(data) {
    if (data) {
      this.strengthWeight = data.messageStrengthWeight;
      this.strength = data.messageStrength;
      if (this._isStrengthHintsChanged(data.messageStrengthHints, this.strengthHints)) {
        setTimeout(() => this._checkContainerHeight());
      }
      this.strengthHints = this.splitHints(data.messageStrengthHints);
    }
  }
  @Output() shortCodeUpdate: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('strengthHintsContainer') strengthHintsContainer: ElementRef;

  public strengthWeight: number = 5;
  public strength = 'weak';
  public strengthHints: MessageStrengthHint[] = [];
  public isCollapsed: boolean = true;
  public isCollapsedEnabled: boolean;
  public expression = /({\w*\s*}|Reply STOP to unsubscribe)+/;
  public regex = RegExp(this.expression);

  private lineNumber = 3;

  constructor() { }

  ngOnInit() { }

  ngAfterViewInit(): void {
    this._checkContainerHeight();
  }

  public updateShortCode(shortCode: string): void {
    this.shortCodeUpdate.emit(shortCode);
  }

  public splitHints(messageStrengthHints): Array<any> {
    return messageStrengthHints.map(element => {
      return { 'hint': element.hint.split(this.expression) };
    });
  }

  private _isStrengthHintsChanged(newArr, oldArr): boolean {
    return newArr
      && (
        (newArr.length !== oldArr.length)
        || (
          newArr.reduce((accumulator, elem) => accumulator + elem.hint, '')
          !== oldArr.reduce((accumulator, elem) => accumulator + elem.hint, '')
        )
      );
  }

  private _checkContainerHeight(): void {
    if (!this.strengthHintsContainer) return;

    let totalHeight = 0;
    const children = this.strengthHintsContainer.nativeElement.children;
    if (children.length) {
      [].forEach.call(children, (child) => {
        totalHeight += child.offsetHeight;
      });
    }

    this.isCollapsedEnabled = totalHeight > this.lineNumber * 22;
  }
}
