export interface UpsellItem {
  id: string;
  title?: string;
  imageSrc?: string;
  inventoryPolicy?: string;
  tracksInventory?: boolean;
  totalInventory?: number;
  published: boolean;
  deleted?: boolean;
  isEmpty?: boolean;
  includesProducts?: UpsellItem[];
}

export interface UpsellErrorState {
  isTriggerProductsError?: boolean;
  isTriggerCollectionsError?: boolean;
  isRecommendedProductsError?: boolean;
}
