import { CanDeactivateGuard, CanComponentDeactivate } from './can-deactivate.guard';

export {
  CanDeactivateGuard,
  CanComponentDeactivate,
};

export const guards = [
  CanDeactivateGuard,
];
