import { Injectable } from '@angular/core';
import { Action }     from '@ngxs/store';

import {
  ClearAppSectionList,
  ClearFlowList,
  ClearKeywordList,
  ClearPopupList,
  ClearResponseList,
  ClearSegmentList,
  ClearShareableLinkList,
  ClearCampaignList,
} from '@store/actions';
import { AppRoutes, AppSectionRoutes } from '@core/models/routes';
import { ROUTES }                      from '@core/texts';

@Injectable()
export class TableState {
  private clearActionsMap: AppSectionRoutes = {
    FLOWS: ClearFlowList,
    KEYWORDS: ClearKeywordList,
    POPUPS: ClearPopupList,
    RESPONSES: ClearResponseList,
    SEGMENTS: ClearSegmentList,
    CAMPAIGNS: ClearCampaignList,
    SHAREABLE_LINKS: ClearShareableLinkList,
  };

  @Action(ClearAppSectionList)
  clearAppSectionList(ctx, { payload }: ClearAppSectionList) {
    const routeKey = this._getRouteKey(ROUTES, payload);

    if (this.clearActionsMap[routeKey]) {
      ctx.dispatch(new this.clearActionsMap[routeKey]());
    }
  }

  private _getRouteKey(routesMap: AppRoutes, route: string): string {
    return Object.keys(routesMap).find(key => routesMap[key] === route);
  }
}
