import { Injectable }    from '@angular/core';
import { Store }         from '@ngxs/store';
import { Observable }    from 'rxjs';
import { tap }           from 'rxjs/operators';
import { Redirect }      from '@shopify/app-bridge/actions';

import { ApiService, HelperService, ShopifyService } from '@core/services';
import { SAVED_SUCCESSFULLY }                        from '@core/texts';
import { UpdateMerchantInfo }                        from '@store/actions';
import { NotiService }                               from '@notifications/services';
import { delaysModel }                               from '@shared/models';

@Injectable()
export class SettingsService {
  public delays = delaysModel;

  constructor(private store: Store,
              private _apiService: ApiService,
              private _notiService: NotiService,
              private _helperService: HelperService,
              private _shopifyService: ShopifyService) { }

  public toggleApp(status: boolean): Observable<any> {
    return this._apiService.get(status ? 'app-enable' : 'app-disable')
      .pipe(
        tap(() => {
          this.store.dispatch(new UpdateMerchantInfo({
            key: 'appEnabled',
            value: status,
          }));
        }),
      );
  }

  public getSettings(path: string): Observable<any>  {
    return this._apiService.get(`settings/${path}`);
  }

  public updateSettings(path: string, data: { [key: string]: any }): Observable<any> {
    return this._apiService.patch(`settings/${path}`, data);
  }

  public updateIntegrationsSettings(integrationType: string, data: { [key: string]: any }): Observable<any> {
    const payload = {
      type: integrationType,
      ...this._replaceFormFields(data),
    };

    return this._apiService.patch('settings/integrations', payload)
      .pipe(
        tap(result => {
          if (result.redirectUrl) {
            return this._shopifyService.redirect.dispatch(Redirect.Action.REMOTE, result.redirectUrl);
          }

          this._updateStore(integrationType, data);

          this._notiService.success(SAVED_SUCCESSFULLY);
        }),
      );
  }

  public secondsConverter(time, type, isConvertingToSeconds: boolean): number {
    return this._helperService.secondsConverter(this.delays, time, type, isConvertingToSeconds);
  }

  private _replaceFormFields(data: { [key: string]: any }): { [key: string]: any } {
    const result = {};
    const replacers = [
      ['ocuStatus', 'status'],
      ['zipifypagesStatus', 'status'],
    ];

    for (const prop in data) {
      if (data.hasOwnProperty(prop)) {
        replacers.forEach(replacer => {
          const [formField, replaceField] = replacer;

          if (prop === formField) {
            result[replaceField] = data[prop];
          }
        });
      }
    }

    return result;
  }

  private _updateStore(integrationType: string, data: { [key: string]: any }): void {
    switch (integrationType) {
      case 'ocu':
        this.store.dispatch(new UpdateMerchantInfo({
          key: 'isOCUIntegrationEnabled',
          value: data.ocuStatus,
        }));
    }
  }
}
