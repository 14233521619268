import { AfterViewInit, Component, ElementRef, Input, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { CardSize, CardType } from '../../card/card.model';

@Component({
  selector: 'k-card-loader',
  templateUrl: './card-loader.component.html',
  styleUrls: ['./card-loader.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CardLoaderComponent implements OnInit, AfterViewInit {

  @Input() type: CardType = 'board';
  @Input() title: string;
  @Input() size: CardSize = 'normal';

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngAfterViewInit() {
    this.renderer.addClass(this.el.nativeElement, 'k-card-loader');
    this.renderer.addClass(this.el.nativeElement, `k-card-loader--${this.type}`);
    this.renderer.addClass(this.el.nativeElement, `k-card-loader--${this.size}`);
  }


  ngOnInit(): void {}

}
