import { NgModule }             from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ROUTES }                   from '@core/texts';
import { PageNotFoundComponent }    from '@core/components';
import { CustomPreloadingStrategy } from './custom-preloading-strategy';

const routes = [
  {
    path: '', redirectTo: ROUTES.FLOWS, pathMatch: 'full',
  },
  {
    path: ROUTES.LEGAL_AGREEMENTS,
    loadChildren: () => import('@legal-agreements/legal-agreements.module').then(m => m.LegalAgreementsModule),
  },
  {
    path: ROUTES.FLOWS,
    loadChildren: () => import('@flows/flows.module').then(m => m.FlowsModule),
    data: { clearAppSectionListOnRouteLeave: true },
  },
  {
    path: ROUTES.KEYWORDS,
    loadChildren: () => import('@keywords/keywords.module').then(m => m.KeywordsModule),
    data: { clearAppSectionListOnRouteLeave: true },
  },
  {
    path: ROUTES.POPUPS,
    loadChildren: () => import('@popups/popups.module').then(m => m.PopupsModule),
    data: { clearAppSectionListOnRouteLeave: true },
  },
  {
    path: ROUTES.RESPONSES,
    loadChildren: () => import('@responses/responses.module').then(m => m.ResponsesModule),
    data: { clearAppSectionListOnRouteLeave: true },
  },
  {
    path: ROUTES.CHECKOUT,
    loadChildren: () => import('@checkout/checkout.module').then(m => m.CheckoutModule),
  },
  {
    path: ROUTES.SEGMENTS,
    loadChildren: () => import('@segments/segments.module').then(m => m.SegmentsModule),
    data: { clearAppSectionListOnRouteLeave: true },
  },
  {
    path: ROUTES.CAMPAIGNS,
    loadChildren: () => import('@campaigns/campaigns.module').then(m => m.CampaignsModule),
    data: { clearAppSectionListOnRouteLeave: true },
  },
  {
    path: ROUTES.SHAREABLE_LINKS,
    loadChildren: () => import('@shareable-links/shareable-links.module').then(m => m.ShareableLinksModule),
    data: { clearAppSectionListOnRouteLeave: true },
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: CustomPreloadingStrategy,
      scrollPositionRestoration: 'top',
    }),
  ],
  providers: [CustomPreloadingStrategy],
  exports: [RouterModule],
})
export class AppRoutingModule { }
