import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators }                                  from '@angular/forms';
import { ActivatedRoute }                                                                   from '@angular/router';
import { Observable, of, Subject }                                                          from 'rxjs';
import { debounceTime, delay, first, switchMap, takeUntil }                                 from 'rxjs/operators';
import { Select, Store }                                                                    from '@ngxs/store';

import {
  ClearSettingsSendingLogicData,
  ClearSettingsSendingLogicOriginal,
  SetSettingsSendingLogicOriginal,
}                                                                              from '@store/actions';
import { CanComponentDeactivate }                                              from '@core/guards';
import { MerchantInfo }                                                        from '@core/models';
import { MerchantService, QuietHoursService, ProgressBarService, TimeService } from '@core/services';
import { SAVED_SUCCESSFULLY }                                                  from '@core/texts';
import { delaysModel, DropdownNumberData }                                     from '@shared/models';
import { NotiService }                                                         from '@notifications/services';
import { SettingsService }                                                     from '@settings/services';
import { SettingsSendingLogic }                                                from '@settings/components/models';
import { SettingsSendingLogicChangesService }                                  from '@settings/services/settings-sending-logic-changes.service';

@Component({
  selector: 'k-settings-sending-logic',
  templateUrl: './settings-sending-logic.component.html',
  styleUrls: ['./settings-sending-logic.component.scss'],
})
export class SettingsSendingLogicComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked, CanComponentDeactivate {
  @Select(state => state.merchant) merchant$: Observable<MerchantInfo>;

  public settingsForm: FormGroup;
  public changedField: string = '';
  public messagesThrottlingDisplayType: FormControl = new FormControl('');
  public loading: boolean = true;
  public isExistChanges: boolean = false;
  public isFormDirty = false;
  public isShowTCPASettingsMessage: boolean = true;
  public quietHoursFrom: DropdownNumberData[];
  public quietHoursTo: DropdownNumberData[];
  public delays = delaysModel;
  public selectedOption: string = '';
  public isQuietHoursActive: boolean;

  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(private fb: FormBuilder,
              private _notiService: NotiService,
              private _timeService: TimeService,
              private store: Store,
              private route: ActivatedRoute,
              private changeDetector: ChangeDetectorRef,
              private _settingsService: SettingsService,
              private quietHoursService: QuietHoursService,
              private _settingsSendingLogicChangesService: SettingsSendingLogicChangesService,
              private _merchantService: MerchantService,
              private _progressBarService: ProgressBarService) { }

  ngOnInit() {
    this._progressBarService.initLoading();
    this.quietHoursFrom = this._timeService.getQuietHours(18 * 60, 23 * 60);
    this.quietHoursTo = this._timeService.getQuietHours(6 * 60, 11 * 60);

    this._initForm();

    this.merchant$
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((data: MerchantInfo) => {
        this.isShowTCPASettingsMessage = data.showTCPASettingsMessage;
      });

    this._checkChanges();
  }

  ngAfterViewInit() {
    this.route.queryParams
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(params => {
        if (params && params['scrollTo']) {
          this.scrollToAnchor(params['scrollTo']);
        }
      });
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  public scrollToAnchor(id: string): void {
    of(null)
      .pipe(delay(1000), first())
      .subscribe(() => {
        document.getElementById(`${id}`).scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
      });
  }

  public onSwitcherChange(field: string): void {
    this._saveData(field);
    this.isQuietHoursActive = !this.isQuietHoursActive;
  }

  public onChange(field: string): void {
    this._saveData(field);
  }

  public onFocusOut(field: string): void {
    this._saveData(field);
  }

  public detectClose(): void {
    this._merchantService.updateSettingsNotifierMessageStatus()
      .pipe(
        switchMap(() => this._merchantService.loadMerchantInfo()),
        first(),
      )
      .subscribe();
  }

  public canDeactivate(): boolean {
    return this.isExistChanges ? this._checkChangesAndShowTCPAMessage(): true;
  }

  private _checkChangesAndShowTCPAMessage(): boolean {
    if (this.isShowTCPASettingsMessage && this.isExistChanges) {
      this.detectClose();
    }

    return true;
  }

  private _initForm(): void {
    this.store.dispatch(new ClearSettingsSendingLogicData());
    this.store.dispatch(new ClearSettingsSendingLogicOriginal());

    this.settingsForm = this.fb.group({
      quietHoursActive: [false],
      quietHoursFrom: ['', Validators.required],
      quietHoursTo: ['', Validators.required],
      messagesThrottlingActive: [false, Validators.required],
      messagesThrottlingDisplayType: [this.delays[0].value, Validators.required],
      messagesThrottlingDelay: [4,
        [
          Validators.required,
          Validators.min(0),
          Validators.max(999999999999),
          Validators.pattern(/^[0-9]+$/),
        ],
      ],
      maxMessagesPerMonthActive: [false],
      maxMessagesPerMonthValue: [30,
        [
          Validators.required,
          Validators.min(0),
          Validators.max(999999999999),
          Validators.pattern(/^[0-9]+$/),
        ],
      ],
      maxMessagePriceActive: [false],
      maxMessagePriceValue: [0.07,
        [
          Validators.required,
          Validators.min(0),
          Validators.max(999999999999),
          Validators.pattern(/^[0-9.]+$/),
        ],
      ],
    });

    this._fillForm();
  }

  private _fillForm(): void {
    this._settingsService.getSettings('conditions')
      .pipe(first())
      .subscribe(
        (data: SettingsSendingLogic) => {
          this.settingsForm.setValue({
            quietHoursActive: data.quietHoursActive,
            quietHoursFrom: data.quietHoursFrom,
            quietHoursTo: data.quietHoursTo,
            messagesThrottlingActive: data.messagesThrottlingActive,
            messagesThrottlingDisplayType: data.messagesThrottlingDisplayType,
            messagesThrottlingDelay:
              this._settingsService.secondsConverter(data.messagesThrottlingDelay, data.messagesThrottlingDisplayType, false),
            maxMessagesPerMonthActive: data.maxMessagesPerMonthActive,
            maxMessagesPerMonthValue: data.maxMessagesPerMonthValue,
            maxMessagePriceActive: data.maxMessagePriceActive,
            maxMessagePriceValue: data.maxMessagePriceValue,
          });

          this.isQuietHoursActive = this.settingsForm.get('quietHoursActive').value;
          this._progressBarService.finishLoading();
          this.loading = false;

          this.store.dispatch(new SetSettingsSendingLogicOriginal(data));
        },
        () => {
          this._progressBarService.finishLoading();
          this.loading = false;
        },
      );
  }

  private _saveData(field): void {
    const fieldControl = this.settingsForm.get(field);
    const fieldValue = fieldControl.value;
    if (this.settingsForm.invalid || fieldControl.pristine) return;

    this._settingsService
      .updateSettings('conditions', this._buildTemplate(field, fieldValue))
      .pipe(
        first(),
        switchMap(() => {
          this.isExistChanges = true;
          return this.quietHoursService.getQuietHours();
        }),
      )
      .subscribe(() => {
        this._fillForm();
        fieldControl.markAsPristine();
        this._notiService.success(SAVED_SUCCESSFULLY);
      });
  }

  private _buildTemplate(field: string, value: string): any {
    if (field === 'messagesThrottlingDisplayType' || field === 'messagesThrottlingDelay') {
      switch (field) {
        case 'messagesThrottlingDisplayType':
          return {
            ['messagesThrottlingDisplayType']: value,
            ['messagesThrottlingDelay']: this._settingsService.secondsConverter(
              this.settingsForm.get('messagesThrottlingDelay').value,
              value,
              true,
            ),
          };
        case 'messagesThrottlingDelay':
          return {
            ['messagesThrottlingDelay']: this._settingsService.secondsConverter(
                value,
                this.settingsForm.get('messagesThrottlingDisplayType').value,
              true,
            ),
            ['messagesThrottlingDisplayType']: this.settingsForm.get('messagesThrottlingDisplayType').value,
          };
      }
    } else {
      return { [field]: this._prepareValue(field, value) };
    }
  }

  private _prepareValue(field: string, value: string): any {
    switch (field) {
      case 'quietHoursFrom':
      case 'quietHoursTo':
      case 'maxMessagesPerMonthValue':
      case 'maxMessagePriceValue':
        return +value;
      default:
        return value;
    }
  }

  private _checkChanges(): void {
    this.settingsForm.valueChanges
      .pipe(debounceTime(500), takeUntil(this.ngUnsubscribe$))
      .subscribe(() => {
        this.isFormDirty = this._settingsSendingLogicChangesService.hasChanges();
      });
  }

  ngOnDestroy() {
    this.isExistChanges = false;
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
