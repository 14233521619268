import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'k-legal-agreements',
  templateUrl: './legal-agreements.component.html',
  styleUrls: ['./legal-agreements.component.scss'],
})
export class LegalAgreementsComponent implements OnInit {

  constructor() { }

  ngOnInit() { }
}
