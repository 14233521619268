import { Injectable }             from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable }             from 'rxjs';

import { environment } from '@environments/environment';

@Injectable()
export class ApiService {
  constructor(private http: HttpClient) {}

  public get(path: string, params?: any, isPublic?: string): Observable<any> {
    let httpParams = new HttpParams();

    for (const param in params) {
      if (params.hasOwnProperty(param)) {
        httpParams = httpParams.set(param, params[param].toString());
      }
    }

    return this.http.get(this._getUrl(path, isPublic), { params: httpParams });
  }

  public post(path: string, body?: any): Observable<any> {
    return this.http.post(this._getUrl(path), body);
  }

  public put(path: string, body: any): Observable<any> {
    return this.http.put(this._getUrl(path), body);
  }

  public patch(path: string, body: any): Observable<any> {
    return this.http.patch(this._getUrl(path), body);
  }

  public delete(path: string, httpParams: HttpParams = new HttpParams()): Observable<any> {
    return this.http.delete(this._getUrl(path), { params: httpParams });
  }

  private _getUrl(path: string, isPublic?: string): string {
    return `${isPublic ? '/api/' : environment.apiUrl}${path}`;
  }

  public httpGet(path: string): Observable<any> {
    return this.http.get(path,{ responseType: 'blob' });
  }
}
