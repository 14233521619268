import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnDestroy,
  OnInit,
  ChangeDetectorRef,
}                 from '@angular/core';
import { Router } from '@angular/router';

import { Observable, Subject }         from 'rxjs';
import { first, switchMap, takeUntil } from 'rxjs/operators';
import { NgxSmartModalService }        from 'ngx-smart-modal';
import { Select, Store }               from '@ngxs/store';

import { MerchantInfo }                                  from '@core/models';
import { HelperService, MerchantService, SocketService } from '@core/services';
import { NotiService }                                   from '@notifications/services';
import { NotificationData }                              from '@notifications/models';
import { LEGAL_ROUTS }                                   from '@core/texts';

@Component({
  selector: 'k-onboard-dialog',
  templateUrl: './onboard-dialog.component.html',
  styleUrls: ['./onboard-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardDialogComponent implements OnInit, OnDestroy {
  @Select(state => state.merchant) merchant$: Observable<MerchantInfo>;
  @Select(state => state.notifications.shownAlerts) shownAlerts$: Observable<NotificationData[]>;

  @Input() identifier: string;

  public merchantInfo: MerchantInfo;
  public loading: boolean;
  public provisioningStatus: boolean = false;
  public merchant: MerchantInfo;

  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private cd: ChangeDetectorRef,
    private modal: NgxSmartModalService,
    private store: Store,
    private _notiService: NotiService,
    private _helperService: HelperService,
    private _merchantService: MerchantService,
    private _socketService: SocketService,
  ) { }

  ngOnInit() {
    this.merchant$
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((merchant: MerchantInfo) => {
        if (merchant.instalationProcess === LEGAL_ROUTS.PROVISIONING_IN_PROGRESS
            || merchant.instalationProcess === LEGAL_ROUTS.PROVISIONING_DONE) {
          this._checkShowPopup();
          this.provisioningStatus = merchant.numberProvisioningStatus;
        }
      });

    this.shownAlerts$
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(shownAlerts => {
        if (shownAlerts.some(data => data.trigger === 'numberReady')) {
          this.provisioningStatus = true;
          this._preformCd();
        }
      });
  }

  public detectClose(event): void {
    if (event) {
      this._merchantService.setPopupState()
        .pipe(
          switchMap(() => {
            return this._merchantService.loadMerchantInfo().then(() => {});
          }),
          first(),
        )
        .subscribe();
    }
  }

  private _checkShowPopup(): void {
    this.modal.getModal(this.identifier).open();
  }

  private _preformCd(): void {
    this.cd.detectChanges();
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
