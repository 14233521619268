import { Injectable }               from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { filter, switchMap, tap }   from 'rxjs/operators';
import { Store }                    from '@ngxs/store';

import { ApiService } from '@core/services';

import { CampaignEditItem, CampaignListItem, CampaignSegmentItem } from '../models';

import moment from 'moment';

import {
  AddCampaignListItem,
  SetCampaignOriginal,
  SetCampaignSegmentsList,
  SetScheduledCampaignsDates,
  UpdateCampaignListItem,
} from '@store/actions';
import { NotiService }         from '@notifications/services';
import { CampaignListService } from '@campaigns/services/campaign-list.service';

@Injectable()
export class CampaignService {
  private subscription: Subscription;

  constructor(private apiService: ApiService,
              private _notiService: NotiService,
              private _campaignListService: CampaignListService,
              private store: Store) {}

  public isWrongScheduledCampaignStatus(campaign): boolean {
    const dateNow = moment(new Date()).valueOf();
    const scheduledDate = moment(new Date(campaign.sendAtUTC)).valueOf();
    const isNotAvailable = campaign.sendAtUTC ? scheduledDate < dateNow : false;
    const isCampaignDisabled = campaign.status === false;
    const isScheduledOfDraftCampaign = campaign.type === 'Scheduled' || campaign.type === 'Draft';

    return isCampaignDisabled && isScheduledOfDraftCampaign && isNotAvailable;
  }

  public getCampaignRecipients(campaignId?: number): Observable<CampaignSegmentItem[]> {
    const params = {};
    if (campaignId) params['campaignId'] = campaignId;
    return this.apiService.get('campaigns/segments', params)
      .pipe(
        tap((result) => {
          if (!campaignId && typeof campaignId !== 'number') {
            this.store.dispatch(new SetCampaignSegmentsList(result));
          }
        }),
      );
  }

  public getCampaign(id: number): Observable<CampaignEditItem> {
    return this.apiService.get(`campaigns/${id}`).pipe(
      tap((data) => {
        const { type, failed, planed, sent, ...editItem } = data;
        const { status, ...message } = editItem.message;
        editItem.message = message;
        this.store.dispatch(new SetCampaignOriginal({ ...editItem }));
      }),
    );
  }

  public scheduleCampaign(data: CampaignEditItem): Observable<CampaignListItem> {
    return this.apiService.post('campaigns', data).pipe(
      tap((result) => {
        this.store.dispatch(new AddCampaignListItem(result));
      }),
    );
  }

  public updateCampaign(data: CampaignEditItem): Observable<any> {
    return this.apiService.put(`campaigns/${data.id}`, data).pipe(
      tap((result) => {
        this.store.dispatch(new UpdateCampaignListItem(result));
      }),
    );
  }

  public getScheduledCampaign(start: string, end: string): Observable<any> {
    return this.apiService.get(`campaigns/scheduled`, { 'start': start, 'end': end }).pipe(
      tap((dates) => {
        this.store.dispatch(new SetScheduledCampaignsDates(dates));
      }),
    );
  }

  public isCampaignScheduled(data): Observable<any> {
    return this.apiService.get(`campaigns/validate/time-recipient`, { ...data })
      .pipe(
      tap((isScheduled) => {
        return isScheduled;
      }),
    );
  }

  public undoToggleCampaign(id: number, callback: () => void): Observable<any> {
    return this.apiService.delete(`campaigns/${id}/cancel-delayed-action`)
      .pipe(
        tap(() => callback ? callback() : null),
     );
  }

  public makeUndoProcess(id: number, callback?: () => void): void {
    const campaignEnabledTriggerId: number = this.store.selectSnapshot(state => state.merchant.notifications.campaignEnabled.triggerId);
    const campaignDisabledTriggerId: number = this.store.selectSnapshot(state => state.merchant.notifications.campaignDisabled.triggerId);

    this.unsubscribeUndoProcess();
    this.subscription = this._notiService.emitCloseNotificationById$
      .pipe(
        filter((triggerId) => triggerId === campaignEnabledTriggerId || triggerId === campaignDisabledTriggerId),
        switchMap(() => this.undoToggleCampaign(id, callback)),
      ).subscribe();
  }

  public unsubscribeUndoProcess(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
