import { Injectable }                  from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap }                         from 'rxjs/operators';

import { Store }                                                    from '@ngxs/store';
import { ApiService }                                               from './api.service';
import { Discounts, DiscountTypeNames, FreeShipping }               from '@core/models';
import { SetDiscountData, SetDiscountTemplateData }                 from '@store/actions';
import { DiscountStateModel, DiscountTemplateModel, MessageParams } from '@shared/models';
import { DISCOUNT_TYPE }                                            from '@core/texts';

@Injectable()
export class DiscountService {
  public discountAction$: BehaviorSubject<{templateType?: string, edit?: boolean, remove?: boolean}> = new BehaviorSubject({});
  constructor(private _apiService: ApiService, private store: Store) { }

  public readonly discountTypeNames: DiscountTypeNames[] = [
    DISCOUNT_TYPE.PERCENTAGE,
    DISCOUNT_TYPE.FIXED_AMOUNT,
    DISCOUNT_TYPE.FREE_SHIPPING,
  ];
  public readonly actualDiscountTypes = {
    [DISCOUNT_TYPE.PERCENTAGE] : 'DiscountCodeBasicPercentage',
    [DISCOUNT_TYPE.FIXED_AMOUNT] : 'DiscountCodeBasicAmount',
    [DISCOUNT_TYPE.FREE_SHIPPING] : 'DiscountCodeFreeShipping',
  };

  public getStaticDiscounts(automationType): Observable<Discounts> {
    const params = {};
    if (automationType === 'automation-upsell' || automationType === 'Upsell') {
      params['withoutBxgy'] = true;
    }
    return this._apiService.get('discounts', params);
  }

  public getFreeShippingRegions(): Observable<FreeShipping> {
    return this._apiService.get('free-shipping');
  }

  public getDiscountLink(gid: string, type: string, value: string): Observable<any> {
    const isBasicDiscountType = (type === 'DiscountCodeBasicPercentage' || type === 'DiscountCodeBasicAmount');
    const correctType = isBasicDiscountType ? 'DiscountCodeBasic' : type;

    return this._apiService.post('discounts/link', { type: correctType, gid })
      .pipe(
        tap(data => {
          this.storeStaticDiscountData(value, data.link, data.code);
        }),
      );
  }

  public storeStaticDiscountData(value: string, link: string, code: string): void {
    const data: DiscountStateModel = {
      type: 'static',
      link: link.replace(/\s/g, '&nbsp;'),
      code: code.replace(/\s/g, '&nbsp;'),
      value,
    };
    this.store.dispatch(new SetDiscountData(data));
  }

  public getDynamicDiscountLink(type: string, value: number, region: string) {
    return this._apiService.post('discounts/dynamic-link', { type, value, region })
      .pipe(
        tap(data => {
          this.storeDynamicDiscountData(type, value, data.link, data.code, region);
        }),
      );
  }

  public storeDynamicDiscountData(type: string, value: number, link: string, code: string, region: string): void {
    const data: DiscountStateModel = {
      type: 'dynamic',
      link,
      code,
      value: type === 'DiscountCodeBasicAmount'
        ? `${this.replaceDiscountDigits(value)}`
        : type === 'DiscountCodeBasicPercentage'
          ? `${value * 100}%`
          : region ? `Free Shipping - ${region}` : `Free Shipping`,
    };
    this.store.dispatch(new SetDiscountData(data));
  }

  public storeDiscountTemplate(params: MessageParams): void {
    const data = this.getDiscountTemplate(params);

    this.store.dispatch(new SetDiscountTemplateData(data));
  }

  public replaceDiscountDigits(number) {
    const a = number.toString().replace(/\.(?=.*\.)/g, '');
    const updatedValue = Number(a.replace(/[,]/g, '')).toFixed(2);

    if (Number.isNaN(+updatedValue)) {
      return Number(0).toFixed(2);
    }

    return '$' + updatedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  private getDiscountTemplate(params: MessageParams): DiscountTemplateModel {
    const { discountRedirect, discountView, discountGid, discountId,
      discountCode, discountUrl, discountType, discountValue, discountRegion,
    } = params;

    return {
      discountRedirect, discountView, discountGid, discountId,
      discountCode, discountUrl, discountType, discountValue, discountRegion,
    };
  }

}
