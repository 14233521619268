import { Injectable } from '@angular/core';

import { ShortCodeEditorInfo, ShortCodesData } from '@shared/components/message-builder/short-codes/short-codes.model';
import { SHORT_CODES_BY_TYPE }                 from '@shared/data-model/short-codes.model';

import { TriggerType } from '@flows/models/trigger-type';

@Injectable()
export class ShortCodePlaceholdersService {
  constructor() { }

  public getShortCodes(triggerType: TriggerType): ShortCodesData {
    return SHORT_CODES_BY_TYPE[triggerType];
  }

  public getListOfShortCodeNames(shortCodes: ShortCodesData): ShortCodeEditorInfo[] {
    return [
      ...shortCodes.discounts,
      ...shortCodes.general,
      ...shortCodes.unsubscribe,
      ...shortCodes.tcpa,
    ].map((sc) => ({ name: sc.name, hint: sc.editorHint }));
  }
}
