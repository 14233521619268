export interface MessageStrength {
  messageStrengthWeight: number;
  messageStrengthHints: MessageStrengthHint[];
  messageStrength: string;
}

export interface MessageStrengthHint {
  hint: string;
  name: string | null;
}

export interface MessageStrengthMetrics {
  messageStrengthWeight: number;
  messageStrengthHints: MessageStrengthHint[];
}

export  interface NonGSMSymbols {
  regex: string;
  replacement: string;
}

export interface MessageRecommendation {
  quietHoursFrom: number;
  quietHoursTo: number;
  quietHoursFromSettings: boolean;
  merchantBalance: number;
  messagePrice: number;
  maxMessagePrice: number;
}

export interface FlowMessageParams {
  id: string;
  template: MessageParams;
}

export interface MessageParams {
  type?: string;
  image?: MessageImageParams | null;
  text: string;
  discountValue?: number | null;
  discountGid?: string | null;
  discountId?: number | null;
  discountCode?: string | null;
  discountUrl?: string | null;
  discountView?: string | null;
  discountRedirect?: string | null;
  discountType?: 'static' | 'dynamic' | '' | null;
  discountRegion?: string | null;
}

export interface MessageImageParams {
  name: string;
  url: string;
  size?: number;
}

export interface MessagePriceData {
  mmsPrice: number;
  smsChapterPrice: number;
}

export interface RenderedMessageParams {
  boughtProductName?: string;
  recommendedProductName?: string;
  recommendedProductId?: string;
  discountId?: number;
  discountCode?: string;
  templateString: string;
  keywordName?: string;
}
