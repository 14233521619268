import { Injectable } from '@angular/core';
import { Router }     from '@angular/router';
import { Observable } from 'rxjs';
import { map }        from 'rxjs/operators';
import { ApiService } from '@core/services';

import { SubscriptionFlowItem }            from '@shared/models';
import { RedirectData }                    from '@shared/models/redirectData.model';
import { POPUP_NAME, SHAREABLE_LINK_NAME } from '@shared/texts';

@Injectable()
export class FlowSubscriptionService {

  constructor(private router: Router,
              private _apiService: ApiService) { }

  public getFlows(): Observable<any> {
    return this._apiService.get('flows/subscriptions')
      .pipe(
        map(data => {
          return data.map((flow: SubscriptionFlowItem) => {
            return {
              value: flow.id,
              id: flow.id,
              name: flow.name,
              valid: flow.valid,
              status: flow.status,
              keywordName: flow.keywordName,
              response: flow.response,
            };
          });
        }),
      );
  }

  public redirectToFlow(id: number, flowId: number, placeholderType: string) {
    const redirectData = this.getRedirectData(id, placeholderType);
    this.router.navigate(['/flows', 'edit', flowId], { state: { redirectData }});
  }

  public getRedirectData(id: number, placeholderType: string): RedirectData {
    const redirectMapping: {[key: string]: RedirectData} = {
      [SHAREABLE_LINK_NAME]: { actionButton: 'Back to Shareable Link', backUrl: `/shareable-links/edit/${id}` },
      [POPUP_NAME]: { actionButton: 'Back to Popup', backUrl: `/popups/edit/${id}` },
    };

    return redirectMapping[placeholderType];
  }
}
