import { SegmentItem, SegmentListItem, SegmentStatistics } from '@segments/components/models';
import { TableSortData }                                   from '@shared/models';


export class SetSegmentsData {
  static readonly type = '[Segments List] SetSegmentsData';

  constructor(public payload: any) { }
}

export class SetSegmentsList {
  static readonly type = '[Segments List] SetSegmentsList';

  constructor(public payload: SegmentListItem[]) { }
}

export class DeleteSegmentListItem {
  static readonly type = '[Segments List] DeleteSegmentListItem';

  constructor(public index: number) { }
}

export class ClearSegmentList {
  static readonly type = '[Segments List] ClearSegmentList';

  constructor() { }
}

export class SetSegmentOriginal {
  static readonly type = '[Segment Form] SetSegmentOriginal';

  constructor(public payload: SegmentItem) { }
}

export class ClearSegmentOriginal {
  static readonly type = '[Segment Form] ClearSegmentOriginal';

  constructor() { }
}

export class ClearSegment {
  static readonly type = '[Segment Form] ClearSegment';

  constructor() { }
}

export class SetSegmentsStatistic {
  static readonly type = '[Segments List] SetSegmentsStatistic';

  constructor(public payload: SegmentStatistics) { }
}

export class SetSegmentSortingData {
  static readonly type = '[Sorting] SetSegmentSortingData';

  constructor(public sorting: TableSortData) { }
}
