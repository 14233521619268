import { BlurDirective }              from '@shared/directives/blur.directive';
import { LinkDirective }              from '@shared/directives/link.directive';
import { LinkRouterDirective }        from '@shared/directives/link-router.directive';
import { PreventDefaultDirective }    from '@shared/directives/prevent-default.directive';
import { RetrySrcDirective }          from '@shared/directives/retry-src.directive';
import { ToggleClassDirective }       from '@shared/directives/toggle-class.directive';
import { ContenteditableDirective }   from '@shared/directives/contenteditable.directive';
import { PhoneMaskDirective }         from '@shared/directives/phone-mask.directive';
import { NumbersOnlyDirective }       from '@shared/directives/numbers-only.directive';
import { TableCellTemplateDirective } from '@shared/directives/table-cell-template.directive';
import { UsFormatDirective }          from '@shared/directives/us-format-only.directive';
import { ExternalRedirectDirective }  from '@shared/directives/external-redirect.directive';

export const directives = [
  BlurDirective,
  LinkDirective,
  LinkRouterDirective,
  PreventDefaultDirective,
  RetrySrcDirective,
  ToggleClassDirective,
  ContenteditableDirective,
  PhoneMaskDirective,
  NumbersOnlyDirective,
  TableCellTemplateDirective,
  UsFormatDirective,
  ExternalRedirectDirective,
];
