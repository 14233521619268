import {
  Router,
  RouterEvent,
  ActivationStart,
  NavigationEnd,
  ActivatedRoute,
  Params,
  ActivatedRouteSnapshot,
  ChildActivationEnd,
  ChildActivationStart,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { Store }      from '@ngxs/store';
import { Subject }    from 'rxjs';
import { History }    from '@shopify/app-bridge/actions';

import {
  ClearAppSectionList,
  HideHeader,
  SetIsCenteringPage,
  SetLastLoadedChild,
  SetShopParams,
}                         from '@store/actions';
import { ShopifyService } from './shopify.service';

@Injectable()
export class RouterService {
  public paramsLoaded$: Subject<Params> = new Subject<Params>();

  constructor(private router: Router,
              private route: ActivatedRoute,
              private store: Store,
              private _shopifyService: ShopifyService) {
    this._setShopParams();
  }

  public listenToRouterEvents(): void {
    this.router.events.subscribe((event: RouterEvent) => {
      if (event instanceof ActivationStart) {
        this._listenToActivationStart(event);
      }

      if (event instanceof NavigationEnd) {
        this._listenToNavigationEnd(event);
      }

      if (event instanceof ChildActivationStart) {
        this._checkAndClearAppSectionList(event);
      }

      if (event instanceof ChildActivationEnd) {
        this._checkAndUpdateLoadedChild(event);
      }
    });
  }

  private _checkAndClearAppSectionList(event: ChildActivationEnd): void {
    const lastLoadedChild = this.store.selectSnapshot(state => state.app.lastLoadedChild);
    const config = event.snapshot.routeConfig;

    if (config && lastLoadedChild && config.path && config.path !== lastLoadedChild) {
      this.store.dispatch(new ClearAppSectionList(lastLoadedChild));
    }
  }

  private _checkAndUpdateLoadedChild(event: ChildActivationEnd): void {
    if (event.snapshot.routeConfig && event.snapshot.data.clearAppSectionListOnRouteLeave) {
      this.store.dispatch(new SetLastLoadedChild(event.snapshot.routeConfig.path));
    }
  }

  private _listenToActivationStart(event): void {
    const data = event.snapshot.data;

    this.store.dispatch(new HideHeader(data.hideHeader || false));
    this.store.dispatch(new SetIsCenteringPage(data.isCenteringPage || false));
  }

  private _listenToNavigationEnd(event): void {
    const shopifyHistory = this._shopifyService.history;
    const shopParams = this.store.selectSnapshot(state => state.app.shopParams);

    if (shopifyHistory) {
      shopifyHistory.dispatch(History.Action.PUSH, event.url);
    }

    this.router.navigate([], { queryParams: shopParams, queryParamsHandling: 'merge' });
  }

  private _setShopParams(): void {
    const routeSubscription = this.route.queryParams.subscribe((params) => {
      if (Object.keys(params).length) {

        this._shopifyService.createApp(params.host);

        this.store.dispatch(new SetShopParams(params));
        this.paramsLoaded$.next(params);

        routeSubscription.unsubscribe();
      }
    });
  }

  public getDeepestFirstChildSnapshot(snapshot: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
    if (snapshot.firstChild) {
      return this.getDeepestFirstChildSnapshot(snapshot.firstChild);
    }
    return snapshot;
  }
}
