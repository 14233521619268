import { SettingsBrandingComponent }      from './settings-branding/settings-branding.component';
import { SettingsGeneralComponent }       from './settings-general/settings-general.component';
import { SettingsIntegrationsComponent }  from './settings-integrations/settings-integrations.component';
import { SettingsLayoutComponent }        from './settings-layout/settings-layout.component';
import { SettingsNotificationsComponent } from './settings-notifications/settings-notifications.component';
import { SettingsPaymentsComponent }      from './settings-payments/settings-payments.component';
import { SettingsSendingLogicComponent }  from './settings-sending-logic/settings-sending-logic.component';
import { SettingsTcpaComponent }          from './settings-tcpa/settings-tcpa.component';

export {
  SettingsBrandingComponent,
  SettingsGeneralComponent,
  SettingsIntegrationsComponent,
  SettingsLayoutComponent,
  SettingsNotificationsComponent,
  SettingsPaymentsComponent,
  SettingsSendingLogicComponent,
  SettingsTcpaComponent
};

export const components = [
  SettingsBrandingComponent,
  SettingsGeneralComponent,
  SettingsIntegrationsComponent,
  SettingsLayoutComponent,
  SettingsNotificationsComponent,
  SettingsPaymentsComponent,
  SettingsSendingLogicComponent,
  SettingsTcpaComponent
];
