import {
  Component,
  AfterViewInit,
  ChangeDetectionStrategy,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  ViewEncapsulation,
  HostBinding,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Store }                from '@ngxs/store';
import { Subject }              from 'rxjs';
import { debounceTime }         from 'rxjs/operators';
import { NgxSmartModalService } from 'ngx-smart-modal';

import { AlertType }                    from '@shared/components/base/alert/alert.model';
import { LinkTemplateData, NotiParams } from '@notifications/models';
import { NotiService }                  from '@notifications/services';
import { Router }                       from '@angular/router';

@Component({
  selector: 'k-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() type: AlertType = 'warning';
  @Input() iconName: string;
  @Input() noBorderRadius: boolean = false;
  @Input() isFakeClose: boolean = false;
  @Input() close: boolean = true;
  @Input() data: NotiParams;

  @Output() closeAlert: EventEmitter<number> = new EventEmitter<number>();

  public msgChunks: string[];
  public paymentsTriggersArray: string[] = ['balanceCloseToZero', 'balanceZero'];
  public linkIndexPattern: RegExp;

  private clicks$: Subject<void> = new Subject<void>();
  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  @HostBinding('class.k-alert--no-border-radius')
  get noBorderRadiusClass(): boolean {
    return this.noBorderRadius;
  }

  constructor(private el: ElementRef,
              private renderer: Renderer2,
              private store: Store,
              private router: Router,
              private modal: NgxSmartModalService,
              private _notiService: NotiService) { }

  ngOnInit() {
    this.linkIndexPattern = this._notiService.getLinkIndexPattern();

    if (this.data?.linkTemplates) {
      this.msgChunks = this._notiService.getMessageChunks(this.data.message);
    }
  }

  ngAfterViewInit() {
    this.renderer.addClass(this.el.nativeElement, 'k-alert');
    this.renderer.addClass(this.el.nativeElement, 'k-flex--start');
    this.renderer.addClass(this.el.nativeElement, `k-alert--${this.type}`);

    this.clicks$
      .pipe(debounceTime(200))
      .subscribe(() => {

        this._notiService.closeAlert(this.data);

        this.closeAlert.emit(this.data.triggerId);
        this.renderer.removeClass(this.el.nativeElement, 'k-alert--hide');
    });
  }

  public hideAlert(isFakeClose: boolean): void {
    this.renderer.addClass(this.el.nativeElement, 'k-alert--hide');

    if (!isFakeClose) {
      this.clicks$.next();
    }

    if (isFakeClose) {
      this.closeAlert.emit(1);
    }
  }

  public onLinkClick(linkIndex: string): void {
    const linkData = this.getLinkData(linkIndex);
    switch (linkData.url) {
      case '/settings/payments':
        this.openPaymentsDialog();
        break;
      default:
        this.router.navigate([linkData.url]);
    }
  }

  public openPaymentsDialog(): void {
    this.modal.setModalData({
      paymentType: 'autoRecharge',
      modalTitle: 'Add funds',
      showToggleButtons: true,
    }, 'paymentDialog', true);

    this.modal.getModal('paymentDialog').open();

    this._notiService.trackEvent(this.data.id, this.data.triggerId, 'linkClick').subscribe();
  }

  public getLinkData(linkIndex: string): LinkTemplateData {
    const index = +linkIndex.charAt(1);
    return this.data.linkTemplates[index];
  }

  ngOnDestroy() {
    this.clicks$.complete();

    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
