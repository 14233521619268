import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidatorFn }  from '@angular/forms';
import { Observable, Subject }                               from 'rxjs';
import { distinctUntilChanged, first, takeUntil, tap }       from 'rxjs/operators';
import { NgxSmartModalService }                              from 'ngx-smart-modal';
import { ReadFile, ReadMode }                                from 'ngx-file-helpers';
import { Select, Store }                                     from '@ngxs/store';

import {
  DiscountService,
  HelperService,
  ShortCodePlaceholdersService,
} from '@core/services';
import {
  MMS,
  POPUP_NAME, SHAREABLE_LINK_NAME,
  SHORT_CODES,
  SMS,
  SUBSCRIPTION_TRIGGER_PARAMS,
  TRIGGER_TYPES_DATA,
} from '@shared/texts';
import {
  DiscountStateModel,
  MessageImageParams,
  MessageParams,
  MessageRecommendation,
  MessageStrength,
} from '@shared/models';
import { ClearDiscountData, ClearDiscountTemplateData } from '@store/actions';
import { CustomImage, DiscountView, MerchantInfo }      from '@core/models';
import { MessageBuilderService }                        from '@shared/service/message-builder';
import { ShortCodeEditorInfo, ShortCodesData }          from '@shared/components/message-builder/short-codes/short-codes.model';
import { ValidatorService }                             from '@shared/service/validator.service';
import { NotiService }                                  from '@notifications/services';
import { TriggerType }                                  from '@flows/models/trigger-type';

@Component({
  selector: 'k-message-builder',
  templateUrl: './message-builder.component.html',
  styleUrls: ['./message-builder.component.scss'],
})
export class MessageBuilderComponent implements OnInit, AfterViewInit, OnDestroy {
  @Select(state => state.discount.linkInfo) discount$: Observable<DiscountStateModel>;
  @Select(state => state.merchant) merchant$: Observable<MerchantInfo>;

  @Input() messageParams: MessageParams;
  @Input() triggerType: TriggerType;
  @Input() substitutesOptions: any;
  @Input() templateID: string;
  @Input() templateParams: any;
  @Input() isShowImage: boolean = true;
  @Input() isShowDiscount: boolean = true;
  @Input() isShowShortcode: boolean = true;
  @Input() isShowEmoji: boolean = true;
  @Input() isShowMessagePrice: boolean = true;
  @Input() isShowMessagePreview: boolean = true;
  @Input() isShowMessageStrength: boolean = true;
  @Input() inBotConversation: boolean;
  @Input() messageSuggestionType: 'strength' | 'recommendation' = 'strength';
  @Input() isShowMessageErrors: boolean = true;
  @Input() flowMsgId: string;

  @Input() set messageRecommendationParams(params: MessageRecommendation) {
    this.messageRecommendationData = params;
  }

  @Output() changed: EventEmitter<MessageParams> = new EventEmitter<MessageParams>();
  @Output() messagePriceChanged: EventEmitter<number> = new EventEmitter<number>();
  @Output() messageValidityUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() messageInitialized: EventEmitter<void> = new EventEmitter<void>();

  public emojiToggled = false;
  public shortCodesToggled = false;
  public initTemplateString: boolean = false;
  public messageStrengthData: MessageStrength;
  public messagePrice: number = 0;
  public smsSize: number;
  public smsLimit: number;
  public smsNumber: number;
  public mmsNumber: number;
  public messageForm: FormGroup;
  public message: string;
  public imageFile: any;
  public imageUrl: string;
  public isSMS: boolean = true;
  public imageName: string;
  public imageSize: number;
  public isDiscountsBtnDisabled: boolean = false;
  public discountModalId: string = 'messageDiscount';
  public actualDiscountTypes = {};
  public discountView: DiscountView = { isExists: false };
  public shortCodes: ShortCodesData;
  public availableShortCodes: ShortCodeEditorInfo[];
  public isEdit: boolean = false;
  public isGifAllowed: boolean = true;
  public isImageAttached: boolean;
  public filter: (file: File) => boolean;
  public isFileExtensionCorrect = true;
  public isFileSizeNotTooBig = true;
  public readMode = ReadMode.dataURL;
  public templateString: string;
  public discountId: number;
  public discountCode: string;
  public isTCPARequired: boolean;
  public messageRecommendationData: MessageRecommendation = null;
  public price;
  public settingsData;

  public get isDiscountExist(): boolean {
    return this._getControlValue('messageDiscount')
      && (
        this._getControlValue('discountRedirect')
        || this._getControlValue('discountGid')
        || this._getControlValue('discountId')
        || this._getControlValue('discountCode')
        || this._getControlValue('discountUrl')
        || this._getControlValue('discountType')
        || this._getControlValue('discountValue')
        || this._getControlValue('discountRegion')
      );
  }

  public get isShowMessagePriceNotifications(): boolean {
    return  this.triggerType !== 'Popup' && this.triggerType !== 'Inbox' && this.triggerType !== 'ShareableLink';
  }

  public get messageType(): string {
    return this.messageForm.get('type')?.value;
  }

  public get isCheckoutTrigger(): boolean {
    if (this.triggerType === TRIGGER_TYPES_DATA.SUBSCRIPTION_TRIGGERS_SUBSCRIPTION_VALUE) {
      return this.substitutesOptions?.channel?.name === SUBSCRIPTION_TRIGGER_PARAMS.CHECKOUT_NAME;
    }
    return false;
  }

  public get isDiscountCanBeAdded(): boolean {
    return this.triggerType !== TRIGGER_TYPES_DATA.CART_TRIGGERS_ABANDONED_CART_VALUE
      && this.triggerType !== TRIGGER_TYPES_DATA.PAID_TRIGGERS_UPSELL_CROSS_SELL_VALUE
      && !this.templateStringControl.value.includes(SHORT_CODES.DISCOUNT_CODE_URL);
  }

  public get disabledShortCodes(): string[] {
    const shortCodesArray = [];

    if (this.isDiscountExist) {
      shortCodesArray.push(SHORT_CODES.DISCOUNT_CODE, SHORT_CODES.DISCOUNT_CODE_URL);
    }

    if (this.isCheckoutTrigger) {
      shortCodesArray.push(SHORT_CODES.KEYWORD_NAME);
    }

    return shortCodesArray;
  }

  public get templateStringLength(): boolean {
    return this.templateString ? !!this.templateString.trim().length : false;
  }

  public get displayDiscountValue() {
    return `${
      this.discountView?.uniqueness === 'Static'
        ? this.discountView?.uniqueness + ' | ' + this.discountView?.code + ' (' + this.discountView?.value + ')'
        : this.discountView?.uniqueness + ' | ' + this.discountView?.value
    }`;
  }

  public get templateStringControl(): FormControl {
    return this.messageForm.get('text') as FormControl;
  }

  public get isShowBotNotification(): boolean {
    return this._messageBuilderService.getBotStatus();
  }

  private discountsShortCodes = [SHORT_CODES.DISCOUNT_CODE, SHORT_CODES.DISCOUNT_CODE_URL];
  private merchantInfo: MerchantInfo;
  private shortCodeSubstitutes = {};
  private cursorPosition: number = 0;
  private params: MessageParams;
  private lastUploadedImageFile: ReadFile = null;
  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private store: Store,
    private _helperService: HelperService,
    private _modal: NgxSmartModalService,
    private _notiService: NotiService,
    private _discountService: DiscountService,
    private _messageBuilderService: MessageBuilderService,
    private _shortCodePlaceholdersService: ShortCodePlaceholdersService,
    private _validatorService: ValidatorService,
  ) { }

  ngOnInit(): void {
    this.price = { ...this._messageBuilderService.getPrice() };
    this.isTCPARequired = this.triggerType === POPUP_NAME || this.triggerType === SHAREABLE_LINK_NAME;
    this._getSettings();
    this._getShortCode();
    this.templateString = this.messageParams.text;
    this.discountId = this.messageParams.discountId;
    this.discountCode = this.messageParams.discountCode;

    this._initForm(this.messageParams);
    this._trackAndRecalculateChanges();
  }

  ngAfterViewInit(): void {
    this._trackTemplateStringChange();
    this._updateTemplateString(this.templateString);
    this._preformCd();
  }

  @HostListener('document:click', ['$event.target'])
  onMouseUp(target: Node) {
    const isClickedInsideContainers = this._checkParentNodes(target, ['K-SHORT-CODES', 'EMOJI-PICKER']);
    if (!isClickedInsideContainers) {
      this.closeAll();
    }
  }

  private _getSettings(): void {
    this._messageBuilderService.getSettings('conditions')
      .pipe(first())
      .subscribe(data => {
        this.settingsData = data;
      });
  }

  private _checkParentNodes(node: Node, nodesName: string[]): boolean {
    if (nodesName.includes(node.nodeName)) {
      return true;
    } else if (node.parentNode) {
      return this._checkParentNodes(node.parentNode, nodesName);
    } else {
      return false;
    }
  }

  public onCloseBotNotification(): void {
    this._messageBuilderService.setBotStatus(false);
  }

  public filterFileBeforeReading = (file: File): boolean => {
    this.imageFile = {
      name: file.name,
      size: file.size,
    };
    this.isFileExtensionCorrect = this._helperService.isFileExtentionCorrect(file);
    this.imageSize = file.size;
    this.isFileSizeNotTooBig = file.size < this._helperService.maxFileSize;
    this.isImageAttached = !!file;
    return this.isFileSizeNotTooBig && this.isFileExtensionCorrect;
  }

  public onFilePicked(image): void {
    this._updateImage({ ...image, ...this.imageFile });
    this._updateMessageParams({ ...this.params, image: { ...image, ...this.imageFile }});
    this._updateMessageStrengthData();
    this._updateTemplateTypeAndCounts(this.templateString, this.imageUrl);
  }

  public deleteImage(deleteLastUpdated = false): void {
    if (deleteLastUpdated) {
      this.lastUploadedImageFile = null;
    }

    this.isFileExtensionCorrect = true;
    this.isFileSizeNotTooBig = true;
    this.isImageAttached = false;
    this._updateImageFields(null, null, null);
    if (this.messageForm.get('image')) {
      this.messageForm.get('image').setValue(null);
    }
    this._updateMessageParams({ ...this.params, image: null });
    this._updateTemplateTypeAndCounts(this.templateString, this.imageUrl);
    this._updateMessageStrengthData();
  }

  public onPreviewTestMessagePopup(): void {
    this._preparePreviewMessage();
    this._modal.getModal('popupMessagePreviewDialog').setData('popupMessagePreviewDialog', true).open();
  }

  public onDiscountSubmit(data): void {
    this.isDiscountsBtnDisabled = true;

    if (!this.isEdit) {
      this._insertDiscountShortCodes();
    }

    this.messageForm.get('discountValue').setValue(null);
    this.messageForm.get('discountCode').setValue(data.discount.code);
    this.messageForm.get('discountUrl').setValue(data.discount.link);

    if (data.discount.discountRedirect) { this.messageForm.get('discountRedirect').setValue(data.discount.discountRedirect); }
    if (data.discount.gid) { this.messageForm.get('discountGid').setValue(data.discount.gid); }
    if (data.discount.discountId) { this.messageForm.get('discountId').setValue(data.discount.discountId); }
    if (data.discount.type) { this.messageForm.get('discountType').setValue(data.discount.type); }
    if (data.discount.region) { this.messageForm.get('discountRegion').setValue(data.discount.region); }
    if (data.discount.value) { this.messageForm.get('discountValue').setValue(data.discount.value); }

    this.messageForm
      .get('messageDiscount')
      .setValue(JSON.stringify({
        discountRedirect: this.messageForm.get('discountRedirect').value || null,
        discountView: this.messageForm.get('discountView').value || null,
        discountGid: data.type === 'static' ? this.messageForm.get('discountGid').value : null,
        discountId: data.type === 'static' ? null : this.messageForm.get('discountId').value,
        discountCode: this.messageForm.get('discountCode').value || null,
        discountUrl: this.messageForm.get('discountUrl').value || null,
        discountType: data.type === 'static' ? null : this.messageForm.get('discountType').value,
        discountValue: this.messageForm.get('discountValue').value || null,
        discountRegion: this.messageForm.get('discountRegion').value || null,
      }));

    this.isEdit = false;
    this._discountService.storeDiscountTemplate(this.messageForm.value);
    const discountParams = JSON.parse(this.messageForm.get('messageDiscount').value);
    this._updateMessageParams({ ...this.params, ...discountParams});
  }

  public onDiscountAdd() {
    this._openDiscountDialog();
  }

  public onDiscountEdit() {
    this.isEdit = true;
    this._discountService.discountAction$.next({ edit: true, remove: false });
    this._modal.getModal(this.discountModalId).open();
  }

  private _updateImage(image: MessageImageParams): void {
    this.imageUrl = image.url;
    this.imageName = image.name;
    this.imageSize = image.size;
    this.messageForm.get('image').setValue({ ...image });
  }

  private _trackDiscountChange(): void {
    this.discount$
      .pipe(
        tap((discounts) => {
          this._updateDiscountView(discounts);
          this.messageForm.get('discountView').setValue(JSON.stringify(this.discountView));
        }),
        takeUntil(this.ngUnsubscribe$),
      ).subscribe();
  }

  public onDiscountCancel(): void {
    if (!this.isEdit) {
      this._discountService.discountAction$.next({ edit: false, remove: true });
      this.isDiscountsBtnDisabled = false;
    }
    this.isEdit = false;
  }

  public onDiscountRemove() {
    this.isEdit = false;
    this.isDiscountsBtnDisabled = false;
    this.discountView = { isExists: false };
    this._clearDiscount();
    this._removeDiscountShortCodes();
  }

  public onShortCodeClick(shortCode): void {
    if (this.isDiscountCanBeAdded && this.discountsShortCodes.includes(shortCode) && !this.isDiscountExist) {
      this._openDiscountDialog();
    } else {
      this._insertAtCursorPotion(this.cursorPosition, ` ${shortCode} `);
    }
  }

  public openShortCodes(): void {
    this.emojiToggled = false;
    this.shortCodesToggled = !this.shortCodesToggled;
  }

  public openEmoji() {
    this.shortCodesToggled = false;
    this.emojiToggled = !this.emojiToggled;
  }

  public onShortCodesAdd(shortCode): void {
    this.closeAll();
    if (shortCode) {
      this._insertAtCursorPotion(this.cursorPosition, ` ${shortCode} `);
    }
    this.shortCodesToggled = false;
  }

  public onEmojiChange(emoji): void {
    this._insertAtCursorPotion(this.cursorPosition, emoji.char);
    this.emojiToggled = false;
  }

  public closeAll(): void {
    this.emojiToggled = false;
    this.shortCodesToggled = false;
  }

  public updateCursorPosition(position: number): void {
    this.cursorPosition = position;
  }

  public onTagRemoved(): void {
    this._updateMessageStrengthData();
  }

  public onInitialized(): void {
    this.messageInitialized.emit();
  }

  public clearTemplateStringErrors() {
    this.templateStringControl.setErrors(null);
    this.templateStringControl.markAsUntouched();
    this.messageValidityUpdated.emit(this.templateStringControl.valid && this.templateStringControl.touched);
  }

  private _trackAndRecalculateChanges() {
    this._trackDiscountChange();
    this._trackMerchantInfoChange();
    this._trackTypeControlChange();
    this._updateTemplateTypeAndCounts(this.templateString, this.imageUrl);
    this._updateMessagePrice();
    this._updateMessageStrengthData();
  }

  private _getShortCode(): void {
    this.shortCodes = this._shortCodePlaceholdersService.getShortCodes(this.triggerType);
    this.availableShortCodes = this._shortCodePlaceholdersService.getListOfShortCodeNames(this.shortCodes);
  }

  private _insertAtCursorPotion(position: number, text: string): void {
    const newMessage = this.templateString.substr(0, position) + ` ${text} ` + this.templateString.substr(position);
    this._updateTemplateString(newMessage);
  }

  private _getShortCodeSubstitutes(merchantInfo) {
    return { '{StoreName}': merchantInfo.shop.name };
  }

  private _updateSMSNumbers(templateString): void {
    this.smsLimit = this._messageBuilderService.getLimitOfSMS(templateString);
    this.smsNumber = this._messageBuilderService.getNumberOfSMS(templateString);
    this.smsSize = templateString.length;
  }

  private _updateMMSNumbers(templateString): void {
    this.smsLimit = 1600;
    this.smsSize = templateString.length;
    this.mmsNumber = 1;
  }

  private _updateTemplateTypeAndCounts(templateString, isImageAttached): void {
    const isSubscriptionViewEditorBuilder = this.triggerType === 'Popup' || this.triggerType === 'ShareableLink';

    if (templateString == null) return;

    if (isSubscriptionViewEditorBuilder) {
      const type = this.smsLimit > 160 ? MMS : SMS;
      this.messageForm.get('type').setValue(type);
    } else {
      this._checkDataAndSwitchToType(templateString, isImageAttached);
    }

    isImageAttached || !this.isSMS ? this._updateMMSNumbers(templateString.trim()) : this._updateSMSNumbers(templateString.trim());
  }

  private _checkDataAndSwitchToType(templateString, isImageAttached): void {
    const isSms = this.messageForm.get('type').value === SMS;

    if (isSms && templateString?.trim().length > 699) {
      this.messageForm.get('type').setValue(MMS);
      if (this.isSMS) {
        this._onShowSmsSwitchedToMMSNotification(templateString);
      }
      this._updateMMSNumbers(templateString.trim());
      this.isSMS = false;
    }

    if (isSms && isImageAttached) {
      this.messageForm.get('type').setValue(MMS);
      this.isSMS = false;
      this._updateMMSNumbers(templateString.trim());
    }

    if (!isSms && !isImageAttached && templateString?.trim().length <= 699) {
      this.messageForm.get('type').setValue(SMS);
      this.isSMS = true;
      this._updateSMSNumbers(templateString.trim());
    }

    if (!isSms && !isImageAttached && templateString?.trim().length > 699) {
      if (this.isSMS) {
        this._onShowSmsSwitchedToMMSNotification(templateString);
      }
      this._updateMMSNumbers(templateString.trim());
      this.isSMS = false;
    }

    this._updateMessagePrice();
  }

  private _onShowSmsSwitchedToMMSNotification(templateString: string): void {
    if (!this.initTemplateString && this.messageParams.text === templateString && this.messageParams.image?.url === this.imageUrl) return;
    this.initTemplateString = true;

    const currentlyShownNotifications = this.store.selectSnapshot(state => state.notifications.currentlyShownNotification);
    const notificationTriggerId: number = this.store.selectSnapshot(state => state.merchant.notifications.smsSwitchedToMMS.triggerId);
    const currentNotification: number = currentlyShownNotifications.find((notification) => {
      return notification.triggerId === notificationTriggerId;
    });

    if (!currentNotification) {
      this._notiService.smsSwitchedToMMS();
    }
  }

  private _updateMessagePrice(): void {
    this.messagePrice = this._messageBuilderService.getMessagePrice(
      this.templateString,
      this.messageForm.get('type').value,
      this.smsNumber,
    );
    this.messagePriceChanged.emit(this.messagePrice);
    this.messageRecommendationData = {...this.messageRecommendationData, messagePrice: this.messagePrice};
  }

  private _trackTemplateStringChange(): void {
    this.templateStringControl.valueChanges
      .pipe(distinctUntilChanged(), takeUntil(this.ngUnsubscribe$))
      .subscribe((templateString: string) => {
        this.templateString = templateString;
        this._updateTemplateTypeAndCounts(this.templateString, this.imageUrl);
        this._updateMessagePrice();
        this._updateMessageStrengthData();
        this._updateMessageParams({ ...this.params, text: templateString });
      });
  }

  private _updateMessageStrengthData() {
    if (this.isShowMessageStrength && this.messageSuggestionType === 'strength') {
      this.messageStrengthData = this._messageBuilderService.updateMessageStrength(
        this.templateString,
        this.imageUrl,
        this.shortCodeSubstitutes,
        this.shortCodes);
    }
  }

  private _trackMerchantInfoChange() {
    this.merchant$
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(merchantInfo => {
        this.merchantInfo = merchantInfo;
        this.shortCodeSubstitutes = this._getShortCodeSubstitutes(merchantInfo);
      });
  }

  private _preparePreviewMessage(): void {
    const messageDiscount = this.messageForm.get('discountView').value;
    const discountData = {
      code: this.messageForm.get('discountCode').value,
      link: this.messageForm.get('discountUrl').value,
      type: this.messageForm.get('discountType').value,
      value: messageDiscount ? JSON.parse(messageDiscount).value : '',
    };
    const substitutes = this._messageBuilderService.getFlowSubstitutes(
      this.triggerType, this.merchantInfo, discountData, this.substitutesOptions);
    this.message = this._helperService.substituteShortCodes(this.templateString, substitutes).trim();
  }

  private _getTemplateStringValidators(type: string, triggerType: TriggerType): ValidatorFn[] {
    return [ ...this._validatorService.getMessageValidators(type, triggerType) ];
  }

  private _initForm(data: MessageParams) {
    const validators = this._getTemplateStringValidators(SMS, this.triggerType);
    const controlsConfig = {
      text: ['', validators],
      image: [''],
      type: [data ? data.type : SMS],
    };

    this.messageForm = this.fb.group({
      ...controlsConfig,
      messageDiscount: [ data ?
        JSON.stringify({
          discountRedirect: data.discountRedirect || null,
          discountView: data.discountView || null,
          discountGid: data.discountGid || null,
          discountId: data.discountId || null,
          discountCode: data.discountCode || null,
          discountUrl: data.discountUrl || null,
          discountType: data.discountType || null,
          discountValue: data.discountValue || null,
          discountRegion: data.discountRegion || null,
        }) : '',
      ],
      discountRedirect: [data ? data.discountRedirect : ''],
      discountView: [data ? data.discountView : ''],
      discountGid: [data ? data.discountGid : ''],
      discountId: [data ? data.discountId : ''],
      discountCode: [data ? data.discountCode : ''],
      discountUrl: [data ? data.discountUrl : ''],
      discountType: [data ? data.discountType : ''],
      discountValue: [data ? data.discountValue : ''],
      discountRegion: [data ? data.discountRegion : ''],
    });

    if (data?.image) {
      this.isImageAttached = true;
      this._updateImage({ ...data.image });
    }

    const incomingView = this.messageParams.discountView;
    const discountTemplateType = incomingView ? JSON.parse(incomingView).uniqueness : 'Dynamic';
    this.isDiscountsBtnDisabled = !!incomingView;
    this.discountView = incomingView ? JSON.parse(incomingView) : { isExists: false };
    this.actualDiscountTypes = this._discountService.actualDiscountTypes;

    this.messageValidityUpdated.emit(this.templateStringControl.valid && this.templateStringControl.touched);

    if (discountTemplateType === 'Dynamic' && data.discountUrl) {
      this._discountService.storeDynamicDiscountData(
        data.discountType,
        +data.discountValue,
        data.discountUrl,
        data.discountCode,
        data.discountRegion,
      );
    }

    if (discountTemplateType === 'Static' && data.discountUrl) {
      this._discountService.storeStaticDiscountData(
        this.discountView.value,
        data.discountUrl,
        data.discountCode,
      );
    }

    if (!data.discountUrl) {
      this.store.dispatch(new ClearDiscountData());
    }

    this._discountService.storeDiscountTemplate(data);
  }

  private _clearDiscount() {
    const emptyDiscount: MessageParams = {
      ...this.params,
      discountRedirect: null,
      discountView: null,
      discountGid: null,
      discountId: null,
      discountCode: null,
      discountUrl: null,
      discountType: null,
      discountValue: null,
      discountRegion: null,
    };

    this.messageForm.get('messageDiscount').setValue('');
    this.messageForm.get('discountRedirect').setValue('');
    this.messageForm.get('discountView').setValue('');
    this.messageForm.get('discountGid').setValue('');
    this.messageForm.get('discountId').setValue('');
    this.messageForm.get('discountCode').setValue('');
    this.messageForm.get('discountUrl').setValue('');
    this.messageForm.get('discountType').setValue('');
    this.messageForm.get('discountValue').setValue('');
    this.messageForm.get('discountRegion').setValue('');
    this.store.dispatch(new ClearDiscountData());
    this.store.dispatch(new ClearDiscountTemplateData());
    this._updateMessageParams(emptyDiscount);
    this._discountService.discountAction$.next({ templateType: 'Dynamic', edit: false, remove: true });
  }

  private _updateImageFields(imageUrl: string, imageName: string, imageFile: File | CustomImage): void {
    this.imageUrl = imageUrl;
    this.imageName = imageName;
    this.messageForm.get('image').setValue(imageFile);
  }

  private _updateDiscountView(discounts: DiscountStateModel): void {
    const isPercent = discounts.type === 'dynamic' && discounts.value.includes('%');
    let reformattedValue = '';
    this.discountView.isExists = !!discounts.type;
    this.discountView.uniqueness = discounts.type === 'static'
      ? 'Static'
      : discounts.type === 'dynamic'
        ? 'Dynamic'
        : '';

    if (isPercent) {
      reformattedValue = parseFloat(discounts.value.replace(/[!@#$%^&*]/g, '')).toFixed(0).toString() + '%';
    }

    this.discountView.value = isPercent
      ? reformattedValue
      : discounts.value;

    this.discountView.code = discounts.type === 'static' ? discounts.code : '';
  }

  private _updateMessageParams(params: MessageParams): void {
    this.params = { ...this.params, ...params };
    this.params.text = params.text ? params.text.trim() : params.text;
    this.templateStringControl.markAsTouched();
    this.messageValidityUpdated.emit(this.templateStringControl.valid && this.templateStringControl.touched);
    this.changed.emit(this.params);
  }

  private _trackTypeControlChange(): void {
    this.messageForm.get('type').valueChanges
      .pipe(distinctUntilChanged(), takeUntil(this.ngUnsubscribe$))
      .subscribe((value) => {
        this._updateMessageParams({ ...this.params, type: value });
        if (this.templateString.length > 1600) {
          this.templateStringControl.setValue(this.templateString.slice(0, 1600));
        }
        this._updateValidators(value);
      });
  }

  private _updateValidators(msgType: string): void {
    this.templateStringControl.setValidators(this._getTemplateStringValidators(msgType, this.triggerType));
    this.templateStringControl.updateValueAndValidity();
  }

  private _updateTemplateString(text: string): void {
    this.templateStringControl.setValue(text);
  }

  private _getControlValue(name: string): any {
    return this.messageForm.get(name).value;
  }

  private _removeFromTemplateString(template, text): string {
    return template.split(text).join('');
  }

  private _insertDiscountShortCodes(): void {
    if (this.isDiscountCanBeAdded) {
      this._insertAtCursorPotion(this.cursorPosition, SHORT_CODES.DISCOUNT_CODE_URL);
    }
  }

  private _removeDiscountShortCodes(): void {
    let templateString: string = this.templateString;

    if (this.templateString && this.templateString.includes(SHORT_CODES.DISCOUNT_CODE_URL)) {
      templateString = this._removeFromTemplateString(templateString, SHORT_CODES.DISCOUNT_CODE_URL);
    }

    if (this.templateString && this.templateString.includes(SHORT_CODES.DISCOUNT_CODE)) {
      templateString = this._removeFromTemplateString(templateString, SHORT_CODES.DISCOUNT_CODE);
    }

    if (templateString !== this.templateString) {
      this._updateTemplateString(templateString);
    }
  }

  private _openDiscountDialog() {
    this.isDiscountsBtnDisabled = true;
    this._modal.getModal(this.discountModalId).open();
  }

  private _preformCd(): void {
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    this.store.dispatch(new ClearDiscountData());
    this.store.dispatch(new ClearDiscountTemplateData());
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
