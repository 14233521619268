import { TRIGGER_TYPES_DATA } from '@shared/texts';
import { POPUP_ERRORS }       from '@popups/texts';

export const TRIGGER_ID = 'selectedCardId';

export const TCPA_ERRORS = {
  required: POPUP_ERRORS.legalRequired,
  pattern: 'Invalid URL. Please try again',
  templateWhiteSpaceInvalid: 'Please, fill in this field',
};

export const FLOW_DEFAULT_TEMPLATE = {
  [TRIGGER_TYPES_DATA.SUBSCRIPTION_TRIGGERS_SUBSCRIPTION_VALUE]:
    {
      message: `Here is your Discount. You can use it to save 10% on anything in our store. We will automatically apply it for you at checkout! {DiscountCodeUrl}

Let us know if you have any Q's and happy shopping!`,
      discount: {
        type: 'DiscountCodeBasicPercentage',
        value: 10,
        region: '',
      },
    },
  [TRIGGER_TYPES_DATA.SUBSCRIPTION_TRIGGERS_NEW_CUSTOMER_REGISTRATION_VALUE]:
    {
      message: '{StoreName}: Hey {FirstName} thank you for joining the club ! You\'ve unlocked your 20% discount. Your code will be automatically added to the checkout: {DiscountCodeUrl} Good for all purchases on our site. Reply STOP to unsubscribe',
      discount: {
        type: 'DiscountCodeBasicPercentage',
        value: 20,
        region: '',
      },
    },
  [TRIGGER_TYPES_DATA.SUBSCRIPTION_TRIGGERS_EXISTING_SUBSCRIBER_VALUE]:
    {
      message: 'Hey {FirstName}! Good news, It looks like you\'re already subscribed to the {StoreName} text list. To say thanks for being on our list, here\'s a special 10% OFF Coupon! Shop now using your special discount code: {DiscountCodeUrl}',
      discount: {
        type: 'DiscountCodeBasicPercentage',
        value: 10,
        region: '',
      },
    },
  [TRIGGER_TYPES_DATA.CART_TRIGGERS_ABANDONED_CART_VALUE]:
    {
      message: 'Hey, {FirstName}! It\'s Laura from {StoreName} here. Those {StoreName}! products you were looking' +
        ' at... We\'re holding them for you. We regularly sell out so just wanted to give a heads up! They\'re' +
        ' waiting in your cart here: {AbandonedCheckoutUrl}. Thanks and shoot us a text back if you have any questions.',
      discount: {
        type: 'DiscountCodeBasicPercentage',
        value: 10,
        region: '',
      },
    },
  [TRIGGER_TYPES_DATA.PAID_TRIGGERS_POST_PURCHASE_VALUE]:
    {
      message: 'Hi {FirstName}, It\'s {StoreName} and we received your order! We wanted quickly say thanks for shopping with us and give you 10% OFF on your next order! You can click below to visit our store and your 10% discount will be auto-applied at checkout. {DiscountCodeUrl} Reply STOP to unsubscribe',
      discount: {
        type: 'DiscountCodeBasicPercentage',
        value: 10,
        region: '',
      },
    },
  [TRIGGER_TYPES_DATA.PAID_TRIGGERS_UPSELL_CROSS_SELL_VALUE]:
    {
      message: 'Hey! It\'s Laura from {StoreName}. I wanted to send one more quick thanks for ordering {BoughtProductName}. Your shipment is on the way! In the meantime I wanted to offer you a limited time coupon for 15% off {RecommendedProductName}! Click below to claim your discount! The coupon will be applied at checkout. {RecommendedProductUrl} Reply STOP to unsubscribe',
      discount: {
        type: 'DiscountCodeBasicPercentage',
        value: 15,
        region: '',
      },
    },
  [TRIGGER_TYPES_DATA.BIRTHDAY_TRIGGERS_BIRTHDAY_VALUE]:
    {
      message: 'HAPPY BIRTHDAY! Here at BOOM!, we\'re so excited to celebrate with you! Here\'s your gift from us: It\'s 15% off everything in our Boom store. Go here to shop for your birthday: {DiscountCodeUrl}, and we’ll automatically apply this special discount when you check out. Enjoy!',
      discount: {
        type: 'DiscountCodeBasicPercentage',
        value: 15,
        region: '',
      },
    },
  [TRIGGER_TYPES_DATA.SHIPPING_TRIGGERS_ORDER_DELIVERED_VALUE]:
    {
      message: 'Hey! It\'s {StoreName} here with 10% OFF Coupon for all our products! To claim this discount, use the' +
        ' coupon code {DiscountCode} when you checkout, and we\'ll take 10% off your entire order. But you have to' +
        ' hurry. This exclusive coupon is only valid for the next 48 hours only. Shop Our Store Here: {DiscountCodeUrl} Reply STOP to unsubscribe.',
      discount: {
        type: 'DiscountCodeBasicPercentage',
        value: 10,
        region: '',
      },
    },
  [TRIGGER_TYPES_DATA.RESTOCK_TRIGGERS_RESTOCK_VALUE]:
    {
      message: 'Great news! {RequestedProductName} is back in stock! Check out this Boom favorite in our store—before it sells out again: {RequestedProductUrl}',
      discount: {
        type: 'DiscountCodeBasicPercentage',
        value: 10,
        region: '',
      },
    },
};

export const FLOW_TRIGGER_CHANGES_POPUP = {
  TITLE: 'Are you sure?',
  DESCRIPTION: 'Are you sure you want to change the trigger? All changes will be lost.',
  CHECKBOX: 'Don’t ask me again',
  BUTTON_CHANGE: 'Change',
};

export const FLOW_CANCELLATION_TRIGGERS_DATA = {
  NONE_RADIO_TITLE: 'None',
  NONE_RADIO_DESCRIPTION: `This option will not remove subscribers from this flow under any condition.`,

  PURCHASE_RADIO_TITLE: 'Subscriber completed a purchase.',
  PURCHASE_RADIO_DESCRIPTION: 'Subscriber will be removed from this flow after they\'ve completed a purchase.',

  CHECKBOX_TITLE: 'Send this flow once per subscriber',
  CHECKBOX_DESCRIPTION: `This setting prevents subscribers from seeing this flow more than once.`,

  CHECKOUT_RADIO_TITLE: 'Contact begins the Checkout process',
  CHECKOUT_RADIO_DESCRIPTION: `Choose this option to have the contact removed from this flow when they start a Checkout on your store, but don't complete their order. If you have the "Abandoned Cart" flow setup and enabled, they will begin that one instead.`,

  ABANDONED_CART_PURCHASE_RADIO_DESCRIPTION: `After order paid and the client made a purchase abandoned cart stop` +
   ` existing and messages will not include a link with it. To prevent sending messages without offer and` +
   ` stop abandoned cart flow before post-purchase we made this rule embedded.`,

  ORDER_PAID_PURCHASE_RADIO_DESCRIPTION: `All your clients who agreed to receive marketing messages in checkout` +
   ` and those who have already subscribed will have this message series. Stop it after the finished order` +
   ` by offer from the messages.`,

  ORDER_PAID_CHECKOUT_RADIO_DESCRIPTION: `Choose this option to have the contact removed from this flow when they start a Checkout on your store, but don't complete their order. If you have the "Abandoned Cart" flow setup and enabled, they will begin that one instead.`,

  UP_SELL_PURCHASE_RADIO_TITLE: `Subscriber made a purchase`,
  UP_SELL_PURCHASE_RADIO_DESCRIPTION: `This flow can be stopped by successful purchase and it` +
   ` means message series will be sending even if abandoned cart started with new checkout.`,

  UP_SELL_CHECKOUT_RADIO_DESCRIPTION: `Choose this option to have the contact removed from this flow when they start a Checkout on your store, but don't complete their order. If you have the "Abandoned Cart" flow setup and enabled, they will begin that one instead.`,

  ORDER_DELIVERED_PURCHASE_RADIO_DESCRIPTION: `The current message series will stop after the client` +
   ` successfully paid new order with an offer from this flow.`,

  ORDER_DELIVERED_CHECKOUT_RADIO_DESCRIPTION: `Choose this option to have the contact removed from this flow when they start a Checkout on your store, but don't complete their order. If you have the "Abandoned Cart" flow setup and enabled, they will begin that one instead.`,
};

export const FLOW_TRIGGERS_TYPE = {
  TRIGGER: 'Trigger',
  CONDITION: 'Condition',
  SHOPIFY_DATA_SPLIT: 'ShopifyDataSplit',
  TRIGGERED_EVENT_SPLIT: 'TriggeredEventSplit',
  KINNEKT_DATA_SPLIT: 'KinnektDataSplit',
  TIME_DELAY: 'Delay',
  MAIL: 'Message',
  TCPA: 'TCPA',
  AGENT: 'Agent',
  CANCELLATION: 'Cancellation',
};

export const FLOW_TEMPLATE_LIBRARY_TABS_DATA = {
  TEMPLATE_SUBSCRIPTION_TITLE: 'Subscription',
  TEMPLATE_CART_TITLE: 'Cart',
  TEMPLATE_PAID_TITLE: 'Completed Order',
  TEMPLATE_SHIPPING_TITLE: 'Shipping',
};

export const flowTemplateLibraryTabsData =
  [
    {
      id: 'Subscription',
      icon: 'sms',
      name: FLOW_TEMPLATE_LIBRARY_TABS_DATA.TEMPLATE_SUBSCRIPTION_TITLE,
      color: 'white',
      size: 32,
    },
    {
      id: 'Cart',
      icon: 'basket',
      name: FLOW_TEMPLATE_LIBRARY_TABS_DATA.TEMPLATE_CART_TITLE,
      color: 'white',
      size: 32,
    },
    {
      id: 'Paid',
      icon: 'paid',
      name: FLOW_TEMPLATE_LIBRARY_TABS_DATA.TEMPLATE_PAID_TITLE,
      color: 'white',
      size: 32,
    },
    {
      id: 'Shipping',
      icon: 'shipping',
      name: FLOW_TEMPLATE_LIBRARY_TABS_DATA.TEMPLATE_SHIPPING_TITLE,
      color: 'white',
      size: 32,
    },
  ];
