import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
}                         from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
}                         from '@angular/forms';
import {
  ActivatedRoute,
  Router,
}                         from '@angular/router';

import { Select, Store }       from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { first, takeUntil }    from 'rxjs/operators';
import { TriggerType }         from '@flows/models/trigger-type';
import {
  KeywordPlaceholders,
  MerchantInfo,
} from '@core/models';
import {
  MessageParams,
  SubscriptionFlowItem,
  SubscriptionFlowItemResponseDiscount,
  SubscriptionFlowItemResponseImage,
  SubscriptionEditorUIParameters,
} from '@shared/models';
import { FLOW_HINTS }           from '@popups/texts';
import { NgxSmartModalService } from 'ngx-smart-modal';
import {
  FormService,
  HelperService,
}                                                  from '@core/services';
import { ValidatorService }                        from '@shared/service/validator.service';
import { ImageService }                            from '@shared/service/image.service';
import { MessageBuilderService }                   from '@shared/service/message-builder';
import { SHAREABLE_LINK_NAME, TRIGGER_TYPES_DATA } from '@shared/texts';
import { FlowSubscriptionService }                 from '@shared/service';
import { SHAREABLE_LINKS_CUSTOM_ERRORS }           from '@shareable-links/texts';
import { RedirectData }                            from '@shared/models/redirectData.model';

@Component({
  selector: 'k-subscription-view-editor',
  templateUrl: './subscription-view-editor.component.html',
  styleUrls: ['./subscription-view-editor.component.scss'],
})
export class SubscriptionViewEditorComponent implements OnInit, OnDestroy {
  @Select(state => state.merchant) merchant$: Observable<MerchantInfo>;

  @Input() placeholderType: TriggerType;
  @Input() loading: boolean;
  @Input() defaultTemplateString: string;
  @Input() subscriptionEditorForm: FormGroup;
  @Input() subscriptionEditorUIParameters: SubscriptionEditorUIParameters;
  @Input() subscriptionViewParams;

  @Output() setSelectedFlow: EventEmitter<any> = new EventEmitter<any>();
  @Output() saveAndQuit: EventEmitter<string> = new EventEmitter<string>();
  @Output() quit: EventEmitter<void> = new EventEmitter<void>();
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() messageValidityUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() messageInitialized: EventEmitter<void> = new EventEmitter<void>();
  @Output() flowRedirect: EventEmitter<number> = new EventEmitter<number>();

  public triggerType: TriggerType = 'Subscription';
  public placeholders: KeywordPlaceholders;
  public messageEditor: FormGroup = new FormBuilder().group({
    templateType: 'sms',
    templateString: '',
  });
  public flows = [];
  public isFlowValid: boolean = true;
  public hasError: boolean = false;
  public popupFlow: SubscriptionFlowItem;
  public selectedFlowInfoMessage: string;
  public selectedFlowInfoImage: SubscriptionFlowItemResponseImage;
  public selectedFlowInfoDiscountView: SubscriptionFlowItemResponseDiscount;
  public messageParams: MessageParams = {type: 'sms', text: ''};
  public message: string;
  public popupHints = FLOW_HINTS;
  public shareableLinkType: TriggerType = SHAREABLE_LINK_NAME;
  public isCreatePage: boolean = false;
  public createModalId: string = 'createModalID';
  public saveModalId: string = 'saveModalID';
  public triggerTypes = TRIGGER_TYPES_DATA;
  public selectedInactiveFlowId: number;
  public redirectData: RedirectData;

  public get parsedMessage()  {
    return this.message;
  }

  public get termsAndConditionValidationError(): string {
    const termsAndConditionLink = this.subscriptionEditorForm.get('termsAndConditionLink');
    if (!termsAndConditionLink.errors || termsAndConditionLink.untouched) return '';
    const termsAndConditionLinkError = this._formService.getError(
      this.subscriptionEditorForm,
      'termsAndConditionLink',
      {
        required: SHAREABLE_LINKS_CUSTOM_ERRORS.required,
        minlength: SHAREABLE_LINKS_CUSTOM_ERRORS.invalidUrl,
        maxlength: SHAREABLE_LINKS_CUSTOM_ERRORS.invalidUrl,
        pattern: SHAREABLE_LINKS_CUSTOM_ERRORS.invalidUrl,
      },
    );
    if (termsAndConditionLinkError) return termsAndConditionLinkError;
  }

  private merchantInfo: MerchantInfo;
  private shortCodeSubstitutes = {};
  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(private fb: FormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private store: Store,
              private modal: NgxSmartModalService,
              private changeDetector: ChangeDetectorRef,
              private _formService: FormService,
              private _imageService: ImageService,
              private _messageBuilderService: MessageBuilderService,
              private _helperService: HelperService,
              private _validatorService: ValidatorService,
              private _flowSubscriptionService: FlowSubscriptionService,
  ) { }

  ngOnInit() {
    this.isCreatePage = this.route.snapshot.data.createPage;

    this._initForm();
    this._loadFlows();

    if (this.subscriptionViewParams) {
      this._fillForm(this.subscriptionViewParams);
    }

    this._listenFlowChanges();
    this._trackMerchantInfoChange();
  }

  public redirectToFlow() {
    if (this.route.snapshot.params.id) {
      this._flowSubscriptionService.redirectToFlow(this.route.snapshot.params.id, this.selectedInactiveFlowId, this.placeholderType);
    } else {
      // Suppose, it is shareable link wizard
      this.flowRedirect.emit(this.selectedInactiveFlowId);
    }
  }

  public get flowInfo(): SubscriptionFlowItem {
    return this.popupFlow;
  }

  public get displayDiscountValue() {
    return `${
      this.selectedFlowInfoDiscountView.type === ('Static' || 'static')
        ? this.selectedFlowInfoDiscountView?.type + ' | ' + this.selectedFlowInfoDiscountView?.code + ' (' + this.selectedFlowInfoDiscountView?.value + ')'
        : this.selectedFlowInfoDiscountView?.type + ' | ' + this.selectedFlowInfoDiscountView?.value
    }`;
  }

  public flowPopupAction(action: string): void {
    this.modal.getModal(this[action + 'ModalId']).open();
  }

  public onModalAction(modalId: string): void {
    this.saveAndQuit.emit(modalId);
    this.modal.getModal(this[modalId + 'ModalId']).close();
  }

  public cancelCreateDialog(event): void {
    this.modal.getModal(this.createModalId).close();
    if (event.isCloseIcon) return;

    this.quit.emit();
    this.router.navigate(['/flows/create', { 'triggerType': this.triggerTypes.SUBSCRIPTION_TRIGGERS_SUBSCRIPTION_VALUE }]);
  }

  public cancelSaveDialog(): void {
    this.modal.getModal(this.saveModalId).close();
    this.cancel.emit();
  }

  public imageLoaded(data): void {
    this.selectedFlowInfoImage.size = this._imageService.formatBytes(data.size);
  }

  public onFlowCreate(popupId: string) {
    this.flowPopupAction(popupId);
  }

  public onMessageChange(params: MessageParams) {
    this.messageEditor.get('templateString').setValue(params.text);
  }

  public onMessageValidityUpdated($event: boolean) :void {
    this.messageValidityUpdated.emit($event);
  }

  public getFormError(field): string {
    return this._formService.getError(this.subscriptionEditorForm, field);
  }

  public onMessageInitialized(): void {
    this.messageInitialized.emit();
  }

  private buildResponseStructure(data: SubscriptionFlowItem): void {
    this.popupFlow = data;
    this.selectedFlowInfoMessage = data?.response?.text;
    if (data?.response?.image) {
      this.selectedFlowInfoImage = {
        url: data.response.image.url,
        name: data.response.image.name,
        size: null,
      };
    } else {
      this.selectedFlowInfoImage = null;
    }
    this._formatFlowInfoDiscountView(data?.response);
  }

  private _loadFlows() {
    this._flowSubscriptionService.getFlows()
      .pipe(first())
      .subscribe((data) => {
        const selectedFlowId = this.subscriptionEditorForm.get('flowId').value;
        this.flows = this._concatenateFlowsName(data);
        if (selectedFlowId && this.flows.length) {
          this._setSubscriptionFlow(selectedFlowId);
          this._checkFlowStatus(selectedFlowId);
        }
      });
  }

  private _formatFlowInfoDiscountView(data) {
    const parseData = data?.discountView ? { ...data, 'discountView': JSON.parse(data.discountView) }: '';
    parseData ?
      this.selectedFlowInfoDiscountView = {
        code: parseData?.discountCode,
        link: parseData?.discountUrl,
        type: parseData?.discountView.uniqueness,
        value: parseData?.discountView.value,
      }
      : this.selectedFlowInfoDiscountView = null;
  }

  private _initForm() {
    const storeDomain = this.store.selectSnapshot(state => state.merchant.primaryDomain);
    const termsAndConditionsUrl = `${storeDomain}/policies/terms-of-service`;

    if (!this.subscriptionEditorForm.get('flowId')) {
      this.subscriptionEditorForm.addControl('flowId', new FormControl());
    }

    if (!this.subscriptionEditorForm.get('flowPayload')) {
      this.subscriptionEditorForm.addControl('flowPayload', new FormControl());
    }

    if (this.placeholderType === this.shareableLinkType && !this.subscriptionEditorForm.get('termsAndConditionLink')) {
      this.subscriptionEditorForm.addControl(
        'termsAndConditionLink',
        new FormControl(termsAndConditionsUrl, [
          Validators.required,
          Validators.minLength(11),
          ...this._validatorService.urlValidators(),
        ]));
    }

    this.messageParams.text = this.defaultTemplateString;
    this.messageEditor = this.fb.group({
      templateType: ['sms', Validators.required],
      templateString: ['', Validators.required],
    });
    this.messageEditor.get('templateString').patchValue(this.defaultTemplateString || '');

    if (!this.subscriptionEditorForm.get('messageEditor')) {
      this.subscriptionEditorForm.addControl('messageEditor', this.messageEditor);
    } else {
      this.subscriptionEditorForm.setControl('messageEditor', this.messageEditor);
    }
  }

  private _fillForm(data) {
    if (!data.flowId && !this.isCreatePage) this.hasError = true;

    if (data.templateString) {
      this.messageParams.text = data.templateString;
    }

    this.subscriptionEditorForm.patchValue({
      flowId: data.flowId || false,
      flowPayload: data.flowPayload || false,
      messageEditor: {
        templateType: 'sms',
        templateString: data.templateString,
      },
    });

    if (this.placeholderType === this.shareableLinkType) {
      this.subscriptionEditorForm.get('termsAndConditionLink').setValue(data.termsAndConditionLink);
    }

    this._validateNotEmptyFields();
  }

  private _validateNotEmptyFields(): void {
    Object.keys(this.subscriptionEditorForm.controls).forEach(key => {
      const control = this.subscriptionEditorForm.controls[key];
      const controlValue = control.value;

      if (controlValue?.length) {
        this.subscriptionEditorForm.get(key).markAsTouched();
      }
    });
  }

  private _listenFlowChanges() {
    this.subscriptionEditorForm.get('flowId').valueChanges
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(value => {
        this._setSubscriptionFlow(value);
        this._checkFlowStatus(value);
      });
  }

  private _setSubscriptionFlow(value?: string): void {
    const flowId = value || this.subscriptionEditorForm.get('flowId').value;
    const index = this.flows.findIndex(flow => {
      return flow.id === +flowId;
    });
    this.isFlowValid = this.flows[index]?.valid;

    if (flowId) this.hasError = false;

    this.setSelectedFlow.emit({ flow: this.flows[index], valid: this.isFlowValid });
    this.buildResponseStructure(this.flows[index]);

    if (!this.flows[index] && !this.isCreatePage) this.hasError = true;

    if (this.flows[index]) this._preparePreviewMessage(
      this.flows[index],
      this.flows[index]?.response?.text || '',
      this.selectedFlowInfoDiscountView);
  }

  private _checkFlowStatus(id: number): void {
    this.selectedInactiveFlowId = null;
    const selected: SubscriptionFlowItem = this.flows.find((item: SubscriptionFlowItem) => item.id === +id);
    if (selected && !selected.status) {
      this.selectedInactiveFlowId = id;
      this.hasError = true;
    }
  }

  private _preparePreviewMessage(flow, message, discountData): void {
    const triggerOptions = { 'channel': { name: flow?.keywordName } };
    const substitutes = this._messageBuilderService.getFlowSubstitutes(
      this.triggerType, this.merchantInfo, discountData, triggerOptions);
    this.message = this._helperService.substituteShortCodes(message, substitutes);
  }

  private _trackMerchantInfoChange() {
    this.merchant$
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(merchantInfo => {
        this.merchantInfo = merchantInfo;
        this.shortCodeSubstitutes = this._getShortCodeSubstitutes(merchantInfo);
      });
  }

  private _getShortCodeSubstitutes(merchantInfo) {
    return { '{StoreName}': merchantInfo.shop.name };
  }

  private _concatenateFlowsName(flows): SubscriptionFlowItem[] {
    return flows.map((flow: SubscriptionFlowItem) => {
      flow.name = `${flow.name} (${flow.keywordName}) ${flow.status ? '' : '(Unpublished)'}`;
      return flow;
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
