import { Injectable } from '@angular/core';

import { Store }                  from '@ngxs/store';
import { Observable, throwError } from 'rxjs';
import { catchError, tap }        from 'rxjs/operators';
import {
  SetKeywordOriginal,
  UpdateKeywordListItem,
} from '@store/actions';

import { ApiService }              from '@core/services';
import {
  KeywordItem,
  KeywordsListItem,
}                                  from '@keywords/models';

@Injectable()
export class KeywordService {
  constructor(private _apiService: ApiService, private store: Store) { }

  public getKeyword(id: number): Observable<KeywordItem> {
    return this._apiService.get(`keywords/${id}`);
  }

  public addKeyword(keywordName: any): Observable<any> {
    return this._apiService.post('keywords', keywordName)
      .pipe(
        catchError(error => {
          return throwError(error);
        }),
      );
  }

  public updateKeyword(index, keyword: KeywordItem): Observable<KeywordsListItem> {
    return this._apiService.put(`keywords/${keyword.id}`, this._prepareFormData(keyword))
      .pipe(
        tap((data) => {
          this.store.dispatch(new SetKeywordOriginal(data));
          this.store.dispatch(new UpdateKeywordListItem(index, 'name', keyword.name));
        }),
      );
  }

  private _prepareFormData(keyword: KeywordItem, excludedKeys?: any[]) {
    const data = {
      ...keyword,
    };

    const { ...payload } = data;

    const formData: any = {};

    for (const field in payload) {
      if (payload.hasOwnProperty(field)) {
        if (excludedKeys && excludedKeys.includes(field)) continue;

        if (typeof payload[field] === 'string') payload[field] = payload[field].trim();

        formData[field] = payload[field];
      }
    }

    return formData;
  }


}
