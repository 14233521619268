import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup }                                    from '@angular/forms';
import { NgxSmartModalService }                                      from 'ngx-smart-modal';
import { Subject }                                                   from 'rxjs';

import { ApiService, FormService }     from '@core/services';
import { ValidatorService }            from '@shared/service/validator.service';
import { KeywordService }              from '@shared/service/keyword.service';
import { takeUntil }                   from 'rxjs/operators';
import { KeywordsListItem }            from '@keywords/models';
import { KEYWORD_VALIDATION_ERRORS }   from '@keywords/texts';

@Component({
  selector: 'k-update-keyword-dialog',
  templateUrl: './update-keyword-dialog.component.html',
  styleUrls: ['./update-keyword-dialog.component.scss'],
})
export class UpdateKeywordDialogComponent implements OnInit, OnDestroy {
  @Input() identifier: string;
  @Input() reset: boolean;
  @Input() set index(value: number) {
    if (typeof value === 'number') {
      this.keywordIndex = value;
    }
  }
  @Input() set keyword(data: KeywordsListItem) {
    this.keywordData = data;
    this._serControlValue();
  }

  @Output() keywordUpdate: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();

  public keywordForm: FormGroup;
  public loading: boolean = false;
  public disabled: boolean = true;
  public keywordData: KeywordsListItem;
  public keywordIndex: number;

  public get keywordNameValidationError(): string {
    const nameControl = this.keywordForm.get('keywordName');

    if (!nameControl.errors || nameControl.untouched) return '';

    if (nameControl.errors.maxlength || nameControl.errors.pattern) {
      return KEYWORD_VALIDATION_ERRORS.TO_MANY_SYMBOLS_ERROR;
    }

    const nameError = this._formService.getError(this.keywordForm, 'keywordName');
    if (nameError) return nameError;
    if (nameControl.errors.hasSpecialKeywordError) {
      return KEYWORD_VALIDATION_ERRORS.OPT_OUT_SPECIAL_KEYWORDS_ERROR;
    }

    if (nameControl.value !== this.keywordData.name && nameControl.errors.alreadyExists) {
      return KEYWORD_VALIDATION_ERRORS.ALREADY_EXISTS_ERROR;
    }
  }

  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private modal: NgxSmartModalService,
    private fb: FormBuilder,
    private _apiService: ApiService,
    private _keywordService: KeywordService,
    private _formService: FormService,
    private _validatorService: ValidatorService,
  ) { }

  ngOnInit(): void {
    this._initForm();
    this._listenStatusChanges();
  }

  public onClosed(): void {
    this._close();
  }

  public onCancel(): void {
    this.keywordForm.reset();
    this.cancel.emit();
    this._close();
  }

  public onOpen() {
    this._serControlValue();
  }

  public onUpdate(): void {
    if (this.keywordForm.valid) {
      this.loading = true;
      const name = this.keywordForm.get('keywordName').value;
      this._keywordService.updateKeyword(this.keywordIndex, { 'id': this.keywordData.id, 'name': name })
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(({ id }) => {
          this.keywordUpdate.emit({ id, name });
          this._close();
          this.keywordForm.reset();
          this.loading = false;
        }, () => {
          this.loading = false;
        });
    }
  }

  private _serControlValue(): void {
    if (this.keywordData) {
      this.keywordForm.get('keywordName').setAsyncValidators(
        this._validatorService.alreadyExistsValidator(
          { apiService: this._apiService, method: 'post', path: 'keyword-exists' }, this.keywordData.name),
      );

      this.keywordForm.get('keywordName').setValue(this.keywordData.name);
      this.keywordForm.markAllAsTouched();
    }
  }

  private _initForm() {
    this.keywordForm = this.fb.group({
      keywordName: ['', this._validatorService.keywordNameValidators()],
    });
  }

  private _listenStatusChanges(): void {
    this.keywordForm.statusChanges
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((status) => {
        this.disabled = status !== 'VALID';
      });
  }

  private _close(): void {
    this.loading = false;
    this.modal.getModal(this.identifier).close();
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
