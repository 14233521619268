import { Injectable }     from '@angular/core';
import { customAlphabet } from 'nanoid';
import noLookALikes       from 'nanoid-dictionary/nolookalikes';

@Injectable()
export class NanoService {
  private readonly _generateNanoId = customAlphabet(noLookALikes, 10);

  public generateNanoId(): string {
    return this._generateNanoId();
  }
}
