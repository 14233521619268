import { NotificationData } from '@notifications/models';

export class AddCurrentlyShownNotification {
  static readonly type = '[NotiService] AddCurrentlyShownNotification';

  constructor(public payload: NotificationData) { }
}

export class RemoveCurrentlyShownNotification {
  static readonly type = '[NotiService] RemoveCurrentlyShownNotification';

  constructor(public triggerId: number) { }
}

export class AddShownAlerts {
  static readonly type = '[NotiService] AddShownAlerts';

  constructor(public payload: NotificationData[]) { }
}

export class RemoveShownAlert {
  static readonly type = '[NotiService] RemoveShownAlert';

  constructor(public triggerId: number, public id?: number) { }
}

export class HideAlert {
  static readonly type = '[NotiService] HideAlert';

  constructor(public triggerId: number, public id?: number) { }
}

export class UnhideAlert {
  static readonly type = '[NotiService] UnhideAlert';

  constructor(public triggerId: number, public id?: number) { }
}

export class AddRemainingAlerts {
  static readonly type = '[NotiService] AddRemainingAlerts';

  constructor(public payload: NotificationData[]) { }
}

export class RemoveRemainingAlert {
  static readonly type = '[NotiService] RemoveRemainingAlert';

  constructor(public triggerId: number) { }
}

export class MoveLastShownAlertsToRemaining {
  static readonly type = '[NotiService] MoveShownAlertsToRemaining';

  constructor(public count: number) { }
}

export class SetShownNotifications {
  static readonly type = '[NotiService] SetShownNotifications';

  constructor(public payload: NotificationData[]) { }
}

export class SetRemainingNotifications {
  static readonly type = '[NotiService] SetRemainingNotifications';

  constructor(public payload: NotificationData[]) { }
}
