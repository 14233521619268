import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators }        from '@angular/forms';
import { ResourcePicker }                                         from '@shopify/app-bridge/actions';
import { UpdateUpsellParamsData }                                 from '@store/actions';
import { Subject, Observable, Subscription }                      from 'rxjs';
import { Store }                                                  from '@ngxs/store';

import { ShopifyService }             from '@core/services';
import { ValidatorService }           from '@shared/service/validator.service';
import { UpsellService }              from '@shared/service/upsell/upsell.service';
import { UpsellItemValidatorService } from '@shared/service/upsell/upsell-item-validator.service';
import { ComponentName }              from '@shared/components/base/upsell/upsell.model';

@Component({
  selector: 'k-upsell',
  templateUrl: './upsell.component.html',
  styleUrls: ['./upsell.component.scss'],
})
export class UpsellComponent implements OnInit, OnDestroy {
  @Input() set data(data) {
    const items = JSON.parse(JSON.stringify(data));
    if (data.triggerProducts) this.flowUpsellItems.triggerProducts = items.triggerProducts;
    if (data.triggerCollections) this.flowUpsellItems.triggerCollections = items.triggerCollections;
    if (data.recommendedProducts) this.flowUpsellItems.recommendedProducts = items.recommendedProducts;

    this.flowUpsellItems.excludePurchasedProducts = items.excludePurchasedProducts || false;
  }

  @Input() componentName: ComponentName;
  @Input() triggerOptions: FormGroup;
  @Input() flowEdit: boolean;

  @Input() upsellParameters: FormControl;
  @Input() upsellParametersType: string;
  @Input() events: Observable<string>;

  public flowUpsellItems = {
    triggerProducts: [],
    triggerCollections: [],
    recommendedProducts: [],
    excludePurchasedProducts: false,
  };
  public ruleUpsellItems = { products: [], collections: [] };
  public triggerProductError: boolean = false;
  public triggerCollectionError: boolean = false;
  public recommendedProductError: boolean = false;
  public isUpsellErrors: boolean = false;

  public get upsellProductsCollectionsCreateError(): boolean {
    return !this.flowEdit
      && (this.triggerProductError || this.triggerCollectionError);
  }

  public get upsellProductsCollectionsEditError(): boolean {
    return this.flowEdit
      && (
        (
          !this.flowUpsellItems.triggerProducts.length || this.triggerProductError
        ) && (
          !this.flowUpsellItems.triggerCollections.length || this.triggerCollectionError
        )
      );
  }

  public get upsellRecommendedProductsCreateError(): boolean {
    return this.flowUpsellItems.recommendedProducts.length && !this.flowEdit && this.recommendedProductError;
  }

  public get upsellRecommendedProductsEditError(): boolean {
    return this.flowEdit && (!this.flowUpsellItems.recommendedProducts.length || this.recommendedProductError);
  }

  private eventsSubscription: Subscription;
  private picker: ResourcePicker.ResourcePicker;
  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private cd: ChangeDetectorRef,
    private _shopifyService: ShopifyService,
    private _upsellService: UpsellService,
    private _upsellItemValidatorService: UpsellItemValidatorService,
    private _validatorService: ValidatorService,
  ) { }

  ngOnInit(): void {
    this._initRuleUpsell();
    this._initOrSetFlowUpsellControls();
    if (this.flowEdit) {
      this._checkFlowUpsellErrors(this.flowUpsellItems);
    }
  }

  public selectProductsPurchased(): void {
    this.picker = this._shopifyService.productPicker.dispatch(ResourcePicker.Action.OPEN);
    this.picker.subscribe(ResourcePicker.Action.SELECT, (data) => {

      if (this.isFlowComponent()) {
        this._selectItems(data.selection, 'flowUpsellItems', 'triggerProducts');
      }

      if (this.isRuleComponent()) {
        this._selectItems(data.selection, 'ruleUpsellItems', 'products');
      }

      this.picker.unsubscribe();
    });

    this._unsubscribeOnCancel();
  }

  public selectCollectionsPurchased(): void {
    this.picker = this._shopifyService.collectionPicker.dispatch(ResourcePicker.Action.OPEN);
    this.picker.subscribe(ResourcePicker.Action.SELECT, (data) => {
      this._upsellService.getCollectionProducts(data.selection)
        .subscribe(products => {
          if (this.isFlowComponent()) {
            this._selectItems(data.selection, 'flowUpsellItems', 'triggerCollections', products);
          }

          if (this.isRuleComponent()) {
            this._selectItems(data.selection, 'ruleUpsellItems', 'collections', products);
          }
        });

      this.picker.unsubscribe();
    });

    this._unsubscribeOnCancel();
  }

  public selectRecommendsProduct(): void {
    this.picker = this._shopifyService.productPicker.dispatch(ResourcePicker.Action.OPEN);
    this.picker.subscribe(ResourcePicker.Action.SELECT, (data) => {
      this._selectItems(data.selection, 'flowUpsellItems', 'recommendedProducts');
      this.picker.unsubscribe();
    });

    this._unsubscribeOnCancel();
  }

  public deleteFlowUpsellItem(index: number, type: string): void {
    this.flowUpsellItems[type].splice(index, 1);
    this._setFlowUpsellItems(this.flowUpsellItems);
    this._updateFlowUpsellStoreDate(type, this.flowUpsellItems[type]);
    this._checkFlowUpsellErrors(this.flowUpsellItems);
  }

  public deleteRuleUpsellItem(index: number, type: string): void {
    this.ruleUpsellItems[type].splice(index, 1);
    this._setRuleUpsellItems(type);
  }

  public changeFlowUpsellOption(optionName: string, value: any): void {
    this.flowUpsellItems[optionName] = value;
    this._setFlowUpsellItems(this.flowUpsellItems);
    this._updateFlowUpsellStoreDate(optionName, value);
  }

  public changeRuleUpsellOption(optionName: string, value: any): void {
    this.ruleUpsellItems[optionName] = value;
    this._setRuleUpsellItems(optionName);
  }

  public trackByFn(index): number {
    return index;
  }

  private isFlowComponent(): boolean {
    return this.componentName === 'flow';
  }

  private isRuleComponent(): boolean {
    return this.componentName === 'rule';
  }

  private _initRuleUpsell(): void {
    if (this.isRuleComponent()) {
      if (this.upsellParameters?.value?.length) {
        this._fillForm();
      }

      this.eventsSubscription = this.events.subscribe((type) => {
        type === 'products' ? this.selectProductsPurchased() : this.selectCollectionsPurchased();
      });
    }
  }

  private _initOrSetFlowUpsellControls(): void {
    if (this.isFlowComponent()) {
      if (!this.flowUpsellItems.recommendedProducts.length) {
        this.triggerOptions.addControl(
          'triggerProducts',
          this.fb.control('', this._validatorService.upsellValidator(this.flowUpsellItems)));
        this.triggerOptions.addControl(
          'triggerCollections',
          this.fb.control('', this._validatorService.upsellValidator(this.flowUpsellItems)));
        this.triggerOptions.addControl('recommendedProducts', this.fb.control('', Validators.required));
        this.triggerOptions.addControl('excludePurchasedProducts', this.fb.control(''));
      } else {
        this.triggerOptions.addControl(
          'triggerProducts',
          this.fb.control(this.flowUpsellItems.triggerProducts, this._validatorService.upsellValidator(this.flowUpsellItems)),
        );
        this.triggerOptions.addControl(
          'triggerCollections',
          this.fb.control(this.flowUpsellItems.triggerCollections, this._validatorService.upsellValidator(this.flowUpsellItems)),
        );
        this.triggerOptions.addControl(
          'recommendedProducts',
          this.fb.control(this.flowUpsellItems.recommendedProducts, Validators.required),
        );
        this.triggerOptions.addControl(
          'excludePurchasedProducts', this.fb.control(this.flowUpsellItems.excludePurchasedProducts),
        );
      }
    }
  }

  private _fillForm(): void {
    this.ruleUpsellItems[this.upsellParametersType] = [ ...this.upsellParameters.value ];
  }

  private _unsubscribeOnCancel(): void {
    this.picker.subscribe(ResourcePicker.Action.CANCEL, () => {
      this.picker.unsubscribe();
    });
  }

  private _selectItems(selectedItems, arrayName: string, type: string, collectionsProducts?: any): void {
    const items = this._upsellService.buildItemsArray(selectedItems, type, this.componentName);

    this[arrayName][type].push(...items);

    const uniqueItemsIds = [...new Set(this[arrayName][type].map(item => item.id))];

    this[arrayName][type] = uniqueItemsIds.map(id => {
      return this[arrayName][type].find(item => item.id === id);
    });

    if (type === 'triggerCollections' || type === 'collections') {
      const collectionsIds = this[arrayName][type].map(item => item.id);

      collectionsIds.forEach(collectionId => {
        const collectionIndex = this[arrayName][type].findIndex(item => item.id === collectionId);

        if (collectionsProducts[collectionId]) {
          this[arrayName][type][collectionIndex].includesProducts = collectionsProducts[collectionId];
        }

        if (!this[arrayName][type][collectionIndex].includesProducts) {
          this[arrayName][type][collectionIndex].includesProducts = [];
        }
      });
    }

    if (this.isFlowComponent()) {
      this._updateFlowUpsellStoreDate(type, this[arrayName][type]);
      this._setFlowUpsellItems(this[arrayName]);
    }

    if (this.isRuleComponent()) {
      this._setRuleUpsellItems(type);
    }
  }

  private _setFlowUpsellItems(items) {
    // this.flowUpsellItems = items;

    this.triggerOptions.setValue(items);
    this.triggerOptions.markAsTouched();
    this._checkFlowUpsellErrors(items);
  }

  private _setRuleUpsellItems(type: string) {
    this.upsellParameters.patchValue([...this.ruleUpsellItems[type]]);
    this.upsellParameters.markAsTouched();
  }

  private _checkFlowUpsellErrors(item): void {
    this._checkAndValidityUpsells(item);

    const { triggerProducts, triggerCollections, recommendedProducts } = item;
    this.triggerProductError = this.triggerProductError
      || this._upsellItemValidatorService.triggerProductsError(triggerProducts, true);
    this.triggerCollectionError = this.triggerCollectionError
      || this._upsellItemValidatorService.triggerCollectionsError(triggerCollections, true);
    this.recommendedProductError = this.recommendedProductError
      || this._upsellItemValidatorService.recommendedProductsError(recommendedProducts, true);

    this._checkAlertState();
  }

  private _checkAlertState(): void {
    if (!this.triggerProductError && !this.triggerCollectionError && !this.recommendedProductError) {
      this._upsellService.alert.next();
    }
  }

  private _checkAndValidityUpsells(triggerOptions): void {
    const errors = this._upsellItemValidatorService.checkAndValidity(triggerOptions);
    if (errors.isRecommendedProductsError != null) {
      this.recommendedProductError = errors.isRecommendedProductsError;
    }
    if (errors.isTriggerCollectionsError != null) {
      this.triggerCollectionError = errors.isTriggerCollectionsError;
    }
    if (errors.isTriggerProductsError != null) {
      this.triggerProductError = errors.isTriggerProductsError;
    }
  }

  private _updateFlowUpsellStoreDate(type: string, data: [] | boolean) {
    if (this.flowEdit) {
      this.store.dispatch(new UpdateUpsellParamsData({
        key: type,
        value: data,
      }));
    }
  }

  private _preformCd(): void {
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    if (this.isFlowComponent()) {
      this.triggerOptions.removeControl('triggerProducts');
      this.triggerOptions.removeControl('triggerCollections');
      this.triggerOptions.removeControl('recommendedProducts');
      this.triggerOptions.removeControl('excludePurchasedProducts');
    }
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

}
