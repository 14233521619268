import { Component }  from '@angular/core';
import { CdkStepper } from '@angular/cdk/stepper';

// https://material.angular.io/cdk/stepper/overview
// https://indepth.dev/posts/1284/building-a-custom-stepper-using-angular-cdk
@Component({
  selector: 'k-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  providers: [ {  provide: CdkStepper, useExisting: StepperComponent } ],
})
export class StepperComponent extends CdkStepper {}
