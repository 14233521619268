export class Discounts {
  discounts: Discount[];
}

export class Discount {
  data: DiscountInfo;
  endsAt: Date | null;
  gid: string;
  isShopify: boolean;
  startsAt: Date;
  status: string;
  summary: string;
  title: string;
  type: string;
}

export class FreeShipping {
  regions: string[];
}

export class DiscountInfo {
  oncePerCustomer: boolean;
  used: number;
  usageLimit: number;
}

export interface DiscountTypeConst {
  PERCENTAGE: DiscountTypeNames;
  FIXED_AMOUNT: DiscountTypeNames;
  FREE_SHIPPING: DiscountTypeNames;
}

export type DiscountTypeNames = 'Fixed amount' | 'Free Shipping' | 'Percentage';

export class DiscountView {
  isExists: boolean;
  uniqueness?: string;
  value?: string;
  code?: string;
}

export type DiscountDynamicLinkType = 'DiscountCodeBasicAmount' | 'DiscountCodeBasicPercentage' | 'DiscountCodeBxgy'
  | 'DiscountCodeFreeShipping';

export interface DiscountDynamicLink {
  code: string;
  discountId: number;
  link: string;
  region: string;
  type: DiscountDynamicLinkType;
  value: number;
}
