import { Component, OnInit } from '@angular/core';

import { UnsavedChangesService } from '@features/services/unsaved-changes.service';

@Component({
  selector: 'k-unsaved-changes-dialog',
  templateUrl: './unsaved-changes-dialog.component.html',
  styleUrls: ['./unsaved-changes-dialog.component.scss'],
})
export class UnsavedChangesDialogComponent implements OnInit {
  public unsavedChangesDialogModalId: string;

  constructor(private _unsavedChangesService: UnsavedChangesService) { }

  ngOnInit(): void {
    this.unsavedChangesDialogModalId = this._unsavedChangesService.getModalId();
  }

  public closeWithoutSaving(): void {
    this._unsavedChangesService.closeWithoutSaving();
  }
  public cancelCloseWithoutSaving(): void {
    this._unsavedChangesService.cancelCloseWithoutSaving();
  }

}
