import { Component, OnDestroy, OnInit }       from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Select, Store }                      from '@ngxs/store';
import { NgxSmartModalService }               from 'ngx-smart-modal';
import { Observable, Subject }                from 'rxjs';
import { first, takeUntil }                   from 'rxjs/operators';

import { SAVED_SUCCESSFULLY }              from '@core/texts';
import { MerchantInfo }                    from '@core/models';
import { FormService, ProgressBarService } from '@core/services';
import { SettingsService }                 from '@settings/services';
import { PaymentType }                     from '@features/models';
import { UpdateMerchantInfo }              from '@store/actions';
import { PaymentService }                  from '@features/services';
import { NotiService }                     from '@notifications/services';

@Component({
  selector: 'k-settings-payments',
  templateUrl: './settings-payments.component.html',
  styleUrls: ['./settings-payments.component.scss'],
})
export class SettingsPaymentsComponent implements OnInit, OnDestroy {
  @Select(state => state.merchant) merchant$: Observable<MerchantInfo>;

  public paymentsData: any;
  public settingsForm: FormGroup;
  public changedField: string = '';
  public loading: boolean = true;

  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(private fb: FormBuilder,
              private modal: NgxSmartModalService,
              private store: Store,
              private _notiService: NotiService,
              private _formService: FormService,
              private _paymentService: PaymentService,
              private _settingsService: SettingsService,
              private _progressBarService: ProgressBarService) { }

  ngOnInit() {
    this._progressBarService.initLoading();
    this._initForm();
  }

  public getFormControlError(field: string): string {
    return this._formService.getError(this.settingsForm, field);
  }

  public onChange(field: string, value?: any): void {
    if (field === 'autoRechargeActive') {
      this._trackAutoRechargeActive(value);
    }
    this._saveData(field);
  }

  public onFocusOut(field): void {
    this._saveData(field);
  }

  public onShowPaymentDialog(type: PaymentType, title: string): void {
    this.modal.setModalData({
      paymentType: type,
      modalTitle: title,
      showToggleButtons: false,
    }, 'paymentDialog', true);

    this.modal.getModal('paymentDialog').open();
  }

  private _trackAutoRechargeActive(value: boolean) {
    if (this.paymentsData.autoRechargeUsed) {
      this._paymentService.toggleAutoRecharge(value)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(() => {
          this._notiService.success(SAVED_SUCCESSFULLY);
        });
    } else {
      this.onShowPaymentDialog('autoRecharge', 'Enable Auto-recharge');
    }

    this.store.dispatch(new UpdateMerchantInfo({ key: 'autoRechargeActive', value }));
  }

  private _initForm(): void {
    this.settingsForm = this.fb.group({
      autoRechargeActive: [false],
      lowBalanceNotificationActive: [false],
      lowBalanceNotificationActiveThreshold: [10,
        [
          Validators.required,
          Validators.min(0),
          Validators.max(10000),
          Validators.pattern(/^[0-9.]+$/),
        ],
      ],
    });

    this._fillForm();
  }

  private _fillForm(): void {
    this._settingsService.getSettings('payments')
      .pipe(first())
      .subscribe(
        (data) => {
          this.paymentsData = data;

          this.settingsForm.setValue({
            autoRechargeActive: data.autoRechargeActive,
            lowBalanceNotificationActive: data.lowBalanceNotificationActive,
            lowBalanceNotificationActiveThreshold: data.lowBalanceNotificationActiveThreshold,
          });

          this._progressBarService.finishLoading();
          this.loading = false;
        },
        () => {
          this._progressBarService.finishLoading();
          this.loading = false;
        },
      );
  }

  private _saveData(field): void {
    const fieldControl = this.settingsForm.get(field);
    const fieldValue = fieldControl.value;
    if (this.settingsForm.invalid || fieldControl.pristine) return;
    if (field === 'autoRechargeActive' && fieldValue) return;

    this._settingsService
      .updateSettings('payments', { [field]: this._prepareValue(field, fieldValue) })
      .pipe(first())
      .subscribe(() => {
        fieldControl.markAsPristine();
        this._notiService.success(SAVED_SUCCESSFULLY);
      });
  }

  private _prepareValue(field: string, value: string): any {
    switch (field) {
      case 'lowBalanceNotificationActiveThreshold':
        return +value;
      default:
        return value;
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
