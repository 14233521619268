import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { NotifierType } from '@shared/models';

@Component({
  selector: 'k-notifier',
  templateUrl: './notifier.component.html',
  styleUrls: ['./notifier.component.scss'],
})
export class NotifierComponent implements OnInit {
  @Input() isCloseBtnHidden: boolean = false;
  @Input() type: NotifierType = 'success';

  @Output() closed: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }

  public onClose(): void {
    this.closed.emit();
  }

}
