import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

@Injectable()
export class ProgressBarService {

  private initLoadingSubject: Subject<void> = new Subject<void>();
  private finishLoadingSubject: Subject<void> = new Subject<void>();

  public initLoadingObservable$ = this.initLoadingSubject.asObservable();
  public finishLoadingObservable$ = this.finishLoadingSubject.asObservable();

  public initLoading(): void {
    this.initLoadingSubject.next();
  }

  public finishLoading(): void {
    this.finishLoadingSubject.next();
  }
}
