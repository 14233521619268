import { AppRoutes }         from '@core/models/routes';
import { DiscountTypeConst } from '@core/models';

export const URL_REG_EXP = /^http(s?):\/\/\S*$/;

export const DISCOUNT_TYPE: DiscountTypeConst = {
  PERCENTAGE: 'Percentage',
  FIXED_AMOUNT: 'Fixed amount',
  FREE_SHIPPING: 'Free Shipping',
};

export const CREATED_SUCCESSFULLY = 'Successfully created!';
export const SAVED_SUCCESSFULLY = 'Successfully saved!';
export const FLOW_PUBLISHED = 'Flow published!';
export const FLOW_PUBLISHED_SUCCESSFULLY = 'Flow successfully published!';
export const FLOW_UNPUBLISHED = 'Flow unpublished';
export const POPUP_PUBLISHED_SUCCESSFULLY = 'Mobile Popup published!';
export const POPUP_UNPUBLISHED_SUCCESSFULLY = 'Mobile Popup unpublished';
export const SHAREABLE_LINK_PUBLISHED_SUCCESSFULLY = 'Shareable Link successfully published & Copied to Clipboard!';
export const SHAREABLE_LINK_UNPUBLISHED_SUCCESSFULLY = 'Shareable Link unpublished';
export const SENT_SUCCESSFULLY = 'Successfully sent!';
export const LEAVE_TEXT = 'Do you want to discard the changes?';
export const TCPA_NOTIFICATION_TITLE = 'Flow updated';
export const TCPA_NOTIFICATION_MESSAGE = 'We\'ve updated Flow structure based on the legal requirements. Please, review the Message block the next after TCPA message';

export const ROUTES: AppRoutes = {
  FLOWS: 'flows',
  KEYWORDS: 'keywords',
  POPUPS: 'popups',
  RESPONSES: 'inbox',
  CHECKOUT: 'checkout',
  LEGAL_AGREEMENTS: 'legal',
  SEGMENTS: 'segments',
  CAMPAIGNS: 'campaigns',
  SHAREABLE_LINKS: 'shareable-links',
};

export const LEGAL_ROUTS = {
  INSTALL: 0,
  TERMS: 1,
  PROVISIONING_IN_PROGRESS: 2,
  PROVISIONING_DONE: 3,
  DONE: 4,
};
