import { ApiService }                   from '@core/services/api.service';
import { FontService }                  from '@core/services/font.service';
import { RouterService }                from '@core/services/router.service';
import { ShopifyService }               from '@core/services/shopify.service';
import { HelperService }                from '@core/services/helper.service';
import { MerchantService }              from '@core/services/merchant.service';
import { FormService }                  from '@core/services/form.service';
import { DiscountService }              from '@core/services/discount.service';
import { SocketService }                from '@core/services/socket.service';
import { TimeService }                  from '@core/services/time.service';
import { CurrencyService }              from '@core/services/currency.service';
import { ProgressBarService }           from '@core/services/progress-bar.service';
import { ShortCodePlaceholdersService } from '@core/services/short-code-placeholders.service';
import { TitleBarService }              from '@core/services/title-bar.service';
import { QuietHoursService }            from '@core/services/quiet-hours.service';
import { NonGsmSymbolsService }         from '@core/services/non-gsm-symbols.service';
import { NanoService }                  from '@core/services/nano.service';
import { SentryService }                from '@core/services/sentry.service';


export {
  ApiService,
  FontService,
  RouterService,
  ShopifyService,
  HelperService,
  MerchantService,
  FormService,
  DiscountService,
  SocketService,
  TimeService,
  CurrencyService,
  ProgressBarService,
  TitleBarService,
  ShortCodePlaceholdersService,
  QuietHoursService,
  SentryService,
  NonGsmSymbolsService,
  NanoService,
};

export const services = [
  ApiService,
  FontService,
  RouterService,
  ShopifyService,
  HelperService,
  MerchantService,
  FormService,
  DiscountService,
  SocketService,
  TimeService,
  CurrencyService,
  ProgressBarService,
  ShortCodePlaceholdersService,
  TitleBarService,
  QuietHoursService,
  SentryService,
  NonGsmSymbolsService,
  NanoService,
];
