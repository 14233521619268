import { Directive, HostListener } from '@angular/core';
import { NgControl }               from '@angular/forms';

import { MessagePreviewService } from '@shared/service';

@Directive({
  selector: '[formControl][kPhoneMask],[formControlName][kPhoneMask]',
})
export class PhoneMaskDirective {

  constructor(
    public ngControl: NgControl,
    private _messagePreviewService: MessagePreviewService,
  ) { }

  @HostListener('input', ['$event'])
  input(event) {
    this.onInputChange(event.target.value, false);
  }

  @HostListener('keydown.backspace', ['$event'])
  keydownBackspace(event) {
    this.onInputChange(event.target.value, true);
  }

  @HostListener('paste', ['$event'])
  paste(event) {
    this.onInputChange(event.target.value, true);
  }

  onInputChange(event, backspace) {
    this.ngControl.valueAccessor.writeValue(this._messagePreviewService.setPhoneMask(event, backspace));
  }
}
