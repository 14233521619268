import {
  AfterViewInit, ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { Subject } from 'rxjs';

import { ShortCodesData, ShortCodeStyles, ShortCodeStylesObj } from '@shared/components/message-builder/short-codes/short-codes.model';
import { MessageBuilderService }                               from '@shared/service/message-builder';
import { REPLY_STOP_TO_UNSUBSCRIBE, STOP }                     from '@shared/data-model/short-codes.model';


@Component({
  selector: 'k-short-codes',
  templateUrl: './short-codes.component.html',
  styleUrls: ['./short-codes.component.scss'],
})
export class ShortCodesComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() isTCPARequired: boolean;
  @Input() placeholders: ShortCodesData;
  @Input() disabledShorCodes: string[] = [];
  @Input() set templateString(data: string) {
    const shortCodeStyles = this._shortCodesService.getInitialCodeStyles(this.placeholders, this.disabledShorCodes);
    this._updateShortCodesChanges(data, shortCodeStyles);
  }

  @Output() selected: EventEmitter<any> = new EventEmitter<any>();

  public shortCodeStyles: ShortCodeStyles[] = [];
  public shortCodeStylesObj: ShortCodeStylesObj = {};
  public loading: boolean = false;
  public stopShortCode: string = STOP;
  public isContentVisible: boolean = true;
  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(private _shortCodesService: MessageBuilderService,
              private elementRef: ElementRef,
              private renderer: Renderer2,
              private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.renderer.addClass(this.elementRef.nativeElement, 'k-short-code');
  }

  ngAfterViewInit(): void {
    const el = this.elementRef.nativeElement;
    let height = el.offsetHeight;
    if (el.offsetHeight > 316) {
      height = 316;
      this.renderer.addClass(el, 'k-scroll--active');
    }
    this.renderer.setStyle(el, 'height', `${height}px`);
    this.isContentVisible = false;
    this._preformCd();
    setTimeout(() => {
      this.isContentVisible = true;
    });
  }

  public onShortCodeClick(shortCode): void {
    const sc = shortCode === REPLY_STOP_TO_UNSUBSCRIBE ? this.stopShortCode : shortCode;
    const isActive = this._shortCodesService.isShortCodeActive(this.shortCodeStylesObj, sc);
    if (isActive) {
      this.selected.emit();
    } else {
      this.selected.emit(shortCode);
    }
  }

  public isDisabled(name: string): boolean {
    return this.disabledShorCodes.includes(name);
  }

  private _updateShortCodesChanges(templateString, shortCodeStyles): void {
    this.shortCodeStyles = this._shortCodesService.updateShortCodeStyles(templateString, shortCodeStyles);
    this.shortCodeStylesObj = this._shortCodesService.getShortCodeStylesObj(this.shortCodeStyles);
  }

  private _preformCd(): void {
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
