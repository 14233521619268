import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR }            from '@angular/forms';

import { CustomControlValueAccessor } from '../custom-control-value-accessor';

@Component({
  selector: 'k-switcher',
  templateUrl: './switcher.component.html',
  styleUrls: ['./switcher.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SwitcherComponent),
      multi: true,
    },
  ],
})
export class SwitcherComponent extends CustomControlValueAccessor {
  @Input() disabled: boolean = false;

  constructor() {
    super();
  }

  public onSwitch(): void {
    this.value = !this.value;
  }
}
