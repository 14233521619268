import { Injectable }          from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { ApiService } from '@core/services';
import { UpsellItem}  from '@shared/models';

@Injectable()
export class UpsellService {

  public alert: Subject<string> = new Subject<string>();

  constructor(private _apiService: ApiService) { }

  public getCollectionProducts(selectedColletions: any[]): Observable<any> {
    const collectionsIds = selectedColletions.map(colletion => colletion.id);

    return this._apiService.get(`collections/products?collection=${collectionsIds.join(',')}`);
  }

  public buildItemsArray(selectedItems: any[], type: string, componentName: string): UpsellItem[] {
    let typeName = '';

    if (componentName === 'flow') {
        typeName = 'triggerCollections';
    }

    if (componentName === 'rule') {
      typeName = 'collections';
    }

    return selectedItems.reduce((acc, item) => {
      const image = item.image
        ? item.image.originalSrc
        : (item.images && item.images.length) ? item.images[0].originalSrc : '';

      return [
        ...acc,
        type !== typeName
          ? this._buildProductItem(item, image)
          : this._buildCollectionItem(item, image),
      ];
    }, []);
  }

  private _buildProductItem(item, image): UpsellItem {
    return {
      id: item.id,
      title: item.title,
      imageSrc: image,
      tracksInventory: item.tracksInventory,
      inventoryPolicy: this._checkInventoryPolicy(item.variants) || 'DENY',
      totalInventory: item.totalInventory,
      published: true,
      deleted: false,
    };
  }

  private _checkInventoryPolicy(variants): string {
    const continueVariantExists = variants.some(variant => {
      return variant.inventoryPolicy === 'CONTINUE';
    });

    return continueVariantExists ? 'CONTINUE' : 'DENY';
  }

  private _buildCollectionItem(item, image): UpsellItem {
    return {
      id: item.id,
      title: item.title,
      imageSrc: image,
      isEmpty: !item.productsCount,
      published: true,
      deleted: false,
    };
  }
}
