import { Component, Input, OnInit } from '@angular/core';
import { FormGroup }                from '@angular/forms';

@Component({
  selector: 'k-text-styles',
  templateUrl: './text-styles.component.html',
  styleUrls: ['./text-styles.component.scss'],
})
export class TextStylesComponent implements OnInit {
  @Input() styles: FormGroup;
  @Input() disabled: boolean = false;

  public textStyles = ['bold', 'italic', 'underline'];

  constructor() { }

  ngOnInit() { }

  public toggleStyle(style) {
    this.styles.patchValue({ [style]: !this.styles.get(style).value });
  }
}
