export type DiscountType = 'static' | 'dynamic';

export interface DiscountStateModel {
  code: string;
  link: string;
  type: DiscountType;
  value: string;
}

export interface DiscountTemplateModel {
  discountRedirect: string;
  discountView: string;
  discountGid: string;
  discountId: number;
  discountCode: string;
  discountUrl: string;
  discountType: string;
  discountValue: number;
  discountRegion: string;
}
