import { Injectable } from '@angular/core';
import { Store }      from '@ngxs/store';
import { Observable } from 'rxjs';
import { map, tap }   from 'rxjs/operators';

import { ApiService }       from '@core/services';
import { BaseTableService } from '@shared/models/base-table';

import {
  DeleteCampaignListItem,
  SetCampaignsData,
  SetCampaignSegmentsList,
  SetCampaignsList,
  StopCampaignListItem,
  UpdateCampaignStatuses,
} from '@store/actions';
import { CampaignListItem, CampaignSegmentItem, CampaignsStatistic } from '@campaigns/models';
import { PER_PAGE }                                                  from '@config';
import { TableColumnSort }                                           from '@shared/models';

import { CAMPAIGN_TYPES } from '@campaigns/texts';

@Injectable()
export class CampaignListService implements BaseTableService {

  constructor(private _apiService: ApiService,
              private store: Store) { }

  public getCampaignStatistic(): Observable<CampaignsStatistic> {
    return this._apiService.get(`campaigns/statistics`);
  }

  public getList(page: number = 1,
                 perPage: number = PER_PAGE,
                 field: string = 'name',
                 sort: TableColumnSort = 'ASC'): Observable<{ items: CampaignListItem[]; totalCount: number }> {
    const skip: number = 0;
    const limit: number = page * perPage;
    const params = { skip, limit, order: field, sort };

    return this._apiService.get('campaigns', params)
      .pipe(
        map((campaignList: { items: CampaignListItem[]; totalCount: number }) => {
          const metaData = { totalCount: campaignList.totalCount || 0 };

          const date = new Date();
          campaignList.items.forEach((campaign) => {
            if (campaign.sendAt === null && campaign.type === CAMPAIGN_TYPES.IN_PROGRESS) {
              campaign.sendAt = date;
            }
            campaign.class = campaign.type === CAMPAIGN_TYPES.IN_PROGRESS ? 'k-table--row__highlight' : '';
          });
          this.store.dispatch(new SetCampaignsData(metaData));
          this.store.dispatch(new SetCampaignsList(campaignList.items));

          return campaignList;
        }),
      );
  }

  public stopCampaign(campaign: CampaignListItem): Observable<any> {
    return this._apiService.put(`campaigns/${campaign.id}/stop`, {}).pipe(
      tap((result) => {
        if (result.success) {
          this.store.dispatch(new StopCampaignListItem(campaign.index));
        }
      }),
    );
  }

  public deleteItem(campaign: CampaignListItem): any {
    return this._apiService.delete(`campaigns/${campaign.id}`).pipe(
      tap(() => {
        this.store.dispatch(new DeleteCampaignListItem(campaign.id));
      }),
    );
  }

  public toggleCampaign(id: number, status: boolean): Observable<any> {
    return this._apiService.put(`campaigns/${id}/update-status`, {status}).pipe(
      tap(() => {
        this.store.dispatch(new UpdateCampaignStatuses([{id, status}]));
      }),
    );
  }
}
