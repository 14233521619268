import {
  Component,
  AfterViewInit,
  ElementRef,
  forwardRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  Renderer2,
}                            from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { CustomControlValueAccessor } from '../custom-control-value-accessor';

import { SelectorSize} from './checkbox.model';

@Component({
  selector: 'k-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})
export class CheckboxComponent extends CustomControlValueAccessor implements AfterViewInit, OnChanges {
  @Input() indeterminate: boolean = false;
  @Input() oneLine: boolean = false;
  @Input() checkboxDisabled: boolean = false;
  @Input() big: boolean = false;
  @Input() selectorSize: SelectorSize;
  @Input() checkboxIndicatorMargin: SelectorSize = 'big';
  @Input() wrapperClasses: string[];

  @ViewChild('checkbox') checkbox: ElementRef;
  @ViewChild('label') label: ElementRef;

  constructor(private renderer: Renderer2) {
    super();
  }

  ngAfterViewInit() {
    this._changeIndeterminateState();

    if (this.wrapperClasses?.length) {
      this.wrapperClasses.map(wrapperClass => {
        this.renderer.addClass(this.label.nativeElement, wrapperClass);
      });
    } else {
      this.renderer.addClass(this.label.nativeElement, 'k-flex--start');
    }

    if (this.selectorSize) {
      this.renderer.addClass(this.label.nativeElement, `k-checkbox__label--size-${this.selectorSize}`);
      this.renderer.addClass(this.label.nativeElement, `k-checkbox__label--wrapper-space-${this.checkboxIndicatorMargin}`);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.indeterminate && this.checkbox) {
      this._changeIndeterminateState();
    }
  }

  public onSelect(): void {
    this.value = !this.value;
  }

  private _changeIndeterminateState(): void {
    this.checkbox.nativeElement.indeterminate = this.indeterminate;
  }
}
