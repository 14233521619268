import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule }                           from '@angular/platform-browser';
import { BrowserAnimationsModule }                 from '@angular/platform-browser/animations';
import { Router }                                  from '@angular/router';
import { NgxEmojiPickerModule }                    from 'ngx-emoji-moc';

import { StoreModule }           from '@store/store.module';
import { CoreModule }            from '@core/core.module';
import { FontService }           from '@core/services';
import { NotificationsModule }   from '@notifications/notifications.module';
import { FeaturesModule }        from '@features/features.module';
import { SettingsModule }        from '@settings/settings.module';
import { DashboardModule }       from '@dashboard/dashboard.module';
import * as Sentry               from '@sentry/angular';
import { LegalAgreementsModule } from './legal-agreements/legal-agreements.module';
import { ExamplesModule }        from './examples/examples.module';
import { AppRoutingModule }      from './app-routing.module';

import { AppComponent } from './app.component';

export function initializeApp(_fontsService: FontService) {
  return () => {
    _fontsService.loadFonts();
  };
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    StoreModule,
    CoreModule,
    NotificationsModule,
    ExamplesModule,
    DashboardModule,
    LegalAgreementsModule,
    FeaturesModule,
    SettingsModule,
    NgxEmojiPickerModule.forRoot(),
    AppRoutingModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [FontService, Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
