export const POPUP_ERRORS = {
  nameMaxlength: 'This Popup contains too many letters, spaces or invalid symbols',
  nameAlreadyExists: `A Popup with the same name has already exists. Please, try another one`,
  legalRequired: 'Please, fill in this field',
  invalidUrl: 'Invalid URL. Please try again',
};

export const LEGAL_LANGUAGE_TEXT = `By clicking the button, you are agreeing to receive promotional and personalized marketing text messages from {Store Name} periodically, at the cell number you used to contact us. You are also consenting to our Terms and Conditions & Privacy Policy. Consent is not a condition of any purchase. Reply STOP to cancel. Msg and data rates may apply.`;

export const DISPLAY_TYPE_TEXTS = {
  all: {
    helper: '',
    title: '',
  },
  include: {
    helper: `Choose which pages will show the Mobile Popup.`,
    title: 'Show the Popup on:',
  },
  exclude: {
    helper: 'This mobile popup will trigger on every page of your site except the URLS you select.',
    title: `Don't show the Popup on:`,
  },
};

export const POPUP_DEFAULTS = {
  delay: 5,
  delayActive: true,
  pagesDisplayType: 'all',
  templateString: 'Send this text to receive your {KeywordName} coupon and subscribe to automated recurring personalized marketing alerts from {StoreName}',
  headline: 'Get 10% OFF?',
  subHeadline: 'Text us to get a limited time 10% OFF coupon code.',
  buttonText: 'Get My 10% Coupon',
  popupSize: 'partial',
  anchorTo: 'bottom',
  fontFamily: `'Lato', sans-serif`,
  legalLanguageText: LEGAL_LANGUAGE_TEXT,
  headlineStyle: {
    color: '#000',
    size: '32',
    style: {
      bold: true,
      italic: false,
      underline: false,
    },
  },
  subHeadlineStyle: {
    color: '#000',
    size: '16',
    style: {
      bold: false,
      italic: false,
      underline: false,
    },
  },
  mainTextButtonStyle: {
    color: '#fff',
    size: '18',
    style: {
      bold: true,
      italic: false,
      underline: false,
    },
  },
  legalLanguageStyle: {
    color: '#999999',
    size: '8',
    style: {
      bold: false,
      italic: false,
      underline: false,
    },
  },
  buttonColor: '#000',
  backgroundColor: '#eee',
  showMinButton: true,
  minButtonText: '10% OFF',
  minButtonBackgroundColor: '#000',
  minButtonStyles: {
    color: '#fff',
    size: '18',
    style: {
      bold: true,
      italic: false,
      underline: false,
    },
  },
};

export const FLOW_HINTS = {
  SHAREABLE_LINKS_FLOW_HINT: 'Connect the flow that will be connected with this Shareable Link. Based on the Keyword connected to the Flow, a welcome message will be sent after the subscription.',
  MOBILE_POPUPS_FLOW_HINT: 'Connect the flow that will be connected with this Mobile Popup. Based on the Keyword connected to the Flow, a welcome message will be sent after the subscription.',
  RESPONSE: 'This message will be generated on your client’s phone by click popup button for the subscription.',
  NO_FLOW: 'Choose a Flow above to unlock this section.',
  NO_MESSAGE: 'There is no message in the chosen flow',
};
