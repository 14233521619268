export const SHAREABLE_LINKS_DEFAULTS = {
  templateString: 'Send this text to receive your {KeywordName} coupon and subscribe to automated recurring personalized marketing alerts from {StoreName} and agree to our Terms and Conditions {Link} even if your number is on a \'do not call\' list. Consent is not a condition of any purchase',
};

export const SHAREABLE_LINKS_CUSTOM_ERRORS = {
  required: 'This field is required.',
  templateWhiteSpaceInvalid: 'This field can not be empty',
  htmlTagsInvalid: 'HTML Tags are not allowed',
  pattern: 'This field is empty or has an invalid value. Please update and try again.',
  alreadyExists: 'A Shareable Link with this name already exists. Please use a unique name',
  invalidUrl: 'Invalid URL. Please try again',
};
