import { Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { HttpBackend, HttpClient }          from '@angular/common/http';
import { Subject }                          from 'rxjs';
import { delay, retryWhen, takeUntil, tap } from 'rxjs/operators';

@Directive({
  selector: 'img[kRetrySrc]',
})
export class RetrySrcDirective {
  private httpWithoutInterceptor: HttpClient;
  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  @Input()
  set kRetrySrc(value: string) {
    this.httpWithoutInterceptor.get(`${value}?tk=${Date.now()}`, { responseType: 'blob' })
      .pipe(
        tap((response) => {
          this.loaded.emit(response);
        }),
        retryWhen(errors =>
          errors.pipe(
            delay(500),
            tap(response => {
              if (!response.ok) {
                this._setSrc('/assets/images/loading-image.jpg');
              }
            }),
          ),
        ),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe(() => {
        this._setSrc(value);
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
      });
  }
  @Output() loaded: EventEmitter<any> = new EventEmitter<any>();

  constructor(private httpBackend: HttpBackend, private element: ElementRef<HTMLImageElement>) {
    this.httpWithoutInterceptor = new HttpClient(httpBackend);
  }

  private _setSrc(value) {
    this.element.nativeElement.src = value;
  }
}
