import {
  AfterViewInit,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {  Subject } from 'rxjs';

import { Table, TableColumnSort, TableSortData } from '@shared/models';
import { TableCellTemplateDirective }            from '@shared/directives/table-cell-template.directive';

@Component({
  selector: 'k-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TableComponent implements OnInit, AfterViewInit, OnDestroy {
  @ContentChildren(TableCellTemplateDirective) cellTemplates: QueryList<TableCellTemplateDirective>;

  @Input() layout: 'auto' | 'fixed' = 'fixed';
  @Input() columns: Table[];
  @Input() rows: any[];
  @Input() placeholder: string;
  @Input() borderRadius: number = 0;
  @Input() analyticsShowMoreButton: string;
  @Input() isTableShowMoreButtonShown: boolean = false;
  @Input() activeSorting: TableSortData;

  @Output() updateTable: EventEmitter<void> = new EventEmitter<void>();
  @Output() updateSorting: EventEmitter<TableSortData> = new EventEmitter<TableSortData>();

  @ViewChild('table') table: ElementRef;
  @ViewChild('tableHeadCell') tableHeadCell: ElementRef;
  public isTableScrolled: boolean = false;
  public ngUnsubscribe$: Subject<void> = new Subject<void>();
  public sortState: {[key: string]: { sort: TableColumnSort, state: boolean }} = {};

  private initStickyHeaderPosition: number;

  constructor(private el?: ElementRef, private renderer?: Renderer2) { }

  ngOnInit() {
    this.updateSortingInitState(this.columns);
  }

  ngAfterViewInit() {
    if (this.borderRadius) this.renderer.setStyle(this.table.nativeElement, 'border-radius', `${this.borderRadius}px`);
  }

  @HostListener('window:scroll')
  onScroll() {
    if (!this.tableHeadCell) return;
    if (!this.initStickyHeaderPosition) {
      this.initStickyHeaderPosition = this.tableHeadCell.nativeElement.offsetTop;
    }
    this.isTableScrolled = this.initStickyHeaderPosition < this.tableHeadCell.nativeElement.offsetTop;
  }

  public onUpdateTableListClick(): void {
    this.updateTable.emit();
  }

  public onSort(field: string, sort: TableColumnSort) {
    if (sort != null) {
      this.clearSortingState(this.columns, field);

      let sortValue = sort;
      if (this.sortState[field]?.state) {
        sortValue = sort === 'ASC' ? 'DESC' : 'ASC';
      }
      this.updateSortingState(field, sortValue, true);

      this.updateSorting.emit({ sort: sortValue, field });
    }
  }

  private clearSortingState(columns: Table[], excludeField?: string): void {
    columns.forEach((c: Table) => {
      if (c.value !== excludeField) {
        this.updateSortingState(c.value, c.sort, false);
      }
    });
  }

  private updateSortingInitState(columns: Table[]): void {
    columns.forEach((c: Table) => {
      if (c.value === this.activeSorting.field) {
        this.updateSortingState(this.activeSorting.field, this.activeSorting.sort, true);
      } else {
        this.updateSortingState(c.value, c.sort, false);
      }
    });
  }

  private updateSortingState(field: string, sort: TableColumnSort, state: boolean): void {
    this.sortState[field] = { sort, state };
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
