import {
  Component,
  AfterViewInit,
  ChangeDetectionStrategy,
  ElementRef,
  Input,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

import { IconColor } from './icon.model';

@Component({
  selector: 'k-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent implements AfterViewInit {
  @Input() name: string;
  @Input() size: number;
  @Input() width: number;
  @Input() height: number;
  @Input() fill: boolean;
  @Input() color: IconColor = 'gray';

  @ViewChild('icon') icon: ElementRef;

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngAfterViewInit() {
    this.renderer.addClass(this.el.nativeElement, `k-icon__wrap`);

    if (this.fill === true) {
      this.renderer.addClass(this.el.nativeElement, `k-icon__fill`);
    }

    if (this.size) {
      this.renderer.setStyle(this.icon.nativeElement, 'width', `${this.size}px`);
      this.renderer.setStyle(this.icon.nativeElement, 'height', `${this.size}px`);
    }

    if (this.width) this.renderer.setStyle(this.icon.nativeElement, 'width', `${this.width}px`);
    if (this.height) this.renderer.setStyle(this.icon.nativeElement, 'height', `${this.height}px`);
  }
}
