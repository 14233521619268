import { AfterViewInit, Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';

import { ButtonType } from '@shared/components/base/back-button/back-button.model';

@Component({
  selector: 'k-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss'],
})
export class BackButtonComponent implements OnInit, AfterViewInit {
  @Input() path: string;
  @Input() type: ButtonType = 'normal';
  @Input() loading: boolean = false;

  @ViewChild('backButton', {read: ElementRef}) backButton: ElementRef;

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngOnInit() { }

  ngAfterViewInit() {
    this.renderer.addClass(this.backButton.nativeElement, `k-back-btn--${this.type}`);
  }
}
