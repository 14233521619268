export * from './store-legal';

export const USA_STATES = [
  'Alabama',
  'Alaska',
  'American Samoa',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'District Of Columbia',
  'Federated States Of Micronesia',
  'Florida',
  'Georgia',
  'Guam',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Marshall Islands',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Northern Mariana Islands',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Palau',
  'Pennsylvania',
  'Puerto Rico',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virgin Islands',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
  'Armed Forces Americas',
  'Armed Forces Europe',
  'Armed Forces Pacific',
  ];

export const CANADA_PROVINCES = [
    'Alberta',
    'British Columbia',
    'Manitoba',
    'New Brunswick',
    'Newfoundland and Labrador',
    'Northwest Territories',
    'Nova Scotia',
    'Nunavut',
    'Ontario',
    'Prince Edward Island',
    'Quebec',
    'Saskatchewan',
    'Yukon',
  ];

export const INSTALL_PAGE = {
  MAIN_TITLE: 'Welcome to Kinnekt!',
  MAIN_TITLE_DESCRIPTION: `Please review the following information pulled from your Shopify account.
  <br />This information will be used to establish legal agreements <br /> between you and the new mobile service provider.`,
  MESSAGES: {
    JOB_TITLE: `Your Name, Job Title, and Business Address will be used to register for the unique telephone number required to use Kinnekt.`,
    EMAIL: `Please <strong>use a personal email address, or an email address only you have direct access to</strong> for this section. The provided email address will be used to complete your account sign-up e-signature, and to contact you with important service updates.`,
    PHONE: `Please share a number you can easily be contacted at. <strong>We will only contact you at the provided number with urgent updates about your account.</strong> (account security, billing issues, errors and outages, etc).`,
    SIGNATURE: `<strong>It may take up to 2 business days for your Kinnekt phone number to activate and be ready for use.</strong> We recommend exploring and configuring the Kinnekt app while you wait for confirmation that your number is active.`,
  },
  ERRORS: {
    REQUIRED: 'At least 1 symbol is required',
    EMAIL: `The email address you've entered is invalid. Please check the spelling, include the "@" symbol, and remove any spaces.`,
    PHONE: `The Phone Number you've entered is invalid. Please format the Phone Number to appear like this: +1(415)123-4567`,
    ZIPCODE: 'Zip Codes can contain between 5-11 characters',
  },
};

export const STORE_LEGAL_INFORMATION = {
  MAIN_TITLE: 'Update Your Store Legal Language',
  MAIN_TITLE_DESCRIPTION: `To ensure that your store is TCPA compliant, copy the text below and <br /> paste it onto your store pages.`,
};
