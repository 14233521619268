import { Injectable } from '@angular/core';

@Injectable()
export class DateTimeService {

  constructor() { }

  public getPreviousYearDate(): Date {
    const date = new Date();
    const year = date.getFullYear() - 1;
    date.setFullYear(year);
    return date;
  }

  public getNextYearDate(): Date {
    const date = new Date();
    const year = date.getFullYear() + 1;
    date.setFullYear(year);
    return date;
  }

  public setDateFormat(number): string {
    if (number < 10) {
      return '0' + number;
    }
    return number;
  }
}
