import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule }                     from '@angular/common';

import { SharedModule }          from '@shared/shared.module';
import { ExamplesRoutingModule } from './examples-routing.module';

import { ExamplesComponent } from './examples.component';

@NgModule({
  declarations: [ExamplesComponent],
  imports: [
    CommonModule,
    SharedModule,
    ExamplesRoutingModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ExamplesModule { }
