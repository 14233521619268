import { Injectable } from '@angular/core';

import { Store }         from '@ngxs/store';
import { MessageParams } from '@shared/models';

import { HasChangesService } from '@features/models';

@Injectable()
export class CampaignChangesService implements HasChangesService {

  constructor(private store: Store) { }

  hasChanges(): boolean {
    const campaignOriginal = this.store.selectSnapshot(state => state.campaigns.campaignOriginal);
    const campaignForm = this.store.selectSnapshot(state => state.campaigns.campaign.model);

    console.log('campaignOriginal', campaignOriginal);
    console.log('campaignForm', campaignForm);

    return (
      campaignForm.id === null &&
      !campaignOriginal &&
      (
        +campaignForm.segmentId
        || campaignForm.name
        || (!!campaignForm?.message && !!campaignForm.message?.text)
      )
    )
     ||
    (
      campaignOriginal &&
      (
        campaignOriginal.name !== campaignForm.name
        || campaignOriginal.segmentId !== campaignForm.segmentId
        || campaignOriginal.sendAt !== campaignForm.sendAt
        || JSON.stringify(this._removeEmptyProps(campaignOriginal.message)) !== JSON.stringify(this._removeEmptyProps(campaignForm.message))
      )
    );
  }

  private _removeEmptyProps(params: MessageParams) {
    const newParams = {};
    Object.keys(params).forEach(prop => {
      if (params[prop]) newParams[prop] = params[prop];
    });

    return newParams;
  }
}
