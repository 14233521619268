import { NgModule }     from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule }                 from '@shared/shared.module';
import { LegalAgreementsRoutingModule } from './legal-agreements-routing.module';

import { components } from './components';

@NgModule({
  declarations: [
    ...components,
  ],
  imports: [
    CommonModule,
    SharedModule,
    LegalAgreementsRoutingModule,
  ],
})
export class LegalAgreementsModule { }
