import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'plural'
})
export class PluralPipe implements PipeTransform {
  private reservedWords: string[] = [
    'minutes'
  ];

  transform(string: string, number: number): string {
    return number > 1 && !this.reservedWords.includes(string) ? `${string}s` : string;
  }
}
