import { Injectable }                from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of }            from 'rxjs';
import { delay, tap }                from 'rxjs/operators';

@Injectable()
export class CustomPreloadingStrategy implements PreloadingStrategy {
  preload(route: Route, load: () => Observable<any>): Observable<any> {
    return of(true).pipe(delay(1000), tap(() => load()));
  }
}
