import { Component, ViewEncapsulation, OnInit, Input, ElementRef, Renderer2 } from '@angular/core';

@Component({
  selector: 'k-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SpinnerComponent implements OnInit {
  @Input() color: string = 'white';
  @Input() size: number;

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngOnInit() {
    this.renderer.addClass(this.el.nativeElement, 'k-spin');
    this.renderer.addClass(this.el.nativeElement, `k-spin--${this.color}`);
    if (this.size) {
      this.renderer.setStyle(this.el.nativeElement, 'width', `${this.size}px`);
      this.renderer.setStyle(this.el.nativeElement, 'height', `${this.size}px`);
    }
  }
}
