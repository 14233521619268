import { Component, Input, OnInit } from '@angular/core';
import { NgxSmartModalService }     from 'ngx-smart-modal';

@Component({
  selector: 'k-message-builder-disabled',
  templateUrl: './message-builder-disabled.component.html',
  styleUrls: ['./message-builder-disabled.component.scss'],
})
export class MessageBuilderDisabledComponent {
  @Input() set message(msg: string) {
    if (msg) {
      this.msg = msg;
      this.msgArr = msg.split(this.sortCodePattern);
    }
  }

  public msg: string;
  public msgArr: string[];
  public sortCodePattern = /({\w*})/;

  constructor(private _modal: NgxSmartModalService) { }

  public onPreviewTestMessagePopup(): void {
    this._modal.getModal('popupMessagePreviewDialog').open();
  }
}
