import { ResponsesListService } from './responses-list.service';
import { ResponseService }      from './response.service';

export {
  ResponsesListService,
  ResponseService,
};

export const services = [
  ResponsesListService,
  ResponseService,
];
