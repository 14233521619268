import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators }   from '@angular/forms';
import { NotificationsService }                 from 'angular2-notifications';

import { NotiService }     from '@notifications/services';
import { TitleBarService } from '@core/services';
import { Breadcrumbs }     from '@core/models';

import { ResponseListItemTypeMap }     from '@responses/models';
import { RESPONSE_LIST_ITEM_TYPE_MAP } from '@responses/texts';

@Component({
  selector: 'k-examples',
  templateUrl: './examples.component.html',
  styleUrls: ['./examples.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ExamplesComponent implements OnInit {
  public exampleForm: FormGroup;
  public ddOptions = [
    {
      name: 'Name 1',
      value: 'Value 1',
    },
    {
      name: 'Name 2',
      value: 'Value 2',
    },
  ];

  public tableColumns = [
    { name: 'Name',    className: 'automation-name k-table__header-cell--column-start' },
    { name: 'Type',    className: 'automation-type k-table__header-cell--column-start' },
    { name: 'Delay',   className: 'automation-delay k-flex--center' },
    { name: 'Sent',    className: 'automation-sent k-flex--end' },
    { name: 'Clicks',  className: 'automation-clicks k-flex--end' },
    { name: 'Revenue', className: 'automation-revenue k-flex--end' },
    { name: 'Status',  className: 'automation-status' },
    { name: 'Actions', className: 'automation-actions' },
  ];

  public typeMap: ResponseListItemTypeMap = RESPONSE_LIST_ITEM_TYPE_MAP;
  public keys;

  constructor(private fb: FormBuilder,
              private notificationsService: NotificationsService,
              private _notiService: NotiService,
              private _titleBarService: TitleBarService) { }

  ngOnInit() {
    this.keys = Object.keys(this.typeMap);
    this._initForm();
    this._listenChanges();
    this._titleBarService.setSectionBreadcrumb(Breadcrumbs.EXAMPLES);
  }

  public onSubmit(): void {
    this._notiService.showNotification$.next({
      title: 'Mind Power The Ultimate',
      message: 'There is a lot of exciting stuff going on in the stars above us that make astronomy so much fun.',
      icon: 'c-cart',
      cancelButton: {
        name: 'Cancel',
      },
      //actionButton: '/route'
    });
  }

  private _initForm(): void {
    this.exampleForm = this.fb.group({
      name: [{ value: 'some cool text', disabled: false }, Validators.required],
      switcher: [false],
      option: ['Value 2'],
      checkbox: [true],
      textarea: ['text value'],
    });
  }

  private _listenChanges(): void {
    this.exampleForm.get('switcher').valueChanges.subscribe((val) => {
      this.exampleForm.get('name')[val ? 'disable' : 'enable']();
    });
  }
}
