import { Injectable }                  from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import {
  HideHeader,
  SetIsCenteringPage,
  SetShopParams,
  SetToken,
  SetSavingProgress,
  SetLastLoadedChild,
} from '@store/actions';

export interface AppStateModel {
  hideHeader: boolean;
  isCenteringPage: boolean;
  token: string;
  shopParams: any;
  savingProgress: boolean;
  lastLoadedChild: string;
}

@State<AppStateModel>({
  name: 'app',
  defaults: {
    hideHeader: false,
    isCenteringPage: false,
    token: '',
    shopParams: {},
    savingProgress: false,
    lastLoadedChild: '',
  },
})
@Injectable()
export class AppState {

  @Action(HideHeader)
  hideHeader({ patchState }: StateContext<AppStateModel>, { payload }: HideHeader) {
    patchState({ hideHeader: payload });
  }

  @Action(SetIsCenteringPage)
  setIsCenteringPage({ patchState }: StateContext<AppStateModel>, { payload }: SetIsCenteringPage) {
    patchState({ isCenteringPage: payload });
  }

  @Action(SetToken)
  setToken({ patchState }: StateContext<AppStateModel>, { payload }: SetToken) {
    patchState({ token: payload });
  }

  @Action(SetShopParams)
  setParams({ patchState }: StateContext<AppStateModel>, { payload }: SetShopParams) {
    patchState({ shopParams: payload });
  }

  @Action(SetSavingProgress)
  setSavingProgress({ patchState }: StateContext<AppStateModel>, { payload }: SetSavingProgress) {
    patchState({ savingProgress: payload });
  }

  @Action(SetLastLoadedChild)
  setLastLoadedChild({ patchState }: StateContext<AppStateModel>, { payload }: SetLastLoadedChild) {
    patchState({ lastLoadedChild: payload });
  }

}
