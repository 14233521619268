import { Injectable }           from '@angular/core';
import { Observable, Subject }  from 'rxjs';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Injectable()
export class UnsavedChangesService {
  private unsavedChangesDialogModalId = 'unsavedChangesDialogModalId';
  private confirmed$: Subject<boolean> = new Subject<boolean>();

  constructor(private modalService: NgxSmartModalService) { }

  public getModalId(): string {
    return this.unsavedChangesDialogModalId;
  }

  public getActionStreamAndOpenDialog() {
    this.openDialog();
    return this.getActionStream();
  }

  public closeWithoutSaving(): void {
    this.modalService.close(this.unsavedChangesDialogModalId);
    this.confirmed$.next(true);
  }

  public cancelCloseWithoutSaving(): void {
    this.confirmed$.next(false);
  }

  private getActionStream(): Observable<boolean> {
    return this.confirmed$;
  }

  private openDialog(): void {
    this.modalService.open(this.unsavedChangesDialogModalId);
  }
}
