export const DEFAULT_WELCOME_MESSAGE = `Thanks for registering for the {StoreName} text VIP club.
 Your 5% discount code is TEST. Good for all purchases on our site {SiteUrl}`;

export const OPT_OUT_SPECIAL_KEYWORDS = ['END', 'QUIT', 'CANCEL', 'UNSUBSCRIBE', 'STOP', 'STOPALL', 'START', 'YES', 'UNSTOP'];
export const KEYWORD_VALIDATION_ERRORS = {
  TO_MANY_SYMBOLS_ERROR: `A maximum of 10 characters`,
  ALREADY_EXISTS_ERROR: `A Keyword with the same name has already exists. Please, try another one`,
  OPT_OUT_SPECIAL_KEYWORDS_ERROR: `This Keyword can't be the same as the re-subscribe/opt-out words`,
  SPECIAL_SYMBOLS_ERROR: `Keyword must contain letters and numbers only`,
};
