import {
  CAMPAIGN_NAME, INBOX_NAME,
  POPUP_NAME,
  SHAREABLE_LINK_NAME,
  SHORT_CODES,
  TRIGGER_TYPES_DATA,
} from '@shared/texts';
import { ShortCodesDataByType } from '@shared/components/message-builder/short-codes/short-codes.model';

export const REPLY_STOP_TO_UNSUBSCRIBE = 'Reply STOP to unsubscribe';
export const STOP = 'STOP';
export const TCPAPopup = 'subscribe to automated recurring personalized marketing alerts from {StoreName}';
export const TCPAShareableLink = 'subscribe to automated recurring personalized marketing alerts from {StoreName} and agree to our Terms and Conditions {Link} even if your number is on a \'do not call\' list. Consent is not a condition of any purchase';

export const flowTypeShortCodes = {
  [TRIGGER_TYPES_DATA.SUBSCRIPTION_TRIGGERS_SUBSCRIPTION_VALUE]: [
    SHORT_CODES.STORE_NAME,
    SHORT_CODES.SITE_URL,
    SHORT_CODES.KEYWORD_NAME,
    SHORT_CODES.DISCOUNT_CODE_URL,
    SHORT_CODES.FIRST_NAME,
  ],
  [TRIGGER_TYPES_DATA.SUBSCRIPTION_TRIGGERS_EXISTING_SUBSCRIBER_VALUE]: [
    SHORT_CODES.STORE_NAME,
    SHORT_CODES.SITE_URL,
    SHORT_CODES.DISCOUNT_CODE,
    SHORT_CODES.DISCOUNT_CODE_URL,
    SHORT_CODES.FIRST_NAME,
    SHORT_CODES.LAST_NAME,
    SHORT_CODES.SUBSCRIBED_TO_KEYWORD_NAME,
    SHORT_CODES.CURRENT_KEYWORD_NAME,
  ],
  [TRIGGER_TYPES_DATA.CART_TRIGGERS_ABANDONED_CART_VALUE]: [
    SHORT_CODES.STORE_NAME,
    SHORT_CODES.SITE_URL,
    SHORT_CODES.ABANDONED_CHECKOUT_URL,
    SHORT_CODES.CHECKOUT_TOTAL,
    SHORT_CODES.CHECKOUT_SUB_TOTAL,
    SHORT_CODES.CHECKOUT_CURRENCY,
    SHORT_CODES.CHECKOUT_UPDATED,
    SHORT_CODES.ORDER_ITEMS_COUNT,
    SHORT_CODES.FIRST_NAME,
    SHORT_CODES.LAST_NAME,
    SHORT_CODES.CUSTOMER_COUNTRY,
    SHORT_CODES.CUSTOMER_CITY,
    SHORT_CODES.CUSTOMER_PROVINCE,
    SHORT_CODES.CUSTOMER_ADDRESS,
  ],
  [TRIGGER_TYPES_DATA.PAID_TRIGGERS_POST_PURCHASE_VALUE]: [
    SHORT_CODES.STORE_NAME,
    SHORT_CODES.SITE_URL,
    SHORT_CODES.DISCOUNT_CODE,
    SHORT_CODES.DISCOUNT_CODE_URL,
    SHORT_CODES.FIRST_NAME,
    SHORT_CODES.LAST_NAME,
    SHORT_CODES.CUSTOMER_EMAIL,
    SHORT_CODES.CUSTOMER_PHONE,
    SHORT_CODES.CUSTOMER_CREATED,
    SHORT_CODES.CUSTOMER_UPDATED,
  ],
  [TRIGGER_TYPES_DATA.PAID_TRIGGERS_UPSELL_CROSS_SELL_VALUE]: [
    SHORT_CODES.STORE_NAME,
    SHORT_CODES.SITE_URL,
    SHORT_CODES.DISCOUNT_CODE,
    SHORT_CODES.FIRST_NAME,
    SHORT_CODES.LAST_NAME,
    SHORT_CODES.ORDER_ID,
    SHORT_CODES.ORDER_NAME,
    SHORT_CODES.BOUGHT_PRODUCT_NAME,
    SHORT_CODES.RECOMMENDED_PRODUCT_NAME,
    SHORT_CODES.RECOMMENDED_PRODUCT_URL,
  ],
  [TRIGGER_TYPES_DATA.BIRTHDAY_TRIGGERS_BIRTHDAY_VALUE]: [
    SHORT_CODES.STORE_NAME,
    SHORT_CODES.SITE_URL,
    SHORT_CODES.FIRST_NAME,
    SHORT_CODES.LAST_NAME,
    SHORT_CODES.CUSTOMER_PHONE,
    SHORT_CODES.CUSTOMER_COUNTRY,
    SHORT_CODES.CUSTOMER_CITY,
    SHORT_CODES.CUSTOMER_PROVINCE,
    SHORT_CODES.CUSTOMER_ADDRESS,
    SHORT_CODES.CUSTOMER_EMAIL,
    SHORT_CODES.CUSTOMER_CREATED,
    SHORT_CODES.CUSTOMER_UPDATED,
    SHORT_CODES.DISCOUNT_CODE,
    SHORT_CODES.DISCOUNT_CODE_URL,
  ],
  [TRIGGER_TYPES_DATA.SHIPPING_TRIGGERS_ORDER_DELIVERED_VALUE]: [
    SHORT_CODES.STORE_NAME,
    SHORT_CODES.SITE_URL,
    SHORT_CODES.DISCOUNT_CODE,
    SHORT_CODES.DISCOUNT_CODE_URL,
    SHORT_CODES.FIRST_NAME,
    SHORT_CODES.LAST_NAME,
    SHORT_CODES.CUSTOMER_EMAIL,
    SHORT_CODES.CUSTOMER_PHONE,
    SHORT_CODES.CUSTOMER_CREATED,
    SHORT_CODES.CUSTOMER_UPDATED,
  ],
  [TRIGGER_TYPES_DATA.RESTOCK_TRIGGERS_RESTOCK_VALUE]: [
    SHORT_CODES.STORE_NAME,
    SHORT_CODES.SITE_URL,
    SHORT_CODES.FIRST_NAME,
    SHORT_CODES.LAST_NAME,
    SHORT_CODES.CUSTOMER_PHONE,
    SHORT_CODES.CUSTOMER_COUNTRY,
    SHORT_CODES.CUSTOMER_CITY,
    SHORT_CODES.CUSTOMER_PROVINCE,
    SHORT_CODES.CUSTOMER_ADDRESS,
    SHORT_CODES.CUSTOMER_EMAIL,
    SHORT_CODES.CUSTOMER_CREATED,
    SHORT_CODES.CUSTOMER_UPDATED,
    SHORT_CODES.DISCOUNT_CODE,
    SHORT_CODES.DISCOUNT_CODE_URL,
  ],
  [CAMPAIGN_NAME]: [
    SHORT_CODES.STORE_NAME,
    SHORT_CODES.SITE_URL,
    SHORT_CODES.FIRST_NAME,
    SHORT_CODES.LAST_NAME,
    SHORT_CODES.CUSTOMER_PHONE,
    SHORT_CODES.CUSTOMER_COUNTRY,
    SHORT_CODES.CUSTOMER_CITY,
    SHORT_CODES.CUSTOMER_PROVINCE,
    SHORT_CODES.CUSTOMER_ADDRESS,
    SHORT_CODES.CUSTOMER_EMAIL,
    SHORT_CODES.CUSTOMER_CREATED,
    SHORT_CODES.CUSTOMER_UPDATED,
    SHORT_CODES.DISCOUNT_CODE,
    SHORT_CODES.DISCOUNT_CODE_URL,
  ],
  [INBOX_NAME]: [
    SHORT_CODES.STORE_NAME,
    SHORT_CODES.SITE_URL,
    SHORT_CODES.DISCOUNT_CODE,
    SHORT_CODES.DISCOUNT_CODE_URL,
  ],
};

export const HINTS = {
  discounts: {
    [SHORT_CODES.DISCOUNT_CODE]: 'The discount code',
    [SHORT_CODES.DISCOUNT_CODE_URL]: 'The link with discount code',
  },
  general: {
    [SHORT_CODES.STORE_NAME]: 'Store name',
    [SHORT_CODES.SITE_URL]: 'Store URL',
    [SHORT_CODES.KEYWORD_NAME]: 'Keyword',
    [SHORT_CODES.CHECKOUT_TOTAL]: 'Total Checkout price',
    [SHORT_CODES.CHECKOUT_SUB_TOTAL]: 'Sub total price',
    [SHORT_CODES.CHECKOUT_CURRENCY]: 'Currency',
    [SHORT_CODES.CHECKOUT_UPDATED]: 'Checkout update date',
    [SHORT_CODES.ORDER_ITEMS_COUNT]: 'Order item count',
    [SHORT_CODES.ABANDONED_CHECKOUT_URL]: 'Abandoned cart URL',
    [SHORT_CODES.SUBSCRIBED_TO_KEYWORD_NAME]: 'Keyword the contact is already subscribed to',
    [SHORT_CODES.CURRENT_KEYWORD_NAME]: 'Keyword that is used for client’s subscriptions',
    [SHORT_CODES.FIRST_NAME]: 'Client first name',
    [SHORT_CODES.LAST_NAME]: 'Client last name',
    [SHORT_CODES.CUSTOMER_EMAIL]: `Client's email`,
    [SHORT_CODES.CUSTOMER_PHONE]: `Client's phone number`,
    [SHORT_CODES.CUSTOMER_COUNTRY]: 'Country',
    [SHORT_CODES.CUSTOMER_CITY]: 'City',
    [SHORT_CODES.CUSTOMER_PROVINCE]: 'Province',
    [SHORT_CODES.CUSTOMER_ADDRESS]: 'Address',
    [SHORT_CODES.CUSTOMER_CREATED]: 'Customer created date',
    [SHORT_CODES.CUSTOMER_UPDATED]: 'Customer updated date',
    [SHORT_CODES.ORDER_ID]: 'Order ID',
    [SHORT_CODES.ORDER_NAME]: 'Order Name',
    [SHORT_CODES.BOUGHT_PRODUCT_NAME]: 'Bought product name',
    [SHORT_CODES.RECOMMENDED_PRODUCT_NAME]: 'Recommended product name',
    [SHORT_CODES.RECOMMENDED_PRODUCT_URL]: 'Link on the recommended product',
    [SHORT_CODES.REQUESTED_PRODUCT_NAME]: 'Requested product name',
    [SHORT_CODES.REQUESTED_PRODUCT_URL]: 'Link on the requested product',
  },
};

export const SHORT_CODES_BY_TYPE: ShortCodesDataByType = {
  [TRIGGER_TYPES_DATA.SUBSCRIPTION_TRIGGERS_SUBSCRIPTION_VALUE]: {
    general: [
      { name: SHORT_CODES.STORE_NAME, strength: 30, group: 'name', hint: HINTS.general[SHORT_CODES.STORE_NAME] },
      { name: SHORT_CODES.SITE_URL, strength: 35, group: 'common-url', hint: HINTS.general[SHORT_CODES.SITE_URL] },
      { name: SHORT_CODES.KEYWORD_NAME, strength: 0, group: 'product', hint: HINTS.general[SHORT_CODES.KEYWORD_NAME] },
    ],
    discounts: [
      { name: SHORT_CODES.DISCOUNT_CODE, strength: 0, group: 'discount', hint: HINTS.discounts[SHORT_CODES.DISCOUNT_CODE] },
      { name: SHORT_CODES.DISCOUNT_CODE_URL, strength: 35, group: 'common-url', hint: HINTS.discounts[SHORT_CODES.DISCOUNT_CODE_URL] },
    ],
    unsubscribe: [
      { name: STOP, strength: 0, group: 'unsubscribe', hint: REPLY_STOP_TO_UNSUBSCRIBE },
    ],
    tcpa: [],
  },
  [TRIGGER_TYPES_DATA.CART_TRIGGERS_ABANDONED_CART_VALUE]: {
    general: [
      { name: SHORT_CODES.STORE_NAME, strength: 20, group: 'site-name', hint: HINTS.general[SHORT_CODES.STORE_NAME] },
      { name: SHORT_CODES.SITE_URL, strength: 0, group: 'site-url', hint: HINTS.general[SHORT_CODES.SITE_URL] },
      { name: SHORT_CODES.FIRST_NAME, strength: 10, group: 'name', hint: HINTS.general[SHORT_CODES.FIRST_NAME] },
      { name: SHORT_CODES.LAST_NAME, strength: 0, group: 'personalization', hint: HINTS.general[SHORT_CODES.LAST_NAME] },
      { name: SHORT_CODES.CHECKOUT_TOTAL, strength: 0, group: 'product', hint: HINTS.general[SHORT_CODES.CHECKOUT_TOTAL] },
      { name: SHORT_CODES.CHECKOUT_SUB_TOTAL, strength: 0, group: 'product', hint: HINTS.general[SHORT_CODES.CHECKOUT_SUB_TOTAL] },
      { name: SHORT_CODES.CHECKOUT_CURRENCY, strength: 0, group: 'product', hint: HINTS.general[SHORT_CODES.CHECKOUT_CURRENCY] },
      { name: SHORT_CODES.CHECKOUT_UPDATED, strength: 0, group: 'product', hint: HINTS.general[SHORT_CODES.CHECKOUT_UPDATED] },
      { name: SHORT_CODES.ORDER_ITEMS_COUNT, strength: 0, group: 'product', hint: HINTS.general[SHORT_CODES.ORDER_ITEMS_COUNT] },
      { name: SHORT_CODES.CUSTOMER_PHONE, strength: 0, group: 'personalization', hint: HINTS.general[SHORT_CODES.CUSTOMER_PHONE] },
      { name: SHORT_CODES.CUSTOMER_COUNTRY, strength: 0, group: 'personalization', hint: HINTS.general[SHORT_CODES.CUSTOMER_COUNTRY] },
      { name: SHORT_CODES.CUSTOMER_CITY, strength: 0, group: 'personalization', hint: HINTS.general[SHORT_CODES.CUSTOMER_CITY] },
      { name: SHORT_CODES.CUSTOMER_PROVINCE, strength: 0, group: 'personalization', hint: HINTS.general[SHORT_CODES.CUSTOMER_PROVINCE] },
      { name: SHORT_CODES.CUSTOMER_ADDRESS, strength: 0, group: 'personalization', hint: HINTS.general[SHORT_CODES.CUSTOMER_ADDRESS] },
      { name: SHORT_CODES.ABANDONED_CHECKOUT_URL, strength: 35, group: 'abandoned-url',
        hint: HINTS.general[SHORT_CODES.ABANDONED_CHECKOUT_URL] },
    ],
    discounts: [],
    unsubscribe: [
      { name: STOP, strength: 0, group: 'unsubscribe', hint: REPLY_STOP_TO_UNSUBSCRIBE },
    ],
    tcpa: [],
  },
  [TRIGGER_TYPES_DATA.SHIPPING_TRIGGERS_ORDER_DELIVERED_VALUE]: {
    general: [
      { name: SHORT_CODES.STORE_NAME, strength: 30, group: 'site-name', hint: HINTS.general[SHORT_CODES.STORE_NAME] },
      { name: SHORT_CODES.SITE_URL, strength: 25, group: 'common-url', hint: HINTS.general[SHORT_CODES.SITE_URL] },
      { name: SHORT_CODES.FIRST_NAME, strength: 10, group: 'name', hint: HINTS.general[SHORT_CODES.FIRST_NAME] },
      { name: SHORT_CODES.LAST_NAME, strength: 0, group: 'personalization', hint: HINTS.general[SHORT_CODES.LAST_NAME] },
      { name: SHORT_CODES.CUSTOMER_EMAIL, strength: 0, group: 'email', hint: HINTS.general[SHORT_CODES.CUSTOMER_EMAIL] },
      { name: SHORT_CODES.CUSTOMER_PHONE, strength: 0, group: 'phone', hint: HINTS.general[SHORT_CODES.CUSTOMER_PHONE] },
      { name: SHORT_CODES.CUSTOMER_CREATED, strength: 0, group: 'update-info', hint: HINTS.general[SHORT_CODES.CUSTOMER_CREATED] },
      { name: SHORT_CODES.CUSTOMER_UPDATED, strength: 0, group: 'update-info', hint: HINTS.general[SHORT_CODES.CUSTOMER_UPDATED] },
    ],
    discounts: [
      { name: SHORT_CODES.DISCOUNT_CODE, strength: 0, group: 'discount', hint: HINTS.discounts[SHORT_CODES.DISCOUNT_CODE] },
      { name: SHORT_CODES.DISCOUNT_CODE_URL, strength: 25, group: 'common-url', hint: HINTS.discounts[SHORT_CODES.DISCOUNT_CODE_URL] },
    ],
    unsubscribe: [
      { name: STOP, strength: 0, group: 'unsubscribe', hint: REPLY_STOP_TO_UNSUBSCRIBE },
    ],
    tcpa: [],
  },
  [TRIGGER_TYPES_DATA.PAID_TRIGGERS_POST_PURCHASE_VALUE]: {
    general: [
      { name: SHORT_CODES.STORE_NAME, strength: 30, group: 'site-name', hint: HINTS.general[SHORT_CODES.STORE_NAME] },
      { name: SHORT_CODES.SITE_URL, strength: 25, group: 'site-url', hint: HINTS.general[SHORT_CODES.SITE_URL] },
      { name: SHORT_CODES.FIRST_NAME, strength: 10, group: 'name', hint: HINTS.general[SHORT_CODES.FIRST_NAME] },
      { name: SHORT_CODES.LAST_NAME, strength: 0, group: 'personalization', hint: HINTS.general[SHORT_CODES.LAST_NAME] },
      { name: SHORT_CODES.CUSTOMER_EMAIL, strength: 0, group: 'email', hint: HINTS.general[SHORT_CODES.CUSTOMER_EMAIL] },
      { name: SHORT_CODES.CUSTOMER_PHONE, strength: 0, group: 'phone', hint: HINTS.general[SHORT_CODES.CUSTOMER_PHONE] },
      { name: SHORT_CODES.CUSTOMER_CREATED, strength: 0, group: 'update-info', hint: HINTS.general[SHORT_CODES.CUSTOMER_CREATED] },
      { name: SHORT_CODES.CUSTOMER_UPDATED, strength: 0, group: 'update-info', hint: HINTS.general[SHORT_CODES.CUSTOMER_UPDATED] },
    ],
    discounts: [
      { name: SHORT_CODES.DISCOUNT_CODE, strength: 0, group: 'discount', hint: HINTS.discounts[SHORT_CODES.DISCOUNT_CODE] },
      { name: SHORT_CODES.DISCOUNT_CODE_URL, strength: 25, group: 'discount', hint: HINTS.discounts[SHORT_CODES.DISCOUNT_CODE_URL] },
    ],
    unsubscribe: [
      { name: STOP, strength: 0, group: 'unsubscribe', hint: REPLY_STOP_TO_UNSUBSCRIBE },
    ],
    tcpa: [],
  },
  [TRIGGER_TYPES_DATA.PAID_TRIGGERS_UPSELL_CROSS_SELL_VALUE]: {
    general: [
      { name: SHORT_CODES.STORE_NAME, strength: 30, group: 'site-name', hint: HINTS.general[SHORT_CODES.STORE_NAME] },
      { name: SHORT_CODES.SITE_URL, strength: 0, group: 'url', hint: HINTS.general[SHORT_CODES.SITE_URL] },
      { name: SHORT_CODES.ORDER_ID, strength: 0, group: 'order', hint: HINTS.general[SHORT_CODES.ORDER_ID] },
      { name: SHORT_CODES.ORDER_NAME, strength: 0, group: 'order', hint: HINTS.general[SHORT_CODES.ORDER_NAME] },
      { name: SHORT_CODES.BOUGHT_PRODUCT_NAME, strength: 0, group: 'product', hint: HINTS.general[SHORT_CODES.BOUGHT_PRODUCT_NAME] },
      { name: SHORT_CODES.FIRST_NAME, strength: 10, group: 'name', hint: HINTS.general[SHORT_CODES.FIRST_NAME] },
      { name: SHORT_CODES.LAST_NAME, strength: 0, group: 'personalization', hint: HINTS.general[SHORT_CODES.LAST_NAME] },
      { name: SHORT_CODES.RECOMMENDED_PRODUCT_URL, strength: 25, group: 'url', hint: HINTS.general[SHORT_CODES.RECOMMENDED_PRODUCT_URL] },
      { name: SHORT_CODES.RECOMMENDED_PRODUCT_NAME, strength: 0, group: 'product',
        hint: HINTS.general[SHORT_CODES.RECOMMENDED_PRODUCT_NAME] },
    ],
    discounts: [
      { name: SHORT_CODES.DISCOUNT_CODE, strength: 0, group: 'discount', hint: HINTS.discounts[SHORT_CODES.DISCOUNT_CODE] },
    ],
    unsubscribe: [
      { name: STOP, strength: 0, group: 'unsubscribe', hint: REPLY_STOP_TO_UNSUBSCRIBE },
    ],
    tcpa: [],
  },
  [TRIGGER_TYPES_DATA.BIRTHDAY_TRIGGERS_BIRTHDAY_VALUE]: {
    general: [
      { name: SHORT_CODES.STORE_NAME, strength: 30, group: 'site-name', hint: HINTS.general[SHORT_CODES.STORE_NAME] },
      { name: SHORT_CODES.SITE_URL, strength: 35, group: 'site-url', hint: HINTS.general[SHORT_CODES.SITE_URL] },
      { name: SHORT_CODES.FIRST_NAME, strength: 0, group: 'name', hint: HINTS.general[SHORT_CODES.FIRST_NAME] },
      { name: SHORT_CODES.LAST_NAME, strength: 0, group: 'personalization', hint: HINTS.general[SHORT_CODES.LAST_NAME] },
      { name: SHORT_CODES.CUSTOMER_PHONE, strength: 0, group: 'personalization', hint: HINTS.general[SHORT_CODES.CUSTOMER_PHONE] },
      { name: SHORT_CODES.CUSTOMER_COUNTRY, strength: 0, group: 'personalization', hint: HINTS.general[SHORT_CODES.CUSTOMER_COUNTRY] },
      { name: SHORT_CODES.CUSTOMER_CITY, strength: 0, group: 'personalization', hint: HINTS.general[SHORT_CODES.CUSTOMER_CITY] },
      { name: SHORT_CODES.CUSTOMER_PROVINCE, strength: 0, group: 'personalization', hint: HINTS.general[SHORT_CODES.CUSTOMER_PROVINCE] },
      { name: SHORT_CODES.CUSTOMER_ADDRESS, strength: 0, group: 'personalization', hint: HINTS.general[SHORT_CODES.CUSTOMER_ADDRESS] },
      { name: SHORT_CODES.CUSTOMER_EMAIL, strength: 0, group: 'email', hint: HINTS.general[SHORT_CODES.CUSTOMER_EMAIL] },
      { name: SHORT_CODES.CUSTOMER_CREATED, strength: 0, group: 'update-info', hint: HINTS.general[SHORT_CODES.CUSTOMER_CREATED] },
      { name: SHORT_CODES.CUSTOMER_UPDATED, strength: 0, group: 'update-info', hint: HINTS.general[SHORT_CODES.CUSTOMER_UPDATED] },
    ],
    discounts: [
      { name: SHORT_CODES.DISCOUNT_CODE, strength: 0, group: 'discount', hint: HINTS.discounts[SHORT_CODES.DISCOUNT_CODE] },
      { name: SHORT_CODES.DISCOUNT_CODE_URL, strength: 0, group: 'discount', hint: HINTS.discounts[SHORT_CODES.DISCOUNT_CODE_URL] },
    ],
    unsubscribe: [
      { name: STOP, strength: 25, group: 'unsubscribe', hint: REPLY_STOP_TO_UNSUBSCRIBE },
    ],
    tcpa: [],
  },
  [TRIGGER_TYPES_DATA.SUBSCRIPTION_TRIGGERS_EXISTING_SUBSCRIBER_VALUE]: {
    general: [
      { name: SHORT_CODES.STORE_NAME, strength: 30, group: 'site-name', hint: HINTS.general[SHORT_CODES.STORE_NAME] },
      { name: SHORT_CODES.SITE_URL, strength: 35, group: 'common-url', hint: HINTS.general[SHORT_CODES.SITE_URL] },
      { name: SHORT_CODES.FIRST_NAME, strength: 10, group: 'name', hint: HINTS.general[SHORT_CODES.FIRST_NAME] },
      { name: SHORT_CODES.LAST_NAME, strength: 0, group: 'personalization', hint: HINTS.general[SHORT_CODES.LAST_NAME] },
      { name: SHORT_CODES.CURRENT_KEYWORD_NAME, strength: 0, group: 'current-keyword',
        hint: HINTS.general[SHORT_CODES.CURRENT_KEYWORD_NAME] },
      { name: SHORT_CODES.SUBSCRIBED_TO_KEYWORD_NAME, strength: 0, group: 'subscribed-to-keyword',
        hint: HINTS.general[SHORT_CODES.SUBSCRIBED_TO_KEYWORD_NAME] },
    ],
    discounts: [
      { name: SHORT_CODES.DISCOUNT_CODE, strength: 0, group: 'discount', hint: HINTS.discounts[SHORT_CODES.DISCOUNT_CODE] },
      { name: SHORT_CODES.DISCOUNT_CODE_URL, strength: 35, group: 'common-url', hint: HINTS.discounts[SHORT_CODES.DISCOUNT_CODE_URL] },
    ],
    unsubscribe: [
      { name: STOP, strength: 0, group: 'unsubscribe', hint: REPLY_STOP_TO_UNSUBSCRIBE },
    ],
    tcpa: [],
  },
  [TRIGGER_TYPES_DATA.RESTOCK_TRIGGERS_RESTOCK_VALUE]: {
    general: [
      { name: SHORT_CODES.STORE_NAME, strength: 30, group: 'site-name', hint: HINTS.general[SHORT_CODES.STORE_NAME] },
      { name: SHORT_CODES.SITE_URL, strength: 35, group: 'site-url', hint: HINTS.general[SHORT_CODES.SITE_URL] },
      { name: SHORT_CODES.FIRST_NAME, strength: 0, group: 'name', hint: HINTS.general[SHORT_CODES.FIRST_NAME] },
      { name: SHORT_CODES.LAST_NAME, strength: 0, group: 'personalization', hint: HINTS.general[SHORT_CODES.LAST_NAME] },
      { name: SHORT_CODES.CUSTOMER_PHONE, strength: 0, group: 'personalization', hint: HINTS.general[SHORT_CODES.CUSTOMER_PHONE] },
      { name: SHORT_CODES.CUSTOMER_COUNTRY, strength: 0, group: 'personalization', hint: HINTS.general[SHORT_CODES.CUSTOMER_COUNTRY] },
      { name: SHORT_CODES.CUSTOMER_CITY, strength: 0, group: 'personalization', hint: HINTS.general[SHORT_CODES.CUSTOMER_CITY] },
      { name: SHORT_CODES.CUSTOMER_PROVINCE, strength: 0, group: 'personalization', hint: HINTS.general[SHORT_CODES.CUSTOMER_PROVINCE] },
      { name: SHORT_CODES.CUSTOMER_ADDRESS, strength: 0, group: 'personalization', hint: HINTS.general[SHORT_CODES.CUSTOMER_ADDRESS] },
      { name: SHORT_CODES.CUSTOMER_EMAIL, strength: 0, group: 'email', hint: HINTS.general[SHORT_CODES.CUSTOMER_EMAIL] },
      { name: SHORT_CODES.CUSTOMER_CREATED, strength: 0, group: 'update-info', hint: HINTS.general[SHORT_CODES.CUSTOMER_CREATED] },
      { name: SHORT_CODES.CUSTOMER_UPDATED, strength: 0, group: 'update-info', hint: HINTS.general[SHORT_CODES.CUSTOMER_UPDATED] },
      { name: SHORT_CODES.REQUESTED_PRODUCT_NAME, strength: 0, group: 'restock', hint: HINTS.general[SHORT_CODES.REQUESTED_PRODUCT_NAME] },
      { name: SHORT_CODES.REQUESTED_PRODUCT_URL, strength: 0, group: 'restock', hint: HINTS.general[SHORT_CODES.REQUESTED_PRODUCT_URL] },
    ],
    discounts: [
      { name: SHORT_CODES.DISCOUNT_CODE, strength: 0, group: 'discount', hint: HINTS.discounts[SHORT_CODES.DISCOUNT_CODE] },
      { name: SHORT_CODES.DISCOUNT_CODE_URL, strength: 0, group: 'discount', hint: HINTS.discounts[SHORT_CODES.DISCOUNT_CODE_URL] },
    ],
    unsubscribe: [
      { name: STOP, strength: 25, group: 'unsubscribe', hint: REPLY_STOP_TO_UNSUBSCRIBE },
    ],
    tcpa: [],
  },
  [POPUP_NAME]: {
    general: [
      { name: SHORT_CODES.KEYWORD_NAME, strength: 0, group: 'product', hint: HINTS.general[SHORT_CODES.KEYWORD_NAME] },
    ],
    discounts: [],
    unsubscribe: [],
    tcpa: [
      { name: TCPAPopup, strength: 0, group: 'tcpa', hint: null },
    ],
  },
  [SHAREABLE_LINK_NAME]: {
    general: [
      { name: SHORT_CODES.KEYWORD_NAME, strength: 0, group: 'product', hint: HINTS.general[SHORT_CODES.KEYWORD_NAME] },
    ],
    discounts: [],
    unsubscribe: [],
    tcpa: [
      { name: TCPAShareableLink, strength: 0, group: 'tcpa', hint: null },
    ],
  },
  [INBOX_NAME]: {
    general: [
      { name: SHORT_CODES.STORE_NAME, strength: 0, group: 'site-name', hint: HINTS.general[SHORT_CODES.STORE_NAME] },
      { name: SHORT_CODES.SITE_URL, strength: 0, group: 'site-url', hint: HINTS.general[SHORT_CODES.SITE_URL] },
    ],
    discounts: [
      { name: SHORT_CODES.DISCOUNT_CODE, strength: 0, group: 'discount', hint: HINTS.discounts[SHORT_CODES.DISCOUNT_CODE] },
      { name: SHORT_CODES.DISCOUNT_CODE_URL, strength: 0, group: 'discount', hint: HINTS.discounts[SHORT_CODES.DISCOUNT_CODE_URL] },
    ],
    unsubscribe: [
      { name: STOP, strength: 0, group: 'unsubscribe', hint: REPLY_STOP_TO_UNSUBSCRIBE },
    ],
    tcpa: [],
  },
  [CAMPAIGN_NAME]: {
    general: [
      { name: SHORT_CODES.STORE_NAME, strength: 30, group: 'site-name', hint: HINTS.general[SHORT_CODES.STORE_NAME] },
      { name: SHORT_CODES.SITE_URL, strength: 35, group: 'site-url', hint: HINTS.general[SHORT_CODES.SITE_URL] },
      { name: SHORT_CODES.FIRST_NAME, strength: 0, group: 'name', hint: HINTS.general[SHORT_CODES.FIRST_NAME] },
      { name: SHORT_CODES.LAST_NAME, strength: 0, group: 'personalization', hint: HINTS.general[SHORT_CODES.LAST_NAME] },
      { name: SHORT_CODES.CUSTOMER_PHONE, strength: 0, group: 'personalization', hint: HINTS.general[SHORT_CODES.CUSTOMER_PHONE] },
      { name: SHORT_CODES.CUSTOMER_COUNTRY, strength: 0, group: 'personalization', hint: HINTS.general[SHORT_CODES.CUSTOMER_COUNTRY] },
      { name: SHORT_CODES.CUSTOMER_CITY, strength: 0, group: 'personalization', hint: HINTS.general[SHORT_CODES.CUSTOMER_CITY] },
      { name: SHORT_CODES.CUSTOMER_PROVINCE, strength: 0, group: 'personalization', hint: HINTS.general[SHORT_CODES.CUSTOMER_PROVINCE] },
      { name: SHORT_CODES.CUSTOMER_ADDRESS, strength: 0, group: 'personalization', hint: HINTS.general[SHORT_CODES.CUSTOMER_ADDRESS] },
      { name: SHORT_CODES.CUSTOMER_EMAIL, strength: 0, group: 'email', hint: HINTS.general[SHORT_CODES.CUSTOMER_EMAIL] },
      { name: SHORT_CODES.CUSTOMER_CREATED, strength: 0, group: 'update-info', hint: HINTS.general[SHORT_CODES.CUSTOMER_CREATED] },
      { name: SHORT_CODES.CUSTOMER_UPDATED, strength: 0, group: 'update-info', hint: HINTS.general[SHORT_CODES.CUSTOMER_UPDATED] },
    ],
    discounts: [
      { name: SHORT_CODES.DISCOUNT_CODE, strength: 0, group: 'discount', hint: HINTS.discounts[SHORT_CODES.DISCOUNT_CODE] },
      { name: SHORT_CODES.DISCOUNT_CODE_URL, strength: 0, group: 'discount', hint: HINTS.discounts[SHORT_CODES.DISCOUNT_CODE_URL] },
    ],
    unsubscribe: [
      { name: STOP, strength: 25, group: 'unsubscribe', hint: REPLY_STOP_TO_UNSUBSCRIBE },
    ],
    tcpa: [],
  },
};
