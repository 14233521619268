import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'k-settings-notifications',
  templateUrl: './settings-notifications.component.html',
  styleUrls: ['./settings-notifications.component.scss']
})
export class SettingsNotificationsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
