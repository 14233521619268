import { Component, OnInit } from '@angular/core';

import { TitleBarService } from '@core/services';
import { Breadcrumbs }     from '@core/models';

@Component({
  selector: 'k-settings-layout',
  templateUrl: './settings-layout.component.html',
  styleUrls: ['./settings-layout.component.scss'],
})
export class SettingsLayoutComponent implements OnInit {
  public settingsMenuItems = [
    {
      title: 'General',
      link: 'general',
    },
    {
      title: 'Sending Logic',
      link: 'sending-logic',
    },
    {
      title: 'Payments',
      link: 'payments',
    },
    // {
    //   title: 'Notifications',
    //   link: 'notifications'
    // },
    // {
    //   title: 'TCPA',
    //   link: 'tcpa'
    // },
    // {
    //   title: 'Branding',
    //   link: 'branding'
    // },
    {
      title: 'Integrations',
      link: 'integrations',
    },
  ];

  constructor(private _titleBarService: TitleBarService) { }

  ngOnInit() {
    this._titleBarService.setSectionBreadcrumb(Breadcrumbs.SETTINGS);
  }

}
