import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl }                                               from '@angular/forms';
import { Subject }                                                   from 'rxjs';

import { ShortCodeEditorInfo } from '@shared/components/message-builder/short-codes/short-codes.model';

@Component({
  selector: 'k-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss'],
})
export class TextAreaComponent implements OnInit, OnDestroy {
  @Input() control: FormControl;
  @Input() availableShortCodes: ShortCodeEditorInfo[];
  @Input() messageType: string;
  @Output() cursorPosition: EventEmitter<number> = new EventEmitter<number>();
  @Output() tagRemoved: EventEmitter<string> = new EventEmitter<string>();
  @Output() blurred: EventEmitter<void> = new EventEmitter<void>();
  @Output() initialized: EventEmitter<void> = new EventEmitter<void>();

  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor() { }

  ngOnInit(): void {
  }

  public updateCursorPosition(position: number): void {
    this.cursorPosition.emit(position);
  }

  public onTagRemoved(tag: string): void {
    this.tagRemoved.emit(tag);
  }

  public onInitialized(): void {
    this.initialized.emit();
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  blur() {
    this.blurred.emit();
  }
}
