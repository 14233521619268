import { Injectable } from '@angular/core';

import * as WebFont from 'webfontloader';

import { GOOGLE_FONT_FAMILIES, EDITOR_FONTS } from '@config';

@Injectable()
export class FontService {
  constructor() { }

  public loadFonts(): void {
    WebFont.load({
      google: {
        families: GOOGLE_FONT_FAMILIES,
      },
      classes: false,
      timeout: 9000,
    });
  }

  public getEditorFonts(): { [key: string]: any } {
    return Object.entries(EDITOR_FONTS).reduce((acc, [key, val]) => {
      const value = val.map(font => {
        return {
          value: `'${font}', ${this._normalizeFontGroup(key)}`,
          name: font,
        };
      });

      return {
        ...acc,
        [key]: value,
      };
    }, {});
  }

  private _normalizeFontGroup(fontGroup: string): string {
    return fontGroup === 'Script'
      ? 'cursive'
      : fontGroup.toLowerCase().replace(/\s/, '-');
  }
}
