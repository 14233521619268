import {
  Component,
  AfterViewInit,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
  forwardRef, Output, EventEmitter, OnDestroy,
} from '@angular/core';

import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { fromEvent, merge, Observable, Subject } from 'rxjs';
import { takeUntil }                             from 'rxjs/operators';

import { CustomControlValueAccessor }            from '../custom-control-value-accessor';

@Component({
  selector: 'k-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextareaComponent),
      multi: true,
    },
  ],
})
export class TextareaComponent extends CustomControlValueAccessor implements OnInit, AfterViewInit, OnDestroy {
  //@Input() size = 'normal';
  @Input() customClass: string;
  @Input() label: string;
  @Input() required: boolean;
  @Input() disabled: boolean = false;
  @Input() helper: string;
  @Input() placeholder: string = 'Enter your message...';
  @Input() eventSubscription: boolean = false;
  @Output() cursorPositionChange: EventEmitter<number> = new EventEmitter<number>();

  @ViewChild('textarea') textarea: ElementRef;

  public id: string;
  private textareaEl;
  private ngUnsubscribe$: Subject<any> = null;

  constructor(private renderer: Renderer2) {
    super();
  }

  ngOnInit() {
    this.id = this._genId();
  }

  ngAfterViewInit() {
    //this.renderer.addClass(this.textarea.nativeElement, `k-textarea--${this.size}`);
    this.textareaEl = this.textarea.nativeElement;

    if (this.eventSubscription) {
      this.ngUnsubscribe$ = new Subject<any>();
      this.textareaEvents
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(() => {
          this.cursorPositionChange.emit(this.textareaEl.selectionStart);
        });
    }
  }

  public onInput(value): void {
    this.value = value;
  }

  private get textareaEvents(): Observable<any> {
    return merge(
      fromEvent(this.textareaEl, 'click'),
      fromEvent(this.textareaEl, 'keyup'),
    );
  }

  private _genId() {
    return `tid-${Math.round(Math.random() * 10e5)}`;
  }

  ngOnDestroy() {
    if (this.ngUnsubscribe$) {
      this.ngUnsubscribe$.next();
      this.ngUnsubscribe$.complete();
    }
  }
}
