import { AfterViewInit, Component, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'k-no-image',
  templateUrl: './no-image.component.html',
  styleUrls: ['./no-image.component.scss'],
})
export class NoImageComponent implements AfterViewInit {
  @Input() size: number = 60;
  @Input() iconHeight: number;
  @Input() textSize: 'small' | 'normal' = 'normal';

  @ViewChild('noImage') noImage: ElementRef;
  @ViewChild('noImageText') noImageText: ElementRef;

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngAfterViewInit() {
    this.renderer.setStyle(this.noImage.nativeElement, 'width', `${this.size}px`);
    this.renderer.setStyle(this.noImage.nativeElement, 'height', `${this.iconHeight ? this.iconHeight : this.size}px`);
    this.renderer.addClass(this.noImageText.nativeElement, `k-no-image--text-${this.textSize}`);
  }
}
