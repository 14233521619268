import { Injectable } from '@angular/core';
import { Store }      from '@ngxs/store';
import moment         from 'moment';
import { Observable } from 'rxjs';

import {
  ShortCodesData,
  ShortCodeStyles,
  ShortCodeStylesObj,
  TemplateType,
}                                               from '@shared/components/message-builder/short-codes/short-codes.model';
import { ApiService, HelperService }            from '@core/services';
import { MerchantInfo }                         from '@core/models';
import {
  MESSAGE_STRENGTH_HINTS,
  SHORT_CODES,
  TRIGGER_TYPES_DATA,
}                                                                               from '@shared/texts';
import { flowTypeShortCodes, STOP }                                             from '@shared/data-model/short-codes.model';
import { DiscountStateModel, MessageStrength, MessageStrengthHint, UpsellItem } from '@shared/models';
import { KeywordItem }                                                          from '@keywords/models';
import { FLOW_TRIGGERS_TYPE }                                                   from '../../flows/texts';

@Injectable()
export class MessageBuilderService {
  public price = { sms: 0.013, mms: 0.0455 };
  private flowShortCodes = flowTypeShortCodes;
  private botStatus: boolean = true;

  constructor(private store: Store, private _apiService: ApiService, private _helperService: HelperService) { }

  public getBotStatus(): boolean {
    return this.botStatus;
  }

  public setBotStatus(status: boolean): void {
    this.botStatus = status;
  }

  public getPrice(): { sms: number, mms: number } { return { ...this.price }; }

  public getInitialCodeStyles(placeholders: ShortCodesData, disabledShorCodes: string[]): ShortCodeStyles[] {
    const discounts = placeholders.discounts
      .map(elem => ({ name: elem.name, style: disabledShorCodes.includes(elem.name) ? 'ghostwhite' : 'lavender' } as ShortCodeStyles));
    const general = placeholders.general
      .map(elem => ({ name: elem.name, style: disabledShorCodes.includes(elem.name) ? 'ghostwhite' : 'lavender' } as ShortCodeStyles));
    const unsubscribe = placeholders.unsubscribe
      .map(elem => ({ name: elem.name, style: disabledShorCodes.includes(elem.name) ? 'ghostwhite' : 'lavender' } as ShortCodeStyles));
    const tcpa = placeholders.tcpa
      .map(elem => ({ name: elem.name, style: disabledShorCodes.includes(elem.name) ? 'ghostwhite' : 'lavender' } as ShortCodeStyles));
    return [ ...general, ...discounts, ...unsubscribe, ...tcpa ];
  }

  public getShortCodeStylesObj(shortCodeStyles: ShortCodeStyles[]): ShortCodeStylesObj {
    const shortCodesObj: ShortCodeStylesObj = {};
    shortCodeStyles.forEach(elem => {
      shortCodesObj[elem.name] = elem.style;
    });

    return shortCodesObj;
  }

  public getSettings(path: string): Observable<any>  {
    return this._apiService.get(`settings/${path}`);
  }

  public isShortCodeActive(shortCodesObj: ShortCodeStylesObj, shortCode: string): boolean {
    return shortCodesObj[shortCode] === 'ghostwhite';
  }

  public updateShortCodeStyles(templateString: string, shortCodeStyles: ShortCodeStyles[]): ShortCodeStyles[] {
    return shortCodeStyles.map(elem => {
      const style = templateString && templateString.includes(elem.name) ? 'ghostwhite' : 'lavender';
      return { name: elem.name, style };
    });
  }

  public updateMessageStrength(templateString: string,
                               imageUrl: string,
                               shortCodeSubstitutes: any,
                               placeholders: ShortCodesData): MessageStrength {
    const strengthMetrics = this._helperService.updateMessageStrengthMetrics(
      [ ...placeholders.general, ...placeholders.discounts ],
      MESSAGE_STRENGTH_HINTS,
      templateString,
      shortCodeSubstitutes,
    );

    const messageStrengthHints: MessageStrengthHint[] = strengthMetrics.messageStrengthHints;
    let messageStrengthWeight: number = strengthMetrics.messageStrengthWeight;

    templateString.includes(STOP)
      ? (messageStrengthWeight += 25)
      : messageStrengthHints.push({ hint: MESSAGE_STRENGTH_HINTS.OPT_PUT, name: null });

    imageUrl
      ? (messageStrengthWeight += 5)
      : messageStrengthHints.push({ hint: MESSAGE_STRENGTH_HINTS.IMAGE, name: null });

    if (templateString.length < 199) {
      messageStrengthWeight += 5;
    } else {
      messageStrengthHints.push({ hint: MESSAGE_STRENGTH_HINTS.VOLUME, name: null });
    }

    const isMessageUrlHintRequired = this._helperService.isMessageUrlHintRequired(templateString, placeholders.general);
    if (isMessageUrlHintRequired) {
      messageStrengthHints.push({ hint: MESSAGE_STRENGTH_HINTS.URL, name: null });
    }

    const messageStrength: string = this._helperService.updateMessageStrength(messageStrengthWeight);

    return { messageStrengthWeight, messageStrength, messageStrengthHints };
  }

  public getLimitOfSMS(templateString): number {
    return this._helperService.getLimitOfSMS(this._helperService.isGSMAlphabet(templateString), templateString);
  }

  public getNumberOfSMS(templateString): number {
    return this._helperService.getNumberOfSMS(this._helperService.isGSMAlphabet(templateString), templateString);
  }

  public getSizeOfSMS(templateString, smsNumber, smsLimit): number {
    return this._helperService.getSizeOfSMS(templateString, smsNumber, smsLimit);
  }

  public getMessagePrice(templateString: string, templateType: TemplateType, chapterAmount: number): number {
    if (templateType === 'mms') return templateString ? this.price.mms : 0;
    if (templateType === 'sms') return this.price.sms * chapterAmount;

    return 0;
  }

  public getFlowSubstitutes(triggerType, merchantInfo: MerchantInfo, discountData: DiscountStateModel, options?) {
    const yesterdayDate = moment().subtract(1, 'days').format('MMM DD');
    const billingAddress = merchantInfo.shop.billingAddress;

    let upsellBoughtProduct: UpsellItem = null;
    let upsellRecommendProduct: UpsellItem = null;

    if (triggerType === TRIGGER_TYPES_DATA.PAID_TRIGGERS_UPSELL_CROSS_SELL_VALUE) {
      upsellBoughtProduct = options.triggerProducts[0] || options.triggerCollections[0]?.includesProducts[0];
      upsellRecommendProduct = options.recommendedProducts[0];
    }

    let keyword: KeywordItem = null;
    if (triggerType === TRIGGER_TYPES_DATA.SUBSCRIPTION_TRIGGERS_SUBSCRIPTION_VALUE) {
      keyword = options.channel;
    }

    let discountValue = 0;

    if (discountData && discountData.value.includes('$')) {
      discountValue = parseFloat(discountData.value) ;
      discountValue = discountValue >= 100 ? 100 : discountValue;
    }

    if (discountData && discountData.value.includes('%')) {
      const valueToInt = parseFloat(discountData.value.replace(/[!@#$%^&*]/g, ''));
      discountValue = discountValue >= 100 ? 100 : Math.floor(100 * valueToInt) / 100;
    }

    const substitutes = [
      { [SHORT_CODES.STORE_NAME]: merchantInfo.shop.name },
      { [SHORT_CODES.SITE_URL]: this._helperService.getShortDomain(merchantInfo) },
      { [SHORT_CODES.DISCOUNT_CODE]: discountData?.code },
      { [SHORT_CODES.DISCOUNT_CODE_URL]: discountData?.link },
      { [SHORT_CODES.ABANDONED_CHECKOUT_URL]: this._helperService.getShortDomain(merchantInfo) },
      { [SHORT_CODES.SUBSCRIBED_TO_KEYWORD_NAME]: 'START' },
      { [SHORT_CODES.CURRENT_KEYWORD_NAME]: 'SAVE10' },
      { [SHORT_CODES.CHECKOUT_TOTAL]: `$${Math.floor(+((100 - discountValue).toFixed(2)) * 100) / 100}` },
      { [SHORT_CODES.CHECKOUT_SUB_TOTAL]: '$85' },
      { [SHORT_CODES.CHECKOUT_CURRENCY]: '$' },
      { [SHORT_CODES.CHECKOUT_UPDATED]: yesterdayDate },
      { [SHORT_CODES.ORDER_ITEMS_COUNT]: 3 },
      { [SHORT_CODES.FIRST_NAME]: billingAddress?.firstName },
      { [SHORT_CODES.LAST_NAME]: billingAddress?.lastName },
      { [SHORT_CODES.CUSTOMER_EMAIL]: merchantInfo.shop.email },
      { [SHORT_CODES.CUSTOMER_PHONE]: billingAddress?.phone },
      { [SHORT_CODES.CUSTOMER_COUNTRY]: billingAddress?.country },
      { [SHORT_CODES.CUSTOMER_CITY]: billingAddress?.city },
      { [SHORT_CODES.CUSTOMER_PROVINCE]: billingAddress?.province },
      { [SHORT_CODES.CUSTOMER_ADDRESS]: billingAddress?.address1 },
      { [SHORT_CODES.CUSTOMER_CREATED]: yesterdayDate },
      { [SHORT_CODES.CUSTOMER_UPDATED]: yesterdayDate },
      { [SHORT_CODES.ORDER_ID]: 1234567891012 },
      { [SHORT_CODES.ORDER_NAME]: '#1123' },
      { [SHORT_CODES.KEYWORD_NAME]: keyword?.name },
      { [SHORT_CODES.BOUGHT_PRODUCT_NAME]: upsellBoughtProduct?.title },
      { [SHORT_CODES.RECOMMENDED_PRODUCT_NAME]: upsellRecommendProduct?.title },
      { [SHORT_CODES.RECOMMENDED_PRODUCT_URL]: this._helperService.getShortDomain(merchantInfo) },
    ];

    const updatedSubs = [];

    substitutes.forEach(elem => {
      const key = Object.keys(elem)[0];
      if (this.flowShortCodes[triggerType].includes(key)) {
        updatedSubs.push(elem);
      }
    });

    return updatedSubs;
  }

  public isGSMSymbols(text: string): boolean {
    const symbols = this.store.selectSnapshot(state => state.nonGSMSymbols.symbols);
    return !symbols.some(symbol => RegExp(symbol.regex, 'g').test(text));
  }

  public replaceNonGSMSymbols(text: string): string {
    const symbols = this.store.selectSnapshot(state => state.nonGSMSymbols.symbols);
    let updatedText: string = text;

    if (symbols.length) {
      symbols.forEach(symbol => {
        updatedText = updatedText.replace(RegExp(symbol.regex, 'g'), symbol.replacement);
      });
    }

    return updatedText;
  }

  public checkFirstMessageByType(cardId: string, firstStepId: string, steps, triggerType: string): boolean {
    return triggerType === TRIGGER_TYPES_DATA.SUBSCRIPTION_TRIGGERS_SUBSCRIPTION_VALUE
      && cardId === this._helperService.getFirstStepId(firstStepId, steps, FLOW_TRIGGERS_TYPE.TCPA);
  }
}
