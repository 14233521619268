import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators }             from '@angular/forms';

import { Observable, Subject }     from 'rxjs';
import { first, takeUntil }        from 'rxjs/operators';
import { Select, Store }           from '@ngxs/store';
import { NgxSmartModalService }    from 'ngx-smart-modal';
import { ApiService, FormService } from '@core/services';
import { MessagePreviewService }   from '@shared/service/message-preview.service';
import { NotiService }             from '@notifications/services';
import { ValidatorService }        from '@shared/service/validator.service';
import { FlowData }                from '@flows/models';
import { RenderedMessageParams }   from '@shared/models';

@Component({
  selector: 'k-message-preview',
  templateUrl: './message-preview.component.html',
  styleUrls: ['./message-preview.component.scss'],
})
export class MessagePreviewComponent implements OnInit, OnDestroy {
  @Select(state => state.merchant) merchant$: Observable<any>;
  @Select(state => state.flows.flowTriggerData) flowTriggerData$: Observable<any>;
  @Input() identifier: string;
  @Input() message: string;
  @Input() discountId: number;
  @Input() discountCode: string;

  public phoneControl: FormControl = new FormControl('', Validators.required);
  public isMessagePreviewOpen: boolean = false;
  public renderedMessage: string;
  public loading: boolean = true;

  public get currentTime() {
    return new Intl
      .DateTimeFormat('default', {
        hour12: true,
        hour: 'numeric',
        minute: 'numeric',
      })
      .format(new Date());
  }

  public get isInvalid(): boolean {
    return this.phoneControl.invalid;
  }

  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor (
    private modal: NgxSmartModalService,
    private store: Store,
    private _notiService: NotiService,
    private _formService: FormService,
    private _validatorService: ValidatorService,
    private _messagePreviewService: MessagePreviewService,
    private _apiService: ApiService,
  ) { }

  ngOnInit(): void {
    this._setPhoneValidator();
  }

  public onOpen(): void {
    this.isMessagePreviewOpen = true;
    this.modal.getModalData(this.identifier);
    this._prepareParamsForTestMessage();
  }

  public onCancel(): void {
    this.isMessagePreviewOpen = false;
    this._close();
  }

  public getTestMessageFormError(): string {
    return this._formService.getControlError(this.phoneControl);
  }

  public onSendTestMessage(): void {
    if (this.isInvalid) {
      this._notiService.warning();
      return;
    }

    this._messagePreviewService
      .sendTestMessage(
        this._messagePreviewService.removePhoneMask(this.phoneControl.value),
        this.renderedMessage,
      )
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(
        () => {
          this._close();
          this._notiService.success('Message sent successfully');
        },
        () => {
          this._close();
        },
      );
  }

  // Use this function to pre-filled phone number on open popup
  private _setPhone(): void {
    const phone = this.store.selectSnapshot(state => state.merchant.shop.billingAddress.phone);
    if (!this.phoneControl.value && phone.trim().length) {
      this.phoneControl.setValue(this._messagePreviewService.setPhoneMask(phone.trim(), false));
    }
  }

  private _close(): void {
    this.modal.getModal(this.identifier).close();
  }

  private _setPhoneValidator(): void {
    this.phoneControl
      .setValidators( [
        Validators.required,
        this._validatorService.minLengthDigitsOnlyValidator(8),
        this._validatorService.maxLengthDigitsOnlyValidator(16),
      ]);
  }

  private _prepareParamsForTestMessage(): void {
    this.flowTriggerData$
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(data => {
        this.loading = true;
        const params: RenderedMessageParams = {
          templateString: this.message ? this.message.trim() : this.message,
        };

        if (this.discountId) {
          params.discountId = this.discountId;
        } else if (!this.discountId && this.discountCode) {
          params.discountCode = this.discountCode;
        }

        if (data.trigger?.triggerOptions.keywordName) {
          params.keywordName = data.trigger.triggerOptions.keywordName;
        }

        if (data.trigger?.triggerOptions.length && data.trigger?.triggerType === 'Upsell') {
          const triggerProduct = data.trigger.triggerOptions.triggerProducts[0];
          const recommendedCollection = data.trigger.triggerOptions.triggerCollections[0];
          const recommendedProduct = data.trigger.triggerOptions.recommendedProducts[0];

          params.boughtProductName = triggerProduct.title;
          params.recommendedProductId = recommendedProduct.id;

          if (recommendedProduct) {
            params.recommendedProductName = recommendedProduct.title;
          } else {
            params.recommendedProductName = recommendedCollection.title;
          }
        }

        this._getRenderedTestMessage(params);
      });
  }

  private _getRenderedTestMessage(params) {
    this._apiService.post('testTemplate/render', params)
      .pipe(first())
      .subscribe(result => {
          this.renderedMessage = result.text;
          this.loading = false;
        },
        () => {
          this.loading = false;
        });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
