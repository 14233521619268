import { Injectable }                  from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { FlowListItem, FlowEditItem, FlowDataParams, FlowConnectionsData } from '@flows/models';

import {
  ClearFlowList,
  SetFlowsData,
  SetFlowsList,
  DeleteFlowListItem,
  UpdateFlowListItemStatus,
  UpdateFlowStatuses,
  SetFlowsTriggerData,
  UpdateCancellations,
  DeleteTriggerData,
  SetFlowName,
  UpdateStepParams,
  SetStepsData,
  SetFirstStepId,
  UpdateUpsellParamsData,
  SetFlowsOriginal,
  SetFlowTriggerOptionChannel,
  ClearFlowsOriginal,
  DeleteFlowStep,
  SetFlowsStatistic,
  SetCreateFromScratchSelectedTriggerId,
  SetFlowSortingData,
  UpdateFlowStatus,
  SetFlowConnectionsData,
  UpdateFlowsOriginalItemStatus,
}                                  from '@store/actions';
import { FlowData, FlowStatistic } from '@flows/models/flow-data';

import { SUBSCRIPTION_TRIGGER_PARAMS, TRIGGER_TYPES_DATA } from '@shared/texts';
import { TableSortData }                                   from '@shared/models';

export interface FlowsStateModel {
  flowConnections: FlowConnectionsData[];
  flowOriginal: FlowData;
  flowTriggerData: {
    name: string;
    trigger: {
      triggerType: null,
      triggerOptions: {
        channel: any,
      },
    },
    cancellations: {},
    firstStepId: string,
    steps: FlowDataParams,
    status: boolean,
  };
  flow: {
    model?: FlowEditItem,
    dirty: boolean,
    status: string,
    errors: {},
  };
  flowsData: {
    totalCount: number;
  };
  flowsList: FlowListItem[];
  flowsStatistic: FlowStatistic;
  createFromScratchSelectedTriggerId: string;
  sortingData: TableSortData;
}

@State<FlowsStateModel>({
  name: 'flows',
  defaults: {
    flowConnections: null,
    flowOriginal: null,
    flowTriggerData: {
      name: '',
      trigger: {
        triggerType: null,
        triggerOptions: {
          channel: null,
        },
      },
      cancellations: null,
      firstStepId: null,
      steps: null,
      status: false,
    },
    flow: {
      model: null,
      dirty: false,
      status: '',
      errors: {},
    },
    flowsData: {
      totalCount: 0,
    },
    flowsList: null,
    flowsStatistic: {
      totalEarned: 0,
      totalOrders: 0,
      totalSent: 0,
    },
    createFromScratchSelectedTriggerId: TRIGGER_TYPES_DATA.SUBSCRIPTION_TRIGGERS_SUBSCRIPTION_VALUE,
    sortingData: {
      sort: 'DESC',
      field: 'createdAt',
    },
  },
})
@Injectable()
export class FlowsState {

  @Action(SetFlowSortingData)
  setFlowSortingData({ patchState }: StateContext<FlowsStateModel>, { sorting }: SetFlowSortingData) {
    patchState({ sortingData: sorting });
  }

  @Action(SetFlowConnectionsData)
  setFlowConnectionsData({ patchState }: StateContext<FlowsStateModel>, { payload }: SetFlowConnectionsData) {
    patchState( { flowConnections: payload });
  }

  @Action(SetFlowsTriggerData)
  setFlowsTriggerData({ getState, setState }: StateContext<FlowsStateModel>, { payload }: SetFlowsTriggerData) {
    const flowTriggerData = { ...payload };
    flowTriggerData.trigger = { ...payload.trigger };
    flowTriggerData.trigger.triggerOptions = { ...payload.trigger.triggerOptions };

    if (flowTriggerData.trigger.triggerOptions.keywordName) {
      const { channel, keywordName } = flowTriggerData.trigger.triggerOptions;
      flowTriggerData.trigger.triggerOptions.channel = { name: keywordName, value: channel };
      delete flowTriggerData.trigger.triggerOptions.keywordName;
    }

    if (flowTriggerData.trigger.triggerOptions.channel === SUBSCRIPTION_TRIGGER_PARAMS.CHECKOUT_VALUE) {
      flowTriggerData.trigger.triggerOptions.channel = {
        name: SUBSCRIPTION_TRIGGER_PARAMS.CHECKOUT_NAME,
        value: SUBSCRIPTION_TRIGGER_PARAMS.CHECKOUT_VALUE,
      };
    }

    setState({
      ...getState(),
      flowTriggerData,
    });
  }

  @Action(SetFlowsStatistic)
  setFlowsStatistic({ patchState }: StateContext<FlowsStateModel>, { payload }: SetFlowsStatistic) {
    patchState({
      flowsStatistic: payload,
    });
  }

  @Action(SetFlowsOriginal)
  setFlowsOriginal({ patchState }: StateContext<FlowsStateModel>, { payload }: SetFlowsOriginal) {
    patchState({
      flowOriginal: payload,
    });
  }

  @Action(UpdateFlowsOriginalItemStatus)
  updateFlowsOriginalItemStatus({ getState, setState }: StateContext<FlowsStateModel>,
                           { status }: UpdateFlowsOriginalItemStatus) {
    const state = getState();
    const flowOriginal = { ... state.flowOriginal};

    if(flowOriginal) {
      flowOriginal.status = status;
    }
    setState({
      ...getState(),
      flowOriginal,
    });
  }

  @Action(ClearFlowsOriginal)
  clearFlowsOriginal({ patchState }: StateContext<FlowsStateModel>) {
    patchState({
      flowOriginal: null,
    });
  }

  @Action(SetFlowName)
  setFlowName({ getState, setState }: StateContext<FlowsStateModel>, { payload }: SetFlowName) {
    const state = getState();
    const flowTriggerData = { ...state.flowTriggerData };
    flowTriggerData.name = payload;

    setState({
      ...getState(),
      flowTriggerData,
    });
  }

  @Action(SetFlowTriggerOptionChannel)
  setFlowTriggerOptionChannel({ getState, setState }: StateContext<FlowsStateModel>, { payload }: SetFlowTriggerOptionChannel) {
    const flowTriggerData = { ...getState().flowTriggerData };
    flowTriggerData.trigger = { ...flowTriggerData.trigger };
    flowTriggerData.trigger.triggerOptions = { ...flowTriggerData.trigger.triggerOptions };
    flowTriggerData.trigger.triggerOptions.channel = payload;

    setState({
      ...getState(),
      flowTriggerData,
    });
  }

  @Action(DeleteTriggerData)
  deleteTriggerData({ getState, setState }: StateContext<FlowsStateModel>) {
    setState({
      ...getState(),
      flowTriggerData: {
        name: '',
        trigger: {
          triggerType: null,
          triggerOptions: {
            channel: null,
          },
        },
        cancellations: null,
        firstStepId: null,
        steps: null,
        status: false,
      },
    });
  }

  @Action(SetFlowsData)
  setFlowsData({ patchState }: StateContext<FlowsStateModel>, { payload }: SetFlowsData) {
    patchState({
      flowsData: payload,
    });
  }

  @Action(SetFlowsList)
  setFlowsList({ patchState }: StateContext<FlowsStateModel>, { payload }: SetFlowsList) {
    patchState({ flowsList: payload });
  }

  @Action(UpdateFlowStatuses)
  updateFlowStatuses({ getState, setState }: StateContext<FlowsStateModel>,
                           { statusUpdates }: UpdateFlowStatuses) {
    const state = getState();
    const flowsList = state.flowsList.map(obj => ({ ...obj }));

    statusUpdates.forEach( flowStatus => {
      const flowIndex = flowsList.findIndex(elem => elem.id === flowStatus.id);
      if (~flowIndex) flowsList[flowIndex].status = flowStatus.status;
    });

    setState({
      ...getState(),
      flowsList,
    });
  }

  @Action(UpdateUpsellParamsData)
  updateUpsellParamsData({ getState, setState }: StateContext<FlowsStateModel>,
                     { payload }: UpdateUpsellParamsData) {
    const state = getState();
    const flowTriggerData = { ...state.flowTriggerData };
    flowTriggerData.trigger = { ...flowTriggerData.trigger };
    flowTriggerData.trigger.triggerOptions = { ...flowTriggerData.trigger.triggerOptions };
    flowTriggerData.trigger.triggerOptions[payload.key] = typeof payload.value === 'boolean' ? payload.value : [...payload.value];

    setState({
      ...getState(),
      flowTriggerData,
    });
  }

  @Action(UpdateCancellations)
  updateCancellations({ getState, setState }: StateContext<FlowsStateModel>,
                      { cancellationsUpdate }: UpdateCancellations) {
    const state = getState();
    const flowTriggerData = { ...state.flowTriggerData };
    flowTriggerData.cancellations = { ...cancellationsUpdate };

    setState({
      ...getState(),
      flowTriggerData,
    });
  }

  @Action(DeleteFlowListItem)
  deleteFlowListItem({ getState, setState }: StateContext<FlowsStateModel>,
                           { index }: DeleteFlowListItem) {
    const state = getState();
    const flowsList = state.flowsList.map(obj => ({ ...obj }));

    flowsList.splice(index, 1);

    setState({
      ...getState(),
      flowsList,
    });
  }

  @Action(UpdateFlowListItemStatus)
  updateFlowListItemStatus({ getState, setState }: StateContext<FlowsStateModel>,
                           { id, status }: UpdateFlowListItemStatus) {
    const state = getState();
    const flowsList = state.flowsList.map(obj => ({ ...obj }));
    const flowItem = flowsList.find((elem) => elem.id === id);

    if(flowItem) {
      flowItem.status = status;
    }
    setState({
      ...getState(),
      flowsList,
    });
  }

  @Action(UpdateFlowStatus)
  updateFlowPublishedStatus({ getState, setState }: StateContext<FlowsStateModel>,
                            { status }: UpdateFlowStatus) {
    const state = getState();
    const flowTriggerData = { ...state.flowTriggerData };

    flowTriggerData.status = status;
    setState({
      ...getState(),
      flowTriggerData,
    });
  }

  @Action(ClearFlowList)
  clearFlowsList({ patchState }: StateContext<FlowsStateModel>) {
    patchState({
      flowsList: null,
      sortingData: {
        sort: 'DESC',
        field: 'createdAt',
      },
    });
  }

  @Action(UpdateStepParams)
  updateStepParams({ getState, setState }: StateContext<FlowsStateModel>,
                   { payload }: UpdateStepParams) {
    const state = getState();
    const flowTriggerData = { ...state.flowTriggerData };
    flowTriggerData.steps = { ...flowTriggerData.steps };
    flowTriggerData.steps[payload.key] = { ...flowTriggerData.steps[payload.key] };
    flowTriggerData.steps[payload.key].params = { ...flowTriggerData.steps[payload.key].params };

    const params = flowTriggerData.steps[payload.key].params;
    Object.entries(flowTriggerData.steps[payload.key].params || {}).forEach(([id, param]) => {
      if (param && payload.value[id]) {
        if (typeof param === 'object') {
          params[id] = { ...params[id], ...payload.value[id] };
        } else {
          params[id] = payload.value[id];
        }
      }
    });

    setState({
      ...getState(),
      flowTriggerData,
    });
  }

  @Action(SetStepsData)
  setStepParams({ getState, setState}: StateContext<FlowsStateModel>,
                { payload }: SetStepsData) {
    const state = getState();
    const flowTriggerData = { ...state.flowTriggerData, steps: payload };

    setState({
      ...getState(),
      flowTriggerData,
    });
  }

  @Action(DeleteFlowStep)
  DeleteFlowStep({ getState, setState}: StateContext<FlowsStateModel>,
                 { stepId, branch }: DeleteFlowStep) {
    const state = getState();
    const flowTriggerData = { ...state.flowTriggerData };
    const stepIds = Object.keys(flowTriggerData.steps);
    flowTriggerData.steps = stepIds.reduce((object, key) => {
      object[key] = { ...flowTriggerData.steps[key] };
      return object;
    }, {});

    if (flowTriggerData.firstStepId === stepId) {
      flowTriggerData.firstStepId = this._getNextStepId(flowTriggerData, stepId, branch);
    }

    stepIds.forEach((objKey) => {
      if (Array.isArray(flowTriggerData.steps[objKey].next)) {
        flowTriggerData.steps[objKey].next = [ ...flowTriggerData.steps[objKey].next ];
        const next = flowTriggerData.steps[objKey].next as string[];
        const index = next.findIndex((s) => s === stepId);

        if (index !== -1) {
          next[index] = this._getNextStepId(flowTriggerData, stepId, branch);
        }
      } else if (flowTriggerData.steps[objKey].next === stepId) {
        flowTriggerData.steps[objKey].next = this._getNextStepId(flowTriggerData, stepId, branch);
      }
    });

    delete flowTriggerData.steps[stepId];

    if (!stepIds.length) {
      flowTriggerData.steps = null;
    }

    if (flowTriggerData.firstStepId) {
      const copiedSteps = {};
      this._copySteps(flowTriggerData.firstStepId, flowTriggerData.steps, copiedSteps);
      flowTriggerData.steps = copiedSteps;
    } else {
      flowTriggerData.steps = null;
    }

    setState({
      ...getState(),
      flowTriggerData,
    });
  }

  @Action(SetFirstStepId)
  setFirstStepId({ getState, setState}: StateContext<FlowsStateModel>,
                { payload }: SetFirstStepId) {
    const state = getState();
    const flowTriggerData = { ...state.flowTriggerData, firstStepId: payload };

    setState({
      ...getState(),
      flowTriggerData,
    });
  }

  @Action(SetCreateFromScratchSelectedTriggerId)
  setCreateFromScratchSelectedTriggerId({ patchState }: StateContext<FlowsStateModel>, { payload }: SetCreateFromScratchSelectedTriggerId) {
    patchState({
      createFromScratchSelectedTriggerId: payload,
    });
  }

  private _getNextStepId(flowTriggerData, stepId: string, branch: string): string {
    switch (branch) {
      case 'yes':
        return flowTriggerData.steps[stepId].next[1];
      case 'no':
        return flowTriggerData.steps[stepId].next[0];
      case 'both':
        return null;
      default:
        return flowTriggerData.steps[stepId].next;
    }
  }

  private _copySteps(id: string, steps: FlowDataParams, newSteps = {}): void {
    if (id && steps !== null && steps[id]) {
      newSteps[id] = { ...steps[id] };
      const next = steps[id].next;
      if (Array.isArray(next)) {
        next.forEach((nextId) => {
          this._copySteps(nextId, steps, newSteps);
        });
      } else {
        this._copySteps(next, steps, newSteps);
      }
    }
  }
}
