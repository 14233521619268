import { Input, Directive }     from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

@Directive()
export class CustomControlValueAccessor implements ControlValueAccessor {
  @Input('value') val: any = false;

  public disabled: boolean = false;

  get value() {
    return this.val;
  }

  set value(val) {
    this.val = val;
    this.onChange(val);
  }

  onChange: any = (_: any) => {};
  onTouched: any = () => {};

  writeValue(value): void {
    this.value = value;
  }

  registerOnChange(fn): void {
    this.onChange = fn;
  }

  registerOnTouched(fn): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public onBlur() {
    this.onTouched();
  }
}
