import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

import { ButtonSize, ButtonStatus, ButtonType } from './button.model';
import { IconColor }                            from '../icon/icon.model';

@Component({
  selector: 'button[kButton], a[kButton]',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent implements AfterViewInit, OnChanges {
  @Input() type: ButtonType = 'button';
  @Input() size: ButtonSize = 'medium';
  @Input() status: ButtonStatus = 'primary';
  @Input() loading: boolean = false;
  @Input() spinColor: string = 'white';
  @Input() iconOnly: boolean;
  @Input() iconHover: boolean;
  @Input() iconName: string;
  @Input() iconSize: number = 16;
  @Input() iconColor: IconColor = 'gray';
  @Input() disabled: boolean = false;

  @ViewChild('buttonName') buttonName: ElementRef;

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngAfterViewInit() {
    this.renderer.addClass(this.el.nativeElement, 'k-btn');
    this.renderer.addClass(this.el.nativeElement, `k-btn--${this.status}`);

    if (this.iconOnly) {
      this.renderer.addClass(this.el.nativeElement, 'k-btn--icon-only');
      this.renderer.addClass(this.el.nativeElement, `k-btn--icon-only-${this.size}`);
    } else {
      this.renderer.addClass(this.el.nativeElement, `k-btn--${this.size}`);
    }

    if (this.iconName && this.buttonName.nativeElement.childNodes.length) {
      this.renderer.addClass(this.el.nativeElement, 'k-btn-with-icon');
      this.renderer.addClass(this.buttonName.nativeElement, 'ml--4');
    }

    if (this.el.nativeElement.nodeName === 'BUTTON') {
      this.renderer.setAttribute(this.el.nativeElement, 'type', this.type);
    }

    if (this.iconHover) {
      this.renderer.addClass(this.el.nativeElement, 'k-btn-icon--hover');
    }

    if (this.disabled) {
      this.renderer.setAttribute(this.el.nativeElement, 'disabled', 'disabled');
    }
  }

  public blur(): void {
    this.el.nativeElement.blur();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.disabled) {
      if (this.disabled) {
        this.renderer.setAttribute(this.el.nativeElement, 'disabled', 'disabled');
      } else {
        this.renderer.removeAttribute(this.el.nativeElement, 'disabled');
      }
    }

    if (changes.loading) {
      if (this.loading) {
        this.renderer.addClass(this.el.nativeElement, 'k-btn--spin');
        this.renderer.setAttribute(this.el.nativeElement, 'disabled', 'disabled');
      } else {
        this.renderer.removeClass(this.el.nativeElement, 'k-btn--spin');
        if (!this.disabled) this.renderer.removeAttribute(this.el.nativeElement, 'disabled');
      }
    }
  }
}
