import { Pipe, PipeTransform } from '@angular/core';

import { TableColumnAlignment, TableColumnType } from '@shared/models';

@Pipe({
  name: 'kTableCellAlign',
})
export class TableCellAlignPipe implements PipeTransform {

  transform(align: TableColumnAlignment, type: TableColumnType): TableColumnAlignment {
    if (align) return align;
    if (type && (type === 'number' || type === 'money')) return 'right';
    return 'left';
  }

}
