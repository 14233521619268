import { Injectable }                  from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { KeywordsListItem, KeywordItem } from '@keywords/models';

import {
  SetKeywordsList,
  ClearKeywordList,
  SetKeywordsData,
  UpdateKeywordListItem,
  SetKeywordOriginal,
  ClearKeywordOriginal,
  UpdateKeywordFormImage,
  ClearKeyword,
  SetKeywordSortingData,
} from '@store/actions';
import { TableSortData } from '@shared/models';

export interface KeywordsStateModel {
  keywordOriginal: KeywordItem;
  keyword: {
    model?: KeywordItem,
    dirty: boolean,
    status: string,
    errors: {},
  };
  keywordsData: any;
  keywordsList: KeywordsListItem[];
  sortingData: TableSortData;
}

@State<KeywordsStateModel>({
  name: 'keywords',
  defaults: {
    keywordOriginal: null,
    keyword: {
      model: null,
      dirty: false,
      status: '',
      errors: {},
    },
    keywordsData: null,
    keywordsList: [],
    sortingData: {
      sort: 'DESC',
      field: 'createdAt',
    },
  },
})
@Injectable()
export class KeywordsState {

  @Action(SetKeywordSortingData)
  setKeywordSortingData({ patchState }: StateContext<KeywordsStateModel>, { sorting }: SetKeywordSortingData) {
    patchState({ sortingData: sorting });
  }

  @Action(SetKeywordsData)
  setKeywordsData({ patchState }: StateContext<KeywordsStateModel>, { payload }: SetKeywordsData) {
    patchState({
      keywordsData: payload,
    });
  }

  @Action(SetKeywordsList)
  setKeywordsList({ patchState }: StateContext<KeywordsStateModel>, { payload }: SetKeywordsList) {
    patchState({ keywordsList: payload });
  }

  @Action(UpdateKeywordListItem)
  updateKeywordListItem({ getState, setState }: StateContext<KeywordsStateModel>, { index, key, value }: UpdateKeywordListItem) {
    const state = getState();
    const keywordsList = state.keywordsList.map(obj => ({ ...obj }));

    const firstDeletedIndexInList = keywordsList.findIndex(keyword => keyword.deleted);
    const normalizedIndex = firstDeletedIndexInList ? firstDeletedIndexInList - 1 : 0;

    keywordsList[index][key] = value;

    if (key === 'deleted') {
      const keywordItem = keywordsList.splice(index, 1);
      keywordsList.splice(normalizedIndex, 0, { ...keywordItem[0], usedIn: [] });
      keywordsList.sort(( a, b ) => {
        if ( a.deleted < b.deleted ) {
          return -1;
        }
        if ( a.deleted > b.deleted ){
          return 1;
        }
        return 0;
      });
    }

    setState({
      ...getState(),
      keywordsList,
    });
  }

  @Action(ClearKeywordList)
  clearKeywordsList({ patchState }: StateContext<KeywordsStateModel>) {
    patchState({
      keywordsList: null,
      sortingData: {
        sort: 'DESC',
        field: 'createdAt',
      },
    });
  }

  @Action(SetKeywordOriginal)
  setKeywordOriginal({ patchState }: StateContext<KeywordsStateModel>, { payload }: SetKeywordOriginal) {
    patchState({
      keywordOriginal: payload,
    });
  }

  @Action(ClearKeywordOriginal)
  clearKeywordOriginal({ patchState }: StateContext<KeywordsStateModel>) {
    patchState({
      keywordOriginal: null,
    });
  }

  @Action(UpdateKeywordFormImage) // need for alert changes in form
  updateKeywordFormImage({ getState, setState }: StateContext<KeywordsStateModel>, { payload }: UpdateKeywordFormImage) {
    const state = getState();
    const keyword = JSON.parse(JSON.stringify(state.keyword));

    keyword.model.messageEditor.image = payload;

    setState({
      ...getState(),
      keyword,
    });
  }

  @Action(ClearKeyword)
  clearKeyword({ getState, setState }: StateContext<KeywordsStateModel>) {
    setState({
      ...getState(),
      keyword: {
        model: null,
        dirty: false,
        status: '',
        errors: {},
      },
    });
  }

}
