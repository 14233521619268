import { Pipe, PipeTransform } from '@angular/core';

import { MessagePreviewService } from '@shared/service';

@Pipe({
  name: 'phoneMask',
})
export class PhoneMaskPipe implements PipeTransform {
  constructor(private _messagePreviewService: MessagePreviewService) { }

  transform(phone: string): any {
    return this._messagePreviewService.setPhoneMask(phone, false);
  }

}
