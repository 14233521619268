import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
}                                 from '@angular/core';
import { NgxSmartModalComponent } from 'ngx-smart-modal';
import { ButtonStatus }           from '@shared/components/base/button/button.model';

@Component({
  selector: 'k-welcome-dialog',
  templateUrl: './welcome-dialog.component.html',
  styleUrls: ['./welcome-dialog.component.scss'],
})
export class WelcomeDialogComponent implements OnInit, AfterViewInit {
  @Input() identifier: string;
  @Input() cancelBtnName: string = '';
  @Input() padding: boolean = true;
  @Input() cancelButtonStatus: ButtonStatus = 'secondary';
  @Input() footerWrapperClasses: string[] = ['k-flex--between'];

  @Output() openingModal: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() dismiss: EventEmitter<any> = new EventEmitter<any>();
  @Output() closed: EventEmitter<any> = new EventEmitter<any>();
  @Output() anyClose: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('footer') footer: ElementRef;

  constructor(private renderer: Renderer2) { }

  ngOnInit() { }

  ngAfterViewInit() {
    this.footerWrapperClasses.map(wrapperClass => {
      this.renderer.addClass(this.footer.nativeElement, wrapperClass);
    });
  }

  public onOpeningModal(): void {
    this.openingModal.emit({});
  }

  public onClose(modal: NgxSmartModalComponent, isCloseIcon?: boolean): void {
    modal.close();
    this.cancel.emit(isCloseIcon ? { isCloseIcon: true } : {});
  }

  public onAnyClose(): void {
    this.anyClose.emit({});
  }

  public onDismiss(): void {
    this.dismiss.emit({});
  }

  public onCloseFinished(): void {
    this.closed.emit();
  }
}
