import { Component, OnDestroy, OnInit }   from '@angular/core';
import { Params }                         from '@angular/router';
import { Select }                         from '@ngxs/store';
import { Observable, Subject, zip }       from 'rxjs';
import { debounceTime, first, takeUntil } from 'rxjs/operators';

import {
  MerchantService,
  RouterService,
  SocketService,
  QuietHoursService,
  NonGsmSymbolsService,
} from '@core/services';
import { NotiService }      from '@notifications/services';
import { ResponseService }  from '@responses/services';
import { SentryService }    from '@core/services/sentry.service';
import { MerchantInfo }     from '@core/models';
import {
  FAKE_ALERT_APP_DISABLING_TRIGGER_ID,
  FAKE_ALERT_APP_ENABLING_TRIGGER_ID,
  FAKE_ALERT_APP_STATUS_TRIGGER_ID,
} from '@shared/texts';

@Component({
  selector: 'k-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [ResponseService],
})
export class AppComponent implements OnInit, OnDestroy {
  @Select(state => state.app.hideHeader) hideHeader$: Observable<boolean>;
  @Select(state => state.merchant) merchant$: Observable<MerchantInfo>;

  public isExpanded: boolean = false;
  public isShown: boolean = false;
  public hasTransition: boolean = false;

  private merchantShop: string;
  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(private _sentryService: SentryService,
              private _routerService: RouterService,
              private _notiService: NotiService,
              private _merchantService: MerchantService,
              private _nonGsmSymbolsService: NonGsmSymbolsService,
              private quietHoursService: QuietHoursService,
              private _socketService: SocketService,
              private _responseService: ResponseService) { }

  ngOnInit() {
    this._routerService.listenToRouterEvents();

    this._routerService.paramsLoaded$
      .pipe(first())
      .subscribe((params: Params) => {
        this._initDataOnRouterParams(params);
      });

    this._showAppStatusState();
  }

  public onToggle(e): void {
    this.isExpanded = e.isExpanded;
    this.hasTransition = e.hasTransition;
  }

  private _showAppStatusState(): void {
    zip(this.merchant$.pipe(
        debounceTime(100),
        takeUntil(this.ngUnsubscribe$),
      ))
      .subscribe(([merchantInfo]) => {
        this._closeFakeAlerts();

        if (merchantInfo.appStatusChangingTo !== null && merchantInfo.appStatusChangingTo === false) {
          this.isShown = false;
          this._notiService.closeFakeAlert({ triggerId: FAKE_ALERT_APP_STATUS_TRIGGER_ID, id: FAKE_ALERT_APP_STATUS_TRIGGER_ID });
          this._notiService.showAppDisablingAlert();
        }

        if (merchantInfo.appStatusChangingTo !== null && merchantInfo.appStatusChangingTo === true) {
          this.isShown = false;
          this._notiService.closeFakeAlert({ triggerId: FAKE_ALERT_APP_STATUS_TRIGGER_ID, id: FAKE_ALERT_APP_STATUS_TRIGGER_ID });
          this._notiService.showAppEnablingAlert();
        }

        if (merchantInfo.appStatusChangingTo === null) {
          this._notiService.closeFakeAlert({ triggerId: FAKE_ALERT_APP_DISABLING_TRIGGER_ID, id: FAKE_ALERT_APP_DISABLING_TRIGGER_ID });
          this._notiService.closeFakeAlert({ triggerId: FAKE_ALERT_APP_ENABLING_TRIGGER_ID, id: FAKE_ALERT_APP_ENABLING_TRIGGER_ID });

          this._showAppDisableAlert(merchantInfo);
        }
      });
  }

  private _closeFakeAlerts(): void {
    this._notiService.closeFakeAlert({ triggerId: FAKE_ALERT_APP_STATUS_TRIGGER_ID, id: FAKE_ALERT_APP_STATUS_TRIGGER_ID });
    this._notiService.closeFakeAlert({ triggerId: FAKE_ALERT_APP_DISABLING_TRIGGER_ID, id: FAKE_ALERT_APP_DISABLING_TRIGGER_ID });
    this._notiService.closeFakeAlert({ triggerId: FAKE_ALERT_APP_ENABLING_TRIGGER_ID, id: FAKE_ALERT_APP_ENABLING_TRIGGER_ID });
  }

  private _showAppDisableAlert(merchantInfo: MerchantInfo): void {
    if (!this.isShown && merchantInfo.activeLOA && merchantInfo.appEnabled === false) {
      this.isShown = true;
      this._notiService.showAppDisabledStateAlert();
    }

    if (merchantInfo.activeLOA && merchantInfo.appEnabled === true) {
      this._notiService.closeFakeAlert({ triggerId: FAKE_ALERT_APP_STATUS_TRIGGER_ID, id: FAKE_ALERT_APP_STATUS_TRIGGER_ID });
    }
  }

  private _initDataOnRouterParams(params: Params): void {
    this._sentryService.init();

    this._getResponsesUnreadStatus();
    this._socketService.initSocket();
    this._socketService.sendShop(params.shop);
    this._updateResponses();

    this._showRealtimeNotifications();
    this._trackSocketDisconnect();

    this.merchantShop = params.shop;
    this._merchantService.loadMerchantInfo();
    this._nonGsmSymbolsService.getNonGSMSymbols();
    this.quietHoursService.getQuietHours();

    this._notiService.getNotifications()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe();
  }

  private _trackSocketDisconnect(): void {
    this._socketService.onDisconnect()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe();
  }

  private _showRealtimeNotifications(): void {
    this._socketService.onNewNotification()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe();
  }

  private _getResponsesUnreadStatus(): void {
    this._responseService.getResponsesUnreadStatus()
      .pipe(first())
      .subscribe();
  }

  private _updateResponses(): void {
    this._socketService.onNewResponse()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe();

    this._socketService.onBotStatusConversation()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe();

    this._socketService.onAppStatusChangingTo()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe();

    this._socketService.onResponseMessageStatus()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe();

    this._socketService.onResponseMessageInit()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe();

    this._socketService.onBalanceUpdate()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe();

    this._socketService.onChangeContactStatus()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe();
  }

  ngOnDestroy() {
    this._socketService.disconnect();

    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
