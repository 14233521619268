import { BalanceComponent }              from '@features/components/balance/balance.component';
import { PaymentDialogComponent }        from '@features/components/payment-dialog/payment-dialog.component';
import { OnboardDialogComponent }        from '@features/components/onboard-dialog/onboard-dialog.component';
import { IntercomComponent }             from '@features/components/intercom/intercom.component';
import { UnsavedChangesDialogComponent } from '@features/components/unsaved-changes-dialog/unsaved-changes-dialog.component';

export const components = [
  BalanceComponent,
  PaymentDialogComponent,
  OnboardDialogComponent,
  IntercomComponent,
  UnsavedChangesDialogComponent,
];
