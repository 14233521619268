import { Injectable }                  from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { patch }                       from '@ngxs/store/operators';

import { SetMerchantInfo, UpdateMerchantInfo, UpdateMerchantShortenerSettings } from '@store/actions';
import { ShortUrl }                                                             from '@core/models';
import { IntercomUserModel }                                                    from '@features/components/intercom/models';

export interface MerchantStateModel {
  appEnabled: boolean;
  appStatusChangingTo: boolean | null;
  botAssignedFeature: boolean;
  isCheckoutActive: boolean;
  isShopifyPlus: boolean;
  isSubscriptionActive: boolean;
  isSubscriptionFree: boolean;
  domain: string;
  primaryDomain: string;
  isOCUCheckoutAvailable: boolean;
  isOCUIntegrationEnabled: boolean;
  intercom: IntercomUserModel;
  phone: string;
  shortenerSettings: {
    globalShortUrl: ShortUrl;
  };
  shop: any;
  notifications: any;
  balance: number;
  autoRechargeActive: boolean;
  autoRechargeUsed: boolean;
  autoRechargeSpent: number;
  autoRechargeAmount: number;
  autoRechargeThreshold: number;
  autoRechargeTotalAmountLimit: number;
  shareableLinksWizardViewed: boolean;
  showTCPASettingsMessage: boolean;
  hasInstalledOCU: boolean;
  themeId: number;
}

@State<MerchantStateModel>({
  name: 'merchant',
  defaults: {
    appEnabled: false,
    appStatusChangingTo: null,
    botAssignedFeature: null,
    isCheckoutActive: false,
    isShopifyPlus: null,
    domain: '',
    primaryDomain: '',
    isSubscriptionFree: false,
    isSubscriptionActive: false,
    isOCUCheckoutAvailable: false,
    isOCUIntegrationEnabled: false,
    intercom: null,
    phone: '',
    shortenerSettings: {
      globalShortUrl: 'default',
    },
    shop: {},
    notifications: {},
    balance: 0,
    autoRechargeActive: false,
    autoRechargeUsed: false,
    autoRechargeSpent: 0,
    autoRechargeAmount: 0,
    autoRechargeThreshold: 0,
    autoRechargeTotalAmountLimit: 0,
    shareableLinksWizardViewed: true,
    showTCPASettingsMessage: false,
    hasInstalledOCU: false,
    themeId: null,
  },
})
@Injectable()
export class MerchantState {

  @Action(SetMerchantInfo)
  setMerchantInfo({ setState }: StateContext<MerchantStateModel>, { payload }: SetMerchantInfo) {
    setState(payload);
  }

  @Action(UpdateMerchantInfo)
  updateMerchantInfo({ patchState }: StateContext<MerchantStateModel>, { payload }: UpdateMerchantInfo) {
    patchState({ [payload.key]: payload.value });
  }

  @Action(UpdateMerchantShortenerSettings)
  updateMerchantShortenerSettings({ setState }: StateContext<MerchantStateModel>, { payload }: UpdateMerchantShortenerSettings) {
    setState(
      patch({
        shortenerSettings: patch({
          [payload.key]: payload.value,
        }),
      }),
    );
  }

}
