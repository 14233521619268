export interface SubscriptionEditorUIParameters {
  flow: {
    title?: string;
    hint: string;
  };
  editor?: {
    title: string;
  };
  response?: {
    hint: string;
  };
  create?: {
    title: string;
    description: string;
    cancel: string;
    save: string;
  };
  save?: {
    title: string;
    description: string;
    cancel: string;
    save: string;
  };
}
