import { Injectable }     from '@angular/core';
import { Store }          from '@ngxs/store';
import { Observable }     from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { Router }         from '@angular/router';

import { SetMerchantInfo } from '@store/actions';
import { ApiService }      from './api.service';
import { MerchantInfo }    from '@core/models';
import { LoaModel }        from '@legal-agreements/models';
import { LEGAL_ROUTS }     from '@core/texts';

@Injectable()
export class MerchantService {
  public isLOA: any;

  constructor(
    private store: Store,
    private _apiService: ApiService,
    private router: Router) { }

  /*
  * TODO: need to remove the conversion to Promise
   */
  public loadMerchantInfo() {
    return this._apiService.get('merchant')
      .pipe(
        tap((merchantInfo: MerchantInfo) => {
          this.store.dispatch(new SetMerchantInfo(merchantInfo));
          switch (merchantInfo.instalationProcess) {
            case LEGAL_ROUTS.INSTALL:
              this.router.navigate(['/legal/install']);
              break;
            case LEGAL_ROUTS.TERMS:
              this.router.navigate(['/legal/terms']);
              break;
            case LEGAL_ROUTS.PROVISIONING_IN_PROGRESS:
            case LEGAL_ROUTS.PROVISIONING_DONE:
              this.router.navigate(['/settings/sending-logic']);
              break;
          }
        }),
      ).toPromise();
  }

  public addLegalAgreements(loa: LoaModel): Observable<LoaModel> {
    return this._apiService.post('merchant/loa', this._prepareFormData(loa))
      .pipe(
        tap((data) => {
          return data;
        }),
      );
  }

  public addTerms(terms: any): Observable<any> {
    return this._apiService.post('merchant/terms', terms)
      .pipe(
        tap((data) => {
          return data;
        }),
      );
  }

  public setPopupState(): Observable<any> {
    return this._apiService.post('merchant/success-popup-viewed', null)
      .pipe(
        tap((data) => {
          return data;
        }),
      );
  }

  public updateSettingsNotifierMessageStatus(): Observable<any> {
    return this._apiService.post('merchant/close-settings-message', null);
  }

  private _prepareFormData(loaData: LoaModel, excludedKeys?: any[]) {
    const data = {
      ...loaData,
    };

    const { ...payload } = data;

    const formData: any = {};

    for (const field in payload) {
      if (payload.hasOwnProperty(field)) {
        if (excludedKeys && excludedKeys.includes(field)) continue;

        if (typeof payload[field] === 'string') payload[field] = payload[field].trim();

        formData[field] = payload[field];
      }
    }

    return formData;
  }
}
