import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isArray',
})
export class IsArrayPipe implements PipeTransform {

  // tslint:disable-next-line:no-any
  transform(value: any): boolean {
    return Array.isArray(value);
  }

}

