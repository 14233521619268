import { Component, ChangeDetectionStrategy, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { UpsellItemValidatorService } from '@shared/service/upsell/upsell-item-validator.service';
import { ComponentName, UpsellType }  from '@shared/components/base/upsell/upsell.model';
import { UpsellItem }                 from '@shared/models/upsell/upsell-item';

@Component({
  selector: 'k-upsell-item',
  templateUrl: './upsell-item.component.html',
  styleUrls: ['./upsell-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpsellItemComponent implements OnInit {
  @Input() componentName: ComponentName;
  @Input() item: UpsellItem;
  @Input() type: UpsellType;
  @Input() flowEdit: boolean;

  @Output() deleteItem: EventEmitter<void> = new EventEmitter<void>();

  public get error(): { [key: string]: string } {
    return this[`${this.type}Error`];
  }

  private get triggerProductsError(): { [key: string]: string } {
    let error: { [key: string]: string };

    if (
      this._upsellItemValidatorService.isDeleted(this.item)
      || this._upsellItemValidatorService.noQuantity(this.item)
      || !this._upsellItemValidatorService.isPublished(this.item)
    ) {
      error = {
        hint: 'Flow would not be triggered by this Product',
      };
    }

    return error;
  }

  private get triggerCollectionsError(): { [key: string]: string } {
    let error: { [key: string]: string };

    if (
      this._upsellItemValidatorService.isCollectionEmpty(this.item)
      || this._upsellItemValidatorService.isDeleted(this.item)
      || this._upsellItemValidatorService.isCollectionProductsInvalid(this.item)
    ) {
      error = {
        hint: 'Flow would not be triggered by this Collection',
      };
    }

    return error;
  }

  private get recommendedProductsError(): { [key: string]: string } {
    let error: { [key: string]: string };

    if (this._upsellItemValidatorService.isDeleted(this.item)) {
      error = {
        hint: 'This item would not be sent as it is not in the stock anymore',
      };
    } else if (this._upsellItemValidatorService.noQuantity(this.item) && this._upsellItemValidatorService.isPublished(this.item)) {
      error = {
        hint: `This item would not be sent as it is sold`,
      };
    } else if (!this._upsellItemValidatorService.isPublished(this.item)) {
      error = {
        hint: 'This item would not be sent as it is not available anymore',
      };
    }

    return error;
  }

  constructor( private _upsellItemValidatorService: UpsellItemValidatorService ) { }

  ngOnInit() { }
}
