import {
  Component,
  OnInit,
  AfterViewInit,
  ElementRef,
  forwardRef,
  Input,
  Renderer2,
  ViewChild,
}                            from '@angular/core';
import { KeyValue }          from '@angular/common';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { DropdownSize }               from './dropdown.model';
import { CustomControlValueAccessor } from '../custom-control-value-accessor';

@Component({
  selector: 'k-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownComponent),
      multi: true,
    },
  ],
})
export class DropdownComponent extends CustomControlValueAccessor implements OnInit, AfterViewInit {
  @Input() size: DropdownSize = 'normal';
  @Input() readonly: boolean;
  @Input() hasError: boolean = false;
  @Input() placeholder: string;
  @Input() label: string;
  @Input() helper: string;
  @Input() helperClass: string = '';
  @Input() options: any[];
  @Input() optgroupOptions: { [key: string]: any };

  @ViewChild('dropdown') dropdown: ElementRef;

  public id: string;

  constructor(private renderer: Renderer2) {
    super();
  }

  ngOnInit() {
    this.id = this._genId();
  }

  ngAfterViewInit() {
    this.renderer.addClass(this.dropdown.nativeElement, `k-dropdown--${this.size}`);
  }

  public onSelect(value): void {
    this.value = value;
  }

  private _genId() {
    return `did-${Math.round(Math.random() * 10e5)}`;
  }

  public originalOrder(a: KeyValue<number, string>, b: KeyValue<number, string>): number {
    return 0;
  }
}
