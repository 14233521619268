import { Inject, Injectable } from '@angular/core';
import { DOCUMENT }           from '@angular/common';
import * as Sentry            from '@sentry/angular';
import { Integrations }       from '@sentry/tracing';

import { AnalyticsIDs } from '@core/models/anaytics.model';

@Injectable()
export class SentryService {

  constructor(@Inject(DOCUMENT) private document) {
  }

  public init() {
    const element = this.document.getElementById('knkt-analytics');
    if (element) {
      try {
        const keys = JSON.parse(element.textContent) as AnalyticsIDs;
        if (!!keys.sentry.length) {
          this._init(keys.sentry);
        }
      } catch (e) {
        console.warn('Error during parsing ANALYTICS keys');
      }
    }
  }

  private _init(id: string): void {
    Sentry.init({
      dsn: id,
      integrations: [
        new Integrations.BrowserTracing({
          tracingOrigins: ['localhost', /^\//],
          routingInstrumentation: Sentry.routingInstrumentation,
        }),
      ],

      tracesSampleRate: 1.0,
    });
  }
}
