import { ResponseListItemTypeMap } from '@responses/models';

export const inboxListTabsData =
  [
    {
      id: 'Unresolved',
      name: 'UNRESOLVED',
    },
    {
      id: 'Resolved',
      name: 'RESOLVED',
    },
    {
      id: 'All',
      name: 'ALL',
    },
  ];

export const RESPONSE_LIST_ITEM_TYPE_MAP: ResponseListItemTypeMap = {
  'Black Friday': { tagColor: 'dark-lavender' },
  Price: { tagColor: 'dark-lavender' },
  Restock: { tagColor: 'darkslateblue' },
  'Order Issues': { tagColor: 'gold' },
  'Delivery Issue': { tagColor: 'gold' },
  Delivery: { tagColor: 'lavenderblush' },
  Delivered: { tagColor: 'lavenderblush' },
  Payment: { tagColor: 'orange' },
  'Product Request': { tagColor: 'blueviolet' },
  Discount: { tagColor: 'dodgerblue' },
  'Yes Confirmation': { tagColor: 'limegreen' },
  'Purchase Later': { tagColor: 'mediumturquoise' },
  No: { tagColor: 'darkorange' },
  Thanks: { tagColor: 'lightcyan' },
  Greeting: { tagColor: 'darkturquoise' },
  Stop: { tagColor: 'deeppink' },
  'Link Issues': { tagColor: 'hotpink' },
  Contact: { tagColor: 'lightyellow' },
  Order: { tagColor: 'midnightblue' },
  Subscribe: { tagColor: 'darkcyan' },
  'Sample Request': { tagColor: 'silver' },
  Unknown: { tagColor: 'transparent' },
  'Retail Stores': { tagColor: 'darkturquoise' },
  'Are You a Bot': { tagColor: 'darkcyan' },
  Farewell: { tagColor: 'lightcyan' },
  'Animal Testing': { tagColor: 'midnightblue' },
  'International Shipping': { tagColor: 'silver' },
  'How Are You': { tagColor: 'blueviolet' },
  'Shipping Cost': { tagColor: 'darkorange' },
  Feedback: { tagColor: 'gold' },
  'Order Status': { tagColor: 'gold' },
  'Dark Circles': { tagColor: 'darkturquoise' },
  Autoship: { tagColor: 'limegreen' },
  'Skin Tones': { tagColor: 'lavenderblush' },
  'Last All Day': { tagColor: 'limegreen' },
  'Become Model': { tagColor: 'limegreen' },
  Help: { tagColor: 'hotpink' },
  Foundation: { tagColor: 'deeppink' },
  Holiday: { tagColor: 'deeppink' },
  'Brand Love': { tagColor: 'deeppink' },
  'Sensitive Skin': { tagColor: 'darkcyan' },
  'First Product': { tagColor: 'darkcyan' },
  Sunscreen: { tagColor: 'hotpink' },
};
