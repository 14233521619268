import {
  Directive,
  ElementRef,
  Renderer2,
  HostListener,
  HostBinding,
  forwardRef,
  Input,
  Inject,
  ComponentFactoryResolver,
  Injector,
  OnInit,
  Output,
  EventEmitter,
  NgModuleRef,
  OnDestroy,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DOCUMENT }                                from '@angular/common';
import { Subject }                                 from 'rxjs';
import { debounceTime, first, takeUntil, tap }     from 'rxjs/operators';

import { HelperService, NanoService }               from '@core/services';
import { TagComponent }                             from '@shared/components/base/tag/tag.component';
import { TagRemovedData }                           from '@shared/components/base/tag/tag.model';
import { STOP, TCPAPopup, TCPAShareableLink }       from '@shared/data-model/short-codes.model';
import { ShortCodeEditorInfo }                      from '@shared/components/message-builder/short-codes/short-codes.model';
import { MessageBuilderService }                    from '@shared/service/message-builder';
import { MMS }                                      from '@shared/texts';

@Directive({
  selector: '[kContenteditable][formControlName],[kContenteditable][formControl],[kContenteditable][ngModel]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ContenteditableDirective),
      multi: true,
    },
  ],
})
export class ContenteditableDirective implements ControlValueAccessor, OnInit, OnDestroy {
  @HostBinding('attr.contenteditable') @Input() contenteditable = true;

  @Input() propValueAccessor = 'innerText';
  @Input() nonformattedPaste: boolean | string = false;
  @Input() liveTagPaste: boolean = false;
  @Input() messageType: string;
  @Input() availableTags: ShortCodeEditorInfo[];
  @Input() maxlength: number;

  @Output() cursorPosition: EventEmitter<number> = new EventEmitter<number>();
  @Output() tagRemoved: EventEmitter<string> = new EventEmitter<string>();
  @Output() blurred: EventEmitter<void> = new EventEmitter<void>();
  @Output() initialized: EventEmitter<void> = new EventEmitter<void>();

  private onChange: (value: string) => void;
  private onTouched: () => void;
  private removeDisabledState: () => void;
  private keywordPattern = /{\w*}|STOP|Msg and Data Rates may apply|subscribe to automated recurring personalized marketing alerts from {StoreName} and agree to our Terms and Conditions {Link} even if your number is on a 'do not call' list. Consent is not a condition of any purchase|subscribe to automated recurring personalized marketing alerts from {StoreName}/;
  private breakLinePattern = /\r?\n|\r/;
  private breakLineAtTheEndPattern = /(\r?\n|\r)$/;
  private isManualInsertReady = false;
  private tagsState: { valid: number, invalid: number };
  private stopCursorRecursion = false;
  private cachedCursorPosition: number;
  private cursorPositionBeforeBlur: number;
  private processChange$: Subject<void> = new Subject<void>();
  private processPaste$: Subject<string> = new Subject<string>();
  private processGSMCheck$: Subject<string> = new Subject<string>();
  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  private get _value(): string {
    const value = this.elementRef.nativeElement[this.propValueAccessor];
    if (value) {
      return value
        .replace(/[\u00A0]/gm, ' ')
        .replace(/[\uFFFC]/g, '')
        .replace(/}\n/gm, '}')
        .replace(/STOP\n/gm, STOP)
        .replace(/subscribe to automated recurring personalized marketing alerts from {StoreName} and agree to our Terms and Conditions {Link} even if your number is on a 'do not call' list. Consent is not a condition of any purchase\n/gm, TCPAShareableLink)
        .replace(/subscribe to automated recurring personalized marketing alerts from {StoreName}\n/gm, TCPAPopup)
        .replace(/}(\r?\n|\r)$/g, '}');
    }
    return value;
  }

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private componentFactoryResolver: ComponentFactoryResolver,
    @Inject(DOCUMENT) private document: Document,
    private injector: Injector,
    // tslint:disable-next-line:no-any
    private ngModule: NgModuleRef<any>,
    private _messageBuilderService: MessageBuilderService,
    private _nanoService: NanoService,
    private _helperService: HelperService,
  ) { }

  ngOnInit() {
    this.processChange$
      .pipe(debounceTime(100), takeUntil(this.ngUnsubscribe$))
      .subscribe(() => {
        this._processChange();
      });

    this.processChange$
      .pipe(first())
      .subscribe(() => {
        this.initialized.emit();
      });

    this.processPaste$
      .pipe(
        debounceTime(500),
        tap(() => { this.processChange$.next(); }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe((text: string) => {
        this._processPaste(text);
      });

    this.processGSMCheck$
      .pipe(
        debounceTime(500),
        tap(() => { this.processChange$.next(); }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe(() => {
        if (!this._isGSMTextValid()) {
          this._processGSMReplacementPaste();
        }
      });
  }

  @HostListener('click')
  public updateCursorPosition(): void {
    this.cursorPosition.emit(this._getPosition());
  }

  @HostListener('keydown', ['$event'])
  onEvent(event) {
    if (
      (this.maxlength
        && this._value.length >= this.maxlength + 1
        && (event.key.length === 1
          && !(event.key === 'Control' || event.ctrlKey)
        )
      ) || (event.key === 'Control' || event.ctrlKey) && event.key === 'b'
        || (event.key === 'Control' || event.ctrlKey) && event.key === 'i'
        || (event.key === 'Control' || event.ctrlKey) && event.key === 'u'
    ) {
      return false;
    } else {
      this.processGSMCheck$.next();
    }
  }

  @HostListener('keypress', ['$event'])
  onKeypressHandler(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      if (!this._value.length) {
        this._insert(this._createBRTag());
      }
      if (window.getSelection) {
        const selection = window.getSelection();
        const range = window.getSelection().getRangeAt(0);
        const br = document.createElement('br');
        range.deleteContents();
        range.insertNode(br);
        range.setStartAfter(br);
        range.setEndAfter(br);
        range.collapse(false);
        selection.removeAllRanges();
        selection.addRange(range);
        return false;
      }
    }
  }

  @HostListener('keyup', ['$event'])
  onKeyupHandler(event: KeyboardEvent) {
    if (event.key === 'Backspace' && this._value === '\n') {
      this._clear();
    }
    this._insertBRAtTheEndIfNeeded();
    if (this._value.length) {
      this.processChange$.next();
    }
    this.updateCursorPosition();
  }

  @HostListener('input', ['$event'])
  public callOnChange(event: InputEvent): void {
    const oldTagsState = { ...this.tagsState };
    this._calculateTagStates();
    const newTagsState = { ...this.tagsState };
    const isValidTagByManualInsert = oldTagsState.invalid - 1 === newTagsState.invalid
      || oldTagsState.valid + 1 === newTagsState.valid;
    this.isManualInsertReady = this._isTagsValid(event.data) || isValidTagByManualInsert;

    if (this._isLiveTagPasteEnabled()) {
      const position = this._getPosition();
      const oldLength = this._getTextLength();
      const text = this._value;
      this._setText(text);
      const newLength = this._getTextLength();
      const newPosition = position + (newLength - oldLength);
      this._setCurrentCursorPosition(newPosition);
    }

    this.processChange$.next();
  }

  @HostListener('focus')
  public callOnFocus(): void {
    this.cursorPositionBeforeBlur = null;
  }

  @HostListener('blur')
  public callOnTouched(): void {
    if (typeof this.onTouched === 'function') {
      this.onTouched();
    }
    this.blurred.emit();
    this.cursorPositionBeforeBlur = this.cachedCursorPosition;
  }

  public writeValue(value: string): void {
    let normalizedValue = value == null ? '' : value;
    if (normalizedValue) {
      if (this.maxlength && normalizedValue.length >= this.maxlength) {
        normalizedValue = normalizedValue.substr(0, this.maxlength + 1);
      }
      this._updateText(normalizedValue);
    } else {
      this._clear();
    }
  }

  public registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.renderer.setAttribute(this.elementRef.nativeElement, 'disabled', 'true');
      this.removeDisabledState = this.renderer.listen(
        this.elementRef.nativeElement,
        'keydown',
        this._listenerDisabledState,
      );
    } else {
      if (this.removeDisabledState) {
        this.renderer.removeAttribute(this.elementRef.nativeElement, 'disabled');
        this.removeDisabledState();
      }
    }
  }

  @HostListener('paste', ['$event'])
  public preventFormatedPaste(event: ClipboardEvent): void {
    if (this.nonformattedPaste === false || this.nonformattedPaste === 'false') {
      return;
    }
    event.preventDefault();
    const { clipboardData } = event;
    const textLengthDifference = 1600 - this._value.length;
    let textToPaste = clipboardData.getData('text/plain') || clipboardData.getData('text');
    if (textToPaste.length >= textLengthDifference && this.messageType === MMS) {
      textToPaste = textToPaste.substr(0, textLengthDifference);
    }

    this.processPaste$.next(textToPaste);
  }

  private _focus(): void {
    this.elementRef.nativeElement.focus();
  }

  private _processChange(): void {
    if (typeof this.onChange === 'function') {
      this.onChange(this._value);
    }
  }

  private _processPaste(textToPaste: string): void {
    if (textToPaste && typeof this._value === 'string') {
      const cursorPosition = this._getPosition();
      const textBeforeCursor = this._value.substr(0, this._getPosition());
      const textAfterCursor = this._value.substr(this._getPosition(), this._getTextLength());
      this._setText(this._messageBuilderService.replaceNonGSMSymbols(textBeforeCursor + textToPaste + textAfterCursor));
      this._setCurrentCursorPosition(cursorPosition + textToPaste.length);
    }
  }

  private _isGSMTextValid(): boolean {
    return this._messageBuilderService.isGSMSymbols(this._value);
  }

  private _processGSMReplacementPaste(): void {
    if (typeof this._value === 'string') {
      const cursorPosition = this._getPosition();
      this._setText(this._messageBuilderService.replaceNonGSMSymbols(this._value));
      this._setCurrentCursorPosition(cursorPosition);
    }
  }

  private _isLiveTagPasteEnabled(): boolean {
    return this.liveTagPaste
      && this._value
      && this.isManualInsertReady;
  }

  private _calculateTagStates(): void {
    this.tagsState = { valid: 0, invalid: 0 };
    this._updateTagsState(this._value);
  }

  private _updateTagsState(text: string): void {
    const match = text.match(/{\w*}|STOP/gm);
    if (match) {
      if (this._isTagsValid(match[0])) {
        this.tagsState.valid++;
      } else {
        this.tagsState.invalid++;
      }
      this._updateTagsState(text.replace(match[0], ''));
    }
  }

  private _setText(text: string): void {
    this._clear();
    this._loadText(text);
    this._insertBRAtTheEndIfNeeded();
  }

  private _loadText(text: string): void {
    const match = this.keywordPattern.exec(text);
    if (match) {
      const textArr = text.split(match[0]);
      if (this._isTagsValid(match[0])) {
        const component = this._prepareComponent(match[0]);
        if (textArr[0].length > 0) {
          this._insertTextNode(textArr[0]);
        }
        this._insert(component);
      } else {
        this._insertTextNode(textArr[0] + match[0]);
      }
      textArr.shift();
      const joiner = textArr.length > 1 ? match[0] : '';
      this._loadText(textArr.join(joiner));
    } else {
      this._insertTextNode(text);
    }
  }

  private _insertTextNode(text): void {
    const match = this.breakLinePattern.exec(text);
    if (match) {
      const textArr = text.split(this.breakLinePattern);
      textArr.forEach((textElement, index) => {
        this._insert(this.document.createTextNode(textElement));

        if (textArr.length - 1 !== index) {
          this._insert(this._createBRTag());
        }
      });
    } else {
      this._insert(this.document.createTextNode(text));
    }
  }

  private _insertBRAtTheEndIfNeeded(): void {
    if (this._value.length && !this.breakLineAtTheEndPattern.test(this.elementRef.nativeElement[this.propValueAccessor])) {
      this._insert(this._createBRTag());
    }
  }

  private _createBRTag(): HTMLElement {
    return this.document.createElement('br');
  }

  private _isTagsValid(tag: string): boolean {
    return this.availableTags && !!this._getAvailableShortCode(tag);
  }

  private _getAvailableShortCode(name: string): ShortCodeEditorInfo {
    return this.availableTags.find((sc) => sc.name === name);
  }

  private _updateText(text: string): void {
    const position = this.cursorPositionBeforeBlur || this._getPosition();
    const oldLength = this._getTextLength();
    this._setText(this._messageBuilderService.replaceNonGSMSymbols(text));
    const newLength = this._getTextLength();
    const cursorPosition = Math.abs(newLength - oldLength) + position;
    this._setCurrentCursorPosition(cursorPosition);
    this.processChange$.next();
  }

  private _prepareComponent(content: string): Node {
    const tagTooltip = this._getAvailableShortCode(content);
    const tagContent = this.renderer.createElement('span');
    const text = this._helperService.getSoftHyphens(content);
    this.renderer.setProperty(tagContent,'innerHTML', `<wbr>${text}`);
    const tagComponentFactory = this.componentFactoryResolver.resolveComponentFactory(TagComponent);
    const tagComponentRef = tagComponentFactory.create(this.injector, [[tagContent]], null, this.ngModule);
    tagComponentRef.instance.isCloseButtonEnabled = true;
    tagComponentRef.instance.isUsedInEditor = true;
    tagComponentRef.instance.tagColor = 'lavender';
    tagComponentRef.instance.displayHint = !!tagTooltip.hint;
    tagComponentRef.instance.hint = tagTooltip.hint;
    tagComponentRef.instance.tagRemoved.subscribe((tag: TagRemovedData) => {
      this.elementRef.nativeElement.blur();
      this.tagRemoved.emit(tag.text);
      const newText = this._getValueWithoutTag(tag.id);
      this._setCurrentCursorPosition(this._getPosition() - 1);
      this._updateText(newText);
      this.processChange$.next();
    });
    tagComponentRef.location.nativeElement.setAttribute('contenteditable', false);
    tagComponentRef.changeDetectorRef.detectChanges();
    tagComponentRef.location.nativeElement.setAttribute('id', 'id' + this._nanoService.generateNanoId());
    return tagComponentRef.location.nativeElement;
  }

  private _listenerDisabledState(e: KeyboardEvent): void {
    e.preventDefault();
  }

  private _insert(element: Node): void {
    this.elementRef.nativeElement.append(element);
  }

  private _clear(): void {
    this.renderer.setProperty(this.elementRef.nativeElement, this.propValueAccessor, '');
  }

  private _getTextLength(): number {
    return this.elementRef.nativeElement.textContent.length;
  }

  private _getValueWithoutTag(tagId: string): string {
    this.elementRef.nativeElement.querySelector(`#${tagId}`).remove();
    return this._value;
  }

  private _getPosition(): number {
    this._focus();
    const _range = this.document.getSelection().getRangeAt(0);
    const range = _range.cloneRange();
    range.selectNodeContents(this.elementRef.nativeElement);
    range.setEnd(_range.endContainer, _range.endOffset);
    const content = range.cloneContents();
    let childElementCount = range.cloneContents().childElementCount;

    if (content.textContent) {
      childElementCount -= content.textContent.split(this.keywordPattern).length;
    }

    this.cachedCursorPosition = range.toString().length + 1 + childElementCount;
    return this.cachedCursorPosition;
  }

  private _createRange(node: Node, position: { length: number }, range?: Range): Range {
    if (!range) {
      range = this.document.createRange();
      range.selectNode(node);
      range.setStart(node, 0);
    }

    if (position.length === 0) {
      range.setEnd(node, position.length);
    } else if (node && position.length > 0) {
      if (node.nodeType === Node.TEXT_NODE) {
        if (node.textContent.length < position.length) {
          position.length -= node.textContent.length;
        } else {
          if (node.parentNode.nodeName === 'K-TAG') {
            position.length = 0;
            return range;
          }
          range.setEnd(node, position.length);
          position.length = 0;
        }
      } else if (node.nodeName === 'BR') {
        position.length -= 1;
      } else {
        const childNodes = Array.from(node.childNodes);
        childNodes.every((childNode) => {
          range = this._createRange(childNode, position, range);
          if (node.nodeName === 'K-TAG' && position.length === 0) {
            this.stopCursorRecursion = true;
            return true;
          }
          if (node.parentNode.nodeName === 'K-TEXT-AREA' && node.childNodes.length > 0 && this.stopCursorRecursion) {
            this.stopCursorRecursion = false;
            return true;
          }
          return !(position.length === 0);
        });
      }
    }

    return range;
  }

  private _setCurrentCursorPosition(cursorPositions): void {
    if (cursorPositions >= 0) {
      const selection = this.document.getSelection();
      const messageLength = this._value.length + 2;
      const position = cursorPositions > messageLength ? messageLength : cursorPositions;

      const range = this._createRange(this.elementRef.nativeElement, { length: position });

      if (range) {
        range.collapse(false);
        selection.removeAllRanges();
        selection.addRange(range);
      }

      this.updateCursorPosition();
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
