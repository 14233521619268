import { Injectable }              from '@angular/core';
import { ActivatedRoute }          from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { delay, first, tap }       from 'rxjs/operators';
import { Store }                   from '@ngxs/store';

import { notificationsConfig }                                 from '@config';
import { ApiService }                                          from '@core/services/api.service';
import { RouterService }                                       from '@core/services/router.service';
import { NotificationData, NotificationEventType, NotiParams } from '@notifications/models';
import {
  AddShownAlerts,
  AddRemainingAlerts,
  RemoveRemainingAlert,
  RemoveShownAlert,
  SetRemainingNotifications,
  SetShownNotifications,
} from '@store/actions';
import { SAVED_SUCCESSFULLY }                                  from '@core/texts';
import {
  FAKE_ALERT_APP_DISABLING_TRIGGER_ID,
  FAKE_ALERT_APP_ENABLING_TRIGGER_ID,
  FAKE_ALERT_APP_STATUS_TRIGGER_ID,
  FAKE_ALERT_TRIGGER_NAME,
} from '@shared/texts';

@Injectable()
export class NotiService {
  public showNotification$: Subject<NotiParams> = new Subject<NotiParams>();
  public removeNotification$: Subject<NotiParams> = new Subject<NotificationData>();
  public showAlert$: Subject<NotiParams> = new Subject<NotiParams>();
  public removeAlert$: Subject<NotificationData> = new Subject<NotificationData>();
  public emitCloseNotificationById$: Subject<number> = new Subject<number>();

  private icons = {
    balanceOneTimePayment: 'c-balance',
    bonusStep: 'c-saved',
    bonusCompleted: 'c-celebration',
    checkoutDone: 'c-celebration',
  };
  private showServerError: boolean = true;
  private linkIndexPattern: RegExp = /{\d}/;

  constructor(private store: Store,
              private route: ActivatedRoute,
              private _apiService: ApiService,
              private _routerService: RouterService) { }

  public getNotifications(): Observable<NotificationData[]> {
    return this._apiService.get('notifications')
      .pipe(
        tap((data: NotificationData[]) => {
          const alerts = data.filter(notification => notification.channel === 'alert');
          const notifications = data.filter(notification => notification.channel === 'notification');

          this.showAlerts(alerts, this._maxAlertsStack);
          this.showNotifications(notifications, notificationsConfig.maxStack);
        }),
      );
  }

  public removeNotification(notification: NotiParams): void {
    this.removeNotification$.next(notification);
  }

  // todo check title in all function calls
  public success(title, icon?: string): void {
    this.showNotification$.next({
      title: title || this._getSavedNotification.title,
      icon: icon ? icon : 'c-saved',
      id: this._getSavedNotification.userNotificationId,
      triggerId: this._getSavedNotification.triggerId,
      timer: this._getSavedNotification.timer,
    });
  }

  public notify(merchantNotification: string, config: NotiParams = {title: SAVED_SUCCESSFULLY}): void {
    const params: NotiParams = {
      title: config.title || this._getMerchantNotification(merchantNotification).title,
      message: config.message || this._getMerchantNotification(merchantNotification).message,
      icon: config.icon || 'c-saved',
      id: config.id || this._getMerchantNotification(merchantNotification).userNotificationId,
      triggerId: config.triggerId || this._getMerchantNotification(merchantNotification).triggerId,
      trigger: config.trigger || this._getMerchantNotification(merchantNotification).trigger,
      timer: config.timer || this._getMerchantNotification(merchantNotification).timer,
    };

    if (config.cancelButton) {
      params.cancelButton = { name: config.cancelButton.name };
    }

    this.showNotification$.next(params);
  }

  public copied(icon: string, title?: string, message?: string): void {
    this.showNotification$.next( {
      title: title || this._getCopiedNotification.title,
      message: message || this._getCopiedNotification.message,
      icon: icon ? icon : 'copied',
      id: this._getCopiedNotification.userNotificationId || this._generateRandomRange(42, 4200),
      triggerId: this._getCopiedNotification.triggerId,
      timer: this._getCopiedNotification.timer,
      cancelButton: {
        name: this._getCopiedNotification.buttons.cancelButton.name,
      },
    });
  }

  public smsSwitchedToMMS(icon?: string, title?: string, message?: string): void {
    this.showNotification$.next( {
      title: title || this._getSmsSwitchedToMMSNotification.title,
      message: message || this._getSmsSwitchedToMMSNotification.message,
      icon: icon ? icon : 'c-saved',
      id: this._getSmsSwitchedToMMSNotification.userNotificationId,
      triggerId: this._getSmsSwitchedToMMSNotification.triggerId,
      timer: this._getSmsSwitchedToMMSNotification.timer,
    });
  }

  public savedKeywordSegment(icon?: string, title?: string): void {
    this.showNotification$.next( {
      title: title || this._getSavedKeywordSegmentNotification.title,
      icon: icon ? icon : 'c-saved',
      id: this._getSavedKeywordSegmentNotification.userNotificationId,
      triggerId: this._getSavedKeywordSegmentNotification.triggerId,
      timer: this._getSavedKeywordSegmentNotification.timer,

    });
  }

  public savedMessaging(icon?: string, title?: string, message?: string): void {
    this.showNotification$.next( {
      title: title || this._getSavedMessagingNotification.title,
      message: message || this._getSavedMessagingNotification.message,
      icon: icon ? icon : 'c-saved',
      id: this._getSavedMessagingNotification.userNotificationId,
      triggerId: this._getSavedMessagingNotification.triggerId,
      timer: this._getSavedMessagingNotification.timer,

    });
  }

  public savedSubscription(icon?: string, title?: string, message?: string): void {
    this.showNotification$.next( {
      title: title || this._getSavedSubscriptionNotification.title,
      message: message || this._getSavedSubscriptionNotification.message,
      icon: icon ? icon : 'c-saved',
      id: this._getSavedSubscriptionNotification.userNotificationId,
      triggerId: this._getSavedSubscriptionNotification.triggerId,
      timer: this._getSavedSubscriptionNotification.timer,

    });
  }

  public flowPublished(title: string, icon?: string): void {
    this.showNotification$.next({
      title: title || this._getFlowPublishedNotification.title,
      icon: icon ? icon : 'c-saved',
      id: this._getFlowPublishedNotification.userNotificationId,
      triggerId: this._getFlowPublishedNotification.triggerId,
      timer: this._getFlowPublishedNotification.timer,
    });
  }

  public flowSuccessfullyPublished(title: string, actionButtonName: string, linkTo: string): void {
    console.log(linkTo);
    this.showNotification$.next({
      title: title || this._getFlowPublishedNotification.title,
      icon: 'c-saved',
      id: this._getFlowPublishedNotification.triggerId,
      triggerId: this._getFlowPublishedNotification.triggerId,
      message: 'You can now continue editing and publishing your popup',
      timer: null,
      cancelButton: {
        name: 'Got it!',
      },
      actionButton: {
        name: actionButtonName,
        linkTo,
      },
    });
  }

  public flowUnpublished(title: string, icon?: string): void {
    this.showNotification$.next({
      title: title || this._getFlowUnpublishedNotification.title,
      icon: icon ? icon : 'c-saved',
      id: this._getFlowUnpublishedNotification.userNotificationId,
      triggerId: this._getFlowUnpublishedNotification.triggerId,
      timer: this._getFlowUnpublishedNotification.timer,
    });
  }

  public campaignPublished(): void {
    const params: NotiParams = {
      title: this._getCampaignPublishedNotification.title,
      icon: 'c-saved',
      id: 100001,
      triggerId: this._getCampaignPublishedNotification.triggerId,
      trigger: this._getCampaignPublishedNotification.trigger,
      timer: this._getCampaignPublishedNotification.timer,
    };

    if (this._getCampaignPublishedNotification.buttons.cancelButton) {
      params.cancelButton = { name: this._getCampaignPublishedNotification.buttons.cancelButton.name };
    }

    this.showNotification$.next(params);
  }

  public campaignUnpublished(): void {
    const params: NotiParams = {
      title: this._getCampaignUnpublishedNotification.title,
      message: this._getCampaignUnpublishedNotification.message,
      icon: 'c-saved',
      id: 100001,
      triggerId: this._getCampaignUnpublishedNotification.triggerId,
      trigger: this._getCampaignUnpublishedNotification.trigger,
      timer: this._getCampaignUnpublishedNotification.timer,
    };

    if (this._getCampaignPublishedNotification.buttons.cancelButton) {
      params.cancelButton = { name: this._getCampaignUnpublishedNotification.buttons.cancelButton.name };
    }

    this.showNotification$.next(params);
  }

  public popupPublished(title: string, icon?: string): void {
    this.showNotification$.next({
      title: title || this._getPopupPublishedNotification.title,
      icon: icon ? icon : 'c-saved',
      id: this._getPopupPublishedNotification.userNotificationId,
      triggerId: this._getPopupPublishedNotification.triggerId,
      timer: this._getPopupPublishedNotification.timer,
    });
  }

  public popupUnpublished(title: string, icon?: string): void {
    this.showNotification$.next({
      title: title || this._getPopupUnpublishedNotification.title,
      icon: icon ? icon : 'c-saved',
      id: this._getPopupUnpublishedNotification.userNotificationId,
      triggerId: this._getPopupUnpublishedNotification.triggerId,
      timer: this._getPopupUnpublishedNotification.timer,
    });
  }

  public linkPublished(title: string, icon?: string): void {
    this.showNotification$.next({
      title: title || this._getShareableLinkPublishedNotification.title,
      icon: icon ? icon : 'c-saved',
      id: this._getShareableLinkPublishedNotification.userNotificationId,
      triggerId: this._getShareableLinkPublishedNotification.triggerId,
      timer: this._getShareableLinkPublishedNotification.timer,
    });
  }

  public linkUnpublished(title: string, icon?: string): void {
    this.showNotification$.next({
      title: title || this._getShareableLinkUnpublishedNotification.title,
      icon: icon ? icon : 'c-saved',
      id: this._getShareableLinkUnpublishedNotification.userNotificationId,
      triggerId: this._getShareableLinkUnpublishedNotification.triggerId,
      timer: this._getShareableLinkUnpublishedNotification.timer,
    });
  }

  public flowTurnedOff(title?: string, message?: string, icon?: string): void {
    this.showNotification$.next({
      title: title || this._getFlowTurnedOffNotification.title,
      icon: icon ? icon : 'c-error',
      message: message || this._getFlowTurnedOffNotification.message,
      id: this._getFlowTurnedOffNotification.userNotificationId,
      triggerId: this._getFlowTurnedOffNotification.triggerId,
      trigger: this._getFlowTurnedOffNotification.trigger,
      timer: this._getFlowTurnedOffNotification.timer,
    });
  }

  public error(title): void {
    // error shows after websocket response
    if (['BodyValidationError', 'backendError', 'systemErrorShopify', 'systemErrorAmazon', 'campain not valid', 'campaign not valid', 'campaign is not valid'].includes(title)) {
      return;
    }

    if (['Server is not responding'].includes(title)) {
      if (this.showServerError) {
        this.showServerError = false;
        of(null).pipe(delay(1000), first())
          .subscribe(() => { this.showServerError = true; });
      } else {
        return;
      }
    }

    this.showNotification$.next({
      title,
      icon: 'c-error',
      timer: 6,
    });
  }

  public warning(): void {
    this.showNotification$.next({
      icon: 'c-error',
      timer: 6,
      triggerId: this._getSaveFieldErrorNotification.triggerId,
      message: this._getSaveFieldErrorNotification.message,
      title: this._getSaveFieldErrorNotification.title,
    });
  }

  public showSubscriptionWarning(): void {
    this.showNotification$.next({
      icon: 'c-error',
      timer: 6,
      triggerId: this._getChangedSubscriptionSourceErrorNotification.triggerId,
      message: this._getChangedSubscriptionSourceErrorNotification.message,
      title: this._getChangedSubscriptionSourceErrorNotification.title,
    });
  }

  public showAlerts(data: NotificationData[], count: number, removeRemainingAlert?: boolean): void {
    const shownAlerts = this.store.selectSnapshot(state => state.notifications.shownAlerts);
    const alertsToShow = data.splice(0, count);

    if (shownAlerts.length === this._maxAlertsStack) {
      this.store.dispatch(new AddRemainingAlerts(alertsToShow));
      return;
    }

    alertsToShow.forEach(alert => {
      this.showAlert$.next({
        name: alert.name || '',
        id: alert.userNotificationId,
        triggerId: alert.triggerId,
        trigger: alert.trigger,
        type: alert.type,
        title: alert.title,
        message: alert.message,
        linkTemplates: alert.linkTemplates,
        icon: alert.icon || 'c-error',
        cancelButton: alert.buttons?.cancelButton,
      });
    });

    this.store.dispatch(new AddShownAlerts(alertsToShow));

    if (removeRemainingAlert && alertsToShow.length) {
      alertsToShow.forEach((alertToShow: NotificationData) => {
        this.store.dispatch(new RemoveRemainingAlert(alertToShow.triggerId));
      });
    }

    if (removeRemainingAlert && !alertsToShow.length) {
      this.store.dispatch(new AddRemainingAlerts(data));
    }
  }

  public showNotifications(data: NotificationData[], count: number) {
    const shownNotifications = data.splice(0, count);

    shownNotifications.forEach(notification => {
      //TODO: Need to find more appropriate way for setting icons for specific cases
      const icon = this.icons[notification.trigger] || 'c-error';

      this.showNotification$.next({
        id: notification.userNotificationId,
        triggerId: notification.triggerId,
        trigger: notification.trigger,
        title: notification.title,
        message: notification.message,
        linkTemplates: notification.linkTemplates,
        icon,
        cancelButton: notification.buttons?.cancelButton,
        actionButton: notification.buttons?.actionButton,
        timer: notification.timer,
      });
    });

    this.store.dispatch(new SetShownNotifications(shownNotifications));
    this.store.dispatch(new SetRemainingNotifications(data));
  }

  public showOfflineAlert(): void {
    if (navigator.onLine) return;

    const offlineAlert = {
      name: 'offline',
      ...this._getOfflineAlert,
    };

    if (!this._hasAlertWithTriggerId(offlineAlert.triggerId)) {
      this.showAlerts([offlineAlert], 1);
    }
  }

  public removeOfflineAlert(): void {
    if (!this._getOfflineAlert) return;
    if (!this._hasAlertWithTriggerId(this._getOfflineAlert.triggerId)) return;

    this.removeAlert$.next(this._getOfflineAlert);
    this.store.dispatch(new RemoveShownAlert(this._getOfflineAlert.triggerId));
    this.store.dispatch(new RemoveRemainingAlert(this._getOfflineAlert.triggerId));
  }

  public trackEvent(userNotificationId: number, triggerId: number, event: NotificationEventType): Observable<void> {
    const params: { triggerId: number, event: string, userNotificationId?: number } = { triggerId, event };

    if (userNotificationId) {
      params.userNotificationId = userNotificationId;
    }

    return this._apiService.post('notifications/event', params);
  }

  public isNumberReady(alerts): boolean {
    const numberInProvisioning = alerts.find(alert => alert.trigger === 'numberInProvisioning');
    const numberReady = alerts.find(alert => alert.trigger === 'numberReady');

    return numberInProvisioning && numberReady;
  }

  public closeAlert(data): void {
    this.trackEvent(data.userNotificationId, data.triggerId, 'close').subscribe();

    this.store.dispatch(new RemoveShownAlert(data.triggerId, data.id));
  }

  public closeFakeAlert(data): void {
    this.store.dispatch(new RemoveShownAlert(data.triggerId, data.id));
  }

  public getLinkIndexPattern(): RegExp {
    return this.linkIndexPattern;
  }

  public getMessageChunks(template: string): string[] {
    return template.split(/({\d+})/);
  }

  public showAppDisabledStateAlert(): void {
    const alert: NotificationData = {
      channel: 'alert',
      linkTemplates: [
        {
          linkText: 'turn it on here',
          url: '/settings/general',
          openOnNewPage: false,
          absolute: false,
        },
      ],
      message: 'The app is currently disabled and messages sending is suspended. You can {0}.',
      pages: ['allPages'],
      title: null,
      trigger: FAKE_ALERT_TRIGGER_NAME,
      triggerId: FAKE_ALERT_APP_STATUS_TRIGGER_ID,
      userNotificationId: FAKE_ALERT_APP_STATUS_TRIGGER_ID,
      type: 'warning',
    };

    this.showAlerts([alert], 1);
  }

  public showAppDisablingAlert(): void {
    const alert: NotificationData = {
      channel: 'alert',
      linkTemplates: null,
      message: 'Disabling is in progress. It may take about a minute',
      pages: ['allPages'],
      title: null,
      trigger: FAKE_ALERT_TRIGGER_NAME,
      triggerId: FAKE_ALERT_APP_DISABLING_TRIGGER_ID,
      userNotificationId: FAKE_ALERT_APP_DISABLING_TRIGGER_ID,
      type: 'warning',
      icon: 'hourglass',
    };

    this.showAlerts([alert], 1);
  }

  public showAppEnablingAlert(): void {
    const alert: NotificationData = {
      channel: 'alert',
      linkTemplates: null,
      message: 'Enabling is in progress. It may take about a minute',
      pages: ['allPages'],
      title: null,
      trigger: FAKE_ALERT_TRIGGER_NAME,
      triggerId: FAKE_ALERT_APP_ENABLING_TRIGGER_ID,
      userNotificationId: FAKE_ALERT_APP_ENABLING_TRIGGER_ID,
      type: 'success',
      icon: 'hourglass',
    };

    this.showAlerts([alert], 1);
  }

  private _generateRandomRange(min: number, max: number): number {
    return (Math.floor(Math.random() * (Math.ceil(max) - Math.floor(min) + 1) + min)) * 42;
  }

  private _getMerchantNotification(id: string): NotificationData {
    return this.store.selectSnapshot(state => state.merchant.notifications[id]);
  }

  private _hasAlertWithTriggerId(triggerId: number): boolean {
    const alerts = [
      ...this.store.selectSnapshot(state => state.notifications.shownAlerts),
      ...this.store.selectSnapshot(state => state.notifications.remainingAlerts),
    ];

    return !!alerts.find((alert: NotificationData) => alert.triggerId === triggerId);
  }

  private get _maxAlertsStack(): number {
    const route = this._routerService.getDeepestFirstChildSnapshot(this.route.snapshot);

    return route.data.hideHeader ? 2 : notificationsConfig.maxStack;
  }

  private get _getOfflineAlert(): NotificationData {
    return this.store.selectSnapshot(state => state.merchant.notifications.lostConnection);
  }

  private get _getSavedNotification(): NotificationData {
    return this.store.selectSnapshot(state => state.merchant.notifications.saved);
  }

  private get _getCopiedNotification(): NotificationData {
    return this.store.selectSnapshot(state => state.merchant.notifications.shareableLinkCopied);
  }

  private get _getSmsSwitchedToMMSNotification(): NotificationData {
    return this.store.selectSnapshot(state => state.merchant.notifications.smsSwitchedToMMS);
  }

  private get _getSaveFieldErrorNotification(): NotificationData {
    return this.store.selectSnapshot(state => state.merchant.notifications.saveFieldError);
  }

  private get _getChangedSubscriptionSourceErrorNotification(): NotificationData {
    return this.store.selectSnapshot(state => state.merchant.notifications.changedSubscriptionSource);
  }

  private get _getSavedKeywordSegmentNotification(): NotificationData {
    return this.store.selectSnapshot(state => state.merchant.notifications.savedKeywordSegment);
  }

  private get _getSavedMessagingNotification(): NotificationData {
    return this.store.selectSnapshot(state => state.merchant.notifications.savedMessaging);
  }

  private get _getSavedSubscriptionNotification(): NotificationData {
    return this.store.selectSnapshot(state => state.merchant.notifications.savedSubscription);
  }

  private get _getFlowPublishedNotification(): NotificationData {
    return this.store.selectSnapshot(state => state.merchant.notifications.flowPublished);
  }

  private get _getFlowUnpublishedNotification(): NotificationData {
    return this.store.selectSnapshot(state => state.merchant.notifications.flowUnpublished);
  }

  private get _getCampaignPublishedNotification(): NotificationData {
    return this.store.selectSnapshot(state => state.merchant.notifications.campaignEnabled);
  }

  private get _getCampaignUnpublishedNotification(): NotificationData {
    return this.store.selectSnapshot(state => state.merchant.notifications.campaignDisabled);
  }

  private get _getPopupPublishedNotification(): NotificationData {
    return this.store.selectSnapshot(state => state.merchant.notifications.popupPublished);
  }

  private get _getPopupUnpublishedNotification(): NotificationData {
    return this.store.selectSnapshot(state => state.merchant.notifications.popupUnpublished);
  }

  private get _getShareableLinkPublishedNotification(): NotificationData {
    return this.store.selectSnapshot(state => state.merchant.notifications.linkPublished);
  }

  private get _getShareableLinkUnpublishedNotification(): NotificationData {
    return this.store.selectSnapshot(state => state.merchant.notifications.linkUnpublished);
  }

  private get _getFlowTurnedOffNotification(): NotificationData {
    return this.store.selectSnapshot(state => state.merchant.notifications.flowTurnedOff);
  }
}
