import { Component, AfterContentInit, ContentChildren, QueryList } from '@angular/core';

import { AccordionItemComponent } from './accordion-item/accordion-item.component';

@Component({
  selector: 'k-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent implements AfterContentInit {
  @ContentChildren(AccordionItemComponent) items: QueryList<AccordionItemComponent>;

  ngAfterContentInit() {
    this.items.forEach((item: AccordionItemComponent) => {
      item.toggle.subscribe((itemState) => this.itemToggledHandler(item, itemState));
    });
  }

  public itemToggledHandler(item: AccordionItemComponent, itemState: boolean) {
    //this.items.forEach((t: AccordionItemComponent) => t.opened = false);
    item.opened = !itemState;
  }
}
