import { AfterViewInit, Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';

import { LabelAligning } from '@shared/components/base/label/label.model';

@Component({
  selector: 'k-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss'],
})
export class LabelComponent implements OnInit, AfterViewInit {

  @Input() labelTitle: string;
  @Input() largeLabel: boolean = false;
  @Input() labelBackLine: boolean = false;
  @Input() aligning: LabelAligning = 'left';

  @ViewChild('label') label: ElementRef;

  constructor(private renderer: Renderer2) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.renderer.addClass(this.label.nativeElement, `k-label--${this.aligning}`);
  }
}
