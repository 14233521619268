import { Injectable }                  from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import moment from 'moment';

import {
  AddResponseHistoryMessage,
  AddResponseHistoryMessages,
  ClearResponse,
  ClearResponseList,
  ClearResponseOriginal,
  DeleteResponseHistoryMessage,
  MoveDownResentedScheduledMessage,
  SetResponseHistory,
  SetResponseOriginal,
  SetResponsesData,
  SetResponsesFilter,
  SetResponsesList,
  SetResponsesUnreadStatus,
  UpdatedResponseItemSubscription,
  UpdatedResponsesList,
  UpdateResponseHistoryMessage,
  UpdateResponseContactStatus,
  SetResponsesSortingData,
  UpdateBotConversationStatus,
  UpdateUnresolvedResponsesList,
  SetCurrentResponsesTab,
  UpdateUnresolvedTabOnUnsubscribe,
} from '@store/actions';

import { ResponseHistoryItem, ResponseListItem, ResponseStatuses } from '@responses/models';
import { TableSortData }                                           from '@shared/models';

export interface ResponsesStateModel {
  responseOriginal: any;
  response: {
    model?: any,
    dirty: boolean,
    status: string,
    errors: {},
  };
  responseHistory: {
    messages: ResponseHistoryItem[],
    totalCount: number,
  };
  responsesData: {
    totalCount: number;
    unresolvedResponses: boolean;
  };
  currentTab: string;
  responsesList: ResponseListItem[];
  responsesUnreadStatus: boolean;
  responsesFilter: ResponseStatuses | null;
  sortingData: TableSortData;
}

@State<ResponsesStateModel>({
  name: 'responses',
  defaults: {
    responseOriginal: null,
    response: {
      model: null,
      dirty: false,
      status: '',
      errors: {},
    },
    responseHistory: {
      messages: [],
      totalCount: 0,
    },
    responsesData: {
      totalCount: 0,
      unresolvedResponses: false,
    },
    currentTab: 'Unresolved',
    responsesList: null,
    responsesUnreadStatus: false,
    responsesFilter: 'Unresolved',
    sortingData: {
      sort: 'DESC',
      field: 'receivedAt',
    },
  },
})
@Injectable()
export class ResponsesState {
  @Action(SetResponsesData)
  setResponsesData({ patchState }: StateContext<ResponsesStateModel>, { payload }: SetResponsesData) {
    patchState({
      responsesData: payload,
    });
  }

  @Action(SetResponsesFilter)
  setResponsesFilter({ patchState }: StateContext<ResponsesStateModel>, { payload }: SetResponsesFilter) {
    patchState({ responsesFilter: payload });
  }

  @Action(SetResponsesList)
  setResponsesList({ patchState }: StateContext<ResponsesStateModel>, { payload }: SetResponsesList) {
    patchState({ responsesList: payload });
  }

  @Action(SetResponsesSortingData)
  setResponsesSortingData({ patchState }: StateContext<ResponsesStateModel>, { sorting }: SetResponsesSortingData) {
    patchState({ sortingData: sorting });
  }

  @Action(UpdateBotConversationStatus)
  updateBotConversationStatus({ getState, patchState }: StateContext<ResponsesStateModel>, { status }: UpdateBotConversationStatus) {
    const state = getState();
    const responsesList = state.responsesList?.map(obj => ({ ...obj })) || [];

    responsesList.map((response) => {
      if (response.responseId === status.responseId) {
        response.inBotConversation = status.inBotConversation;
      }
      return response;
    });
    patchState({
      ...getState(),
      responsesList,
    });
  }

  @Action(UpdatedResponsesList)
  updatedResponsesList({ getState, setState }: StateContext<ResponsesStateModel>, { payload }: UpdatedResponsesList) {
    const state = getState();
    const responsesList = state.responsesList?.map(obj => ({ ...obj })) || [];
    const responsesFilter = state.currentTab;
    const responseIndex = responsesList.findIndex(elem => payload.id === elem.id);

    if (payload.type === 'outgoing') return;

    let isOptedOut = false;
    responsesList
      .map((response) => {
        if (payload.responseId === response.id && response.subscribed === 'ACTIVE') {
          response.lastMessage = payload.lastMessage;
          response.responseStatuses = [...response.responseStatuses, 'Opted out'];
          response.subscribed = 'UNSUBSCRIBED';
          response.inBotConversation = payload.inBotConversation;
          response.botAssigned = payload.botAssigned;
          isOptedOut = true;
        }
        return response;
      });

    if (responsesFilter !== 'Unresolved') {
      const isNotFilteredResponse = responsesFilter === 'Resolved' && responsesFilter !== responsesList[responseIndex].status;
      if (isNotFilteredResponse) return;
    }

    if (responsesFilter === 'Opted out' && responsesList[responseIndex].subscribed !== 'UNSUBSCRIBED') return;

    let totalCount = state.responsesData.totalCount;
    const unresolvedResponses = state.responsesData.unresolvedResponses;
    const method = state.sortingData.sort === 'ASC' ? 'push' : 'unshift';

    if (responseIndex > -1) {
      responsesList.splice(responseIndex, 1);
      responsesList[method](payload);
    } else if (!isOptedOut) {
      const hasMoreItems = totalCount > responsesList.length;

      if (hasMoreItems && state.sortingData.sort !== 'ASC') {
        responsesList.pop();
        responsesList[method](payload);
      }

      if (!hasMoreItems) {
        responsesList[method](payload);
      }

      totalCount++;
    }

    setState({
      ...getState(),
      responsesList,
      responsesData: {
        totalCount,
        unresolvedResponses,
      },
    });
  }

  @Action(UpdateUnresolvedResponsesList)
  updateUnresolvedResponsesList({ getState, patchState }: StateContext<ResponsesStateModel>,
                                { responseId }: UpdateUnresolvedResponsesList) {
    const state = getState();
    const responsesList = state.responsesList.map(obj => ({ ...obj })) || [];
    const id = responsesList.findIndex(response => response.id === responseId);
    responsesList.splice(id, 1);

    patchState({
      responsesList,
    });
  }

  @Action(SetCurrentResponsesTab)
  setCurrentResponsesTab({ getState, patchState }: StateContext<ResponsesStateModel>,
                         { responsesCurrentTab }: SetCurrentResponsesTab) {
    const state = { ...getState() };
    state.currentTab = responsesCurrentTab;
    patchState({
      ...getState(),
    });
  }

  @Action(UpdateUnresolvedTabOnUnsubscribe)
  updateUnresolvedTabOnUnsubscribe({ getState, patchState }: StateContext<ResponsesStateModel>,
                                   { responseId }: UpdateUnresolvedTabOnUnsubscribe) {
    const state = getState();
    const responsesList = state.responsesList.map(obj => ({ ...obj })) || [];
    if ( state.currentTab === 'Unresolved') {
      const id = responsesList.findIndex(response => response.id === responseId);
      responsesList.splice(id, 1);
    }

    patchState({
      responsesList,
    });
  }

  @Action(SetResponsesUnreadStatus)
  setResponsesUnreadStatus({ patchState }: StateContext<ResponsesStateModel>, { payload }: SetResponsesUnreadStatus) {
    patchState({ responsesUnreadStatus: payload });
  }

  @Action(UpdatedResponseItemSubscription)
  updateResponseItemSubscription({ getState, setState }: StateContext<ResponsesStateModel>, { id }: UpdatedResponseItemSubscription) {
    const state = getState();
    const responsesList = state.responsesList.map(obj => {
      const subscribed: 'ACTIVE' | 'UNSUBSCRIBED' =  obj.id === id ? 'UNSUBSCRIBED' : obj.subscribed;
      const isRead: string = obj.id === id ? 'true' : obj.isRead;
      const status: 'Unresolved' | 'Resolved' = obj.id === id ? 'Resolved' : obj.status;
      const responseStatuses: ResponseStatuses[] = obj.id === id ? ['Resolved', 'Opted out'] : obj.responseStatuses;
      return { ...obj, subscribed, isRead, status, responseStatuses };
    });

    setState({
      ...getState(),
      responsesList,
    });
  }


  @Action(ClearResponseList)
  clearResponseList({ patchState }: StateContext<ResponsesStateModel>) {
    patchState({
      responsesList: null,
      sortingData: {
        sort: 'DESC',
        field: 'receivedAt',
      },
    });
  }

  @Action(SetResponseOriginal)
  setResponseOriginal({ patchState }: StateContext<ResponsesStateModel>, { payload }: SetResponseOriginal) {
    patchState({
      responseOriginal: payload,
    });
  }

  @Action(ClearResponseOriginal)
  clearResponseOriginal({ patchState }: StateContext<ResponsesStateModel>) {
    patchState({
      responseOriginal: null,
    });
  }

  @Action(ClearResponse)
  clearResponse({ getState, setState }: StateContext<ResponsesStateModel>) {
    setState({
      ...getState(),
      response: {
        model: null,
        dirty: false,
        status: '',
        errors: {},
      },
    });
  }

  @Action(SetResponseHistory)
  setResponseHistory({ patchState }: StateContext<ResponsesStateModel>, { payload }: SetResponseHistory) {
    patchState({ responseHistory: payload });
  }

  @Action(AddResponseHistoryMessages)
  addResponseHistoryMessages({ getState, patchState }: StateContext<ResponsesStateModel>, { payload }: AddResponseHistoryMessages) {
    const history = getState().responseHistory;

    patchState({
      responseHistory: {
        messages: [...payload, ...history.messages],
        totalCount: history.totalCount,
      },
    });
  }

  @Action(AddResponseHistoryMessage)
  addResponseHistoryMessage(
    { getState, patchState }: StateContext<ResponsesStateModel>,
    { payload, responseId }: AddResponseHistoryMessage,
  ) {
    const history = getState().responseHistory;
    const responseOriginal = getState().responseOriginal;

    if (responseOriginal && responseOriginal.id !== responseId) return;

    let messages: ResponseHistoryItem[] = history.messages;
    let isCanceled: boolean;
    messages.forEach(message => {
      if (message.status === 'canceled') {
        isCanceled = true;
      }
    });
    let incomingFilteredMessages: ResponseHistoryItem[];
    if (payload.type === 'incoming') {
      incomingFilteredMessages = messages.map(obj => {
          if (obj.scheduledResponseDate) {
            return {...obj, status: 'canceled'};
          } else {
            return {...obj};
          }
      });
      messages = [ ...incomingFilteredMessages, payload ];
    } else if (payload.type === 'outgoing' && !payload.scheduledResponseDate && !isCanceled) {
      const scheduledMessages = messages.filter((message) => message.scheduledResponseDate);
      const otherMessages = messages.filter((message) => !message.scheduledResponseDate);
      messages = [ ...otherMessages, payload, ...scheduledMessages ];
    } else {
      messages = [...messages, payload];
    }

    patchState({
      responseHistory: {
        messages: [ ...messages],
        totalCount: history.totalCount,
      },
    });
  }

  @Action(UpdateResponseHistoryMessage)
  updateResponseHistoryMessage(
    { getState, patchState }: StateContext<ResponsesStateModel>,
    { payload, responseId }: UpdateResponseHistoryMessage,
  ) {
    const history = getState().responseHistory;
    const responseOriginal = getState().responseOriginal;

    if (responseOriginal && responseOriginal.id !== responseId) return;

    const messages = history.messages.map(obj => ({ ...obj }));

    const messageIndex = messages.findIndex(msg => (msg.id === payload.id && msg.type === payload.type));

    messages[messageIndex] = {
      ...messages[messageIndex],
      message: payload.message,
      date: moment(new Date()).format('h:mm A'),
      status: payload.status,
      error: payload.error,
      scheduledResponseDate: payload.scheduledResponseDate,
    };

    patchState({
      responseHistory: {
        messages,
        totalCount: history.totalCount,
      },
    });
  }

  @Action(MoveDownResentedScheduledMessage)
  moveDownResentedScheduledMessage(
    { getState, patchState }: StateContext<ResponsesStateModel>,
    { payload, responseId }: MoveDownResentedScheduledMessage,
  ) {
    const history = getState().responseHistory;
    const responseOriginal = getState().responseOriginal;

    if (responseOriginal && responseOriginal.id !== responseId) return;
    let messages = history.messages.map(obj => ({ ...obj }));
    const messageIndex = messages.findIndex(message => message.id === payload.id);
    messages.splice(messageIndex, 1);
    messages = [...messages, payload];

    patchState({
      responseHistory: {
        messages,
        totalCount: history.totalCount,
      },
    });
  }

  @Action(DeleteResponseHistoryMessage)
  deleteResponseHistoryMessage({ getState, patchState }: StateContext<ResponsesStateModel>, { messageId }: DeleteResponseHistoryMessage) {
    const history = getState().responseHistory;
    const messages = history.messages.map(obj => ({ ...obj }));

    const messageIndex = messages.findIndex(message => message.id === messageId);

    messages.splice(messageIndex, 1);

    patchState({
      responseHistory: {
        messages,
        totalCount: history.totalCount,
      },
    });
  }

  @Action(UpdateResponseContactStatus)
  updateResponseContactStatus({ getState, patchState }: StateContext<ResponsesStateModel>, { payload }: UpdateResponseContactStatus) {
    const state = getState();
    const responsesList = state.responsesList?.map(obj => ({ ...obj })) || [];
    const response = responsesList.find((responseItem: ResponseListItem) => responseItem.id === payload.responseId);
    if (response) {
      response.subscribed = payload.subscribed;

      if (response.subscribed === 'ACTIVE' && response.responseStatuses.includes('Opted out')) {
        const responseStatuses = [...response.responseStatuses];
        const index = responseStatuses.findIndex(status => status === 'Opted out');
        responseStatuses.splice(index, 1);
        response.responseStatuses = responseStatuses;
      }

      if (response.subscribed === 'UNSUBSCRIBED' && !response.responseStatuses.includes('Opted out')) {
        response.responseStatuses = [...response.responseStatuses, 'Opted out'];
      }

      if (response.subscribed === 'UNSUBSCRIBED' && response.responseStatuses.includes('Opted out')) {
        response.status = 'Resolved';
        response.isRead = 'true';
        response.responseStatuses = ['Resolved', 'Opted out'];
      }
    }

    patchState({
      responsesList: [ ...responsesList ],
    });

    const responseOriginal = { ...state.responseOriginal };
    if (payload.responseId === responseOriginal.id) {
      if (responseOriginal.contactStatus !== payload.subscribed) {
        responseOriginal.contactStatus = payload.subscribed;

        patchState({
          responseOriginal: { ...responseOriginal },
        });
      }
    }
  }
}
