import { Component, Input, Output, EventEmitter } from '@angular/core';

import { AccordionTitleTextColor, AccordionTitleBgColor } from './accordion.model';

@Component({
  selector: 'k-accordion-item',
  templateUrl: './accordion-item.component.html',
  styleUrls: ['./accordion-item.component.scss'],
})
export class AccordionItemComponent {
  @Input() opened: boolean = true;
  @Input() noBorder: boolean = false;
  @Input() isCheckoutInstruction: boolean = false;
  @Input() name: string;
  @Input() checked: boolean = false;
  @Input() color: AccordionTitleTextColor = 'gray';
  @Input() bgcolor: AccordionTitleBgColor = 'gray';
  @Input() radius: boolean = false;
  @Input() isWarning: boolean = false;
  @Input() iconName: string;

  @Output() toggle: EventEmitter<boolean> = new EventEmitter<boolean>();

  public onToggle() {
    this.toggle.emit(this.opened);
  }
}
