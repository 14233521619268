import { AccordionComponent }      from './base/accordion/accordion.component';
import { AccordionItemComponent }  from './base/accordion/accordion-item/accordion-item.component';
import { AlertComponent }          from './base/alert/alert.component';
import { BackButtonComponent }     from './base/back-button/back-button.component';
import { BaronComponent }          from './base/baron/baron.component';
import { ButtonComponent }         from './base/button/button.component';
import { CardComponent }           from './base/card/card.component';
import { CheckboxComponent }       from './base/checkbox/checkbox.component';
import { ColorpickerComponent }    from './base/colorpicker/colorpicker.component';
import { ConfirmDialogComponent }  from './base/confirm-dialog/confirm-dialog.component';
import { WelcomeDialogComponent }  from './base/welcome-dialog/welcome-dialog.component';
import { ContentLoaderComponent }  from './base/loaders/content-loader/content-loader.component';
import { DropdownComponent }       from './base/dropdown/dropdown.component';
import { HintComponent }           from './base/hint/hint.component';
import { IconComponent }           from './base/icon/icon.component';
import { InputComponent }          from './base/input/input.component';
import { LabelComponent }          from './base/label/label.component';
import { ModalDialogComponent }    from './base/modal-dialog/modal-dialog.component';
import { NoImageComponent }        from './base/no-image/no-image.component';
import { RadioComponent }          from './base/radio/radio.component';
import { SliderComponent }         from './base/slider/slider.component';
import { SpinnerComponent }        from './base/spinner/spinner.component';
import { SwitcherComponent }       from './base/switcher/switcher.component';
import { TableComponent }          from './base/table/table.component';
import { TabsComponent }           from './base/tabs/tabs.component';
import { TagComponent }            from './base/tag/tag.component';
import { TextareaComponent }       from './base/textarea/textarea.component';
import { TextStylesComponent }     from './base/text-styles/text-styles.component';
import { TableLoaderComponent }    from './base/loaders/table-loader/table-loader.component';
import { CardLoaderComponent }     from './base/loaders/card-loader/card-loader.component';
import { DroplistComponent }       from './base/droplist/droplist.component';
import { StepperComponent }        from './base/stepper/stepper.component';
import { SpinnerRoundedComponent } from './base/loaders/spinner-rounded/spinner-rounded.component';
import { EditorDialogComponent }   from './base/editor-dialog/editor-dialog.component';
import { DateTimePickerComponent } from './base/date-time-picker/date-time-picker.component';
import { DatePickerComponent }     from './base/date-picker/date-picker.component';
import { DateRangeComponent }      from './base/date-range/date-range.component';
import { NotifierComponent }       from './base/notifier/notifier.component';

import { EditorComponent }                from './editor/editor.component';
import { EditorPreviewComponent }         from './editor/editor-preview/editor-preview.component';
import { FilePickerComponent }            from './editor/file-picker/file-picker.component';
import { MessageRecommendationComponent } from './message-builder/message-recommendation/message-recommendation.component';

import { FlowFilePickerComponent }        from './message-builder/flow-file-picker/flow-file-picker.component';
import { MessageBuilderComponent }        from './message-builder/message-builder.component';
import { TextAreaComponent }              from './message-builder/text-area/text-area.component';
import { MessagePreviewComponent }        from './message-builder/message-preview/message-preview.component';
import { FlowAddDiscountDialogComponent } from './message-builder/flow-add-discount-dialog/flow-add-discount-dialog.component';
import { ShortCodesComponent }            from './message-builder/short-codes/short-codes.component';
import { MsgStrengthComponent }           from './message-builder/msg-strength/msg-strength.component';

import { MessageBuilderDisabledComponent } from './message-builder-disabled/message-builder-disabled.component';

import { RulesListComponent }      from './rules/rules-list/rules-list.component';
import { RuleComponent }           from './rules/rules-list/rule/rule.component';
import { UpsellComponent }         from './base/upsell/upsell.component';
import { UpsellItemComponent }     from './base/upsell/upsell-item/upsell-item.component';

import { CreateKeywordDialogComponent } from './keywords/create-keyword-dialog/create-keyword-dialog.component';
import { UpdateKeywordDialogComponent } from './keywords/update-keyword-dialog/update-keyword-dialog.component';

import { RecipientsDropdownComponent } from './recipients-dropdown/recipients-dropdown.component';

import { SubscriptionViewEditorComponent } from './subscription-view-editor/subscription-view-editor.component';

export const components = [
  AccordionComponent,
  AccordionItemComponent,
  AlertComponent,
  BackButtonComponent,
  BaronComponent,
  ButtonComponent,
  CardComponent,
  CheckboxComponent,
  ColorpickerComponent,
  ConfirmDialogComponent,
  WelcomeDialogComponent,
  ContentLoaderComponent,
  DropdownComponent,
  EditorComponent,
  EditorPreviewComponent,
  FilePickerComponent,
  HintComponent,
  IconComponent,
  InputComponent,
  LabelComponent,
  MessageRecommendationComponent,
  ModalDialogComponent,
  NoImageComponent,
  RadioComponent,
  SliderComponent,
  SpinnerComponent,
  SwitcherComponent,
  TableComponent,
  TabsComponent,
  TagComponent,
  TextareaComponent,
  TextStylesComponent,
  DroplistComponent,
  MessagePreviewComponent,
  FlowFilePickerComponent,
  MessageBuilderComponent,
  TextAreaComponent,
  ShortCodesComponent,
  FlowAddDiscountDialogComponent,
  MsgStrengthComponent,
  CreateKeywordDialogComponent,
  UpdateKeywordDialogComponent,
  EditorDialogComponent,
  RecipientsDropdownComponent,
  DateTimePickerComponent,
  StepperComponent,
  SubscriptionViewEditorComponent,
  TableLoaderComponent,
  CardLoaderComponent,
  SpinnerRoundedComponent,
  DatePickerComponent,
  DateRangeComponent,
  NotifierComponent,
  RulesListComponent,
  RuleComponent,
  UpsellComponent,
  UpsellItemComponent,
  MessageBuilderDisabledComponent,
];
