import { Pipe, PipeTransform } from '@angular/core';

import { EnteringType }                                from '@shared/components/base/input/input.model';
import { RULE_LINE_ITEMS_OPTIONS, RULE_TYPES_OPTIONS } from '@shared/components/rules/texts';


@Pipe({
  name: 'numberOnlyStatus',
})
export class NumberOnlyStatusPipe implements PipeTransform {

  transform(type: string, parameter: string): EnteringType {
    switch (true) {
      case [
          RULE_TYPES_OPTIONS.MONEY_SPENT_NAME,
          RULE_TYPES_OPTIONS.AVERAGE_MONEY_AMOUNT_NAME,
          RULE_TYPES_OPTIONS.TOTAL_DISCOUNTS_NAME,
        ].includes(type)
        || RULE_TYPES_OPTIONS.LINE_ITEMS_NAME === type
          && [
            RULE_LINE_ITEMS_OPTIONS.PRICE_NAME,
            RULE_LINE_ITEMS_OPTIONS.DISCOUNT_NAME,
          ].includes(parameter):
        return 'numberOneDotOnly';
      case RULE_TYPES_OPTIONS.NUMBER_OF_ORDERS_NAME === type
        || RULE_TYPES_OPTIONS.LINE_ITEMS_NAME === type
          && [
            RULE_LINE_ITEMS_OPTIONS.QUANTITY_NAME,
            RULE_LINE_ITEMS_OPTIONS.PRODUCT_ID_NAME,
            RULE_LINE_ITEMS_OPTIONS.VARIANT_ID_NAME,
          ].includes(parameter):
        return 'numberOnly';
      default:
        return 'any';
    }
  }

}
