import { PopupEditItem, PopupListItem } from '@popups/models';
import { TableSortData }                from '@shared/models';

export class SetPopupsData {
  static readonly type = '[Popups List] SetPopupsData';

  constructor(public payload: any) { }
}

export class SetPopupsList {
  static readonly type = '[Popups List] SetPopupsList';

  constructor(public payload: PopupListItem[]) { }
}

export class DeletePopupListItem {
  static readonly type = '[Popup List] DeletePopupListItem';

  constructor(public index: number) { }
}

export class UpdatePopupStatuses {
  static readonly type = '[Popup List] UpdatePopupStatuses';

  constructor(public statusUpdates: any[]) { }
}

export class ClearPopupList {
  static readonly type = '[Popups List] ClearPopupsList';

  constructor() { }
}

export class SetPopupOriginal {
  static readonly type = '[Popup Form] SetPopupOriginal';

  constructor(public payload: PopupEditItem) { }
}

export class UpdatePopupFormImage {
  static readonly type = '[Popup Form] UpdatePopupFormImage';

  constructor(public payload: any) { }
}

export class SetPopupFlow {
  static readonly type = '[Popup Form] SetPopupFlow';

  constructor(public payload: any) { }
}

export class ClearPopupFlow {
  static readonly type = '[Popup Form] ClearPopupFlow';

  constructor() { }
}

export class ClearPopupOriginal {
  static readonly type = '[Popup Form] ClearPopupOriginal';

  constructor() { }
}

export class ClearPopup {
  static readonly type = '[Popup Form] ClearPopup';

  constructor() { }
}

export class SetPopupSortingData {
  static readonly type = '[Sorting] SetPopupSortingData';

  constructor(public sorting: TableSortData) { }
}
