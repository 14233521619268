import { NotificationAlertsComponent } from './notification-alerts/notification-alerts.component';
import { NotificationToastComponent }  from './notification-toast/notification-toast.component';

export {
  NotificationAlertsComponent,
  NotificationToastComponent,
};

export const components = [
  NotificationAlertsComponent,
  NotificationToastComponent,
];
