import { Injectable } from '@angular/core';

import { Observable }                     from 'rxjs';
import { tap }                            from 'rxjs/operators';
import { Store }                          from '@ngxs/store';
import { SetRulePopups, SetRuleKeywords } from '@store/actions';

import { ApiService, HelperService }                  from '@core/services';
import { DropdownStringData, KeywordItem, PopupItem } from '@shared/models';

import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
}                                                      from '@angular/forms';
import { RULE_LINE_ITEMS_OPTIONS, RULE_TYPES_OPTIONS } from '@shared/components/rules/texts';

@Injectable()
export class RulesService {

  private moneyValidators = [
    Validators.min(0),
    Validators.max(999999999.99),
  ];

  private numberValidators = [
    Validators.min(0),
    Validators.max(999),
    Validators.pattern(/^[0-9]+$/),
  ];

  private maxStringValidators = [
    Validators.maxLength(255),
  ];

  private requiredValidator = [
    Validators.required,
  ];

  private ruleTypeToValidators = {
    [RULE_TYPES_OPTIONS.MONEY_SPENT_VALUE]: [...this.moneyValidators, ...this.requiredValidator],
    [RULE_TYPES_OPTIONS.AVERAGE_MONEY_AMOUNT_VALUE]: [...this.moneyValidators, ...this.requiredValidator],
    [RULE_TYPES_OPTIONS.TOTAL_DISCOUNTS_VALUE]: [...this.moneyValidators, ...this.requiredValidator],
    [RULE_TYPES_OPTIONS.NUMBER_OF_ORDERS_VALUE]: [...this.requiredValidator, ...this.numberValidators],
    [RULE_TYPES_OPTIONS.LINE_ITEMS_VALUE]: [...this.requiredValidator],
  };

  private ruleSubTypeToValidators = {
    [RULE_LINE_ITEMS_OPTIONS.PRODUCT_TITLE_VALUE]: [...this.requiredValidator, ...this.maxStringValidators],
    required: [...this.requiredValidator],
  };

  constructor(
    private store: Store,
    private _apiService: ApiService,
    private _helperService: HelperService) { }

  public getKeywords(): Observable<KeywordItem[]> {
    return this._apiService.get('conditions/keywords')
      .pipe(
        tap(data => {
          this.store.dispatch(new SetRuleKeywords(data));
        }),
      );
  }

  public getPopups(): Observable<PopupItem[]> {
    return this._apiService.get('conditions/popups')
      .pipe(
        tap(data => {
          this.store.dispatch(new SetRulePopups(data));
        }),
      );
  }

  public transformInputOptions(options, sourceType): DropdownStringData[] {
    return options.map((item) => {
      if (item.deleted) {
        return { name: `${item.name} (Deleted)`, value: item.id, error: `${sourceType} is deleted` };
      } else if (item.disabled) {
        return { name: `${item.name} (Disabled)`, value: item.id, error: `${sourceType} is disabled` };
      } else {
        return { name: item.name, value: item.id };
      }
    });
  }

  public createFormControl(form: FormGroup, controlName: string,
                           options: {value?: any, validators?: ValidatorFn[] | null} = {}): AbstractControl {
      const control = new FormControl(options.value || '', options.validators);
      form.addControl(controlName, control);
      return form.get(controlName);
  }

  public createRequiredControl(form: FormGroup, controlName: string, value?: any): AbstractControl {
    return this.createFormControl(form, controlName, {value, validators: [Validators.required]});
  }

  public createRuleValueControl(formArray: FormArray, type: string, subType: string,
                                options: {value?: any, validators?: ValidatorFn[] | null} = {}): void {
    let validators = [];
    if (this.ruleTypeToValidators[type]) {
      validators = [ ...validators, ...this.ruleTypeToValidators[type] ];
    }
    if (this.ruleSubTypeToValidators[subType]) {
      validators = [ ...validators, ...this.ruleSubTypeToValidators[subType] ];
    }
    if (options.validators) {
      validators = [ ...validators, ...options.validators ];
    }
    formArray.push(new FormControl(options.value || '', validators));
  }

  public createSecondRuleValueControl(formArray: FormArray, type: string, subType: string, value?: string): void {
    this.createRuleValueControl(formArray, type, subType,
      { value: value || '', validators: [this._greaterLessSecondValueValidator(formArray)]});
  }

  public createRangeValueControl(formArray: FormArray, value?: string): void {
    formArray.push(new FormControl(value || '', [Validators.required]));
  }

  public createRuleValuesControls(form: FormGroup, type: string, values: string[] = [], subType: string): FormArray {
    const formArray = new FormArray([]);
    this.createRuleValueControl(formArray, type, subType,
      { value: values[0], validators: [this._greaterLessFirstValueValidator(formArray)]});
    if (values[1]) {
      this.createSecondRuleValueControl(formArray, type, subType, values[1]);
    }
    form.addControl('values', formArray);
    return form.get('values') as FormArray;
  }

  public createRangeValuesControls(form: FormGroup, values: string[] = []): FormArray {
    const formArray = new FormArray([]);
    this.createRangeValueControl(formArray, values[0]);
    this.createRangeValueControl(formArray, values[1]);
    form.addControl('range', formArray);
    return form.get('range') as FormArray;
  }

  public getProductTitleValidators(subType: string): ValidatorFn[] {
    return this.ruleSubTypeToValidators[subType];
  }

  private _greaterLessFirstValueValidator(formArray: FormArray): ValidatorFn {
    return (control: AbstractControl) => {
      return this._greaterLessValueValidator(
        control,
        formArray.controls[1],
        (formArray.controls[1]?.value && +control.value >= +formArray.controls[1].value),
      );
    };
  }

  private _greaterLessSecondValueValidator(formArray: FormArray): ValidatorFn {
    return (control: AbstractControl) => {
      return this._greaterLessValueValidator(
        control,
        formArray.controls[0],
        (formArray.controls[0].value && +formArray.controls[0].value >= +control.value),
      );
    };
  }

  private _greaterLessValueValidator(
    control: AbstractControl,
    siblingControl: AbstractControl,
    condition: boolean,
  ): ValidationErrors | null {
    if (condition) {
      control.markAsTouched();
      siblingControl?.setErrors({greaterThanLess: true});
      return { greaterLessValueInvalid: true };
    }
    const errors = siblingControl?.errors;
    if (errors?.greaterThanLess) {
      delete errors.greaterThanLess;
    }
    if (errors?.greaterLessValueInvalid) {
      delete errors.greaterLessValueInvalid;
    }
    if (errors && Object.keys(errors).length) {
      siblingControl.setErrors({ ...errors });
    } else {
      siblingControl?.setErrors(null);
    }
    return null;
  }
}
