import {
  AfterViewInit,
  Component, ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output, Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Store }                                 from '@ngxs/store';
import { SetCreateFromScratchSelectedTriggerId } from '@store/actions';

import { TabItemModel } from '@flows/models';
import { TabsType } from '@shared/components/base/tabs/tabs.model';

@Component({
  selector: 'k-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() type: TabsType = 'bottom';
  @Input() tabs: TabItemModel[];
  @Input() selected: string;
  @Input() default: string;

  @Output() tabSelected: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('tab') tab: ElementRef;
  @ViewChild('item') item: ElementRef;
  @ViewChild('tabName') tabName: ElementRef;

  public tabId: string;

  constructor(private store: Store,
              private renderer: Renderer2) { }

  ngOnInit() {
    this.onSelectTab(this.default || this.selected);
  }

  ngAfterViewInit() {
    this.renderer.addClass(this.tab.nativeElement, `k-tab--type-${this.type}`);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.selected) {
      this.tabId = changes.selected.currentValue;
    }
  }

  public onSelectTab(id: string): void {
    this.tabSelected.emit(id);
    if (!this.selected) {
      this.tabId = id;
    }
    this.store.dispatch(new SetCreateFromScratchSelectedTriggerId(id));
  }

  public get isTypeBottom(): boolean {
    return this.type === 'bottom';
  }

  public get isTypeCircle(): boolean {
    return this.type === 'circle';
  }
}
