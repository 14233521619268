import { Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { ReadFile, ReadMode }                            from 'ngx-file-helpers';
import { Store }                                         from '@ngxs/store';

import { ApiService }        from '@core/services';
import { SetSavingProgress } from '@store/actions';

@Component({
  selector: 'k-file-picker',
  templateUrl: './file-picker.component.html',
  styleUrls: ['./file-picker.component.scss'],
})
export class FilePickerComponent implements OnInit {
  @Input() set imageUrl(data) {
    this.image = data;
  }
  @Input() isGifAllowed: boolean = true;
  @Input() filter: (file: File) => boolean;
  @Input() imageName: string;
  @Output() filePicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() fileDeleted: EventEmitter<any> = new EventEmitter<any>();

  public image: string;
  public readMode = ReadMode.dataURL;
  public loading: boolean = false;

  constructor(
    private store: Store,
    private _apiService: ApiService,
  ) { }

  ngOnInit() { }

  public onFilePicked(file: ReadFile): void {
    this._setLoading(true);

    const formData = new FormData();

    formData.set('image', file.underlyingFile);

    this._apiService.post('images', formData)
      .subscribe(
        (data) => {
          this.image = data.url;
          this.filePicked.emit(data);
          this._setLoading(false);
        },
        () => {
          this._setLoading(false);
        },
      );
  }

  public deleteFile(): void {
    this.fileDeleted.emit();
  }

  private _setLoading(state: boolean): void {
    this.loading = state;
    this.store.dispatch(new SetSavingProgress(state));
  }
}
