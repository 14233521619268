import { Injectable } from '@angular/core';
import { Meta }       from '@angular/platform-browser';

import createApp from '@shopify/app-bridge';
import {
  History,
  Loading,
  Redirect,
  ResourcePicker,
  TitleBar,
  Button,
} from '@shopify/app-bridge/actions';

@Injectable()
export class ShopifyService {
  private readonly apiKey: string;
  private loading: Loading.Loading;
  private shopifyHistory: History.History;
  private shopifyProductPicker: ResourcePicker.ResourcePicker;
  private shopifyCollectionPicker: ResourcePicker.ResourcePicker;
  private shopifyRedirect: Redirect.Redirect;
  private shopifyTitleBar: TitleBar.TitleBar;
  private shopifyButton: Button.Button;

  constructor(meta: Meta) {
    const apiKeyMetaTag = meta.getTag('name="x-shopify-api-key"');
    if (apiKeyMetaTag) {
      this.apiKey = apiKeyMetaTag.content;
    }
  }

  public get history(): History.History {
    return this.shopifyHistory;
  }

  public get productPicker(): ResourcePicker.ResourcePicker {
    return this.shopifyProductPicker;
  }

  public get collectionPicker(): ResourcePicker.ResourcePicker {
    return this.shopifyCollectionPicker;
  }

  public get redirect(): Redirect.Redirect {
    return this.shopifyRedirect;
  }

  public get titleBar(): TitleBar.TitleBar {
    return this.shopifyTitleBar;
  }

  public get button(): Button.Button {
    return this.shopifyButton;
  }

  public createApp(host) {
    const app = createApp({
      apiKey: this.apiKey,
      host,
    });

    this.shopifyTitleBar = TitleBar.create(app, {});
    this.shopifyButton = Button.create(app, { label: '' });

    this.shopifyHistory = History.create(app);

    this.loading = Loading.create(app);

    this.shopifyProductPicker = ResourcePicker.create(app, {
      resourceType: ResourcePicker.ResourceType.Product,
      options: {
        //initialQuery: '(inventory_total:>0 OR out_of_stock_somewhere:false) AND published_status:published',
        showVariants: false,
        showHidden: false,
      },
    });

    this.shopifyCollectionPicker = ResourcePicker.create(app, {
      resourceType: ResourcePicker.ResourceType.Collection,
    });

    this.shopifyRedirect = Redirect.create(app);
  }

  public showBar(): void {
    this.loading.dispatch(Loading.Action.START);
  }

  public hideBar(): void {
    this.loading.dispatch(Loading.Action.STOP);
  }
}
