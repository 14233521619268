import { PaymentService }        from './payment.service';
import { UnsavedChangesService } from './unsaved-changes.service';

export {
  PaymentService,
  UnsavedChangesService,
};

export const services = [
  PaymentService,
  UnsavedChangesService,
];
